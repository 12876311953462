// App.js
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useRef, useState, useEffect } from 'react';
import {
  MapContainer,
  TileLayer,
  useMap,
  Popup,
  Marker,
  Polygon,
  Tooltip
} from 'react-leaflet'
// import { MapContainer, Marker, Polygon, Popup, TileLayer, Tooltip, useMap } from 'react-leaflet';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import './GeoLoc.css';
import { useNavigate } from 'react-router-dom';
import 'leaflet.heat';
import 'leaflet.heat/dist/leaflet-heat.js';
import { db, auth } from '../../firebase/Config';
import { collection, endBefore, getDocs } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import {
  Box,
  Button
} from '@mui/material';

const Geocollection = collection(db, "farms")

const customIcon = new L.Icon({
  iconUrl: require('./marker.png'),
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
});

// const App = () => {
//   const farmsRef = collection(db, '/farms')
//   const [farms, loading, error] = useCollectionData(farmsRef)


//   const [markers1, setMarkers1] = useState([]);
//   const bounds = [
//     [14.10051, 122.56002], // Southwest coordinates
//     [14.10051, 123.06002],  // Northeast coordinates
//   ];
//   const [imgUrls, setImgUrls] = useState({});
//   const [locationList, setLocationList] = useState([]);
//   const [isValid, setIsValid] = useState(true);

//   const getStatusColor = (status) => {
//     switch (status) {
//       case 'vegetative':
//         return 'green';
//       case 'flowering':
//         return 'red';
//       case 'fruiting':
//         return 'orange';
//       default:
//         return 'gray'; // Default color for unknown status
//     }
//   };

//   const HeatLayerExample = ({ markers }) => {
//     const map = useMap();

//     useEffect(() => {
//       if (!markers || markers.length === 0) {
//         // Remove any existing heat layer when markers are empty
//         map.eachLayer(layer => {
//           if (layer instanceof L.HeatLayer) {
//             map.removeLayer(layer);
//           }
//         });
//         return;
//       }

//       try {
//         // Remove any existing heat layer before adding new ones
//         map.eachLayer(layer => {
//           if (layer instanceof L.HeatLayer) {
//             map.removeLayer(layer);
//           }
//         });

//         // Group markers by cropStage
//         const groupedMarkers = markers.reduce((acc, marker) => {
//           const { cropStage } = marker;
//           if (!acc[cropStage]) {
//             acc[cropStage] = [];
//           }
//           acc[cropStage].push([marker.position[0], marker.position[1], 90]); // Fixed intensity value
//           return acc;
//         }, {});

//         // Create a heat layer for each group with different gradient
//         Object.keys(groupedMarkers).forEach(cropStage => {
//           const gradientColor = getStatusColor(cropStage.toString().toLowerCase());
//           const gradient = {
//             0.2: 'blue',
//             0.9: gradientColor
//           };

//           L.heatLayer(groupedMarkers[cropStage], { radius: 50, gradient, blur: 10 }).addTo(map);
//         });

//       } catch (error) {
//         console.error('Error in HeatLayerExample:', error);
//       }
//     }, [map, markers]);

//     return null;
//   };

//   useEffect(() => {
//     const getData = async () => {
//       try {
//         const data = await getDocs(Geocollection);
//         const filteredData = data.docs.map(doc => {
//           const { geopoint, title, cropStage } = doc.data();
//           return { geopoint, title, cropStage };
//         });

//         const markers = filteredData.flatMap(({ geopoint, title, cropStage }) => {
//           if (geopoint && Array.isArray(geopoint)) {
//             return geopoint.map(geoPoint => ({
//               cropStage,
//               title,
//               position: [geoPoint.latitude, geoPoint.longitude]
//             }));
//           }
//           if (geopoint) {
//             return [{
//               cropStage,
//               title,
//               position: [geopoint.latitude, geopoint.longitude]
//             }];
//           }
//           return [];
//         });

//         const sortedData = await Promise.all(
//           data.docs.map(async (doc) => {
//             const eventsCollection = collection(doc.ref, "events");
//             const snapshot = await getDocs(eventsCollection);
//             const eventsData = snapshot.docs.map((doc) => {
//               const eventData = doc.data();
//               return {
//                 ...eventData,
//                 date: eventData.start_time.toDate(), // Convert timestamp to date
//                 startDate: new Date(eventData.start_time.toDate().getTime() - 86400000), // Set timestamp to be 1 day before the date
//                 endDate: eventData.end_time.toDate(), // Set timestamp to be the date itself
//               };
//             });
//             return { title: doc.data().title, eventsData };
//           })
//         );

//         const filteredSortedData = sortedData.sort((a, b) => a.title.localeCompare(b.title));

//         console.log("Filtered and Sorted Data:", filteredSortedData);
//         setMarkers(markers);
//         setMarkers1(sortedData);
//         console.log("marker data:", markers1);
//       } catch (err) {
//         console.error("Error fetching data:", err);
//       }
//     };

//     getData();
//   }, []);

//   const [markers, setMarkers] = useState([
//     { title: 'Farm', Info:'Test' , position: { lat: 14.10051, lng: 122.96002 } },


//   ]);




//   const [userLocation, setUserLocation] = useState(null);
//   const mapRef = useRef(null); // Reference to the map instance
//   const ZOOM_LEVEL = 10; // Adjust the desired zoom level

//   const getUserLocation = () => {
//     // ... (existing code)
//   };

//   // const handleMapClick = () => {
//   //   setState({ isPaneOpen: false, selectedMarker: null });
//   // };

//   // const handleMarkerClick = (marker) => {
//   //   setState({ isPaneOpen: true, selectedMarker: marker });
//   //   setSelectedMarker(marker);
//   // };

//   // Custom icon
//   const navigate = useNavigate();
//   // Redirect to the admin page
//   const redirectToAdmin = () => {
//     navigate('/geo'); // Replace '/admin' with your actual admin route
//   };


//   const [selectedMarker, setSelectedMarker] = useState(null);f

//   useEffect(() => {
//     const getData = async () => {
//       try {
//         const data = await getDocs(Geocollection);
//         const filteredData = data.docs.map(doc => {

//           const {geopoint, title  } = doc.data() // Assuming 'Location' is an array of GeoPoints and 'title' is the   marker name
//           return { geopoint, title }; // Return an object with 'title' and 'Location'
//         });

//         const markers = filteredData.flatMap(({ geopoint, title }) => {
//           if (geopoint && Array.isArray(geopoint)) {
//             return geopoint.map(geoPoint => ({
//               title,
//               position: [geoPoint.latitude, geoPoint.longitude]
//             }));
//           }
//           if (geopoint) {
//             return [{
//               title,
//               position: [geopoint.latitude, geopoint.longitude]
//             }];
//           }
//           return [];
//         });

//         setMarkers(markers);


//       } catch (err) {
//         console.error(err);
//       }
//     };
//     getData();
//   }, []);

//   return (
//     <div>
//     {/* {markers.map((Name) => (
//       <SlidingPane
//         key={Name.id}
//         className="some-custom-class"
//         overlayClassName="some-custom-overlay-class"
//         isOpen={selectedMarker !== null && selectedMarker.title === Name.title}
//         title={Name.title}
//         subtitle="Daet, Camarines Norte"
//         onRequestClose={() => setSelectedMarker(null)}
//         from="left"
//         width="100hv"
//       >
//         <div>
//           <p>{Name.Info} </p>
//           <button className="oval-button" onClick={redirectToAdmin}>
//             Go here
//           </button>
//         </div>
//         <br />
//       </SlidingPane>
//     ))} */}
//     <Box xs={12} lg={12} sx={{ backgroundColor: '#f9fafb', padding: 4, borderRadius: 4, height: '100%', overflow: 'auto' }}>

//         <div className={`map-container ${selectedMarker ? 'map-container-open' : ''}`}>
//           <MapContainer
//             center={
//               markers.length > 0 ? markers[markers.length - 1].position : { lng: 0, lat: 0 }
//             }
//             zoom={ZOOM_LEVEL}
//             style={{
//               height: '100vh',
//               width: '100%',
//               position: 'absolute',
//             }}
//             ref={mapRef}
//             maxBounds={bounds}
//             maxBoundsViscosity={1.0}
//           >
//             <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

//             {/* Markers */}
//             {markers.map((marker) => (
//               <Marker
//                 key={marker.id}
//                 position={marker.position}
//                 icon={customIcon}
//               //   eventHandlers={{
//               //     click: () => handleMarkerClick(marker),
//               // }}
//             />
//           ))}

//           {/* Marker for the user's location */}
//           {userLocation && (
//             <Marker key="user" position={userLocation} icon={customIcon} />
//           )}

//           {/* Polygon overlay */}

//           <Polygon positions={polygonCoordsLabo} color="white" fillColor="red" fillOpacity={0.4} weight={2} opacity={0.8} dashArray="5, 10" dashOffset="0"><Tooltip direction="top" offset={[0, -20]}>
//             Labo
//           </Tooltip></Polygon>
//           <Polygon positions={polygonMercedes} color="white" fillColor="blue" fillOpacity={0.4} weight={2} opacity={0.8} dashArray="5, 10" dashOffset="0"><Tooltip direction="top" offset={[0, -20]}>
//             Mercedes
//           </Tooltip></Polygon>
//           <Polygon positions={polygonMercedes1} color="white" fillColor="blue" fillOpacity={0.4} weight={2} opacity={0.8} dashArray="5, 10" dashOffset="0"><Tooltip direction="top" offset={[0, -20]}>
//             Mercedes
//           </Tooltip></Polygon>
//           <Polygon positions={polygonMercedes2} color="white" fillColor="blue" fillOpacity={0.4} weight={2} opacity={0.8} dashArray="5, 10" dashOffset="0"><Tooltip direction="top" offset={[0, -20]}>
//             Mercedes
//           </Tooltip></Polygon>
//           <Polygon positions={polygonMercedes3} color="white" fillColor="blue" fillOpacity={0.4} weight={2} opacity={0.8} dashArray="5, 10" dashOffset="0"><Tooltip direction="top" offset={[0, -20]}>
//             Mercedes
//           </Tooltip></Polygon>

//           <Polygon positions={polygonMercedes5} color="white" fillColor="blue" fillOpacity={0.4} weight={2} opacity={0.8} dashArray="5, 10" dashOffset="0"><Tooltip direction="top" offset={[0, -20]}>
//             Mercedes
//           </Tooltip></Polygon>
//           <Polygon positions={polygonMercedes6} color="white" fillColor="blue" fillOpacity={0.4} weight={2} opacity={0.8} dashArray="5, 10" dashOffset="0"><Tooltip direction="top" offset={[0, -20]}>
//             Mercedes
//           </Tooltip></Polygon>
//           <Polygon positions={polygonMercedes7} color="white" fillColor="blue" fillOpacity={0.4} weight={2} opacity={0.8} dashArray="5, 10" dashOffset="0"><Tooltip direction="top" offset={[0, -20]}>
//             Mercedes
//           </Tooltip></Polygon>
//           <Polygon positions={polygonMercedes8} color="white" fillColor="blue" fillOpacity={0.4} weight={2} opacity={0.8} dashArray="5, 10" dashOffset="0"><Tooltip direction="top" offset={[0, -20]}>
//             Mercedes
//           </Tooltip></Polygon>
//           <Polygon positions={polygonMercedes9} color="white" fillColor="blue" fillOpacity={0.4} weight={2} opacity={0.8} dashArray="5, 10" dashOffset="0"><Tooltip direction="top" offset={[0, -20]}>
//             Mercedes
//           </Tooltip></Polygon>
//           <Polygon positions={polygonMercedes10} color="white" fillColor="blue" fillOpacity={0.4} weight={2} opacity={0.8} dashArray="5, 10" dashOffset="0"><Tooltip direction="top" offset={[0, -20]}>
//             Mercedes
//           </Tooltip></Polygon>
//           <Polygon positions={polygonMercedes11} color="white" fillColor="blue" fillOpacity={0.4} weight={2} opacity={0.8} dashArray="5, 10" dashOffset="0"><Tooltip direction="top" offset={[0, -20]}>
//             Mercedes
//           </Tooltip></Polygon>
//           <Polygon positions={polygonCoordsSTE} color="white" fillColor="yellow" fillOpacity={0.4} weight={2} opacity={0.8} dashArray="5, 10" dashOffset="0"  ><Tooltip direction="top" offset={[0, -20]}>
//             Santa Elena
//           </Tooltip></Polygon>
//           <Polygon positions={polygonCoordsCPL} color="white" fillColor="blue" fillOpacity={0.4} weight={2} opacity={0.8} dashArray="5, 10" dashOffset="0"><Tooltip direction="top" offset={[0, -20]}>
//             Capalonga
//           </Tooltip></Polygon>
//           <Polygon positions={polygonCoordsJpg} color="white" fillColor="brown" fillOpacity={0.4} weight={2} opacity={0.8} dashArray="5, 10" dashOffset="0"><Tooltip direction="top" offset={[0, -20]}>
//             Jose Panganiban
//           </Tooltip></Polygon>
//           <Polygon positions={polygonCoordsPCL} color="white" fillColor="purple" fillOpacity={0.4} weight={2} opacity={0.8} dashArray="5, 10" dashOffset="0"><Tooltip direction="top" offset={[0, -20]}>
//             Paracale
//           </Tooltip></Polygon>
//           <Polygon positions={polygonCoordsPCL2} color="white" fillColor="purple" fillOpacity={0.4} weight={2} opacity={0.8} dashArray="5, 10" dashOffset="0"><Tooltip direction="top" offset={[0, -20]}>
//             Paracale
//           </Tooltip></Polygon>
//           <Polygon positions={polygonCoordsVIN} color="white" fillColor="yellow" fillOpacity={0.4} weight={2} opacity={0.8} dashArray="5, 10" dashOffset="0"><Tooltip direction="top" offset={[0, -20]}>
//             Vinzons
//           </Tooltip></Polygon>
//           <Polygon positions={polygonCoordsVIN2} color="white" fillColor="yellow" fillOpacity={0.4} weight={2} opacity={0.8} dashArray="5, 10" dashOffset="0"><Tooltip direction="top" offset={[0, -20]}>
//             Vinzons
//           </Tooltip></Polygon>
//           <Polygon positions={polygonCoordsTAL} color="white" fillColor="brown" fillOpacity={0.4} weight={2} opacity={0.8} dashArray="5, 10" dashOffset="0"><Tooltip direction="top" offset={[0, -20]}>
//             Talisay
//           </Tooltip></Polygon>
//           <Polygon positions={polygonCoordsTAL2} color="white" fillColor="brown" fillOpacity={0.4} weight={2} opacity={0.8} dashArray="5, 10" dashOffset="0"><Tooltip direction="top" offset={[0, -20]}>
//             Talisay
//           </Tooltip></Polygon>
//           <Polygon positions={polygonCoordsDaet} color="white" fillColor="red" fillOpacity={0.4} weight={2} opacity={0.8} dashArray="5, 10" dashOffset="0"><Tooltip direction="top" offset={[0, -20]}>
//             Daet
//           </Tooltip></Polygon>
//           <Polygon positions={polygonCoordsSLR} color="white" fillColor="yellow" fillOpacity={0.4} weight={2} opacity={0.8} dashArray="5, 10" dashOffset="0"><Tooltip direction="top" offset={[0, -20]}>
//             San Lorenzo Ruiz
//           </Tooltip></Polygon>
//           <Polygon positions={polygonCoordsBAS} color="white" fillColor="purple" fillOpacity={0.4} weight={2} opacity={0.8} dashArray="5, 10" dashOffset="0"><Tooltip direction="top" offset={[0, -20]}>
//             Basud
//           </Tooltip></Polygon>
//           <Polygon positions={polygonSVE} color="white" fillColor="purple" fillOpacity={0.4} weight={2} opacity={0.8} dashArray="5, 10" dashOffset="0"><Tooltip direction="top" offset={[0, -20]}>
//             San Vicente
//           </Tooltip></Polygon>
//           <HeatLayerExample markers={markers} />
//         </MapContainer>
//       </div>
//       </Box>

//     </div>
//   );
// };

const MyPolygon = (props) => {
  const map = useMap();

  const [selectedPolygon, setSelectedPolygon] = useState(null);

  const handleClick = (e) => {
    let polygonName = e.target.options.name;
    map.fitBounds(e.target.getBounds());
    setSelectedPolygon(polygonName === selectedPolygon ? null : polygonName);
  };
  const handleMouseOver = (e) => {
    e.target.setStyle({
      weight: 3,
      color: "#666",
      dashArray: "3",
      fillOpacity: 0.4,
    });
  };
  const handleMouseOut = (e) => {
    e.target.setStyle({
      color: "transparent",
      weight: 2,
      fillOpacity: 0.2,
      opacity: 0.8,
      dashArray: "5, 10",
      dashOffset: "0",
    });
  };
  return (
    <Polygon
      {...props}
      color="transparent"
      name={props.name}
      eventHandlers={{
        mouseover: handleMouseOver,
        mouseout: handleMouseOut,
        click: (e) => handleClick(e, props.name),
      }}
    ></Polygon>
  )
}
const App = () => {
  const farmsColl = collection(db, '/farms')
  const [farms] = useCollectionData(farmsColl)

  const initPosition = [14.153655055051429, 122.7702990538368];

  console.log("farmms", farms)

  const apiKey = 'e30a4cb23a6a41028fcbd7df077cde27'
  const custIcon = `https://api.geoapify.com/v1/icon/?type=material&color=%23f6a30b&size=small&icon=grass&iconSize=small&textSize=small&strokeColor=%23f6a30b&noWhiteCircle&apiKey=${apiKey}`
  const customIcon = L.icon({
    iconUrl: custIcon,
    iconSize: [28, 40],
    // iconAnchor: [0, 0],
    popupAnchor: [0, -76],
    shadowSize: [68, 95],
    // shadowAnchor: [0, 0]
  });
  return (
    <Box xs={12} lg={12} sx={{ borderRadius: 4, }}>
      <MapContainer
        center={initPosition}
        zoom={10}
        maxZoom={20}
        scrollWheelZoom={false}
        style={{ height: '700px' }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {
          farms?.map((farm, index) => {
            if (!farm.geopoint) return;

            return (
              <Marker
                key={farm.id}
                icon={customIcon}
                position={[farm.geopoint.latitude, farm.geopoint.longitude]}
              >
                <Popup>
                  {farm.title}
                </Popup>
              </Marker>
            )
          })
        }
        <MyPolygon
          positions={polygonCoordsLabo}
          color="transparent"
          fillColor="green"
          dashArray="5, 10"
          dashOffset="0"
          name="Labo"
        />
        <MyPolygon
          positions={polygonCoordsDaet}
          color="transparent"
          fillColor="red"
          dashArray="5, 10"
          weight={2}
          dashOffset="0"
          name="Daet"
        />
        <MyPolygon
          positions={polygonMer}
          color="transparent"
          fillColor="blue"
          dashArray="5, 10"
          weight={2}
          dashOffset="0"
          name="Mercedes"
        />
        <MyPolygon
          positions={polygonSVE}
          color="transparent"
          fillColor="aquamarine"
          dashArray="5, 10"
          weight={2}
          dashOffset="0"
          name="San Vicente"
        />
        <MyPolygon
          positions={polygonCoordsSLR}
          color="transparent"
          fillColor="yellow"
          dashArray="5, 10"
          weight={2}
          dashOffset="0"
          name="San Lorenzo Ruiz"
        />
        <MyPolygon
          positions={polygonCoordsSTE}
          color="transparent"
          fillColor="pink"
          dashArray="5, 10"
          weight={2}
          dashOffset="0"
          name="Santa Elena"
        />
        <MyPolygon
          positions={polygonCoordsCPL}
          color="transparent"
          fillColor="aquamarine"
          dashArray="5, 10"
          weight={2}
          dashOffset="0"
          name="Capalonga"
        />
        <MyPolygon
          positions={polygonCoordsJpg}
          color="transparent"
          fillColor="violet"
          dashArray="5, 10"
          weight={2}
          dashOffset="0"
          name="Jose Panganiban"
        />
        <MyPolygon
          positions={polygonVinzons}
          color="transparent"
          fillColor="blue"
          dashArray="5, 10"
          weight={2}
          dashOffset="0"
          name="Vinzons"
        />
        <MyPolygon
          positions={polygonParacale}
          color="transparent"
          fillColor="yellow"
          dashArray="5, 10"
          weight={2}
          dashOffset="0"
          name="Paracale"
        />
        <MyPolygon
          positions={polygonTalisay}
          color="transparent"
          fillColor="orange"
          dashArray="5, 10"
          weight={2}
          dashOffset="0"
          name="Talisay"
        />
        <MyPolygon
          positions={polygonCoordsBAS}
          color="transparent"
          fillColor="green"
          dashArray="5, 10"
          weight={2}
          dashOffset="0"
          name="Basud"
        />


      </MapContainer>
    </Box>
  )

}

const polygonSVE = [[14.114117286000067, 122.90079259200002],
[14.105946471000038, 122.89749161600002],
[14.103500133000068, 122.89660706400002],
[14.09892553700007, 122.89501826900005],
[14.091251627000076, 122.89233435500012],
[14.08869478500003, 122.89384133700003],
[14.085902951000037, 122.89635831700002],
[14.083724550000056, 122.89583847500012],
[14.083505934000073, 122.89579575000005],
[14.083116382000071, 122.89586172200006],
[14.082927600000062, 122.89576354500002],
[14.082640524000055, 122.89573830000006],
[14.082417798000051, 122.89584620000005],
[14.082154439000021, 122.89640017300007],
[14.082024454000077, 122.8965341500001],
[14.081553274000044, 122.89671256600002],
[14.081404822000025, 122.89679665000006],
[14.081204435000075, 122.8968080200001],
[14.080676741000048, 122.89670623200004],
[14.080386873000066, 122.89654019800003],
[14.080069270000024, 122.89661535800008],
[14.07996853700007, 122.89643693300002],
[14.079876650000074, 122.89607281000008],
[14.079733221000026, 122.89584535600011],
[14.079463555000075, 122.89558309600011],
[14.079329002000065, 122.89551623000011],
[14.079142773000058, 122.89552409800001],
[14.079042534000052, 122.89563239200004],
[14.078988331000062, 122.89602416200012],
[14.078887791000056, 122.896292205],
[14.078646395000021, 122.896640466],
[14.078358398000034, 122.89669709200007],
[14.078263595000067, 122.89653515500004],
[14.078240867000034, 122.89588657600007],
[14.078473039000073, 122.89494528000012],
[14.078471420000028, 122.89459952100003],
[14.078405198000041, 122.89434106500005],
[14.078179157000022, 122.89378081400002],
[14.078019823000034, 122.89364259900003],
[14.077773673000024, 122.8935896590001],
[14.077451016000055, 122.89361613500012],
[14.076866998000071, 122.89375287100006],
[14.076577575000044, 122.89388659500003],
[14.076390098000047, 122.89410261000012],
[14.076246555000068, 122.894136204],
[14.076110223000057, 122.89378565600009],
[14.076001919000078, 122.89292937900007],
[14.076043673000072, 122.89251853600001],
[14.07620128800005, 122.89158946000009],
[14.075916109000048, 122.89135466300002],
[14.075116295000043, 122.89143321900008],
[14.074745130000053, 122.891560776],
[14.07439075600007, 122.89210887100012],
[14.073858305000044, 122.89238724800009],
[14.073586381000041, 122.8923460640001],
[14.073322765000057, 122.89218172100004],
[14.072881702000075, 122.89218152],
[14.072475638000071, 122.89230888100008],
[14.072033513000065, 122.8925277190001],
[14.071696623000037, 122.89274700800001],
[14.071377779000045, 122.89283883500002],
[14.071025076000069, 122.89276629300002],
[14.07076070100004, 122.89256646700005],
[14.070567180000069, 122.89231158300004],
[14.070161516000042, 122.89211234900006],
[14.06986196500003, 122.89187617400012],
[14.069702900000038, 122.89133696700003],
[14.069527007000033, 122.89112155900011],
[14.069316024000045, 122.89128260400003],
[14.068946684000025, 122.89126414400005],
[14.068822965000038, 122.89153361400008],
[14.068717973000048, 122.89206186500007],
[14.068349148000038, 122.89217156000007],
[14.067549663000023, 122.89182288600011],
[14.06689782500007, 122.89135236200002],
[14.06600099800005, 122.890737951],
[14.065456631000075, 122.89077481000004],
[14.065088124000056, 122.89099215700003],
[14.064772486000038, 122.89113763000012],
[14.06457985700007, 122.89092147500003],
[14.065000472000065, 122.89019718400004],
[14.065281023000068, 122.8899806500001],
[14.065368455000055, 122.8896932560001],
[14.065105678000066, 122.8895298010001],
[14.064877805000037, 122.88943853700005],
[14.064632390000043, 122.889185655],
[14.064579859000048, 122.889005343],
[14.064422204000039, 122.88877099700005],
[14.06408927800004, 122.88884358400003],
[14.06368802000003, 122.8890110100001],
[14.063426099000026, 122.88874170400004],
[14.063459346000059, 122.88801509900009],
[14.06388013700007, 122.88742113800004],
[14.063881007000077, 122.88690193600007],
[14.063496625000028, 122.88666933900004],
[14.063145674000054, 122.88679192500001],
[14.062608191000038, 122.88672130000009],
[14.061873586000047, 122.88649429600002],
[14.061312310000062, 122.88606684600006],
[14.061117336000052, 122.88538888200003],
[14.060995693000052, 122.88473698700011],
[14.061239201000035, 122.88411076500006],
[14.06167692400004, 122.88321013300003],
[14.061603619000039, 122.88226176300009],
[14.06194376600007, 122.8822113870001],
[14.061943302000032, 122.8816385240001],
[14.061651871000038, 122.8815395040001],
[14.061603181000066, 122.88121630600006],
[14.061943301000042, 122.88106309600005],
[14.062259363000067, 122.88108579700008],
[14.062259384000072, 122.88066014600008],
[14.062040550000063, 122.8804616430001],
[14.062016253000024, 122.88023657400004],
[14.062137827000072, 122.87980995300006],
[14.062623638000048, 122.879508944],
[14.062889473000041, 122.87918914600004],
[14.062647569000035, 122.87901868000006],
[14.06230699100007, 122.87844351900003],
[14.061894589000076, 122.87851843700003],
[14.061700092000024, 122.87799404500004],
[14.061772460000043, 122.87752030800004],
[14.06186903500003, 122.87714677600002],
[14.061602548000053, 122.87699742300003],
[14.06145727300003, 122.87702227600005],
[14.06087670200003, 122.875805119],
[14.06046617000004, 122.87540853300004],
[14.05964509100005, 122.87513550000006],
[14.058944502000031, 122.8751601890001],
[14.057595348000063, 122.87488606600004],
[14.057038584000054, 122.87470913200002],
[14.056338280000034, 122.87403584000003],
[14.055849309000052, 122.87380457900008],
[14.055496118000065, 122.87426368600006],
[14.054967678000025, 122.87431775800007],
[14.054830931000026, 122.87389414400002],
[14.054265039000029, 122.87376751300008],
[14.053914439000039, 122.87336257900006],
[14.053661455000054, 122.87283870600004],
[14.053621473000021, 122.87207895000006],
[14.05399052000007, 122.87178471400011],
[14.054538045000072, 122.87221046800005],
[14.054812454000057, 122.872094317],
[14.055048175000024, 122.87169281800004],
[14.05506659100007, 122.87116202700008],
[14.054714340000032, 122.870820105],
[14.05432233700003, 122.87072090200002],
[14.054048113000022, 122.87055828500002],
[14.053833323000049, 122.87019342000008],
[14.053619648000051, 122.86958708700001],
[14.053464421000058, 122.86924445900002],
[14.053035253000075, 122.86920418500006],
[14.052356744000065, 122.8690184720001],
[14.052182886000026, 122.86833421600011],
[14.051420734000033, 122.86704851400009],
[14.048510646000068, 122.85229814000002],
[14.046572785000023, 122.8500581300001],
[14.042796074000023, 122.84287211700007],
[14.040017887000033, 122.83884199300007],
[14.034623674000045, 122.83133907900003],
[14.013752262000025, 122.79251799700012],
[14.020245801000044, 122.79893402900007],
[14.023405579000041, 122.80164004500011],
[14.028001258000074, 122.80536106900001],
[14.032484943000043, 122.80919909300007],
[14.035228751000034, 122.81150910600002],
[14.038758505000033, 122.81428312600008],
[14.043384188000061, 122.8169181500001],
[14.042721204000031, 122.82150914100009],
[14.044432090000043, 122.82200915100009],
[14.046138970000072, 122.82351315900007],
[14.052930011000058, 122.8249274640001],
[14.06274847700007, 122.83122105400003],
[14.065872630000058, 122.83282927000005],
[14.069198399000072, 122.83518928800004],
[14.090193887000055, 122.84265305300005],
[14.102272543000026, 122.84843957200007],
[14.110025354000072, 122.85113620200002],
[14.114270164000061, 122.85426670600009],
[14.115679248000049, 122.85664855000005],
[14.116244153000025, 122.85820101500008],
[14.116496741000049, 122.85811628600004],
[14.11680375800006, 122.85776106300011],
[14.116984451000064, 122.85775125100008],
[14.11730102100006, 122.85809455100002],
[14.11740020600007, 122.85847706100003],
[14.117535805000045, 122.85866323000005],
[14.11774411500005, 122.85870911200004],
[14.118092654000066, 122.85866200100008],
[14.118255404000024, 122.85853153000005],
[14.118418160000033, 122.85829859300009],
[14.118635114000028, 122.85827991400004],
[14.118743582000036, 122.85844758000007],
[14.118635072000075, 122.858736442],
[14.118779725000024, 122.85883891800006],
[14.119141392000074, 122.85882021500004],
[14.11939458200004, 122.85890404400004],
[14.119620697000073, 122.8592116210001],
[14.119720182000037, 122.85950068800003],
[14.119855732000076, 122.85973390100003],
[14.120054462000041, 122.85994843300011],
[14.120117656000048, 122.85960359400008],
[14.120117639000057, 122.85925872400003],
[14.120334284000023, 122.85895146900009],
[14.120532809000053, 122.85911015600004],
[14.120823673000075, 122.85894090200009],
[14.120923492000031, 122.85912718700001],
[14.120904734000021, 122.85935147400005],
[14.120786275000057, 122.85938002900002],
[14.120858009000074, 122.85986482600003],
[14.121122100000036, 122.8599294920001],
[14.121204896000052, 122.85968650400002],
[14.121332832000064, 122.85967673700009],
[14.121469956000055, 122.85953616800009],
[14.121616176000032, 122.85948896600007],
[14.121698002000073, 122.85970388300007],
[14.12188965100006, 122.85989993600003],
[14.122116744000039, 122.860245125],
[14.122212152000031, 122.86059499900011],
[14.122482603000037, 122.86066602200003],
[14.12284151600005, 122.86085932500009],
[14.122841527000048, 122.86112972300009],
[14.122761613000023, 122.86134337400006],
[14.122833060000062, 122.86155637700006],
[14.122968851000053, 122.8616130050001],
[14.123303711000062, 122.86159755000006],
[14.123184822000042, 122.86200208000002],
[14.123240038000063, 122.86241917600012],
[14.123458549000077, 122.86259943400012],
[14.12361142900005, 122.86259947000008],
[14.123797314000058, 122.86234031900005],
[14.123906588000068, 122.86205883000002],
[14.12421226300006, 122.862160291],
[14.124659337000026, 122.86288019900007],
[14.124801071000036, 122.86293639300004],
[14.124910128000067, 122.86313881],
[14.125073712000074, 122.86322877400005],
[14.125237296000023, 122.86322877300006],
[14.125520894000033, 122.86347621200002],
[14.125488191000045, 122.86362242300004],
[14.125204582000038, 122.86358863200007],
[14.124964660000046, 122.86364486100001],
[14.12463771800003, 122.86392661500008],
[14.124432393000063, 122.86400530800006],
[14.124279649000073, 122.8642072130001],
[14.124268208000046, 122.86472233900008],
[14.124149946000045, 122.8649580880001],
[14.123802717000046, 122.86516075100008],
[14.123606765000034, 122.86519438500011],
[14.12344356500006, 122.86547546000008],
[14.123323774000028, 122.86587962800002],
[14.123367313000074, 122.86641817800012],
[14.123641469000063, 122.86703033200001],
[14.123901701000023, 122.86726613000008],
[14.12851364200003, 122.8720410200001],
[14.135600991000047, 122.88361144800001],
[14.136722681000037, 122.89012864000006],
[14.135137524000072, 122.8886460760001],
[14.13252316200004, 122.88919040500002],
[14.12893209200007, 122.88933210400012],
[14.119467149000059, 122.88993019500003],
[14.117550270000038, 122.89011168200011],
[14.114117286000067, 122.90079259200002]]

const polygonMercedes9 = [
  [14.067508729000053, 123.03237773700005],
  [14.067463048000036, 123.03270534300009],
  [14.067709593000075, 123.03235682700006],
  [14.06814987100006, 123.03190200200004],
  [14.068890084000031, 123.0315691620001],
  [14.068875379000076, 123.03089146100001],
  [14.068921878000026, 123.03036428900009],
  [14.068887664000044, 123.0299334770001],
  [14.069189427000026, 123.02978941200001],
  [14.069491664000054, 123.02969367300011],
  [14.069770798000036, 123.02949031000003],
  [14.070014988000025, 123.02940671300007],
  [14.071061753000038, 123.02825571100004],
  [14.071266268000045, 123.0266313940001],
  [14.073831640000037, 123.01219174500011],
  [14.074043048000021, 123.01115210000012],
  [14.074873835000062, 123.00992660000009],
  [14.075119931000074, 123.00976083300009],
  [14.075468034000039, 123.00960747200008],
  [14.075764739000022, 123.00962036200008],
  [14.076038437000022, 123.00976420000006],
  [14.076150407000057, 123.00993727500008],
  [14.076389043000063, 123.01055632700002],
  [14.076569033000055, 123.01091136000002],
  [14.076729346000036, 123.01103049700009],
  [14.07699745900004, 123.0111042420001],
  [14.077586391000068, 123.01109217100009],
  [14.077814892000049, 123.01105861100007],
  [14.078030160000026, 123.01088813900003],
  [14.078297687000031, 123.010617797],
  [14.078745831000049, 123.01021940200008],
  [14.079205109000043, 123.00993109900003],
  [14.079439187000048, 123.00957646100005],
  [14.079451610000035, 123.00931080200007],
  [14.079411705000041, 123.00872933900007],
  [14.079535581000073, 123.00861800500002],
  [14.079770755000027, 123.0085989700001],
  [14.079919496000059, 123.00871037500008],
  [14.079908543000045, 123.00804517400002],
  [14.080089645000044, 123.00761211300005],
  [14.080272433000061, 123.00684870300006],
  [14.080326195000055, 123.00594551400002],
  [14.080455222000069, 123.00512834300002],
  [14.08054124000006, 123.00425741100003],
  [14.080519735000053, 123.00363378200007],
  [14.080433717000062, 123.00331121400006],
  [14.080476726000029, 123.00265532600008],
  [14.08062725800005, 123.00211771400006],
  [14.080724028000077, 123.0012360290001],
  [14.080734780000057, 123.00025757500009],
  [14.080218672000058, 122.99952642100004],
  [14.079863848000059, 122.99942965200012],
  [14.079820839000035, 122.99891354300007],
  [14.07993911400007, 122.99868774600009],
  [14.08003588400004, 122.99823615100001],
  [14.080369204000021, 122.99820389500007],
  [14.080573496000056, 122.99805336300005],
  [14.080756285000064, 122.99770929100009],
  [14.080896064000058, 122.99733296200009],
  [14.081143366000049, 122.99680610300004],
  [14.081663390000074, 122.99624416300003],
  [14.082366187000048, 122.99641474900011],
  [14.083544158000052, 122.99649992700006],
  [14.084704397000053, 122.99695530800011],
  [14.08646264600003, 122.99699962200009],
  [14.088577757000053, 122.99685319000002],
  [14.090169417000027, 122.99634017200003],
  [14.091679501000044, 122.99589311900002],
  [14.092476592000025, 122.99528428700012],
  [14.092468455000073, 122.99498612200011],
  [14.092575966000027, 122.99462787000004],
  [14.092765733000022, 122.99453363100008],
  [14.09297176800004, 122.99457621700003],
  [14.093235535000076, 122.99471254200012],
  [14.093367417000024, 122.99466140600009],
  [14.093392073000075, 122.99443139300001],
  [14.09351549300004, 122.99398034400008],
  [14.093614423000076, 122.99380228300004],
  [14.093762714000036, 122.9938022770001],
  [14.094347890000051, 122.99389597600009],
  [14.094455245000063, 122.9938537380001],
  [14.094596318000072, 122.99342949900006],
  [14.097237409000059, 122.99328877500011],
  [14.09751234600003, 122.99308079900004],
  [14.09768712500005, 122.99305860400011],
  [14.098045414000069, 122.99287336900011],
  [14.09824781900005, 122.99290904400004],
  [14.098408685000038, 122.99316505200011],
  [14.098441496000078, 122.99346904000004],
  [14.098528491000025, 122.99353123000003],
  [14.098750141000039, 122.99348992500006],
  [14.098891394000077, 122.99338647400009],
  [14.099114297000028, 122.99337250400004],
  [14.099300773000039, 122.99355885800003],
  [14.09946816400003, 122.99359334300004],
  [14.099817537000035, 122.99353115100007],
  [14.100213308000036, 122.99326701100006],
  [14.100361948000057, 122.9930878560001],
  [14.100436706000039, 122.99281951300009],
  [14.100423723000063, 122.99258517900012],
  [14.100329927000075, 122.99235687400005],
  [14.100417204000053, 122.99222550500008],
  [14.100618415000042, 122.99219765300006],
  [14.100913456000058, 122.99251609300006],
  [14.101074508000067, 122.99249495900006],
  [14.101195494000024, 122.99230732400008],
  [14.101457517000028, 122.9922997760001],
  [14.101464415000066, 122.9921538850001],
  [14.101572143000055, 122.99204241200005],
  [14.101659329000029, 122.9921882650001],
  [14.101907537000045, 122.99222388400005],
  [14.10202097000007, 122.99250889200005],
  [14.102228401000048, 122.99263436800004],
  [14.102208165000036, 122.99290410800006],
  [14.102389165000034, 122.992994242],
  [14.102463054000054, 122.99311867100005],
  [14.102783834000036, 122.99317546300006],
  [14.102966662000028, 122.99328172600008],
  [14.103268932000049, 122.99390410100011],
  [14.103419755000061, 122.99426455800005],
  [14.103610504000073, 122.9944202160001],
  [14.10376136900004, 122.99462497600007],
  [14.10403993500006, 122.99469067900009],
  [14.10419098400007, 122.99482179600011],
  [14.104452704000039, 122.9952803540001],
  [14.104992088000074, 122.99541604300009],
  [14.105075665000072, 122.99491105400011],
  [14.105408251000028, 122.9933099210001],
  [14.105448526000032, 122.99237450800001],
  [14.10553184500003, 122.9916032430001],
  [14.105763546000048, 122.99037352700009],
  [14.105945205000069, 122.98931916800007],
  [14.106132091000063, 122.98810688800006],
  [14.106273061000024, 122.98701661200005],
  [14.106465763000074, 122.98598927100011],
  [14.106604391000076, 122.98548723600004],
  [14.106749825000065, 122.98508577600012],
  [14.106813643000066, 122.98431102500001],
  [14.106812891000061, 122.9838381300001],
  [14.106661417000055, 122.98278402800008],
  [14.106695971000022, 122.98220018100005],
  [14.106965456000069, 122.98112990300001],
  [14.107187125000053, 122.98058548000006],
  [14.107414593000044, 122.97995448200004],
  [14.107595642000035, 122.97933203000002],
  [14.10761879000006, 122.97885861200007],
  [14.113990141000045, 122.97959331200002],
  [14.11396028200005, 122.9799790730001],
  [14.114034220000065, 122.9800212350001],
  [14.11410415100005, 122.98041380300003],
  [14.114752616000033, 122.98062834500001],
  [14.114835554000024, 122.98094917600008],
  [14.114596145000064, 122.98134828400009],
  [14.11486588200006, 122.98138946000006],
  [14.115249609000045, 122.98115269200002],
  [14.115914944000053, 122.9810312400001],
  [14.116093701000068, 122.98087103900002],
  [14.116441292000047, 122.98070473100006],
  [14.116632043000038, 122.98065601200005],
  [14.116873129000055, 122.98046470500003],
  [14.11712602700004, 122.98031892200004],
  [14.117334477000043, 122.98035837600003],
  [14.117445413000041, 122.98054653500003],
  [14.11743348400006, 122.98098955800003],
  [14.117538572000058, 122.98101762200008],
  [14.11781488400004, 122.98136275200011],
  [14.117908317000058, 122.98143097800005],
  [14.117954318000045, 122.98173969700008],
  [14.11824727100003, 122.98184528600007],
  [14.118467234000036, 122.98182266200001],
  [14.118562772000075, 122.9817373200001],
  [14.118581692000078, 122.98155667800006],
  [14.118762006000054, 122.98150814500002],
  [14.119058119000044, 122.98176423100006],
  [14.119102457000054, 122.98195135800006],
  [14.119106306000049, 122.98246992800011],
  [14.11929348800004, 122.98261856400006],
  [14.119492179000076, 122.9826625500001],
  [14.119787417000055, 122.98256550400004],
  [14.120075198000052, 122.98275729600005],
  [14.12029274200006, 122.98274914600006],
  [14.120788237000056, 122.98256569800003],
  [14.120979509000051, 122.98266628500005],
  [14.121062054000049, 122.98250179500008],
  [14.121629849000044, 122.98254936100011],
  [14.122055727000031, 122.98278181],
  [14.122109494000028, 122.98296798400008],
  [14.121938586000056, 122.98331545200006],
  [14.12140838700003, 122.983600593],
  [14.121477668000068, 122.9837965050001],
  [14.121234852000043, 122.98407183000006],
  [14.121351665000077, 122.98423534200003],
  [14.121543921000068, 122.98429557300005],
  [14.122045250000042, 122.984562062],
  [14.122003717000041, 122.98526113800006],
  [14.122087427000054, 122.98538191300008],
  [14.122380243000066, 122.98551125800009],
  [14.122581037000032, 122.98553710800002],
  [14.122731611000063, 122.9854766840001],
  [14.122932405000029, 122.98548527800006],
  [14.123032817000023, 122.98558878800009],
  [14.123242073000029, 122.98564051000005],
  [14.123451455000065, 122.98561457400001],
  [14.123585458000036, 122.9856749700001],
  [14.123677502000078, 122.98587347000012],
  [14.123903851000023, 122.98577852400001],
  [14.123977745000047, 122.98586184300007],
  [14.12394570400005, 122.98631857700002],
  [14.124264181000058, 122.9863468640001],
  [14.125449614000047, 122.98706502300001],
  [14.125909061000073, 122.9874005150001],
  [14.126106937000031, 122.98761802100012],
  [14.126267905000077, 122.98809648300005],
  [14.126235510000072, 122.98830032400008],
  [14.12608352500007, 122.98854670300011],
  [14.125784501000055, 122.98870283700012],
  [14.12549475700007, 122.9887783800001],
  [14.125384285000052, 122.98889679000001],
  [14.12539326600006, 122.98910541400005],
  [14.125296451000054, 122.98935659400001],
  [14.125043529000038, 122.98939424600007],
  [14.124389173000054, 122.98916033400008],
  [14.124039627000059, 122.98926425800005],
  [14.12385542800007, 122.98953904600012],
  [14.123809268000059, 122.98971442000004],
  [14.123877883000034, 122.99005586100009],
  [14.124169786000039, 122.99037384500002],
  [14.124459491000039, 122.99033623300011],
  [14.124786043000029, 122.99023702800002],
  [14.125287294000032, 122.99015697800007],
  [14.125682727000026, 122.99014319000003],
  [14.126046914000028, 122.99022065000008],
  [14.12642285000004, 122.99044744600008],
  [14.12673522700004, 122.99071804300002],
  [14.127008266000075, 122.99112768600003],
  [14.126869714000065, 122.9913302110001],
  [14.126692984000044, 122.99148196600004],
  [14.126392703000022, 122.99184708200005],
  [14.125579924000021, 122.99238746000003],
  [14.12542606900007, 122.99279238100007],
  [14.125264128000026, 122.99296352700003],
  [14.125291332000074, 122.99324486100011],
  [14.125068662000047, 122.99351201800005],
  [14.12483385400003, 122.9936121940001],
  [14.124651680000056, 122.99362888100006],
  [14.124287323000033, 122.99380001500003],
  [14.124165861000051, 122.99402960600003],
  [14.123890842000037, 122.99431122200008],
  [14.123963694000054, 122.99462014000005],
  [14.123854379000022, 122.99479963600004],
  [14.123639809000053, 122.99498330100005],
  [14.12351834900005, 122.99516279700003],
  [14.123485949000042, 122.99538404200007],
  [14.122996059000059, 122.99604358400006],
  [14.122833385000035, 122.99639016700007],
  [14.122529739000072, 122.99675750000006],
  [14.122092504000022, 122.99701211100012],
  [14.121711933000029, 122.99741700900006],
  [14.121626905000028, 122.99760068100011],
  [14.121490426000037, 122.9977332520001],
  [14.121348718000036, 122.99797953400002],
  [14.121146287000045, 122.99819659000002],
  [14.121105793000027, 122.99834269300004],
  [14.120765710000057, 122.99868497200009],
  [14.120583528000054, 122.99879766600009],
  [14.120271786000046, 122.99908150500005],
  [14.11993715500006, 122.99915725400001],
  [14.119645189000039, 122.9992750340001],
  [14.119397647000028, 122.99944517400002],
  [14.119194531000062, 122.99965458500003],
  [14.119035836000023, 122.99996216400007],
  [14.118832033000047, 123.00022679000006],
  [14.11874257200003, 123.00025263200007],
  [14.117681383000047, 123.00028160900001],
  [14.117271463000066, 123.00043193300007],
  [14.117109239000058, 123.00053510000009],
  [14.116917971000078, 123.00073486000008],
  [14.116554590000021, 123.00094712400005],
  [14.116270188000044, 123.00101473200004],
  [14.115790121000032, 123.00128690400004],
  [14.114826682000057, 123.00212930400005],
  [14.114498943000058, 123.00261053200006],
  [14.114093954000055, 123.00337549700009],
  [14.113647032000074, 123.00387472700004],
  [14.11327688800003, 123.00450742300006],
  [14.112872965000065, 123.00525820200005],
  [14.112299405000044, 123.00649494400011],
  [14.111862091000035, 123.00779936100002],
  [14.111659391000046, 123.00847383900009],
  [14.111495490000038, 123.00951659100008],
  [14.111408002000076, 123.01020067400009],
  [14.111396160000027, 123.01060276800001],
  [14.111441318000061, 123.01102659100002],
  [14.111564031000057, 123.01169794700002],
  [14.111752403000025, 123.01296227700004],
  [14.111724404000029, 123.01350788100001],
  [14.11176911800004, 123.01408408800012],
  [14.111682032000033, 123.01440415500008],
  [14.111483201000055, 123.014426092],
  [14.111177128000065, 123.01428969200003],
  [14.110902934000023, 123.01426510200008],
  [14.110481522000043, 123.01405518500007],
  [14.110053281000035, 123.01359892100004],
  [14.109779640000056, 123.01322372000004],
  [14.10947453700004, 123.01314427600005],
  [14.10928536700004, 123.01299384200001],
  [14.109165100000041, 123.01302496700009],
  [14.108566978000056, 123.01254765800002],
  [14.108446736000076, 123.01260536300003],
  [14.10802743000005, 123.01186560200006],
  [14.10815195300006, 123.01179017400011],
  [14.107859191000045, 123.0111126920001],
  [14.10734949500005, 123.01064851500007],
  [14.107275843000025, 123.00997759200004],
  [14.107146445000069, 123.00942199100007],
  [14.106985655000074, 123.00904829700005],
  [14.107014063000065, 123.00864024000009],
  [14.107103068000072, 123.00839370300002],
  [14.107259038000052, 123.00819310600002],
  [14.107035412000073, 123.00786144400001],
  [14.106790144000058, 123.00798983900006],
  [14.106570461000047, 123.00821678500006],
  [14.106312218000028, 123.00813160400003],
  [14.106181418000062, 123.0079805790001],
  [14.106047658000023, 123.00807600400003],
  [14.105664390000072, 123.00792404200001],
  [14.10518405700003, 123.00737212700005],
  [14.104963221000048, 123.00700801800008],
  [14.10486824800006, 123.00666895400002],
  [14.104818089000048, 123.00617155300006],
  [14.10465458300007, 123.00593259700008],
  [14.104556522000053, 123.00549887400007],
  [14.104430832000048, 123.00521108200007],
  [14.104316396000058, 123.0048475430001],
  [14.103851408000025, 123.00491597900009],
  [14.103816169000027, 123.0056235620001],
  [14.103707738000026, 123.00582468700009],
  [14.10363065200005, 123.006130686],
  [14.103469412000038, 123.00644960200009],
  [14.103464153000061, 123.00678272400012],
  [14.103541798000037, 123.00687259800009],
  [14.10365654900005, 123.00661727800002],
  [14.10382411200004, 123.00653717000012],
  [14.103964838000024, 123.00689136800008],
  [14.104082604000041, 123.00736665700003],
  [14.104233670000042, 123.00757392500009],
  [14.104452129000038, 123.008146269],
  [14.104278329000067, 123.00828039800001],
  [14.10428272300004, 123.00850013900003],
  [14.10434573300006, 123.00865780100003],
  [14.104314557000066, 123.0089349110001],
  [14.104239175000032, 123.00899548200005],
  [14.104321126000059, 123.00924171100007],
  [14.104514065000046, 123.00902330100007],
  [14.104617004000033, 123.00930624000011],
  [14.104970345000027, 123.0099394450001],
  [14.105096356000047, 123.0102482740001],
  [14.105128003000061, 123.01048374900006],
  [14.105112245000043, 123.01083302200004],
  [14.105047217000049, 123.011056499],
  [14.10483769900003, 123.01115333700011],
  [14.104649857000027, 123.011145886],
  [14.104322766000053, 123.01103915200008],
  [14.104134121000072, 123.01103440800011],
  [14.103581990000066, 123.01085887800002],
  [14.103250711000044, 123.01066437300005],
  [14.103080469000076, 123.01037024100003],
  [14.103057462000038, 123.01007136400005],
  [14.102795198000024, 123.0099527650001],
  [14.102537539000025, 123.01036075800005],
  [14.102367298000047, 123.0104129450001],
  [14.102192455000022, 123.0103797380001],
  [14.101631112000064, 123.01008561200001],
  [14.100956243000041, 123.00953708300005],
  [14.100758393000035, 123.00926667400006],
  [14.100712379000072, 123.00901049700008],
  [14.100767590000032, 123.00884445600002],
  [14.101103464000062, 123.00854557700006],
  [14.100652551000053, 123.00807204900002],
  [14.100440904000038, 123.00822386200002],
  [14.100238456000056, 123.00820014500005],
  [14.099989996000033, 123.00808155000004],
  [14.098166380000066, 123.01055099500002],
  [14.098424044000069, 123.01130529500006],
  [14.098566678000054, 123.0116421240001],
  [14.098884155000064, 123.01208441000006],
  [14.09911420900005, 123.01232160900008],
  [14.099381072000028, 123.01253983400011],
  [14.099624930000061, 123.01280075600005],
  [14.099670941000056, 123.01305219000005],
  [14.099546711000073, 123.0133273450001],
  [14.099367268000037, 123.01347440800009],
  [14.099187825000058, 123.01349812800004],
  [14.098847344000035, 123.01324194600011],
  [14.098755323000034, 123.0130853930001],
  [14.09875072400007, 123.01266317600005],
  [14.098644899000021, 123.01263471200002],
  [14.098396439000055, 123.01291460700008],
  [14.09825380600006, 123.01269163900008],
  [14.097885719000033, 123.01264894300004],
  [14.09766486600006, 123.0124639280001],
  [14.097559042000057, 123.01216505800005],
  [14.097467020000067, 123.01204645900009],
  [14.097246165000058, 123.01196581200008],
  [14.097075923000034, 123.01184246900004],
  [14.096905680000077, 123.01181875000009],
  [14.096770716000037, 123.01568192900004],
  [14.09712520000005, 123.01576398200007],
  [14.097429042000044, 123.01589824900009],
  [14.097754591000069, 123.01576398800012],
  [14.098210109000036, 123.01593740900012],
  [14.098463170000059, 123.01593266800012],
  [14.098983093000072, 123.01597537100008],
  [14.099130329000047, 123.01589947000002],
  [14.09920394900007, 123.01576189500008],
  [14.099503021000032, 123.01564329900009],
  [14.099576640000066, 123.01553418800006],
  [14.09988491200005, 123.01556265700003],
  [14.100082760000078, 123.015482012],
  [14.100542870000027, 123.01552471700006],
  [14.10107660400007, 123.01528277900002],
  [14.101372757000036, 123.01497720900011],
  [14.10155680300005, 123.01491553800008],
  [14.102265370000055, 123.01506735600003],
  [14.102440211000044, 123.01507210200009],
  [14.102766890000055, 123.01492029500002],
  [14.102969338000037, 123.01487760100008],
  [14.103475459000038, 123.01491556100007],
  [14.103696311000022, 123.01508635300002],
  [14.104156794000062, 123.01524193300008],
  [14.104409659000055, 123.01516],
  [14.104759513000033, 123.01486204000003],
  [14.104810092000037, 123.01464601400005],
  [14.10500573400003, 123.01467817800005],
  [14.105063784000038, 123.01555954100002],
  [14.105121826000072, 123.01577075400007],
  [14.10533253500006, 123.01623910900003],
  [14.105400363000058, 123.01653530500005],
  [14.105671674000064, 123.01734826300003],
  [14.105777404000037, 123.01744699500011],
  [14.106270984000048, 123.01730759600002],
  [14.106518351000034, 123.01768812700004],
  [14.106823571000064, 123.01765932900003],
  [14.107117507000055, 123.01784382900007],
  [14.107307023000033, 123.01783765700009],
  [14.10766211400005, 123.0181379710001],
  [14.10790150300005, 123.01818322400004],
  [14.108124932000067, 123.01827990200002],
  [14.108342137000022, 123.01857838600006],
  [14.108517688000063, 123.0185516470001],
  [14.108599479000077, 123.0186298110001],
  [14.108548555000027, 123.01893236400008],
  [14.108400933000041, 123.0190763490001],
  [14.10815156800004, 123.0196605220001],
  [14.108075761000066, 123.01973045700004],
  [14.108304890000056, 123.02010700300002],
  [14.10847246000003, 123.02020368100011],
  [14.108606120000047, 123.02006998000002],
  [14.108731798000065, 123.02010083500011],
  [14.108761719000029, 123.020292132],
  [14.109172666000063, 123.02051222900002],
  [14.109234507000053, 123.02062330500007],
  [14.109632144000045, 123.0209821310001],
  [14.109779766000031, 123.021021216],
  [14.110007181000071, 123.02123308500006],
  [14.110019149000038, 123.02136061700003],
  [14.11044006700007, 123.02198525600011],
  [14.110609623000073, 123.02258590500003],
  [14.11075125800005, 123.02276897800004],
  [14.110940772000049, 123.02282863500011],
  [14.111046504000058, 123.02270727500002],
  [14.11118814200006, 123.02269905000003],
  [14.11120610100005, 123.02236376400003],
  [14.111299862000067, 123.02229794300001],
  [14.111472408000054, 123.02277267800002],
  [14.111577517000057, 123.02289408700005],
  [14.111422841000035, 123.02296871100009],
  [14.111561713000071, 123.02309898200008],
  [14.111536542000067, 123.02333131800003],
  [14.111869162000062, 123.02339867900002],
  [14.111963760000037, 123.02356315100008],
  [14.11201279100004, 123.02378664300011],
  [14.111960578000037, 123.02392559100008],
  [14.11211541800003, 123.02400645800003],
  [14.112107089000062, 123.02445228700003],
  [14.112167655000064, 123.0246796560001],
  [14.112051379000036, 123.02480738100007],
  [14.11174696300003, 123.02470646200004],
  [14.111672643000077, 123.02477786700001],
  [14.111684593000064, 123.02497679900011],
  [14.111550728000054, 123.02514378300009],
  [14.11137683000004, 123.02508283100008],
  [14.111211095000044, 123.0249033340001],
  [14.111090701000023, 123.02495462600007],
  [14.111071029000072, 123.02512265400003],
  [14.111182040000074, 123.02521012600005],
  [14.111018899000044, 123.02536394200001],
  [14.110768121000035, 123.02547739200008],
  [14.110792381000067, 123.02568757600011],
  [14.110914110000067, 123.02582951500005],
  [14.110995562000028, 123.02614464600003],
  [14.110876623000024, 123.0263266070001],
  [14.110982728000067, 123.026441599],
  [14.110992284000076, 123.02677102900009],
  [14.110923178000064, 123.02696120600001],
  [14.110714098000074, 123.02725477800004],
  [14.11018511800006, 123.02779157900011],
  [14.109956192000027, 123.02793300300004],
  [14.109864756000036, 123.02806943400003],
  [14.10943837700006, 123.0283923830001],
  [14.109299140000076, 123.02840793200005],
  [14.109062730000062, 123.02879119300007],
  [14.108840033000035, 123.02893364200008],
  [14.108566478000057, 123.02902093400007],
  [14.108393689000025, 123.02916700300011],
  [14.108230598000034, 123.02914782700009],
  [14.107935143000077, 123.02926510200007],
  [14.107704037000076, 123.02921052200008],
  [14.107581725000045, 123.02927005800007],
  [14.107480457000065, 123.02957284000001],
  [14.107254880000028, 123.02966258600009],
  [14.106910019000054, 123.02985537000006],
  [14.106820278000043, 123.02999734700006],
  [14.106531750000045, 123.03012762000003],
  [14.105725739000036, 123.03035092700009],
  [14.10539816000005, 123.03035816600004],
  [14.10530490800005, 123.0301109190001],
  [14.105709686000068, 123.02979956600007],
  [14.106509797000058, 123.0295409250001],
  [14.106733456000029, 123.0295494400001],
  [14.107153075000042, 123.02917233100004],
  [14.107742, 123.02869844500003],
  [14.10788702800005, 123.02844631800008],
  [14.107755187000066, 123.0283875990001],
  [14.107233421000046, 123.02867047400002],
  [14.106914534000055, 123.02894122600003],
  [14.106766504000063, 123.0290191470001],
  [14.106191158000058, 123.02915817100006],
  [14.10587871000007, 123.02911523800003],
  [14.105535413000041, 123.02916697800003],
  [14.105275455000026, 123.02925137900002],
  [14.105003487000033, 123.02921427000001],
  [14.104713700000048, 123.02927053300004],
  [14.104506411000045, 123.02948315200001],
  [14.104266119000044, 123.02936011200006],
  [14.103986621000047, 123.02942719900011],
  [14.103922356000055, 123.02958342600004],
  [14.103702206000037, 123.02958323900009],
  [14.103701300000068, 123.02977679800006],
  [14.103860368000028, 123.02988042400011],
  [14.103993484000057, 123.03007608200005],
  [14.104053412000042, 123.03025967100007],
  [14.10402227000003, 123.03060022900002],
  [14.10395185300007, 123.03070470800003],
  [14.103600860000029, 123.03101573800006],
  [14.10313323500003, 123.03097645500009],
  [14.102947887000028, 123.0309132110001],
  [14.103166129000044, 123.03052783300006],
  [14.103134343000022, 123.03040478100002],
  [14.102842536000026, 123.03044179200003],
  [14.102336292000075, 123.03062210300004],
  [14.102104182000062, 123.03078504700011],
  [14.101801654000042, 123.03105246900009],
  [14.101566481000077, 123.03111687500007],
  [14.101148317000025, 123.0314039220001],
  [14.10087573900006, 123.031548154],
  [14.100515275000078, 123.03168870700006],
  [14.099974316000043, 123.03193400200007],
  [14.099850682000067, 123.03201594200004],
  [14.099605521000058, 123.03238273000011],
  [14.09951407400007, 123.03284675900011],
  [14.099489094000035, 123.03315021800006],
  [14.09939924400004, 123.03347432300006],
  [14.09938091500004, 123.03378434100011],
  [14.099417473000074, 123.03408273800005],
  [14.099379198000065, 123.03426622900008],
  [14.099407426000027, 123.03468468800008],
  [14.099575358000038, 123.03516292300003],
  [14.099696564000055, 123.03540475600005],
  [14.099968140000044, 123.03569264900011],
  [14.100237760000027, 123.03619673300011],
  [14.100435062000031, 123.0369380520001],
  [14.100648375000048, 123.03798971700007],
  [14.100646404000031, 123.03835236500004],
  [14.100496985000063, 123.03880453600004],
  [14.100415476000023, 123.03914657100006],
  [14.100415457000054, 123.03935859700005],
  [14.100541518000057, 123.03978412900005],
  [14.100713732000031, 123.04008230500006],
  [14.100968726000076, 123.04030049200003],
  [14.101217086000077, 123.04028651500005],
  [14.101319887000045, 123.04048455000009],
  [14.101350163000063, 123.04075821200001],
  [14.101196879000042, 123.04102423100005],
  [14.100871389000076, 123.04106772700004],
  [14.100447598000073, 123.04141292200006],
  [14.100344767000024, 123.04153092500007],
  [14.100047311000026, 123.04172573400001],
  [14.099801115000048, 123.04174755400004],
  [14.099830200000042, 123.0419015760001],
  [14.099581843000067, 123.04228159600007],
  [14.099289058000068, 123.04256967700007],
  [14.098676650000073, 123.04303595300007],
  [14.098527250000075, 123.04323596000006],
  [14.098049022000055, 123.04349379100006],
  [14.09783937900005, 123.04356177400007],
  [14.097204864000048, 123.0436960190001],
  [14.096824945000037, 123.04371397700004],
  [14.096489339000073, 123.04368793600008],
  [14.095546553000077, 123.0436858280001],
  [14.095136302000071, 123.04374578700003],
  [14.094879238000033, 123.04369975300006],
  [14.094173162000061, 123.0436396660001],
  [14.092776385000036, 123.04367951000006],
  [14.091448196000044, 123.04364735500008],
  [14.091108591000022, 123.04355099500003],
  [14.090937871000051, 123.04355897500011],
  [14.09066784600003, 123.04348293800001],
  [14.090491307000036, 123.04349091800009],
  [14.090394317000062, 123.04340890100002],
  [14.089982082000063, 123.04339085300012],
  [14.089811908000058, 123.04342506800003],
  [14.089033554000025, 123.04331964500011],
  [14.088734217000024, 123.0433196140001],
  [14.088377775000026, 123.04319953400011],
  [14.087725876000036, 123.04325360000007],
  [14.087472091000052, 123.04331489600008],
  [14.087162281000076, 123.04330259900007],
  [14.086093493000021, 123.0435038820001],
  [14.08539647400005, 123.04375985100012],
  [14.084309142000052, 123.04420849000007],
  [14.083685414000058, 123.04454745200007],
  [14.083371099000033, 123.04468965100011],
  [14.08283472900007, 123.04480228400007],
  [14.082099355000025, 123.0450145310001],
  [14.081600760000072, 123.04526969300002],
  [14.08149964200004, 123.04529216700007],
  [14.080932160000032, 123.04568233700002],
  [14.080725939000047, 123.04579369400005],
  [14.079734363000057, 123.04606135000006],
  [14.079381908000073, 123.04619826100009],
  [14.078712881000058, 123.04640311700007],
  [14.07792332300005, 123.04659107600003],
  [14.076709123000057, 123.04668086700008],
  [14.076228022000066, 123.04669102900004],
  [14.07600992500005, 123.04673801700005],
  [14.075795800000037, 123.04671959500001],
  [14.075034225000024, 123.04677627900003],
  [14.074270347000038, 123.04694303300005],
  [14.07393795400003, 123.04703618100007],
  [14.073679780000077, 123.04715406500009],
  [14.072642849000033, 123.04770907500006],
  [14.072195886000031, 123.0480454100001],
  [14.071739140000034, 123.04826312800003],
  [14.071054021000066, 123.04840816300009],
  [14.070217949000039, 123.0484509800001],
  [14.069853829000067, 123.04842640600009],
  [14.069339754000055, 123.04845495500001],
  [14.068418247000068, 123.04823409000005],
  [14.06817948400004, 123.04809366100005],
  [14.06781628300007, 123.04769449100002],
  [14.067692554000075, 123.04746851200002],
  [14.067699124000058, 123.04720567900006],
  [14.067897280000068, 123.04670035400011],
  [14.067990476000034, 123.04659612400008],
  [14.068668595000076, 123.04528777200005],
  [14.068887646000064, 123.04450803500004],
  [14.068959060000054, 123.04413808700008],
  [14.068909572000052, 123.04336155500005],
  [14.068850129000054, 123.04299440600005],
  [14.068772831000047, 123.0427368600001],
  [14.068732892000071, 123.04220177900004],
  [14.068765934000055, 123.0419765900001],
  [14.06892890100005, 123.04140220500005],
  [14.068957280000063, 123.04089696200003],
  [14.069010143000071, 123.04056783600004],
  [14.069058633000054, 123.03975543600006],
  [14.069027849000065, 123.03912914600005],
  [14.069080701000075, 123.03895210600001],
  [14.069023468000069, 123.03875463000008],
  [14.069014306000042, 123.038449581],
  [14.068900818000031, 123.03809426200007],
  [14.06878320900006, 123.03761554500011],
  [14.06853353300005, 123.03684744000009],
  [14.068463388000055, 123.03636659900008],
  [14.068128050000041, 123.03571970400003],
  [14.06808884700007, 123.03554311100004],
  [14.068001727000023, 123.03478197200002],
  [14.067900621000035, 123.03434781500005],
  [14.067865913000048, 123.03405433400007],
  [14.067698758000063, 123.033747951],
  [14.067434628000058, 123.03284526300001],
  [14.067508729000053, 123.03237773700005]
]

const polygonMercedes10 = [
  [14.129729695000037, 123.054069937],
  [14.12969925300007, 123.05425587000002],
  [14.12941825400003, 123.05436936100011],
  [14.129212188000054, 123.0542534550001],
  [14.129261364000058, 123.05408201000012],
  [14.129596220000053, 123.05391781000003],
  [14.129729695000037, 123.054069937],

]

const polygonMercedes11 = [
  [14.129602478000038, 123.05207999400011],
  [14.129582102000029, 123.05219771100008],
  [14.129705965000028, 123.05233394700008],
  [14.129649821000044, 123.05254341300008],
  [14.129540830000053, 123.05268646700006],
  [14.129595333000054, 123.0529137210001],
  [14.129537536000043, 123.0530159010001],
  [14.129569556000035, 123.05321612000012],
  [14.129434138000022, 123.05328254000005],
  [14.12920295400005, 123.05327957800012],
  [14.12914020200003, 123.0533987870001],
  [14.129143512000041, 123.05377628000008],
  [14.12905104600003, 123.0545329140001],
  [14.12916679500006, 123.055047693],
  [14.129378829000075, 123.05541574200004],
  [14.129496089000043, 123.0559735270001],
  [14.12951591600006, 123.05684474200007],
  [14.129540691000045, 123.05718533600009],
  [14.129605100000049, 123.0575602990001],
  [14.129687674000024, 123.05783107200011],
  [14.12968932800004, 123.0582793100001],
  [14.129601802000025, 123.05868291500008],
  [14.129562080000028, 123.05902892600011],
  [14.129578593000076, 123.05977138900005],
  [14.129420051000068, 123.06050366500006],
  [14.129181779000078, 123.06147326500002],
  [14.129181777000042, 123.06172700700006],
  [14.129262696000069, 123.06190922700011],
  [14.129228014000034, 123.06211188100008],
  [14.129082678000032, 123.06259670800011],
  [14.128967955000064, 123.0632636790001],
  [14.129056983000055, 123.06361844600008],
  [14.128984415000048, 123.06370276100006],
  [14.128850342000021, 123.06428945200003],
  [14.128578280000056, 123.06473861500001],
  [14.128576254000052, 123.0650051230001],
  [14.128475733000073, 123.06518912000001],
  [14.128447547000064, 123.0656588490001],
  [14.128491972000063, 123.06597365000005],
  [14.128562504000058, 123.06616734700003],
  [14.128821049000067, 123.06653127200002],
  [14.129077346000031, 123.06657937900002],
  [14.129162830000041, 123.06665229600003],
  [14.12934535200003, 123.06663516300011],
  [14.12936945100006, 123.06675022000002],
  [14.12928938400006, 123.06693039600009],
  [14.129331535000063, 123.06725983700005],
  [14.129041568000048, 123.06712159100005],
  [14.128955963000067, 123.06723849000002],
  [14.129030154000077, 123.06752422500006],
  [14.129292397000029, 123.06740552800011],
  [14.129365062000034, 123.0676567370001],
  [14.12931419000006, 123.06791584700011],
  [14.12910981500005, 123.06796151600008],
  [14.128953038000077, 123.06809512600012],
  [14.128870150000068, 123.0680400980001],
  [14.128654611000059, 123.0683067760001],
  [14.129073809000033, 123.06865703400001],
  [14.128861975000063, 123.06878820700001],
  [14.128547122000043, 123.0688032600001],
  [14.128413889000058, 123.0687466710001],
  [14.128414019000047, 123.06854511400002],
  [14.128238018000047, 123.06840309400002],
  [14.128109979000044, 123.06853402000002],
  [14.128074265000066, 123.06880732000002],
  [14.127948500000059, 123.06865148400004],
  [14.127793440000062, 123.06874910300007],
  [14.127667274000032, 123.06854898600011],
  [14.127464882000027, 123.06865115300002],
  [14.127208894000034, 123.06871619600008],
  [14.127062203000037, 123.06870348900009],
  [14.126850797000031, 123.06884381300006],
  [14.126738018000026, 123.06881312100006],
  [14.126446309000073, 123.06890296400002],
  [14.125978941000028, 123.0687405650001],
  [14.125644325000053, 123.06887179900002],
  [14.125468030000036, 123.06872941000006],
  [14.125277651000033, 123.06875809300004],
  [14.125103713000044, 123.069034772],
  [14.124300506000054, 123.06930795100004],
  [14.12390573500005, 123.069313082],
  [14.123778186000038, 123.06940663900002],
  [14.123586105000072, 123.06937048900011],
  [14.123095015000047, 123.0694583930001],
  [14.122665452000035, 123.0694743040001],
  [14.122213439000063, 123.06944058700003],
  [14.122024765000049, 123.06953590300009],
  [14.121579465000025, 123.0694779690001],
  [14.121145170000034, 123.06915892200004],
  [14.120565514000077, 123.06881687300006],
  [14.120223361000058, 123.0687031860001],
  [14.11993237400003, 123.06870658900004],
  [14.119553361000044, 123.0688377890001],
  [14.118616216000078, 123.06927916100005],
  [14.118153951000068, 123.06921041200007],
  [14.118050354000047, 123.06915451600003],
  [14.117721735000032, 123.06870050200007],
  [14.117601365000041, 123.06849596500001],
  [14.117554366000036, 123.06825462400002],
  [14.11731164300005, 123.0682112390001],
  [14.117158113000073, 123.06809975400006],
  [14.11690668700004, 123.06800954200003],
  [14.116285839000057, 123.06800727000007],
  [14.115719961000025, 123.06796568400011],
  [14.114976373000047, 123.06781350900008],
  [14.114285964000032, 123.06760603900011],
  [14.114222403000042, 123.06749413300008],
  [14.11397600600003, 123.06738872100004],
  [14.113684541000055, 123.06735034300004],
  [14.112730059000057, 123.06739027300011],
  [14.111942851000038, 123.06747177400007],
  [14.111615500000028, 123.06753890300001],
  [14.110792744000037, 123.06763798500003],
  [14.11023018800006, 123.06766673800007],
  [14.109018399000036, 123.06757877000007],
  [14.108122964000074, 123.06739475200004],
  [14.107437615000038, 123.06733473800011],
  [14.10686363800005, 123.06738107600006],
  [14.106061918000023, 123.06732253300004],
  [14.104930700000068, 123.06733847700002],
  [14.104567513000063, 123.0673721610001],
  [14.104448137000077, 123.06734498100002],
  [14.103751846000023, 123.0673145830001],
  [14.103222131000052, 123.0675365190001],
  [14.102978719000077, 123.06771075000006],
  [14.102343579000035, 123.06795794600009],
  [14.102157688000034, 123.06809981000004],
  [14.101768341000025, 123.06814357700011],
  [14.101579044000061, 123.06809363900004],
  [14.101451910000037, 123.06822791000002],
  [14.101248815000076, 123.06816875700008],
  [14.100706812000055, 123.06850382400012],
  [14.099989737000044, 123.06887489000007],
  [14.099126106000028, 123.06913892700004],
  [14.098587183000063, 123.0692768240001],
  [14.09850296600007, 123.06959153700006],
  [14.098312451000027, 123.06967016900012],
  [14.09817861700003, 123.06982906700011],
  [14.098020449000046, 123.06972452900004],
  [14.097871, 123.06931383800008],
  [14.097695416000022, 123.06925516000001],
  [14.097474332000047, 123.06863272200007],
  [14.09753775400003, 123.0682407490001],
  [14.097681567000052, 123.06794364100006],
  [14.09763588900006, 123.06745407900007],
  [14.097512340000037, 123.0671288750001],
  [14.097244893000038, 123.06657989200005],
  [14.097225389000073, 123.06636029800006],
  [14.097306685000035, 123.0661759090001],
  [14.097491038000044, 123.06603678300007],
  [14.098028873000032, 123.06577425600005],
  [14.098399265000069, 123.06576253300011],
  [14.098880975000043, 123.06585474400003],
  [14.09923791400007, 123.06588828000008],
  [14.09948600100006, 123.0658363230001],
  [14.099846565000064, 123.06556358400007],
  [14.100093757000025, 123.06531563900012],
  [14.100706356000046, 123.06478563600001],
  [14.100941952000028, 123.06461504700007],
  [14.101166868000064, 123.06454372900009],
  [14.102068968000026, 123.06443814200009],
  [14.103532051000059, 123.06413291000001],
  [14.104020758000047, 123.0640686480001],
  [14.104181479000033, 123.06380686000011],
  [14.104192288000036, 123.06358226500004],
  [14.104424301000051, 123.06329965500004],
  [14.10461867400005, 123.06290615700004],
  [14.104769185000066, 123.06280175200004],
  [14.105974250000031, 123.0626346890001],
  [14.106316732000039, 123.06260923400009],
  [14.106789370000058, 123.06262992800009],
  [14.10687733900005, 123.06245966400002],
  [14.107036453000035, 123.06233789100008],
  [14.107227824000063, 123.06205782900008],
  [14.107226283000045, 123.06188756300003],
  [14.107354376000046, 123.06177617600008],
  [14.107592285000067, 123.06129909700007],
  [14.107826702000068, 123.06109700700006],
  [14.108080890000053, 123.06096334000006],
  [14.108218803000057, 123.06096175000005],
  [14.10849659300004, 123.06105404800007],
  [14.108627210000066, 123.06089048800004],
  [14.10889419800003, 123.06083638600012],
  [14.10938356500003, 123.06085230300005],
  [14.109428907000051, 123.0607536440001],
  [14.10965114000004, 123.06081729700009],
  [14.109816082000066, 123.06073455100011],
  [14.110575574000052, 123.06074092200004],
  [14.110732989000041, 123.06078866100006],
  [14.110936494000043, 123.06060743800003],
  [14.111532201000045, 123.06054219900011],
  [14.111824648000038, 123.06048198300005],
  [14.112292724000042, 123.06045334300006],
  [14.112751314000036, 123.06046130200002],
  [14.113432441000043, 123.06006957200009],
  [14.113704635000033, 123.05983150400004],
  [14.114092630000073, 123.05936527500012],
  [14.114733884000032, 123.05896160200007],
  [14.115187878000029, 123.05876526000009],
  [14.11523310900003, 123.05869863300006],
  [14.115808213000037, 123.0582869210001],
  [14.116108256000075, 123.05804322100005],
  [14.11629021400006, 123.0579533450001],
  [14.116589702000056, 123.05762528800005],
  [14.116836417000059, 123.05750975300009],
  [14.11713175400007, 123.05718556200009],
  [14.11750034000005, 123.05692238000006],
  [14.11797159400004, 123.05669800600003],
  [14.118400493000024, 123.05659972600006],
  [14.118649573000027, 123.05649876000007],
  [14.11893202500005, 123.05594711200001],
  [14.119094318000066, 123.05583229400008],
  [14.119325980000042, 123.0557623310001],
  [14.12007995600004, 123.05564905200004],
  [14.120504535000066, 123.05544506100011],
  [14.120682224000063, 123.05525683300004],
  [14.120927710000046, 123.05521352000005],
  [14.121092781000073, 123.05511190800007],
  [14.121280729000034, 123.0549170160001],
  [14.121422883000037, 123.05493700200009],
  [14.121626927000023, 123.05507206200002],
  [14.121901122000054, 123.05508038700009],
  [14.122217857000066, 123.0549750890001],
  [14.122353549000024, 123.05506670200009],
  [14.122594243000037, 123.05511833600008],
  [14.122974138000075, 123.05505292200007],
  [14.123383327000056, 123.05517617900011],
  [14.123723853000058, 123.0550379020001],
  [14.124155421000069, 123.05473470200002],
  [14.124221651000028, 123.0547180430001],
  [14.125275373000022, 123.05474800600007],
  [14.126042617000053, 123.05472800300004],
  [14.126212146000057, 123.05460422300007],
  [14.126475026000037, 123.05428490300005],
  [14.126869885000076, 123.05363138300004],
  [14.127202359000023, 123.05323008500011],
  [14.127600028000074, 123.05264625000007],
  [14.12784208200003, 123.0524366410001],
  [14.128129254000044, 123.05235667600004],
  [14.12829240800005, 123.05237665900006],
  [14.128441487000032, 123.05231002500011],
  [14.128422098000044, 123.05216843500011],
  [14.128589920000024, 123.05188491500007],
  [14.128765995000037, 123.05179495700008],
  [14.128791480000075, 123.05203208400007],
  [14.128880625000022, 123.0520515830001],
  [14.12925815400007, 123.05194258100005],
  [14.129348482000069, 123.05180024100002],
  [14.12932040100003, 123.05160269700002],
  [14.129533442000024, 123.05171508600006],
  [14.12960669000006, 123.05194375500002],
  [14.12984193400007, 123.05191139200008],
  [14.129876617000036, 123.05201527300005],
  [14.129602478000038, 123.05207999400011]
]

const polygonMercedes4 = [
  [14.067508729000053, 123.03237773700005],
  [14.067463048000036, 123.03270534300009],
  [14.067709593000075, 123.03235682700006],
  [14.06814987100006, 123.03190200200004],
  [14.068890084000031, 123.0315691620001],
  [14.068875379000076, 123.03089146100001],
  [14.068921878000026, 123.03036428900009],
  [14.068887664000044, 123.0299334770001],
  [14.069189427000026, 123.02978941200001],
  [14.069491664000054, 123.02969367300011],
  [14.069770798000036, 123.02949031000003],
  [14.070014988000025, 123.02940671300007],
  [14.071061753000038, 123.02825571100004],
  [14.071266268000045, 123.0266313940001],
  [14.073831640000037, 123.01219174500011],
  [14.074043048000021, 123.01115210000012],
  [14.074873835000062, 123.00992660000009],
  [14.075119931000074, 123.00976083300009],
  [14.075468034000039, 123.00960747200008],
  [14.075764739000022, 123.00962036200008],
  [14.076038437000022, 123.00976420000006],
  [14.076150407000057, 123.00993727500008],
  [14.076389043000063, 123.01055632700002],
  [14.076569033000055, 123.01091136000002],
  [14.076729346000036, 123.01103049700009],
  [14.07699745900004, 123.0111042420001],
  [14.077586391000068, 123.01109217100009],
  [14.077814892000049, 123.01105861100007],
  [14.078030160000026, 123.01088813900003],
  [14.078297687000031, 123.010617797],
  [14.078745831000049, 123.01021940200008],
  [14.079205109000043, 123.00993109900003],
  [14.079439187000048, 123.00957646100005],
  [14.079451610000035, 123.00931080200007],
  [14.079411705000041, 123.00872933900007],
  [14.079535581000073, 123.00861800500002],
  [14.079770755000027, 123.0085989700001],
  [14.079919496000059, 123.00871037500008],
  [14.079908543000045, 123.00804517400002],
  [14.080089645000044, 123.00761211300005],
  [14.080272433000061, 123.00684870300006],
  [14.080326195000055, 123.00594551400002],
  [14.080455222000069, 123.00512834300002],
  [14.08054124000006, 123.00425741100003],
  [14.080519735000053, 123.00363378200007],
  [14.080433717000062, 123.00331121400006],
  [14.080476726000029, 123.00265532600008],
  [14.08062725800005, 123.00211771400006],
  [14.080724028000077, 123.0012360290001],
  [14.080734780000057, 123.00025757500009],
  [14.080218672000058, 122.99952642100004],
  [14.079863848000059, 122.99942965200012],
  [14.079820839000035, 122.99891354300007],
  [14.07993911400007, 122.99868774600009],
  [14.08003588400004, 122.99823615100001],
  [14.080369204000021, 122.99820389500007],
  [14.080573496000056, 122.99805336300005],
  [14.080756285000064, 122.99770929100009],
  [14.080896064000058, 122.99733296200009],
  [14.081143366000049, 122.99680610300004],
  [14.081663390000074, 122.99624416300003],
  [14.082366187000048, 122.99641474900011],
  [14.083544158000052, 122.99649992700006],
  [14.084704397000053, 122.99695530800011],
  [14.08646264600003, 122.99699962200009],
  [14.088577757000053, 122.99685319000002],
  [14.090169417000027, 122.99634017200003],
  [14.091679501000044, 122.99589311900002],
  [14.092476592000025, 122.99528428700012],
  [14.092468455000073, 122.99498612200011],
  [14.092575966000027, 122.99462787000004],
  [14.092765733000022, 122.99453363100008],
  [14.09297176800004, 122.99457621700003],
  [14.093235535000076, 122.99471254200012],
  [14.093367417000024, 122.99466140600009],
  [14.093392073000075, 122.99443139300001],
  [14.09351549300004, 122.99398034400008],
  [14.093614423000076, 122.99380228300004],
  [14.093762714000036, 122.9938022770001],
  [14.094347890000051, 122.99389597600009],
  [14.094455245000063, 122.9938537380001],
  [14.094596318000072, 122.99342949900006],
  [14.097237409000059, 122.99328877500011],
  [14.09751234600003, 122.99308079900004],
  [14.09768712500005, 122.99305860400011],
  [14.098045414000069, 122.99287336900011],
  [14.09824781900005, 122.99290904400004],
  [14.098408685000038, 122.99316505200011],
  [14.098441496000078, 122.99346904000004],
  [14.098528491000025, 122.99353123000003],
  [14.098750141000039, 122.99348992500006],
  [14.098891394000077, 122.99338647400009],
  [14.099114297000028, 122.99337250400004],
  [14.099300773000039, 122.99355885800003],
  [14.09946816400003, 122.99359334300004],
  [14.099817537000035, 122.99353115100007],
  [14.100213308000036, 122.99326701100006],
  [14.100361948000057, 122.9930878560001],
  [14.100436706000039, 122.99281951300009],
  [14.100423723000063, 122.99258517900012],
  [14.100329927000075, 122.99235687400005],
  [14.100417204000053, 122.99222550500008],
  [14.100618415000042, 122.99219765300006],
  [14.100913456000058, 122.99251609300006],
  [14.101074508000067, 122.99249495900006],
  [14.101195494000024, 122.99230732400008],
  [14.101457517000028, 122.9922997760001],
  [14.101464415000066, 122.9921538850001],
  [14.101572143000055, 122.99204241200005],
  [14.101659329000029, 122.9921882650001],
  [14.101907537000045, 122.99222388400005],
  [14.10202097000007, 122.99250889200005],
  [14.102228401000048, 122.99263436800004],
  [14.102208165000036, 122.99290410800006],
  [14.102389165000034, 122.992994242],
  [14.102463054000054, 122.99311867100005],
  [14.102783834000036, 122.99317546300006],
  [14.102966662000028, 122.99328172600008],
  [14.103268932000049, 122.99390410100011],
  [14.103419755000061, 122.99426455800005],
  [14.103610504000073, 122.9944202160001],
  [14.10376136900004, 122.99462497600007],
  [14.10403993500006, 122.99469067900009],
  [14.10419098400007, 122.99482179600011],
  [14.104452704000039, 122.9952803540001],
  [14.104992088000074, 122.99541604300009],
  [14.105075665000072, 122.99491105400011],
  [14.105408251000028, 122.9933099210001],
  [14.105448526000032, 122.99237450800001],
  [14.10553184500003, 122.9916032430001],
  [14.105763546000048, 122.99037352700009],
  [14.105945205000069, 122.98931916800007],
  [14.106132091000063, 122.98810688800006],
  [14.106273061000024, 122.98701661200005],
  [14.106465763000074, 122.98598927100011],
  [14.106604391000076, 122.98548723600004],
  [14.106749825000065, 122.98508577600012],
  [14.106813643000066, 122.98431102500001],
  [14.106812891000061, 122.9838381300001],
  [14.106661417000055, 122.98278402800008],
  [14.106695971000022, 122.98220018100005],
  [14.106965456000069, 122.98112990300001],
  [14.107187125000053, 122.98058548000006],
  [14.107414593000044, 122.97995448200004],
  [14.107595642000035, 122.97933203000002],
  [14.10761879000006, 122.97885861200007],
  [14.113990141000045, 122.97959331200002],
  [14.11396028200005, 122.9799790730001],
  [14.114034220000065, 122.9800212350001],
  [14.11410415100005, 122.98041380300003],
  [14.114752616000033, 122.98062834500001],
  [14.114835554000024, 122.98094917600008],
  [14.114596145000064, 122.98134828400009],
  [14.11486588200006, 122.98138946000006],
  [14.115249609000045, 122.98115269200002],
  [14.115914944000053, 122.9810312400001],
  [14.116093701000068, 122.98087103900002],
  [14.116441292000047, 122.98070473100006],
  [14.116632043000038, 122.98065601200005],
  [14.116873129000055, 122.98046470500003],
  [14.11712602700004, 122.98031892200004],
  [14.117334477000043, 122.98035837600003],
  [14.117445413000041, 122.98054653500003],
  [14.11743348400006, 122.98098955800003],
  [14.117538572000058, 122.98101762200008],
  [14.11781488400004, 122.98136275200011],
  [14.117908317000058, 122.98143097800005],
  [14.117954318000045, 122.98173969700008],
  [14.11824727100003, 122.98184528600007],
  [14.118467234000036, 122.98182266200001],
  [14.118562772000075, 122.9817373200001],
  [14.118581692000078, 122.98155667800006],
  [14.118762006000054, 122.98150814500002],
  [14.119058119000044, 122.98176423100006],
  [14.119102457000054, 122.98195135800006],
  [14.119106306000049, 122.98246992800011],
  [14.11929348800004, 122.98261856400006],
  [14.119492179000076, 122.9826625500001],
  [14.119787417000055, 122.98256550400004],
  [14.120075198000052, 122.98275729600005],
  [14.12029274200006, 122.98274914600006],
  [14.120788237000056, 122.98256569800003],
  [14.120979509000051, 122.98266628500005],
  [14.121062054000049, 122.98250179500008],
  [14.121629849000044, 122.98254936100011],
  [14.122055727000031, 122.98278181],
  [14.122109494000028, 122.98296798400008],
  [14.121938586000056, 122.98331545200006],
  [14.12140838700003, 122.983600593],
  [14.121477668000068, 122.9837965050001],
  [14.121234852000043, 122.98407183000006],
  [14.121351665000077, 122.98423534200003],
  [14.121543921000068, 122.98429557300005],
  [14.122045250000042, 122.984562062],
  [14.122003717000041, 122.98526113800006],
  [14.122087427000054, 122.98538191300008],
  [14.122380243000066, 122.98551125800009],
  [14.122581037000032, 122.98553710800002],
  [14.122731611000063, 122.9854766840001],
  [14.122932405000029, 122.98548527800006],
  [14.123032817000023, 122.98558878800009],
  [14.123242073000029, 122.98564051000005],
  [14.123451455000065, 122.98561457400001],
  [14.123585458000036, 122.9856749700001],
  [14.123677502000078, 122.98587347000012],
  [14.123903851000023, 122.98577852400001],
  [14.123977745000047, 122.98586184300007],
  [14.12394570400005, 122.98631857700002],
  [14.124264181000058, 122.9863468640001],
  [14.125449614000047, 122.98706502300001],
  [14.125909061000073, 122.9874005150001],
  [14.126106937000031, 122.98761802100012],
  [14.126267905000077, 122.98809648300005],
  [14.126235510000072, 122.98830032400008],
  [14.12608352500007, 122.98854670300011],
  [14.125784501000055, 122.98870283700012],
  [14.12549475700007, 122.9887783800001],
  [14.125384285000052, 122.98889679000001],
  [14.12539326600006, 122.98910541400005],
  [14.125296451000054, 122.98935659400001],
  [14.125043529000038, 122.98939424600007],
  [14.124389173000054, 122.98916033400008],
  [14.124039627000059, 122.98926425800005],
  [14.12385542800007, 122.98953904600012],
  [14.123809268000059, 122.98971442000004],
  [14.123877883000034, 122.99005586100009],
  [14.124169786000039, 122.99037384500002],
  [14.124459491000039, 122.99033623300011],
  [14.124786043000029, 122.99023702800002],
  [14.125287294000032, 122.99015697800007],
  [14.125682727000026, 122.99014319000003],
  [14.126046914000028, 122.99022065000008],
  [14.12642285000004, 122.99044744600008],
  [14.12673522700004, 122.99071804300002],
  [14.127008266000075, 122.99112768600003],
  [14.126869714000065, 122.9913302110001],
  [14.126692984000044, 122.99148196600004],
  [14.126392703000022, 122.99184708200005],
  [14.125579924000021, 122.99238746000003],
  [14.12542606900007, 122.99279238100007],
  [14.125264128000026, 122.99296352700003],
  [14.125291332000074, 122.99324486100011],
  [14.125068662000047, 122.99351201800005],
  [14.12483385400003, 122.9936121940001],
  [14.124651680000056, 122.99362888100006],
  [14.124287323000033, 122.99380001500003],
  [14.124165861000051, 122.99402960600003],
  [14.123890842000037, 122.99431122200008],
  [14.123963694000054, 122.99462014000005],
  [14.123854379000022, 122.99479963600004],
  [14.123639809000053, 122.99498330100005],
  [14.12351834900005, 122.99516279700003],
  [14.123485949000042, 122.99538404200007],
  [14.122996059000059, 122.99604358400006],
  [14.122833385000035, 122.99639016700007],
  [14.122529739000072, 122.99675750000006],
  [14.122092504000022, 122.99701211100012],
  [14.121711933000029, 122.99741700900006],
  [14.121626905000028, 122.99760068100011],
  [14.121490426000037, 122.9977332520001],
  [14.121348718000036, 122.99797953400002],
  [14.121146287000045, 122.99819659000002],
  [14.121105793000027, 122.99834269300004],
  [14.120765710000057, 122.99868497200009],
  [14.120583528000054, 122.99879766600009],
  [14.120271786000046, 122.99908150500005],
  [14.11993715500006, 122.99915725400001],
  [14.119645189000039, 122.9992750340001],
  [14.119397647000028, 122.99944517400002],
  [14.119194531000062, 122.99965458500003],
  [14.119035836000023, 122.99996216400007],
  [14.118832033000047, 123.00022679000006],
  [14.11874257200003, 123.00025263200007],
  [14.117681383000047, 123.00028160900001],
  [14.117271463000066, 123.00043193300007],
  [14.117109239000058, 123.00053510000009],
  [14.116917971000078, 123.00073486000008],
  [14.116554590000021, 123.00094712400005],
  [14.116270188000044, 123.00101473200004],
  [14.115790121000032, 123.00128690400004],
  [14.114826682000057, 123.00212930400005],
  [14.114498943000058, 123.00261053200006],
  [14.114093954000055, 123.00337549700009],
  [14.113647032000074, 123.00387472700004],
  [14.11327688800003, 123.00450742300006],
  [14.112872965000065, 123.00525820200005],
  [14.112299405000044, 123.00649494400011],
  [14.111862091000035, 123.00779936100002],
  [14.111659391000046, 123.00847383900009],
  [14.111495490000038, 123.00951659100008],
  [14.111408002000076, 123.01020067400009],
  [14.111396160000027, 123.01060276800001],
  [14.111441318000061, 123.01102659100002],
  [14.111564031000057, 123.01169794700002],
  [14.111752403000025, 123.01296227700004],
  [14.111724404000029, 123.01350788100001],
  [14.11176911800004, 123.01408408800012],
  [14.111682032000033, 123.01440415500008],
  [14.111483201000055, 123.014426092],
  [14.111177128000065, 123.01428969200003],
  [14.110902934000023, 123.01426510200008],
  [14.110481522000043, 123.01405518500007],
  [14.110053281000035, 123.01359892100004],
  [14.109779640000056, 123.01322372000004],
  [14.10947453700004, 123.01314427600005],
  [14.10928536700004, 123.01299384200001],
  [14.109165100000041, 123.01302496700009],
  [14.108566978000056, 123.01254765800002],
  [14.108446736000076, 123.01260536300003],
  [14.10802743000005, 123.01186560200006],
  [14.10815195300006, 123.01179017400011],
  [14.107859191000045, 123.0111126920001],
  [14.10734949500005, 123.01064851500007],
  [14.107275843000025, 123.00997759200004],
  [14.107146445000069, 123.00942199100007],
  [14.106985655000074, 123.00904829700005],
  [14.107014063000065, 123.00864024000009],
  [14.107103068000072, 123.00839370300002],
  [14.107259038000052, 123.00819310600002],
  [14.107035412000073, 123.00786144400001],
  [14.106790144000058, 123.00798983900006],
  [14.106570461000047, 123.00821678500006],
  [14.106312218000028, 123.00813160400003],
  [14.106181418000062, 123.0079805790001],
  [14.106047658000023, 123.00807600400003],
  [14.105664390000072, 123.00792404200001],
  [14.10518405700003, 123.00737212700005],
  [14.104963221000048, 123.00700801800008],
  [14.10486824800006, 123.00666895400002],
  [14.104818089000048, 123.00617155300006],
  [14.10465458300007, 123.00593259700008],
  [14.104556522000053, 123.00549887400007],
  [14.104430832000048, 123.00521108200007],
  [14.104316396000058, 123.0048475430001],
  [14.103851408000025, 123.00491597900009],
  [14.103816169000027, 123.0056235620001],
  [14.103707738000026, 123.00582468700009],
  [14.10363065200005, 123.006130686],
  [14.103469412000038, 123.00644960200009],
  [14.103464153000061, 123.00678272400012],
  [14.103541798000037, 123.00687259800009],
  [14.10365654900005, 123.00661727800002],
  [14.10382411200004, 123.00653717000012],
  [14.103964838000024, 123.00689136800008],
  [14.104082604000041, 123.00736665700003],
  [14.104233670000042, 123.00757392500009],
  [14.104452129000038, 123.008146269],
  [14.104278329000067, 123.00828039800001],
  [14.10428272300004, 123.00850013900003],
  [14.10434573300006, 123.00865780100003],
  [14.104314557000066, 123.0089349110001],
  [14.104239175000032, 123.00899548200005],
  [14.104321126000059, 123.00924171100007],
  [14.104514065000046, 123.00902330100007],
  [14.104617004000033, 123.00930624000011],
  [14.104970345000027, 123.0099394450001],
  [14.105096356000047, 123.0102482740001],
  [14.105128003000061, 123.01048374900006],
  [14.105112245000043, 123.01083302200004],
  [14.105047217000049, 123.011056499],
  [14.10483769900003, 123.01115333700011],
  [14.104649857000027, 123.011145886],
  [14.104322766000053, 123.01103915200008],
  [14.104134121000072, 123.01103440800011],
  [14.103581990000066, 123.01085887800002],
  [14.103250711000044, 123.01066437300005],
  [14.103080469000076, 123.01037024100003],
  [14.103057462000038, 123.01007136400005],
  [14.102795198000024, 123.0099527650001],
  [14.102537539000025, 123.01036075800005],
  [14.102367298000047, 123.0104129450001],
  [14.102192455000022, 123.0103797380001],
  [14.101631112000064, 123.01008561200001],
  [14.100956243000041, 123.00953708300005],
  [14.100758393000035, 123.00926667400006],
  [14.100712379000072, 123.00901049700008],
  [14.100767590000032, 123.00884445600002],
  [14.101103464000062, 123.00854557700006],
  [14.100652551000053, 123.00807204900002],
  [14.100440904000038, 123.00822386200002],
  [14.100238456000056, 123.00820014500005],
  [14.099989996000033, 123.00808155000004],
  [14.098166380000066, 123.01055099500002],
  [14.098424044000069, 123.01130529500006],
  [14.098566678000054, 123.0116421240001],
  [14.098884155000064, 123.01208441000006],
  [14.09911420900005, 123.01232160900008],
  [14.099381072000028, 123.01253983400011],
  [14.099624930000061, 123.01280075600005],
  [14.099670941000056, 123.01305219000005],
  [14.099546711000073, 123.0133273450001],
  [14.099367268000037, 123.01347440800009],
  [14.099187825000058, 123.01349812800004],
  [14.098847344000035, 123.01324194600011],
  [14.098755323000034, 123.0130853930001],
  [14.09875072400007, 123.01266317600005],
  [14.098644899000021, 123.01263471200002],
  [14.098396439000055, 123.01291460700008],
  [14.09825380600006, 123.01269163900008],
  [14.097885719000033, 123.01264894300004],
  [14.09766486600006, 123.0124639280001],
  [14.097559042000057, 123.01216505800005],
  [14.097467020000067, 123.01204645900009],
  [14.097246165000058, 123.01196581200008],
  [14.097075923000034, 123.01184246900004],
  [14.096905680000077, 123.01181875000009],
  [14.096770716000037, 123.01568192900004],
  [14.09712520000005, 123.01576398200007],
  [14.097429042000044, 123.01589824900009],
  [14.097754591000069, 123.01576398800012],
  [14.098210109000036, 123.01593740900012],
  [14.098463170000059, 123.01593266800012],
  [14.098983093000072, 123.01597537100008],
  [14.099130329000047, 123.01589947000002],
  [14.09920394900007, 123.01576189500008],
  [14.099503021000032, 123.01564329900009],
  [14.099576640000066, 123.01553418800006],
  [14.09988491200005, 123.01556265700003],
  [14.100082760000078, 123.015482012],
  [14.100542870000027, 123.01552471700006],
  [14.10107660400007, 123.01528277900002],
  [14.101372757000036, 123.01497720900011],
  [14.10155680300005, 123.01491553800008],
  [14.102265370000055, 123.01506735600003],
  [14.102440211000044, 123.01507210200009],
  [14.102766890000055, 123.01492029500002],
  [14.102969338000037, 123.01487760100008],
  [14.103475459000038, 123.01491556100007],
  [14.103696311000022, 123.01508635300002],
  [14.104156794000062, 123.01524193300008],
  [14.104409659000055, 123.01516],
  [14.104759513000033, 123.01486204000003],
  [14.104810092000037, 123.01464601400005],
  [14.10500573400003, 123.01467817800005],
  [14.105063784000038, 123.01555954100002],
  [14.105121826000072, 123.01577075400007],
  [14.10533253500006, 123.01623910900003],
  [14.105400363000058, 123.01653530500005],
  [14.105671674000064, 123.01734826300003],
  [14.105777404000037, 123.01744699500011],
  [14.106270984000048, 123.01730759600002],
  [14.106518351000034, 123.01768812700004],
  [14.106823571000064, 123.01765932900003],
  [14.107117507000055, 123.01784382900007],
  [14.107307023000033, 123.01783765700009],
  [14.10766211400005, 123.0181379710001],
  [14.10790150300005, 123.01818322400004],
  [14.108124932000067, 123.01827990200002],
  [14.108342137000022, 123.01857838600006],
  [14.108517688000063, 123.0185516470001],
  [14.108599479000077, 123.0186298110001],
  [14.108548555000027, 123.01893236400008],
  [14.108400933000041, 123.0190763490001],
  [14.10815156800004, 123.0196605220001],
  [14.108075761000066, 123.01973045700004],
  [14.108304890000056, 123.02010700300002],
  [14.10847246000003, 123.02020368100011],
  [14.108606120000047, 123.02006998000002],
  [14.108731798000065, 123.02010083500011],
  [14.108761719000029, 123.020292132],
  [14.109172666000063, 123.02051222900002],
  [14.109234507000053, 123.02062330500007],
  [14.109632144000045, 123.0209821310001],
  [14.109779766000031, 123.021021216],
  [14.110007181000071, 123.02123308500006],
  [14.110019149000038, 123.02136061700003],
  [14.11044006700007, 123.02198525600011],
  [14.110609623000073, 123.02258590500003],
  [14.11075125800005, 123.02276897800004],
  [14.110940772000049, 123.02282863500011],
  [14.111046504000058, 123.02270727500002],
  [14.11118814200006, 123.02269905000003],
  [14.11120610100005, 123.02236376400003],
  [14.111299862000067, 123.02229794300001],
  [14.111472408000054, 123.02277267800002],
  [14.111577517000057, 123.02289408700005],
  [14.111422841000035, 123.02296871100009],
  [14.111561713000071, 123.02309898200008],
  [14.111536542000067, 123.02333131800003],
  [14.111869162000062, 123.02339867900002],
  [14.111963760000037, 123.02356315100008],
  [14.11201279100004, 123.02378664300011],
  [14.111960578000037, 123.02392559100008],
  [14.11211541800003, 123.02400645800003],
  [14.112107089000062, 123.02445228700003],
  [14.112167655000064, 123.0246796560001],
  [14.112051379000036, 123.02480738100007],
  [14.11174696300003, 123.02470646200004],
  [14.111672643000077, 123.02477786700001],
  [14.111684593000064, 123.02497679900011],
  [14.111550728000054, 123.02514378300009],
  [14.11137683000004, 123.02508283100008],
  [14.111211095000044, 123.0249033340001],
  [14.111090701000023, 123.02495462600007],
  [14.111071029000072, 123.02512265400003],
  [14.111182040000074, 123.02521012600005],
  [14.111018899000044, 123.02536394200001],
  [14.110768121000035, 123.02547739200008],
  [14.110792381000067, 123.02568757600011],
  [14.110914110000067, 123.02582951500005],
  [14.110995562000028, 123.02614464600003],
  [14.110876623000024, 123.0263266070001],
  [14.110982728000067, 123.026441599],
  [14.110992284000076, 123.02677102900009],
  [14.110923178000064, 123.02696120600001],
  [14.110714098000074, 123.02725477800004],
  [14.11018511800006, 123.02779157900011],
  [14.109956192000027, 123.02793300300004],
  [14.109864756000036, 123.02806943400003],
  [14.10943837700006, 123.0283923830001],
  [14.109299140000076, 123.02840793200005],
  [14.109062730000062, 123.02879119300007],
  [14.108840033000035, 123.02893364200008],
  [14.108566478000057, 123.02902093400007],
  [14.108393689000025, 123.02916700300011],
  [14.108230598000034, 123.02914782700009],
  [14.107935143000077, 123.02926510200007],
  [14.107704037000076, 123.02921052200008],
  [14.107581725000045, 123.02927005800007],
  [14.107480457000065, 123.02957284000001],
  [14.107254880000028, 123.02966258600009],
  [14.106910019000054, 123.02985537000006],
  [14.106820278000043, 123.02999734700006],
  [14.106531750000045, 123.03012762000003],
  [14.105725739000036, 123.03035092700009],
  [14.10539816000005, 123.03035816600004],
  [14.10530490800005, 123.0301109190001],
  [14.105709686000068, 123.02979956600007],
  [14.106509797000058, 123.0295409250001],
  [14.106733456000029, 123.0295494400001],
  [14.107153075000042, 123.02917233100004],
  [14.107742, 123.02869844500003],
  [14.10788702800005, 123.02844631800008],
  [14.107755187000066, 123.0283875990001],
  [14.107233421000046, 123.02867047400002],
  [14.106914534000055, 123.02894122600003],
  [14.106766504000063, 123.0290191470001],
  [14.106191158000058, 123.02915817100006],
  [14.10587871000007, 123.02911523800003],
  [14.105535413000041, 123.02916697800003],
  [14.105275455000026, 123.02925137900002],
  [14.105003487000033, 123.02921427000001],
  [14.104713700000048, 123.02927053300004],
  [14.104506411000045, 123.02948315200001],
  [14.104266119000044, 123.02936011200006],
  [14.103986621000047, 123.02942719900011],
  [14.103922356000055, 123.02958342600004],
  [14.103702206000037, 123.02958323900009],
  [14.103701300000068, 123.02977679800006],
  [14.103860368000028, 123.02988042400011],
  [14.103993484000057, 123.03007608200005],
  [14.104053412000042, 123.03025967100007],
  [14.10402227000003, 123.03060022900002],
  [14.10395185300007, 123.03070470800003],
  [14.103600860000029, 123.03101573800006],
  [14.10313323500003, 123.03097645500009],
  [14.102947887000028, 123.0309132110001],
  [14.103166129000044, 123.03052783300006],
  [14.103134343000022, 123.03040478100002],
  [14.102842536000026, 123.03044179200003],
  [14.102336292000075, 123.03062210300004],
  [14.102104182000062, 123.03078504700011],
  [14.101801654000042, 123.03105246900009],
  [14.101566481000077, 123.03111687500007],
  [14.101148317000025, 123.0314039220001],
  [14.10087573900006, 123.031548154],
  [14.100515275000078, 123.03168870700006],
  [14.099974316000043, 123.03193400200007],
  [14.099850682000067, 123.03201594200004],
  [14.099605521000058, 123.03238273000011],
  [14.09951407400007, 123.03284675900011],
  [14.099489094000035, 123.03315021800006],
  [14.09939924400004, 123.03347432300006],
  [14.09938091500004, 123.03378434100011],
  [14.099417473000074, 123.03408273800005],
  [14.099379198000065, 123.03426622900008],
  [14.099407426000027, 123.03468468800008],
  [14.099575358000038, 123.03516292300003],
  [14.099696564000055, 123.03540475600005],
  [14.099968140000044, 123.03569264900011],
  [14.100237760000027, 123.03619673300011],
  [14.100435062000031, 123.0369380520001],
  [14.100648375000048, 123.03798971700007],
  [14.100646404000031, 123.03835236500004],
  [14.100496985000063, 123.03880453600004],
  [14.100415476000023, 123.03914657100006],
  [14.100415457000054, 123.03935859700005],
  [14.100541518000057, 123.03978412900005],
  [14.100713732000031, 123.04008230500006],
  [14.100968726000076, 123.04030049200003],
  [14.101217086000077, 123.04028651500005],
  [14.101319887000045, 123.04048455000009],
  [14.101350163000063, 123.04075821200001],
  [14.101196879000042, 123.04102423100005],
  [14.100871389000076, 123.04106772700004],
  [14.100447598000073, 123.04141292200006],
  [14.100344767000024, 123.04153092500007],
  [14.100047311000026, 123.04172573400001],
  [14.099801115000048, 123.04174755400004],
  [14.099830200000042, 123.0419015760001],
  [14.099581843000067, 123.04228159600007],
  [14.099289058000068, 123.04256967700007],
  [14.098676650000073, 123.04303595300007],
  [14.098527250000075, 123.04323596000006],
  [14.098049022000055, 123.04349379100006],
  [14.09783937900005, 123.04356177400007],
  [14.097204864000048, 123.0436960190001],
  [14.096824945000037, 123.04371397700004],
  [14.096489339000073, 123.04368793600008],
  [14.095546553000077, 123.0436858280001],
  [14.095136302000071, 123.04374578700003],
  [14.094879238000033, 123.04369975300006],
  [14.094173162000061, 123.0436396660001],
  [14.092776385000036, 123.04367951000006],
  [14.091448196000044, 123.04364735500008],
  [14.091108591000022, 123.04355099500003],
  [14.090937871000051, 123.04355897500011],
  [14.09066784600003, 123.04348293800001],
  [14.090491307000036, 123.04349091800009],
  [14.090394317000062, 123.04340890100002],
  [14.089982082000063, 123.04339085300012],
  [14.089811908000058, 123.04342506800003],
  [14.089033554000025, 123.04331964500011],
  [14.088734217000024, 123.0433196140001],
  [14.088377775000026, 123.04319953400011],
  [14.087725876000036, 123.04325360000007],
  [14.087472091000052, 123.04331489600008],
  [14.087162281000076, 123.04330259900007],
  [14.086093493000021, 123.0435038820001],
  [14.08539647400005, 123.04375985100012],
  [14.084309142000052, 123.04420849000007],
  [14.083685414000058, 123.04454745200007],
  [14.083371099000033, 123.04468965100011],
  [14.08283472900007, 123.04480228400007],
  [14.082099355000025, 123.0450145310001],
  [14.081600760000072, 123.04526969300002],
  [14.08149964200004, 123.04529216700007],
  [14.080932160000032, 123.04568233700002],
  [14.080725939000047, 123.04579369400005],
  [14.079734363000057, 123.04606135000006],
  [14.079381908000073, 123.04619826100009],
  [14.078712881000058, 123.04640311700007],
  [14.07792332300005, 123.04659107600003],
  [14.076709123000057, 123.04668086700008],
  [14.076228022000066, 123.04669102900004],
  [14.07600992500005, 123.04673801700005],
  [14.075795800000037, 123.04671959500001],
  [14.075034225000024, 123.04677627900003],
  [14.074270347000038, 123.04694303300005],
  [14.07393795400003, 123.04703618100007],
  [14.073679780000077, 123.04715406500009],
  [14.072642849000033, 123.04770907500006],
  [14.072195886000031, 123.0480454100001],
  [14.071739140000034, 123.04826312800003],
  [14.071054021000066, 123.04840816300009],
  [14.070217949000039, 123.0484509800001],
  [14.069853829000067, 123.04842640600009],
  [14.069339754000055, 123.04845495500001],
  [14.068418247000068, 123.04823409000005],
  [14.06817948400004, 123.04809366100005],
  [14.06781628300007, 123.04769449100002],
  [14.067692554000075, 123.04746851200002],
  [14.067699124000058, 123.04720567900006],
  [14.067897280000068, 123.04670035400011],
  [14.067990476000034, 123.04659612400008],
  [14.068668595000076, 123.04528777200005],
  [14.068887646000064, 123.04450803500004],
  [14.068959060000054, 123.04413808700008],
  [14.068909572000052, 123.04336155500005],
  [14.068850129000054, 123.04299440600005],
  [14.068772831000047, 123.0427368600001],
  [14.068732892000071, 123.04220177900004],
  [14.068765934000055, 123.0419765900001],
  [14.06892890100005, 123.04140220500005],
  [14.068957280000063, 123.04089696200003],
  [14.069010143000071, 123.04056783600004],
  [14.069058633000054, 123.03975543600006],
  [14.069027849000065, 123.03912914600005],
  [14.069080701000075, 123.03895210600001],
  [14.069023468000069, 123.03875463000008],
  [14.069014306000042, 123.038449581],
  [14.068900818000031, 123.03809426200007],
  [14.06878320900006, 123.03761554500011],
  [14.06853353300005, 123.03684744000009],
  [14.068463388000055, 123.03636659900008],
  [14.068128050000041, 123.03571970400003],
  [14.06808884700007, 123.03554311100004],
  [14.068001727000023, 123.03478197200002],
  [14.067900621000035, 123.03434781500005],
  [14.067865913000048, 123.03405433400007],
  [14.067698758000063, 123.033747951],
  [14.067434628000058, 123.03284526300001],
  [14.067508729000053, 123.03237773700005]
]

const polygonMercedes5 = [
  [14.092064406000077, 123.08158253500005],
  [14.091983342000049, 123.0819672770001],
  [14.09192979200003, 123.08238382200011],
  [14.091870150000034, 123.082579269],
  [14.091663136000022, 123.08282208600008],
  [14.091209946000049, 123.08313008700009],
  [14.09096930000004, 123.0833505810001],
  [14.090112051000062, 123.08396179700003],
  [14.08979940100005, 123.08402020000005],
  [14.089655836000077, 123.08393439600002],
  [14.089536714000076, 123.0841379200001],
  [14.089211378000073, 123.08425058600005],
  [14.08869955800003, 123.08429336800009],
  [14.088387282000042, 123.0841656230001],
  [14.088111022000021, 123.0842463460001],
  [14.08741105100006, 123.08410445700008],
  [14.087200090000067, 123.083992523],
  [14.087079967000022, 123.08386168900006],
  [14.086693152000066, 123.08369202000006],
  [14.086436863000074, 123.08364049800002],
  [14.086291621000044, 123.08369880100008],
  [14.085834477000049, 123.08411406400012],
  [14.085637227000063, 123.08447329300009],
  [14.08546990900004, 123.08470084600003],
  [14.085041834000037, 123.08480640100004],
  [14.085008212000048, 123.08470394500011],
  [14.085220728000024, 123.08459206000009],
  [14.085262015000069, 123.08450379900012],
  [14.085281963000057, 123.08379497500005],
  [14.085417760000041, 123.08264382400012],
  [14.085557192000067, 123.08231788000012],
  [14.085791578000055, 123.08198709400006],
  [14.08610433900003, 123.08137551000004],
  [14.08654120500006, 123.08089001700012],
  [14.087289747000057, 123.08060202100012],
  [14.087589876000038, 123.08058944000004],
  [14.087914221000062, 123.08063833400001],
  [14.088601506000032, 123.080698116],
  [14.089174869000033, 123.08072812000012],
  [14.089619053000035, 123.08086599],
  [14.089964138000028, 123.08094356700008],
  [14.090576297000041, 123.08086758500008],
  [14.090687950000074, 123.08097603800002],
  [14.090911147000043, 123.08106117700004],
  [14.091062246000035, 123.08081997900001],
  [14.091348003000064, 123.08057230600002],
  [14.091636781000034, 123.08057233400007],
  [14.091843377000032, 123.08078917300008],
  [14.092070542000044, 123.08136659000002],
  [14.092064406000077, 123.08158253500005]
]

const polygonMercedes6 = [
  [14.091046713000026, 123.0904077880001],
  [14.091141957000048, 123.09074602900012],
  [14.090990272000056, 123.0908496830001],
  [14.090658680000047, 123.0907151130001],
  [14.08986227500003, 123.09098031500002],
  [14.089648698000076, 123.09124093800006],
  [14.08928661300007, 123.09134524100011],
  [14.088742296000078, 123.09181407100004],
  [14.088610647000053, 123.0919915400001],
  [14.088419276000025, 123.09236836800005],
  [14.08822163900004, 123.09265666500005],
  [14.087745203000054, 123.0929779710001],
  [14.087380525000071, 123.09334077000005],
  [14.08711773300007, 123.09389084000009],
  [14.086957681000058, 123.09402081100006],
  [14.086469739000052, 123.09461098000008],
  [14.086180419000073, 123.0948736580001],
  [14.085999342000036, 123.0949441030001],
  [14.085331577000034, 123.09500936500001],
  [14.084986197000035, 123.09506173500006],
  [14.085020833000044, 123.09514495600001],
  [14.084556894000059, 123.09521452800004],
  [14.084468455000035, 123.09517691300005],
  [14.084081317000027, 123.0952127590001],
  [14.083421387000044, 123.09522098000002],
  [14.083086198000046, 123.09527860300011],
  [14.082880515000056, 123.09527076800009],
  [14.082604289000074, 123.09548096300011],
  [14.082027622000055, 123.09562109900003],
  [14.081906501000049, 123.0955735880001],
  [14.08181796200006, 123.09529617500004],
  [14.08139122700004, 123.09564687400007],
  [14.081135750000044, 123.09608100100002],
  [14.080940093000038, 123.09627332200012],
  [14.08040881200003, 123.09568053800001],
  [14.080278010000029, 123.09546656400005],
  [14.07995496500007, 123.09455394000008],
  [14.079889496000021, 123.0942884100001],
  [14.079883581000047, 123.0939416010001],
  [14.079956553000045, 123.09376915200005],
  [14.080165123000029, 123.09350841900005],
  [14.080991180000069, 123.09291374200006],
  [14.081559953000067, 123.09241868800007],
  [14.082099534000065, 123.09217722400001],
  [14.082805928000028, 123.0917790960001],
  [14.083136396000043, 123.09147249500006],
  [14.083563668000068, 123.09121982200008],
  [14.083860750000042, 123.09089207400007],
  [14.084061698000028, 123.09055205600009],
  [14.084206596000058, 123.09018533500011],
  [14.084354561000055, 123.08969961100001],
  [14.084463108000023, 123.08920198800001],
  [14.084579956000027, 123.0880424180001],
  [14.08462785000006, 123.08702339600006],
  [14.08458220500006, 123.0865548490001],
  [14.084460295000042, 123.08601465700008],
  [14.084449188000065, 123.08560619600007],
  [14.084580887000072, 123.0852091810001],
  [14.084693126000047, 123.08506600300007],
  [14.084874004000028, 123.08533608200003],
  [14.084846119000076, 123.08574938700008],
  [14.085140506000073, 123.08594061100007],
  [14.085323, 123.08593730600012],
  [14.085527419000073, 123.08612696600005],
  [14.085613292000062, 123.08631025200009],
  [14.085777305000022, 123.08634560200005],
  [14.08598528500005, 123.08621122300008],
  [14.086119440000061, 123.08623849600008],
  [14.086412101000064, 123.08639434400004],
  [14.087188811000033, 123.08666454000002],
  [14.087276196000062, 123.08674329700011],
  [14.087918539000043, 123.0868057130001],
  [14.08827055300003, 123.08688594900002],
  [14.088563416000056, 123.08701285400002],
  [14.088875057000052, 123.0870174150001],
  [14.089077463000024, 123.08707897500005],
  [14.089401977000023, 123.08724767700005],
  [14.089677701000028, 123.08723146200009],
  [14.089949036000064, 123.08732448300009],
  [14.090081670000075, 123.0874402070001],
  [14.090056872000048, 123.08793011400007],
  [14.090131822000046, 123.08810697700005],
  [14.090385776000062, 123.08830385100009],
  [14.09057621200003, 123.08861896200006],
  [14.09056550400004, 123.08909177100008],
  [14.090708033000055, 123.089294203],
  [14.090809621000062, 123.0897492690001],
  [14.090998593000052, 123.08989436200011],
  [14.091175995000071, 123.09020207300011],
  [14.091046713000026, 123.0904077880001]
]

const polygonMercedes7 = [
  [14.090735954000024, 123.08078450800008],
  [14.090470538000034, 123.08075799900007],
  [14.090519079000046, 123.08050789200001],
  [14.090711734000024, 123.0803666700001],
  [14.090735954000024, 123.08078450800008]
]

const polygonMercedes8 = [
  [14.090019397000049, 123.10510410600011],
  [14.089880277000077, 123.10553376300004],
  [14.08965366600006, 123.10556770700009],
  [14.089604917000031, 123.10564158900002],
  [14.089693727000054, 123.10594480000009],
  [14.089576173000069, 123.10603492500002],
  [14.089590482000062, 123.10616054400009],
  [14.089503664000063, 123.1064047640001],
  [14.089575306000029, 123.10653778400001],
  [14.089536576000057, 123.10668260400007],
  [14.089282541000046, 123.10648895400004],
  [14.089039953000054, 123.10645357500005],
  [14.088862082000048, 123.10648742300009],
  [14.088715122000053, 123.1066249270001],
  [14.088918100000058, 123.10671865400002],
  [14.088933542000063, 123.10683426800006],
  [14.08867150900005, 123.1069063430001],
  [14.088611554000067, 123.10701197300011],
  [14.088429259000065, 123.10707571800003],
  [14.087976584000046, 123.10695801400004],
  [14.087477522000029, 123.10701969700006],
  [14.087253235000048, 123.10713127100007],
  [14.087031292000063, 123.10745207800005],
  [14.087022116000071, 123.1076679570001],
  [14.087157436000041, 123.10772778500007],
  [14.087158295000052, 123.10788227400008],
  [14.087293592000037, 123.10804575200007],
  [14.087020879000022, 123.1080692700001],
  [14.086914575000037, 123.10793370500005],
  [14.086829459000057, 123.10817088200008],
  [14.086640012000032, 123.10812390800004],
  [14.086448588000053, 123.10824345900005],
  [14.086121136000031, 123.10861468200005],
  [14.085807318000036, 123.10889644500003],
  [14.085629426000025, 123.10901400700004],
  [14.085492905000024, 123.10900998800003],
  [14.085192795000069, 123.10927049200006],
  [14.084882131000029, 123.10946305900006],
  [14.084503600000062, 123.10961171000008],
  [14.083735629000046, 123.10976818100005],
  [14.083594333000065, 123.1099874030001],
  [14.083277252000073, 123.11006705800003],
  [14.08306652400006, 123.11005106400012],
  [14.082350373000054, 123.1104957120001],
  [14.082332952000058, 123.11059138200005],
  [14.08178119200005, 123.11055736700007],
  [14.081370327000059, 123.11080243700007],
  [14.081053222000037, 123.1108541970001],
  [14.080810674000077, 123.11095517400008],
  [14.080632778000052, 123.11109067100006],
  [14.080555633000074, 123.1113648600001],
  [14.080371969000055, 123.11136083000008],
  [14.08028082100003, 123.11143655],
  [14.080099111000038, 123.11133883900004],
  [14.079986967000025, 123.11139063600001],
  [14.080104857000038, 123.11158201100011],
  [14.079863197000066, 123.11155803500003],
  [14.079643606000047, 123.11140806600008],
  [14.079327591000038, 123.11148462400001],
  [14.079213497000069, 123.11161216100004],
  [14.079128457000024, 123.11149653500001],
  [14.078903518000061, 123.11161453000011],
  [14.078493552000054, 123.11206799800004],
  [14.07809893700005, 123.11292289700009],
  [14.077993839000044, 123.1131038530001],
  [14.077819818000023, 123.11320346900004],
  [14.077684420000026, 123.11348447300009],
  [14.077647806000073, 123.11373299700006],
  [14.077672896000024, 123.11391637500003],
  [14.077868150000029, 123.11396226700003],
  [14.077944380000076, 123.11422908700001],
  [14.078241690000027, 123.114422708],
  [14.078260976000024, 123.11462203100007],
  [14.078378889000021, 123.1147037820001],
  [14.078390442000057, 123.11489911600006],
  [14.078165272000035, 123.11490319400002],
  [14.07810941300005, 123.11500902000012],
  [14.077920283000026, 123.11508138900001],
  [14.077893204000077, 123.11534134100009],
  [14.077999409000029, 123.11554042500006],
  [14.078211457000066, 123.11552681600006],
  [14.078325751000023, 123.1157022210001],
  [14.078531162000047, 123.1157296],
  [14.078632261000052, 123.1160495690001],
  [14.078262776000031, 123.11627801200007],
  [14.078191248000053, 123.1164943440001],
  [14.078278491000049, 123.11675744400009],
  [14.078570952000064, 123.11676435100003],
  [14.078900259000022, 123.11703688900002],
  [14.079096400000026, 123.11712175000002],
  [14.079104610000059, 123.11741892700002],
  [14.078925566000066, 123.1175862550001],
  [14.078778126000032, 123.11760158700008],
  [14.078602550000028, 123.11750419000009],
  [14.07863240000006, 123.11737952300007],
  [14.078551263000065, 123.1172360380001],
  [14.078561238000077, 123.11708403700004],
  [14.078124847000026, 123.11680222600012],
  [14.078028811000024, 123.11661091600001],
  [14.077732290000029, 123.11657668300006],
  [14.07785773300003, 123.11688913900002],
  [14.078180786000075, 123.11709806400006],
  [14.078116160000036, 123.11717319500008],
  [14.077925653000023, 123.1171014140001],
  [14.077784837000024, 123.1171218720001],
  [14.077822885000046, 123.1173473240001],
  [14.077740005000067, 123.11754572600012],
  [14.077895720000072, 123.11756626200008],
  [14.077894030000039, 123.11770289300011],
  [14.077738296000064, 123.117757503],
  [14.07767872200003, 123.11793852500011],
  [14.077113250000025, 123.11790763400006],
  [14.077002272000072, 123.11784953600011],
  [14.076696585000036, 123.11786312000004],
  [14.076279333000059, 123.11761834100002],
  [14.076104706000024, 123.11756691000005],
  [14.075833720000048, 123.1173322950001],
  [14.07582878900007, 123.117175169],
  [14.075340797000024, 123.11698030600007],
  [14.075153646000047, 123.11679751600002],
  [14.075079154000036, 123.11657034900009],
  [14.075142162000077, 123.11633297000003],
  [14.074968127000034, 123.11599491000004],
  [14.074714124000025, 123.11583959100005],
  [14.07451045700003, 123.11544331400012],
  [14.074316888000055, 123.11527703000002],
  [14.07440476000005, 123.11496759500005],
  [14.07427724200005, 123.11479848400006],
  [14.074386640000057, 123.11453037700005],
  [14.074321275000045, 123.11425695700007],
  [14.074344535000023, 123.11388324600011],
  [14.074478789000068, 123.113098981],
  [14.074669346000064, 123.11233521300005],
  [14.074806781000063, 123.11211340400007],
  [14.07534544300006, 123.111663829],
  [14.075826387000063, 123.11112905200002],
  [14.076280018000034, 123.11057233500003],
  [14.076366132000032, 123.110379523],
  [14.076374464000025, 123.1101218450001],
  [14.076311629000031, 123.1098231960001],
  [14.076376247000042, 123.10952743400003],
  [14.07660856800004, 123.10913564500004],
  [14.077080586000022, 123.10869614600006],
  [14.077305447000072, 123.10830064000004],
  [14.077350523000064, 123.10802906600009],
  [14.077629690000037, 123.10721151300004],
  [14.077592308000021, 123.10679848500001],
  [14.07735387300005, 123.10638552800003],
  [14.077300971000057, 123.10606054900006],
  [14.077429953000035, 123.10582530500005],
  [14.077636979000033, 123.10563996700012],
  [14.078127523000035, 123.10527061800008],
  [14.078243955000062, 123.10510645600004],
  [14.078240104000031, 123.1049759340001],
  [14.078432405000058, 123.10468440900001],
  [14.078683314000045, 123.10450547100004],
  [14.079157821000024, 123.1039229050001],
  [14.079500497000026, 123.10361779100003],
  [14.079748591000055, 123.10328361300003],
  [14.079873574000032, 123.10301005800011],
  [14.079849615000057, 123.10267161100012],
  [14.079677546000028, 123.10223951400008],
  [14.079685590000054, 123.10203521300002],
  [14.079934999000045, 123.10142294900004],
  [14.079976633000058, 123.10104907900006],
  [14.080001177000042, 123.1000393710001],
  [14.080145084000037, 123.09919804200001],
  [14.080096432000062, 123.09870346900004],
  [14.080330695000043, 123.09844777800004],
  [14.080583653000076, 123.09838132400012],
  [14.08078718300004, 123.098434558],
  [14.080941041000074, 123.09858339200002],
  [14.081104519000064, 123.09906791000003],
  [14.08122060200003, 123.09919030800006],
  [14.081678755000041, 123.09924950100003],
  [14.082049066000025, 123.0991434880001],
  [14.082461137000053, 123.09897202400009],
  [14.082620054000074, 123.09894840700008],
  [14.083086816000048, 123.09940676200006],
  [14.083273445000032, 123.09947294100004],
  [14.08355199700003, 123.09979933500006],
  [14.083702453000058, 123.1000509910001],
  [14.083827143000065, 123.10013524900012],
  [14.083998250000036, 123.10007616700011],
  [14.084167021000042, 123.10029548800003],
  [14.084390618000043, 123.10036055300009],
  [14.084690903000023, 123.1005364670001],
  [14.084642210000027, 123.10029262000012],
  [14.084524695000027, 123.10016846300005],
  [14.084546222000029, 123.1000177310001],
  [14.084446011000068, 123.09994282000002],
  [14.084487609000064, 123.09976844800008],
  [14.084420305000037, 123.09938272900001],
  [14.084436097000037, 123.09923495100008],
  [14.084681240000066, 123.09940934400004],
  [14.08483744800003, 123.09958966600004],
  [14.084922856000048, 123.09978276400011],
  [14.085072866000075, 123.09994519800011],
  [14.085247726000034, 123.10001025200006],
  [14.085196092000047, 123.10020198300003],
  [14.085211826000034, 123.1003911460001],
  [14.085458386000028, 123.10053744700008],
  [14.085664522000059, 123.10071142900006],
  [14.085964775000036, 123.10128058600003],
  [14.086159444000032, 123.10152613800005],
  [14.086285566000072, 123.10161187500012],
  [14.086429300000077, 123.10186703900001],
  [14.086563947000059, 123.10224428100003],
  [14.086658529000033, 123.10236400300005],
  [14.087025887000038, 123.10258415400006],
  [14.087179242000047, 123.102788099],
  [14.087434366000025, 123.10287977400003],
  [14.087782099000037, 123.10312083300005],
  [14.088040291000027, 123.10322823400008],
  [14.088223682000034, 123.10350230300003],
  [14.088493134000032, 123.10363092700004],
  [14.088788746000034, 123.10405032100005],
  [14.088937791000035, 123.1041937010001],
  [14.089448645000061, 123.10450273300012],
  [14.089603441000065, 123.1045441440001],
  [14.08977662500007, 123.10476347300005],
  [14.089932911000062, 123.10468961300012],
  [14.090161803000058, 123.10481033700012],
  [14.090254095000034, 123.10498220400007],
  [14.090146958000048, 123.10515585700011],
  [14.090019397000049, 123.10510410600011]
]

const polygonMercedes1 = [
  [14.046707566000066, 123.10509961900004],
  [14.046685963000073, 123.10558597900001],
  [14.046642544000065, 123.10580456600007],
  [14.046619719000034, 123.10621690900007],
  [14.046538792000035, 123.10666771500007],
  [14.046392031000039, 123.10711776100004],
  [14.046134753000047, 123.10771059400008],
  [14.046017535000033, 123.10790012200005],
  [14.045249133000027, 123.10890301400002],
  [14.044747744000063, 123.10945382400007],
  [14.043362278000075, 123.11044752600003],
  [14.04252639200007, 123.1109940020001],
  [14.04201002600007, 123.11129041100003],
  [14.041528912000047, 123.11149581500001],
  [14.040898820000052, 123.1116974680001],
  [14.040656532000071, 123.11175541300008],
  [14.039367709000032, 123.11192429000005],
  [14.039215188000071, 123.11191749900001],
  [14.03874462500005, 123.11198955300006],
  [14.037762083000075, 123.11221862500008],
  [14.037444466000068, 123.11233177300005],
  [14.036844734000056, 123.11274394400004],
  [14.036486223000054, 123.1129675840001],
  [14.035879119000072, 123.11324506500011],
  [14.035460644000068, 123.11341458000004],
  [14.03470431200003, 123.11359965200006],
  [14.03411933700005, 123.11361762400009],
  [14.033517062000044, 123.11355631000004],
  [14.032635939000045, 123.113386192],
  [14.031952984000043, 123.11322529900008],
  [14.031771105000075, 123.1131456060001],
  [14.031018546000041, 123.1126727510001],
  [14.030389017000061, 123.11244360600006],
  [14.030238523000037, 123.11221162700008],
  [14.030259256000022, 123.11189619400011],
  [14.030411762000028, 123.11170443500009],
  [14.030491287000075, 123.11137578500006],
  [14.030598895000026, 123.11130516500009],
  [14.030621233000033, 123.11112241500007],
  [14.030778314000031, 123.11090985700002],
  [14.031032745000061, 123.1108626670001],
  [14.031201711000051, 123.1108969180001],
  [14.031378861000064, 123.11073584500002],
  [14.031571934000056, 123.11074730400003],
  [14.03177056000004, 123.11089169000002],
  [14.031883527000048, 123.11086999200006],
  [14.031926843000065, 123.11069818800001],
  [14.032103286000051, 123.11031084800004],
  [14.03206988900007, 123.11002489700002],
  [14.032154986000023, 123.10993895500008],
  [14.032168145000071, 123.10971330500001],
  [14.032103439000025, 123.10942309600011],
  [14.032255491000058, 123.10927223400006],
  [14.032286476000024, 123.10912947200006],
  [14.03224614800007, 123.1086783610001],
  [14.032320833000028, 123.1084430410001],
  [14.032345229000043, 123.10817016300007],
  [14.032437433000041, 123.10778193400006],
  [14.032394682000074, 123.10754233900002],
  [14.03252791500006, 123.10707950400001],
  [14.032464521000065, 123.10662516800005],
  [14.032518699000036, 123.1059418970001],
  [14.032634925000025, 123.10540009500005],
  [14.032862529000056, 123.10516203000009],
  [14.033045713000035, 123.10467593200008],
  [14.033033775000035, 123.10440811800004],
  [14.032932965000043, 123.10422799100002],
  [14.03305202200005, 123.10401659800004],
  [14.033009102000051, 123.1038915470001],
  [14.033146694000038, 123.10370197800012],
  [14.033328983000047, 123.10362278700006],
  [14.033663438000076, 123.10335415500003],
  [14.033801591000042, 123.10289568000007],
  [14.034097487000054, 123.10257473800004],
  [14.034279102000028, 123.10243962800007],
  [14.034558867000044, 123.10238776000006],
  [14.034786852000025, 123.10222893800005],
  [14.035313470000062, 123.10165975100006],
  [14.035810637000054, 123.10122346500009],
  [14.03595597900005, 123.10102564600004],
  [14.036148419000028, 123.10094161100005],
  [14.036261103000072, 123.10079388800011],
  [14.036545740000065, 123.09966072000009],
  [14.036708232000024, 123.09942929700003],
  [14.037079497000036, 123.09905613700005],
  [14.037368409000067, 123.09890192900002],
  [14.037517222000076, 123.098753901],
  [14.037652534000074, 123.09848594700009],
  [14.037832385000058, 123.09771172700005],
  [14.037967344000037, 123.09728278900002],
  [14.038207596000063, 123.09633249300009],
  [14.03819108500005, 123.09599669400006],
  [14.038101827000048, 123.09547739100003],
  [14.038164417000075, 123.0952873440001],
  [14.038302087000034, 123.0952285510001],
  [14.038752148000071, 123.09525234900002],
  [14.038954732000036, 123.09510289000002],
  [14.039303739000047, 123.09468268500007],
  [14.03974093100004, 123.09431059700012],
  [14.039890728000046, 123.0942275220001],
  [14.040239911000072, 123.09415999100008],
  [14.040476600000034, 123.09421207100002],
  [14.040996628000073, 123.09426668600008],
  [14.041424279000069, 123.09424941800012],
  [14.041936577000058, 123.0941300500001],
  [14.042167415000051, 123.09421286100007],
  [14.042250248000073, 123.09433586900002],
  [14.042613413000026, 123.09541915000011],
  [14.042829588000075, 123.09555644000011],
  [14.043133774000069, 123.09565683300002],
  [14.043447014000037, 123.09613852100006],
  [14.043585215000064, 123.09622199000012],
  [14.043766530000028, 123.09651452900005],
  [14.043878690000042, 123.09660065900005],
  [14.04424276900005, 123.09712081300006],
  [14.044441260000042, 123.09757831200011],
  [14.044557431000044, 123.09776772100008],
  [14.04460867000006, 123.09808432900002],
  [14.044867120000049, 123.09830392900005],
  [14.04505077600004, 123.09851829500008],
  [14.045310872000073, 123.09872078700005],
  [14.04561228600005, 123.09960389700007],
  [14.045718736000026, 123.09999550500004],
  [14.045862512000042, 123.10064874600005],
  [14.046070117000056, 123.10146209900006],
  [14.04631243600005, 123.10257026300008],
  [14.046475933000067, 123.10384045800004],
  [14.046538232000046, 123.1039892880001],
  [14.046702737000032, 123.10481683300009],
  [14.046707566000066, 123.10509961900004]
]

const polygonMercedes2 = [
  [14.056751396000038, 123.08927832600011],
  [14.056544513000063, 123.08908392800004],
  [14.056458622000036, 123.08934852300001],
  [14.056764368000074, 123.08982870300008],
  [14.056724088000067, 123.08997214100009],
  [14.05649706500003, 123.08998912600009],
  [14.056264547000069, 123.09011180100003],
  [14.056037524000033, 123.0900098830001],
  [14.055649813000059, 123.0900004770001],
  [14.055508841000062, 123.08978154300007],
  [14.055336743000055, 123.08970604900003],
  [14.05494275500007, 123.08968575600011],
  [14.054580249000026, 123.08972161200006],
  [14.054214799000022, 123.0895518210001],
  [14.054015238000034, 123.0895159590001],
  [14.053907219000052, 123.089421592],
  [14.053846803000056, 123.08918001300003],
  [14.053877928000077, 123.08887426700005],
  [14.053508102000023, 123.08862702600004],
  [14.053339666000056, 123.08826088600006],
  [14.05318679800007, 123.08803868700011],
  [14.052915835000022, 123.08777634800003],
  [14.05303850200005, 123.08759705200009],
  [14.053474239000025, 123.08763668600011],
  [14.054061839000042, 123.08754768600011],
  [14.054252246000033, 123.08723627600011],
  [14.054402371000037, 123.0867644440001],
  [14.054438985000047, 123.08628374300008],
  [14.05438405800004, 123.08598554500009],
  [14.054314486000067, 123.08583644700002],
  [14.054014227000039, 123.08558732300003],
  [14.053981271000055, 123.08547219500008],
  [14.054100274000064, 123.08528535000005],
  [14.054244910000023, 123.08538915100007],
  [14.054601927000022, 123.08602329000007],
  [14.05494907700006, 123.08657580500005],
  [14.055066252000074, 123.08705518700003],
  [14.055093716000044, 123.08756485300012],
  [14.055243844000074, 123.08785739100006],
  [14.055295106000074, 123.08805593800002],
  [14.055560652000054, 123.08838570900002],
  [14.055787675000033, 123.08851593600002],
  [14.05604032900004, 123.08854990800012],
  [14.05642184900006, 123.08873490200006],
  [14.056520713000054, 123.08886324200012],
  [14.056745904000024, 123.0889500610001],
  [14.056815476000054, 123.08917074800002],
  [14.056751396000038, 123.08927832600011]
]

const polygonMercedes3 = [
  [14.070565141000031, 123.0784490640001],
  [14.070028344000036, 123.07839996200005],
  [14.06915325600005, 123.07820840300008],
  [14.06824596100006, 123.07798527500006],
  [14.067343269000048, 123.07774439500008],
  [14.06689825500007, 123.07759363500008],
  [14.066252218000045, 123.07744792100004],
  [14.065933610000059, 123.0773985300001],
  [14.065586044000042, 123.07719756400002],
  [14.065280728000062, 123.07686159200011],
  [14.06512428600007, 123.07661327500011],
  [14.064804887000037, 123.07590363000008],
  [14.06472455000005, 123.07576552700004],
  [14.06470928400006, 123.07556826100006],
  [14.064590741000075, 123.0751328880001],
  [14.064583175000053, 123.07471666200001],
  [14.064684685000032, 123.07426889800001],
  [14.064813034000053, 123.07357257300009],
  [14.064910773000065, 123.07283491400005],
  [14.064975889000038, 123.07255245200008],
  [14.065338690000033, 123.07125446400005],
  [14.065413339000031, 123.07114203900005],
  [14.065635976000067, 123.07034294100004],
  [14.065963330000045, 123.06948464900006],
  [14.066292915000076, 123.06888905700009],
  [14.067005180000024, 123.06776510500003],
  [14.067423867000059, 123.066955989],
  [14.067546957000047, 123.06651017800004],
  [14.067703868000024, 123.06648653500008],
  [14.067969812000058, 123.06666412400011],
  [14.068756540000038, 123.06744994400003],
  [14.069020588000058, 123.06753284700005],
  [14.069250176000025, 123.06770254100002],
  [14.069542976000037, 123.06784010600006],
  [14.069814651000058, 123.0680630710001],
  [14.069914119000032, 123.06824654900004],
  [14.070803957000066, 123.06912490400009],
  [14.071020986000065, 123.06945906400006],
  [14.071300381000071, 123.069815919],
  [14.071551062000026, 123.07020556200007],
  [14.072109943000044, 123.07114326600004],
  [14.072412020000058, 123.07194748800009],
  [14.072915464000062, 123.0734627710001],
  [14.073291151000035, 123.07482157400011],
  [14.073396267000021, 123.07541512600005],
  [14.073455434000039, 123.0761226840001],
  [14.073476362000065, 123.0766684030001],
  [14.073407386000042, 123.07707970000001],
  [14.073201828000037, 123.07778186100006],
  [14.072940913000025, 123.0780952450001],
  [14.072770585000058, 123.0782155390001],
  [14.072461394000072, 123.078350193],
  [14.072027736000052, 123.07841913400011],
  [14.071047192000037, 123.0784701880001],
  [14.070565141000031, 123.0784490640001]
]

const polygonMercedes = [

  [13.879259786000034, 123.00822191000009],

  [13.881269652000071, 123.00853492100009],

  [13.884730430000047, 123.00792294300004],
  [13.887325256000054, 123.00853495800004],
  [13.890612029000067, 123.01057497500005],
  [13.89268188400007, 123.01221398700011],
  [13.89353281600006, 123.0144619890001],
  [13.892494868000028, 123.01711398000009],
  [13.892809828000054, 123.02042397600007],
  [13.892837805000056, 123.02403997300007],
  [13.89283179000006, 123.02673896800002],
  [13.893139752000025, 123.02979896700003],
  [13.894330656000022, 123.03286097000012],
  [13.895878537000044, 123.03592097500007],
  [13.896639470000025, 123.03898097600006],
  [13.897405397000057, 123.04293997500008],
  [13.89854334000006, 123.03990798600012],
  [13.89975927300003, 123.037747996],
  [13.901993136000044, 123.03609901200002],
  [13.904276982000056, 123.03699902500011],
  [13.904743964000033, 123.03484303100004],
  [13.906109873000048, 123.03501903900008],
  [13.907335785000043, 123.03645804400003],
  [13.90839670400004, 123.03844104900008],
  [13.90809771000005, 123.04078704300002],
  [13.907944706000023, 123.04294704000006],
  [13.908097683000051, 123.04528703800008],
  [13.909354595000025, 123.04638804400008],
  [13.909472575000052, 123.04853304200003],
  [13.908558621000054, 123.05069403400012],
  [13.906853727000055, 123.05175802200006],
  [13.906702719000066, 123.05482801700009],
  [13.907481651000069, 123.05771401800007],
  [13.909793510000043, 123.05598903400005],
  [13.910873451000043, 123.05407804300012],
  [13.911953392000044, 123.05195205200005],
  [13.91321630400006, 123.05303105900009],
  [13.914126245000034, 123.05280106500004],
  [13.915419171000053, 123.05113207500006],
  [13.91684807100006, 123.05218208200006],
  [13.918109996000055, 123.0509100910001],
  [13.922393706000037, 123.05254811500004],
  [13.923607618000062, 123.05381712100007],
  [13.924545562000048, 123.05320112800007],
  [13.92475455400006, 123.05221513100003],
  [13.925588503000029, 123.05159813600005],
  [13.926110469000037, 123.05159813900002],
  [13.926318451000043, 123.05233714000008],
  [13.92663142200007, 123.05369314000006],
  [13.928299312000036, 123.05394015000002],
  [13.929446238000025, 123.05369315700011],
  [13.930071207000026, 123.0520911640001],
  [13.930800170000055, 123.05011617100001],
  [13.932051095000077, 123.04912917900003],
  [13.933616007000069, 123.0466631920001],
  [13.934866933000023, 123.0451812010001],
  [13.935585869000022, 123.04825020200008],
  [13.93694176200006, 123.0513332060001],
  [13.93850864600006, 123.05367721200003],
  [13.940057534000061, 123.0555492200001],
  [13.940057526000032, 123.05690421800011],
  [13.940474493000067, 123.05789021900011],
  [13.941204446000029, 123.05776722400003],
  [13.942038388000071, 123.05838322800003],
  [13.94224736800004, 123.05936922900003],
  [13.942247365000071, 123.05998522700008],
  [13.943289297000035, 123.0598612340001],
  [13.94391525900005, 123.05936823800005],
  [13.944958193000048, 123.0589992460001],
  [13.947150054000076, 123.05825925900001],
  [13.949131925000074, 123.05813527200007],
  [13.954124558000046, 123.06511129300009],
  [13.957892313000059, 123.06492931600008],
  [13.960247164000066, 123.06418933200007],
  [13.961660076000044, 123.06344934100002],
  [13.963072994000072, 123.06159935300002],
  [13.96511386700007, 123.06048936600007],
  [13.966212807000034, 123.0584543760001],
  [13.96715475600007, 123.05660438400002],
  [13.969352620000052, 123.05530939900007],
  [13.969824619000065, 123.0503004090001],
  [13.970923560000074, 123.04808041800004],
  [13.969040705000054, 123.04437041100005],
  [13.971238560000074, 123.04455542500011],
  [13.972808463000035, 123.04363043500007],
  [13.974221378000038, 123.04233544700003],
  [13.975477307000062, 123.04048345700005],
  [13.976086678000058, 123.0391675520001],
  [13.976286066000057, 123.03895213100009],
  [13.976557488000026, 123.03822943000011],
  [13.97701019300007, 123.0379268370001],
  [13.977154319000022, 123.03775754500009],
  [13.977321048000022, 123.03767845200002],
  [13.977520137000056, 123.03772037500005],
  [13.97773763500004, 123.03770197600011],
  [13.978292900000042, 123.037476136],
  [13.978469032000078, 123.03727070900004],
  [13.978585235000025, 123.03673652200007],
  [13.978589334000048, 123.0364188420001],
  [13.978826039000069, 123.03595763600003],
  [13.97920238000006, 123.03588847800006],
  [13.979509091000068, 123.03599458400004],
  [13.979867003000038, 123.03597693000006],
  [13.98015085000003, 123.03577699400012],
  [13.980283539000027, 123.03556464300004],
  [13.980390401000022, 123.03499117900003],
  [13.980283715000041, 123.03430089400001],
  [13.980446123000036, 123.03431248400011],
  [13.980956455000069, 123.03446221400009],
  [13.981306428000039, 123.03432153100005],
  [13.981517709000059, 123.03427389900003],
  [13.981967616000077, 123.03426357800004],
  [13.982730885000024, 123.03474747300004],
  [13.983113638000077, 123.03504799300003],
  [13.98328125100005, 123.03543842600004],
  [13.983480278000059, 123.03563743500001],
  [13.983610526000064, 123.0358380130001],
  [13.98378393300004, 123.03583532100004],
  [13.983914040000059, 123.03537692600003],
  [13.983909423000057, 123.03508922500009],
  [13.983951227000034, 123.03480036100007],
  [13.984189560000061, 123.03457976600009],
  [13.984816033000072, 123.03479450100008],
  [13.985233494000056, 123.03488156500009],
  [13.985673466000037, 123.03487159300005],
  [13.986077714000032, 123.03471711300006],
  [13.986566649000054, 123.03462102900005],
  [13.986863609000068, 123.03259253600004],
  [13.977302257000076, 123.02868148200002],
  [13.979622125000049, 123.02526050100005],
  [13.982231971000033, 123.02252152000005],
  [13.983439930000031, 123.01607253600002],
  [13.985297794000076, 123.01846254500003],
  [13.985482902000058, 123.01466572100003],
  [13.985817174000033, 123.01438391300007],
  [13.98608944800003, 123.01440225700003],
  [13.986289015000068, 123.01466274500001],
  [13.98658647600007, 123.01485003700009],
  [13.986828735000074, 123.01469827800008],
  [13.986804241000073, 123.01428658900011],
  [13.986845318000064, 123.01413447000004],
  [13.987198241000044, 123.01373661600007],
  [13.987254875000076, 123.01355623200004],
  [13.987479048000068, 123.01373548000004],
  [13.987878884000054, 123.01398083800007],
  [13.988021587000048, 123.01399435100006],
  [13.988071002000027, 123.01383094700009],
  [13.988039232000062, 123.01343569100004],
  [13.988111970000034, 123.01324073900003],
  [13.988267295000071, 123.0131468290001],
  [13.988464056000055, 123.01336264000008],
  [13.988610593000033, 123.01389526900005],
  [13.988705323000033, 123.0140996990001],
  [13.988724064000053, 123.01427665500012],
  [13.988607341000034, 123.01461324000002],
  [13.98856760800004, 123.01492443100005],
  [13.988784491000047, 123.0149663520001],
  [13.988897941000062, 123.01513148400011],
  [13.989053468000066, 123.01485612200008],
  [13.989447184000028, 123.01464664700006],
  [13.989820080000072, 123.01454614400006],
  [13.990094290000059, 123.01437335100002],
  [13.990202258000068, 123.01416374700011],
  [13.99038383800007, 123.01394120400005],
  [13.99043479900007, 123.01374714000008],
  [13.990589914000054, 123.01349805200005],
  [13.990721533000055, 123.01349297400009],
  [13.990779762000045, 123.01365458200007],
  [13.991033209000022, 123.014077186],
  [13.991361664000067, 123.01447677100009],
  [13.991536411000027, 123.0148160330001],
  [13.991630040000075, 123.01487730200006],
  [13.991834528000027, 123.01485214500008],
  [13.992069609000055, 123.01458545700007],
  [13.992002299000035, 123.01413560300011],
  [13.991919188000054, 123.01400909600011],
  [13.991923370000052, 123.01383005100001],
  [13.99201057700003, 123.01373695200004],
  [13.992448867000064, 123.01356473100009],
  [13.992820172000052, 123.01355878700008],
  [13.992915511000035, 123.01367440900003],
  [13.993031536000046, 123.01407584900005],
  [13.99312741500006, 123.01426558300011],
  [13.993310331000032, 123.01429918000008],
  [13.993434227000023, 123.01413054500006],
  [13.993551746000037, 123.01378205500009],
  [13.99391788400004, 123.01286948300003],
  [13.994038652000029, 123.01280454800008],
  [13.99450231000003, 123.01326800800007],
  [13.99497976200007, 123.01345817300012],
  [13.995412456000054, 123.01327203400001],
  [13.995643812000026, 123.01334229800011],
  [13.995497155000066, 123.01358398100001],
  [13.995493673000055, 123.01387821700007],
  [13.995726927000021, 123.01398301200004],
  [13.995948095000074, 123.01387761500007],
  [13.995979958000078, 123.01368291100005],
  [13.995960360000026, 123.01327308900011],
  [13.99606504600007, 123.01305454400006],
  [13.996232426000063, 123.0129967040001],
  [13.996505437000053, 123.01298724700007],
  [13.997030577000032, 123.01310102100001],
  [13.997060852000061, 123.01343612800008],
  [13.996967253000037, 123.01357728100004],
  [13.996736354000063, 123.01378379500011],
  [13.996969891000049, 123.01395835000005],
  [13.997298087000047, 123.01452895400007],
  [13.997536680000053, 123.01509596500011],
  [13.997743022000066, 123.01578596800005],
  [13.997831263000023, 123.01598869400004],
  [13.997939402000043, 123.0160827410001],
  [13.998171755000044, 123.01599595700009],
  [13.99842290500004, 123.0156965330001],
  [13.998494404000041, 123.01552485200011],
  [13.998745180000071, 123.01533719400004],
  [13.998929029000067, 123.01532960300005],
  [13.999138233000053, 123.0155204880001],
  [13.999222119000024, 123.01568092900004],
  [13.999503235000077, 123.0159499670001],
  [13.99958267900007, 123.01610353400008],
  [13.999799695000036, 123.01631695200001],
  [14.000009250000062, 123.01629437600002],
  [13.999995598000055, 123.0158088170001],
  [14.000100923000048, 123.01497082500009],
  [14.00025697500007, 123.01462891400001],
  [14.000833845000045, 123.0143912630001],
  [14.000966743000049, 123.01444301700008],
  [14.001125502000036, 123.01475779500004],
  [14.001138369000046, 123.01490660500008],
  [14.001071592000073, 123.0152661080001],
  [14.000982833000023, 123.01537255800008],
  [14.000780147000057, 123.01528008100001],
  [14.000615125000024, 123.01527915300005],
  [14.000407774000053, 123.01544564900007],
  [14.000423788000035, 123.01574670800005],
  [14.000524301000041, 123.01595163000002],
  [14.000679938000076, 123.01611797300006],
  [14.001131715000042, 123.01612782400002],
  [14.001493682000046, 123.01596669300011],
  [14.001845640000056, 123.01624802800006],
  [14.001925479000022, 123.01639235000005],
  [14.002102656000034, 123.01650208100011],
  [14.00234869600007, 123.01654689000009],
  [14.002511041000048, 123.01646896700004],
  [14.002750057000071, 123.01615649400003],
  [14.002839154000071, 123.01619154100001],
  [14.003169310000033, 123.0164804960001],
  [14.003270318000034, 123.01663448800002],
  [14.003529162000063, 123.01685736000002],
  [14.003631381000048, 123.01687523100009],
  [14.003938928000025, 123.01678517100004],
  [14.004104178000034, 123.01688117800006],
  [14.004109803000063, 123.01745802200003],
  [14.004139126000041, 123.01759443300011],
  [14.004296561000046, 123.01765615500005],
  [14.004527408000058, 123.01755083900002],
  [14.004634993000025, 123.01735290300007],
  [14.004837014000032, 123.01711547800005],
  [14.004935222000029, 123.01710773600007],
  [14.005001207000078, 123.01733279700011],
  [14.00511565100004, 123.01744643800009],
  [14.005238072000054, 123.01775412500001],
  [14.005486622000035, 123.01784288800002],
  [14.00583356900006, 123.0178132740001],
  [14.006057987000077, 123.01769711300005],
  [14.006276489000072, 123.01765538500001],
  [14.006694014000061, 123.0177722520001],
  [14.00676204900003, 123.01788171100009],
  [14.00709037300004, 123.01814644900003],
  [14.007065211000054, 123.01825159100008],
  [14.006770025000037, 123.01843087800012],
  [14.006624156000044, 123.01840812900002],
  [14.00635251400007, 123.01870783800007],
  [14.006026299000041, 123.01885646100004],
  [14.005987117000075, 123.01904612700002],
  [14.00611912100004, 123.0192543280001],
  [14.006303183000057, 123.01928208000004],
  [14.00651149600003, 123.01973654800008],
  [14.006467894000025, 123.01993798800004],
  [14.007128417000047, 123.02029775100004],
  [14.007243297000059, 123.02024991000008],
  [14.007178199000066, 123.01979848700012],
  [14.007056994000038, 123.01946319300009],
  [14.007117151000045, 123.01935339600004],
  [14.007114387000058, 123.0190034090001],
  [14.00720543600005, 123.01883102800002],
  [14.007497731000058, 123.01877465200005],
  [14.008027610000056, 123.01928927800009],
  [14.008226663000073, 123.01938696900004],
  [14.008367994000025, 123.01935732600009],
  [14.00859704800007, 123.01892518800003],
  [14.008692464000035, 123.01864221400001],
  [14.00881709500004, 123.01841367100008],
  [14.009022246000029, 123.01852452500009],
  [14.009030920000043, 123.0188607980001],
  [14.008961888000044, 123.01918099900001],
  [14.009216741000046, 123.01998720100005],
  [14.009345139000061, 123.02025864600012],
  [14.009465221000028, 123.02039701900003],
  [14.009709817000044, 123.0204090630001],
  [14.010020261000022, 123.02027032400008],
  [14.010262201000046, 123.02021031400011],
  [14.01037560900005, 123.019917544],
  [14.010749984000029, 123.01937874100008],
  [14.011152800000048, 123.01928255300004],
  [14.011278429000072, 123.01928774900011],
  [14.011878572000057, 123.01907619100007],
  [14.012077605000059, 123.01913897200006],
  [14.012198080000076, 123.01930400100002],
  [14.012249126000029, 123.0195205330001],
  [14.012490799000034, 123.01948588400012],
  [14.012860641000032, 123.0192313020001],
  [14.013159519000055, 123.01889955200011],
  [14.013603363000072, 123.0186645010001],
  [14.01379027300004, 123.01863841700003],
  [14.014301362000026, 123.01847940100004],
  [14.014345535000075, 123.01828722300002],
  [14.014481032000049, 123.0180330180001],
  [14.014710856000022, 123.01788759500005],
  [14.015075240000044, 123.01801072100011],
  [14.015312434000066, 123.01827554300007],
  [14.015584421000028, 123.01874277800005],
  [14.015856137000071, 123.01895032300001],
  [14.015946561000021, 123.01908506600012],
  [14.016086775000076, 123.01970668800004],
  [14.016367302000049, 123.0198051320001],
  [14.016706580000061, 123.01972650700009],
  [14.017068228000028, 123.01957939300007],
  [14.017307252000023, 123.01970123000001],
  [14.017440702000044, 123.02029224000012],
  [14.017567378000024, 123.0206773110001],
  [14.01766709800006, 123.02087970000002],
  [14.017815528000028, 123.02095728800009],
  [14.018075846000045, 123.02090333400008],
  [14.01820000600003, 123.0208109990001],
  [14.018283555000039, 123.02060426600008],
  [14.018131248000032, 123.0204329820001],
  [14.018101170000023, 123.02029551700002],
  [14.017880414000047, 123.02003615800004],
  [14.01776897600007, 123.01996606600005],
  [14.01768273700003, 123.01974370800008],
  [14.017732852000051, 123.01961072000006],
  [14.017987186000028, 123.01942793300009],
  [14.01826559500006, 123.01932016],
  [14.018569427000045, 123.01903696800002],
  [14.018598449000024, 123.01875002400004],
  [14.018359659000055, 123.0183428150001],
  [14.018366817000071, 123.01823156900002],
  [14.019050225000058, 123.01793734900002],
  [14.019340061000037, 123.01793351900005],
  [14.019540327000072, 123.0180060990001],
  [14.019814793000023, 123.01821592300007],
  [14.020162612000036, 123.018583239],
  [14.02024306800007, 123.01883248200011],
  [14.02010639200006, 123.01903194800002],
  [14.01992628100004, 123.01938728400012],
  [14.019751495000037, 123.019842777],
  [14.01986538400007, 123.01996605800002],
  [14.020074627000042, 123.02003815000012],
  [14.020363279000037, 123.02002366300007],
  [14.020907744000056, 123.01983874600012],
  [14.02132259700005, 123.01990629800002],
  [14.021603704000029, 123.02006329000005],
  [14.021812211000054, 123.02029572800006],
  [14.02191928700006, 123.02052939300006],
  [14.022166076000076, 123.02068250200011],
  [14.022467947000052, 123.02061914900003],
  [14.022803968000062, 123.02066427600005],
  [14.023491732000025, 123.02038641900003],
  [14.02414815700007, 123.0202044780001],
  [14.024551604000067, 123.02007305000006],
  [14.024608645000058, 123.02013791000002],
  [14.024575604000063, 123.0205096630001],
  [14.024764724000022, 123.02069852400007],
  [14.024915157000066, 123.02071728400006],
  [14.024903170000073, 123.0251987800001],
  [14.025299119000067, 123.03429340000002],
  [14.025385086000028, 123.03447615800007],
  [14.02563069100006, 123.0346221210001],
  [14.025927438000053, 123.03473281800007],
  [14.02595377800003, 123.03485680900008],
  [14.026110366000069, 123.03496361400005],
  [14.026421237000022, 123.03496361100008],
  [14.026508744000068, 123.035234048],
  [14.026402820000044, 123.03560194300007],
  [14.026229417000025, 123.03591488300003],
  [14.025976115000049, 123.03616172800002],
  [14.025118845000065, 123.03675129700002],
  [14.024294207000025, 123.03742759900001],
  [14.02399413300003, 123.03782034000005],
  [14.023860529000046, 123.038207854],
  [14.023959545000025, 123.03871340700005],
  [14.024102909000021, 123.03903511100009],
  [14.024148962000027, 123.03922499100008],
  [14.02411672200003, 123.03948558900004],
  [14.023934800000063, 123.03996265900003],
  [14.02347280500004, 123.04032724900003],
  [14.023262358000068, 123.04061681200005],
  [14.02231424200005, 123.04086957700008],
  [14.021701178000058, 123.04107366900007],
  [14.020976336000047, 123.04117808900003],
  [14.020909473000074, 123.04134854300003],
  [14.020750280000073, 123.04143635900004],
  [14.02029456300005, 123.04149377400006],
  [14.019383226000059, 123.04163986500009],
  [14.018656631000056, 123.04186430800007],
  [14.017842142000063, 123.04216078800005],
  [14.017610233000028, 123.0423098440001],
  [14.017323410000074, 123.04240028400011],
  [14.017073998000058, 123.04256063100001],
  [14.016518215000076, 123.04280466400007],
  [14.016084559000035, 123.04301763],
  [14.015692949000027, 123.04328635900004],
  [14.014979820000065, 123.04368459900002],
  [14.01466040300005, 123.04389913400007],
  [14.014000559000067, 123.04438835200006],
  [14.013617665000027, 123.0446006740001],
  [14.013247935000038, 123.04486035900004],
  [14.012989482000023, 123.04508250000003],
  [14.012710552000044, 123.04537204700011],
  [14.01234131900003, 123.04587875800007],
  [14.012021146000052, 123.04625174000012],
  [14.01196301400006, 123.04659129200002],
  [14.011838081000064, 123.0468555010001],
  [14.011711527000045, 123.04700098100011],
  [14.011521697000035, 123.047101311],
  [14.011356208000052, 123.04702271400004],
  [14.011425978000034, 123.04675683300002],
  [14.011388684000053, 123.04661113300006],
  [14.011176422000062, 123.0465393720001],
  [14.011115287000052, 123.0467371420001],
  [14.010362567000072, 123.04724592800005],
  [14.009917407000046, 123.04743734600004],
  [14.009351493000054, 123.0476274240001],
  [14.00838910400006, 123.047770377],
  [14.008218858000077, 123.04776874900006],
  [14.007790088000036, 123.04784185200003],
  [14.007308789000035, 123.04796440200005],
  [14.007081791000076, 123.04807487500011],
  [14.006274378000057, 123.04853831600008],
  [14.006221739000068, 123.04861155300011],
  [14.00587177500006, 123.04875381000011],
  [14.00557640900007, 123.04894816500007],
  [14.005049658000075, 123.04915656800006],
  [14.00480882200003, 123.04928535700003],
  [14.004447568000046, 123.04938136200008],
  [14.004252171000076, 123.04951249300007],
  [14.003688818000057, 123.04974665100008],
  [14.003536591000056, 123.04986607400008],
  [14.002927886000066, 123.0501829960001],
  [14.002455202000021, 123.0504976520001],
  [14.001840900000047, 123.05080153900008],
  [14.001686096000071, 123.05091802900006],
  [14.001241342000071, 123.0511408750001],
  [14.001149566000038, 123.05122444400001],
  [14.00072201200004, 123.05145488700009],
  [14.00045909000005, 123.05166760600002],
  [13.99983222000003, 123.05207596100001],
  [13.99937026300006, 123.0524051650001],
  [13.998868990000062, 123.05271664100007],
  [13.998741215000052, 123.05275715700009],
  [13.998412298000062, 123.05295961500008],
  [13.998326295000027, 123.05306597300012],
  [13.997829936000073, 123.05336731700004],
  [13.997783862000063, 123.05349149300002],
  [13.997602026000038, 123.0536459650001],
  [13.997567623000066, 123.05377005000003],
  [13.997395620000077, 123.0537599160001],
  [13.997233441000049, 123.05396756800008],
  [13.997245724000038, 123.054086589],
  [13.997061428000052, 123.05432209600008],
  [13.996888499000022, 123.0544036870001],
  [13.996352823000052, 123.05475820200002],
  [13.996134126000072, 123.05499117400007],
  [13.995738171000028, 123.05517412200004],
  [13.99535484100005, 123.05548305800005],
  [13.995144720000042, 123.05558718800012],
  [13.994456096000022, 123.05614872100011],
  [13.993950577000021, 123.05648891100009],
  [13.993491429000073, 123.05698613800007],
  [13.993225485000039, 123.05738127500001],
  [13.993113594000022, 123.05764581500011],
  [13.992889811000055, 123.05796122600009],
  [13.992649654000047, 123.05838211200012],
  [13.99248013700003, 123.05856959200003],
  [13.992304416000025, 123.05893177100006],
  [13.992046003000041, 123.05932804000008],
  [13.991681096000036, 123.05972393700006],
  [13.991474364000055, 123.06007972300006],
  [13.99113946400007, 123.06030767700008],
  [13.991058271000043, 123.06030572100008],
  [13.990967518000048, 123.06053952700006],
  [13.990887071000031, 123.06106453200005],
  [13.990800440000044, 123.0613961140001],
  [13.990956723000068, 123.06182114300009],
  [13.991062551000027, 123.06202345200006],
  [13.991064609000034, 123.06222325100009],
  [13.991132665000066, 123.06245281000008],
  [13.990905781000038, 123.06288428800008],
  [13.990864527000042, 123.06307346100004],
  [13.990632727000047, 123.06340483800011],
  [13.990597654000055, 123.06376617700005],
  [13.990508962000035, 123.06395534700005],
  [13.990529567000067, 123.0645251520001],
  [13.990680218000023, 123.0648398940001],
  [13.991089663000025, 123.06538879200002],
  [13.991319667000027, 123.0655677090001],
  [13.991400406000025, 123.06572165600005],
  [13.991442781000046, 123.06611068100005],
  [13.991402399000037, 123.0663582410001],
  [13.991457513000057, 123.06656247800004],
  [13.991350519000036, 123.06673514200008],
  [13.991100200000062, 123.06698685000003],
  [13.990944757000022, 123.06721151700003],
  [13.990853910000055, 123.06742578600006],
  [13.990839770000036, 123.0676379790001],
  [13.990884166000058, 123.06791466300001],
  [13.990438036000057, 123.0682329330001],
  [13.990137483000069, 123.06854424100004],
  [13.99006875200007, 123.06876142100009],
  [13.99005547400003, 123.06929736300003],
  [13.989902752000035, 123.06950509300009],
  [13.989858456000036, 123.06967820700004],
  [13.989885929000025, 123.06994103300008],
  [13.98981720200004, 123.07007007900006],
  [13.989659903000074, 123.070173941],
  [13.989419570000052, 123.07017077700004],
  [13.989075961000026, 123.07033443000012],
  [13.988630517000047, 123.07070723200002],
  [13.98842739500003, 123.07097318700005],
  [13.988208991000022, 123.07142642100007],
  [13.988135673000045, 123.07168766500001],
  [13.98806539900005, 123.0721084270001],
  [13.988062322000076, 123.07243577400004],
  [13.988106605000041, 123.07250781700009],
  [13.988098941000032, 123.07292644100005],
  [13.98824552900004, 123.07313261700006],
  [13.988177732000054, 123.07329757200011],
  [13.987908941000057, 123.07356824200008],
  [13.987788285000022, 123.0737775450001],
  [13.98755297200006, 123.07431488600002],
  [13.987173153000072, 123.07479797300005],
  [13.987082644000054, 123.07498295700009],
  [13.98676421600004, 123.07545495400007],
  [13.986511962000066, 123.07613985600005],
  [13.986544577000075, 123.07641352400003],
  [13.986704755000062, 123.07691779800007],
  [13.986953955000047, 123.07729086000006],
  [13.987044447000073, 123.0775100840001],
  [13.987004366000065, 123.07779750500004],
  [13.987023633000035, 123.07801613200002],
  [13.986792153000067, 123.07856802700007],
  [13.98673199500007, 123.07879600900003],
  [13.98659549100006, 123.07901614900004],
  [13.986028710000028, 123.07949363000012],
  [13.985797270000035, 123.07959909700003],
  [13.985191653000072, 123.0799883200001],
  [13.984397109000042, 123.08047284700001],
  [13.983550356000023, 123.0811979020001],
  [13.983407228000033, 123.08139197300011],
  [13.98283110400007, 123.0818661620001],
  [13.982632231000025, 123.08208352100007],
  [13.982227352000052, 123.08273766600007],
  [13.98199683100006, 123.08331522300011],
  [13.981737119000059, 123.0834524070001],
  [13.981046512000034, 123.08362744700003],
  [13.980863297000042, 123.08363666000002],
  [13.980284437000023, 123.0838393360001],
  [13.979953660000035, 123.08399134500007],
  [13.97961676600005, 123.08407656000009],
  [13.979341863000059, 123.08420784100008],
  [13.978973093000036, 123.08426081000005],
  [13.978738422000049, 123.08408346300007],
  [13.978711603000022, 123.08398672800001],
  [13.978412117000062, 123.0838784770001],
  [13.977848385000073, 123.08390380700007],
  [13.97706093000005, 123.08397750400002],
  [13.976206648000073, 123.08413871700009],
  [13.975920569000039, 123.08422623500007],
  [13.975381941000023, 123.08426077800004],
  [13.97493482300007, 123.08432855400008],
  [13.974596835000057, 123.08443739300003],
  [13.974042645000054, 123.0847347350001],
  [13.973861777000025, 123.08486548700012],
  [13.973680458000047, 123.08515210200005],
  [13.973713897000039, 123.0856981290001],
  [13.973626472000035, 123.08609282000009],
  [13.97288870400007, 123.08691093300001],
  [13.97265105300005, 123.08706330600012],
  [13.972287007000034, 123.08713041800002],
  [13.971924375000071, 123.08704877800005],
  [13.971809955000026, 123.08690002600008],
  [13.97143987800007, 123.08674945500002],
  [13.97129024800006, 123.08674763800002],
  [13.971292017000053, 123.08630320400005],
  [13.97110189700004, 123.08636487700005],
  [13.97110189500006, 123.08652451000012],
  [13.971018929000024, 123.0869010350001],
  [13.97092056400004, 123.08695392000004],
  [13.97090987200005, 123.08713461000002],
  [13.970755908000058, 123.08735937100005],
  [13.970610498000042, 123.08748056500008],
  [13.970407353000041, 123.08752683800003],
  [13.970025718000045, 123.08748276400001],
  [13.96968144300007, 123.087359364],
  [13.96886364200003, 123.08721833100003],
  [13.968502260000037, 123.08708832100001],
  [13.96799457800006, 123.08683053300001],
  [13.967515585000058, 123.08671815100001],
  [13.966786025000033, 123.08642948700003],
  [13.966246244000047, 123.086342],
  [13.965477829000065, 123.08597143700001],
  [13.965051434000031, 123.08580267600007],
  [13.964692080000077, 123.08540276300005],
  [13.964288401000033, 123.08513805300004],
  [13.963921121000055, 123.08502274600005],
  [13.96342870500007, 123.08482565100007],
  [13.962944783000069, 123.08454581500007],
  [13.96267414600004, 123.08427229300003],
  [13.962490378000041, 123.08417130100008],
  [13.962363781000022, 123.08396089900009],
  [13.962204515000053, 123.08388094700001],
  [13.961729022000043, 123.0837519370001],
  [13.961377820000052, 123.08346368900004],
  [13.960920520000059, 123.08314572300003],
  [13.960869394000042, 123.08305987200004],
  [13.960621340000046, 123.08292719400004],
  [13.96043577100005, 123.0827145180001],
  [13.96023126700004, 123.08258379100005],
  [13.959789288000024, 123.08241058500005],
  [13.959639697000057, 123.0822837610001],
  [13.959125904000075, 123.08201959000007],
  [13.958761022000033, 123.08197668300011],
  [13.958622710000043, 123.08190925400004],
  [13.958353015000057, 123.08189699700006],
  [13.95786518500006, 123.08199712800001],
  [13.95701927600004, 123.08195595400002],
  [13.956638034000036, 123.08198787600008],
  [13.956066167000074, 123.08179146400005],
  [13.955634191000058, 123.08177919400009],
  [13.954938425000023, 123.08193142700009],
  [13.954171055000074, 123.08200509300002],
  [13.95357653700006, 123.08221624900011],
  [13.953014206000034, 123.08252070300011],
  [13.952717006000057, 123.0827239030001],
  [13.952147528000069, 123.08327387500003],
  [13.951795713000024, 123.0835150370001],
  [13.951315970000053, 123.08367426500001],
  [13.95094118000003, 123.08376932600004],
  [13.950064723000025, 123.08410441900003],
  [13.949815623000063, 123.08424938900009],
  [13.94926950000007, 123.08469389700008],
  [13.948665387000062, 123.0852423020001],
  [13.947946638000076, 123.0856482150001],
  [13.947666625000068, 123.08577257200011],
  [13.947195916000055, 123.0857700790001],
  [13.947135769000056, 123.08580241200002],
  [13.946599886000058, 123.0857277880001],
  [13.94597923200007, 123.08547905800003],
  [13.945609908000051, 123.08544672100004],
  [13.945308173000058, 123.08533976700005],
  [13.944285885000056, 123.08469890900005],
  [13.944084903000032, 123.08465991000003],
  [13.943803540000033, 123.08448447100011],
  [13.942991834000054, 123.0843089870001],
  [13.942656083000031, 123.08417983400011],
  [13.942308500000024, 123.08416031400009],
  [13.942192642000066, 123.08410670600006],
  [13.94181771600006, 123.08407531800003],
  [13.941418120000037, 123.08396808700002],
  [13.940869844000076, 123.08393637000006],
  [13.939816066000049, 123.08411412900011],
  [13.939416274000052, 123.08422129100006],
  [13.939101566000033, 123.08425748700006],
  [13.938546313000074, 123.08440745400003],
  [13.937366152000038, 123.08436667],
  [13.937099634000049, 123.084386988],
  [13.935970120000036, 123.0846759430001],
  [13.93550643900005, 123.0847185130001],
  [13.935224099000038, 123.08482257500009],
  [13.93494131400007, 123.08497393900007],
  [13.93446386000005, 123.08534052100003],
  [13.934197587000028, 123.08547296200004],
  [13.93393069900003, 123.08567198300011],
  [13.933338469000034, 123.0859770730001],
  [13.93233606900003, 123.08619734100012],
  [13.931861455000046, 123.08638389700002],
  [13.931563045000075, 123.08655417600005],
  [13.930953292000027, 123.08694494300005],
  [13.930549291000034, 123.08709393400011],
  [13.930254281000032, 123.08708920000004],
  [13.930107371000076, 123.08716960900006],
  [13.92990353500005, 123.087169463],
  [13.929738143000066, 123.08723336100002],
  [13.929313180000065, 123.08724992300006],
  [13.929124818000048, 123.08721442100011],
  [13.928874433000033, 123.0872664850001],
  [13.928611319000026, 123.08726411600003],
  [13.928156494000064, 123.08719784200002],
  [13.927995967000072, 123.08723807400008],
  [13.927329428000064, 123.08719546500004],
  [13.926674752000054, 123.08734455800004],
  [13.926111602000049, 123.0874084510001],
  [13.925599545000068, 123.08756464600003],
  [13.924832310000056, 123.08774213400011],
  [13.92415502700004, 123.08773878600005],
  [13.923888562000059, 123.08779558200001],
  [13.923530214000039, 123.08781451100003],
  [13.923282124000025, 123.08789970700002],
  [13.92286309900004, 123.08794746600006],
  [13.922626498000056, 123.08792853000011],
  [13.922116538000068, 123.08809182100003],
  [13.921740900000032, 123.08831151900006],
  [13.920820629000048, 123.08900203100006],
  [13.920530364000058, 123.08916166600011],
  [13.91990230700003, 123.08964907100005],
  [13.919419979000054, 123.090157212],
  [13.918692020000037, 123.09080064800003],
  [13.91831318900006, 123.09121569400008],
  [13.917706586000065, 123.09174187600001],
  [13.91755170700003, 123.09181908300002],
  [13.91736435100006, 123.092094459],
  [13.917034706000038, 123.09233143600011],
  [13.916632519000075, 123.09247555500008],
  [13.916303571000071, 123.0924961390001],
  [13.915656574000025, 123.09268400600001],
  [13.915382390000048, 123.09270104900008],
  [13.915087020000044, 123.09264024900006],
  [13.914397649000023, 123.092447676],
  [13.913985033000074, 123.0922711710001],
  [13.913656873000036, 123.0918585070001],
  [13.913209163000033, 123.09152746500001],
  [13.913001648000034, 123.09144294200007],
  [13.912583678000033, 123.09115663900002],
  [13.912257928000031, 123.0910174280001],
  [13.91200639300007, 123.09094988600009],
  [13.911128072000054, 123.0908081870001],
  [13.910760634000042, 123.09070875100008],
  [13.91064239900004, 123.09071123600006],
  [13.910311823000029, 123.09056705600005],
  [13.909839460000057, 123.09030852400008],
  [13.909617467000032, 123.09026875100005],
  [13.909265110000035, 123.09010780400001],
  [13.909235834000071, 123.09005251100007],
  [13.90883575600003, 123.08977102400002],
  [13.908662545000027, 123.08970568000007],
  [13.908311243000071, 123.08948702600003],
  [13.908013613000037, 123.08938398300006],
  [13.90760705200006, 123.08934628600002],
  [13.907084979000047, 123.08925329800002],
  [13.906799547000048, 123.08924073300011],
  [13.906256795000047, 123.0890522420001],
  [13.906010396000056, 123.08902711100006],
  [13.905729842000028, 123.0889265830001],
  [13.905490761000067, 123.08892909800011],
  [13.905264034000027, 123.08886375500003],
  [13.904998119000027, 123.08890396900006],
  [13.90459314800006, 123.0890673340001],
  [13.904167250000057, 123.08897937200004],
  [13.904020875000072, 123.08908744200005],
  [13.903789115000052, 123.08916284000009],
  [13.903353465000066, 123.08924075300001],
  [13.903072913000074, 123.08935636300009],
  [13.902657603000023, 123.08961222900007],
  [13.902379490000044, 123.08984595900006],
  [13.901818873000025, 123.09039655100003],
  [13.901574914000037, 123.09048702700011],
  [13.901138225000068, 123.09071321600004],
  [13.900437723000039, 123.09144967300006],
  [13.899536870000077, 123.0922712250001],
  [13.898749615000042, 123.09296536900001],
  [13.898501150000072, 123.09314479900002],
  [13.898147664000021, 123.093347977],
  [13.89780954300005, 123.0936250420001],
  [13.897374077000052, 123.09379651000006],
  [13.896823357000073, 123.09390469100003],
  [13.896029858000077, 123.09423775900007],
  [13.895540614000026, 123.09434593900005],
  [13.895026666000035, 123.09456222800009],
  [13.894711603000076, 123.0945991640001],
  [13.894496437000043, 123.09470471000009],
  [13.893961595000064, 123.09509328500008],
  [13.893729436000058, 123.09505937300003],
  [13.893551744000035, 123.09509978300002],
  [13.893115592000072, 123.09511641400002],
  [13.892876383000043, 123.09505935600009],
  [13.892345616000057, 123.09508311800005],
  [13.891865778000067, 123.09529230400005],
  [13.891374240000061, 123.09540164500004],
  [13.890891009000029, 123.09536122200007],
  [13.89028178500007, 123.0951781650001],
  [13.890011788000038, 123.09506405400009],
  [13.88988942800006, 123.09495973500009],
  [13.889598662000026, 123.09481947400002],
  [13.889162515000066, 123.09452469400003],
  [13.88881867200007, 123.09445575000007],
  [13.888425932000075, 123.09453181200001],
  [13.888010549000057, 123.09457459400005],
  [13.887687827000036, 123.09432805900008],
  [13.887655522000045, 123.09421395400011],
  [13.887433490000035, 123.09390491800002],
  [13.88733426400006, 123.09364105100008],
  [13.887048118000052, 123.09309698200002],
  [13.887075812000035, 123.09290918600004],
  [13.886818859000073, 123.09241737400009],
  [13.886565014000041, 123.09217727700002],
  [13.88654193900004, 123.09194669200008],
  [13.886445017000028, 123.09174701000006],
  [13.88604809800006, 123.0914665040001],
  [13.885825325000042, 123.09099731900005],
  [13.885549530000048, 123.09067499100001],
  [13.884838775000048, 123.09025691500005],
  [13.884518177000075, 123.09003518100008],
  [13.884154072000058, 123.08967899200002],
  [13.884165521000057, 123.0895209470001],
  [13.884348717000023, 123.0894666910001],
  [13.883929651000074, 123.08916947500006],
  [13.883366055000067, 123.08888669100008],
  [13.883249264000028, 123.08862721500009],
  [13.883210571000063, 123.08837526700006],
  [13.88278921400007, 123.08805446600002],
  [13.882267365000075, 123.08774674900008],
  [13.882180345000052, 123.0876335260001],
  [13.881804786000032, 123.08735518600008],
  [13.881587234000051, 123.08706976600001],
  [13.881445304000067, 123.08703236400004],
  [13.880817854000043, 123.08700878800005],
  [13.880433141000026, 123.08704417800004],
  [13.879783509000049, 123.08717628500006],
  [13.878895230000069, 123.08745936100001],
  [13.878766994000046, 123.08759145800002],
  [13.878702877000023, 123.08775893600011],
  [13.878531129000066, 123.08772119800005],
  [13.878275336000058, 123.08758438900009],
  [13.878092142000071, 123.087742434],
  [13.877970771000037, 123.0875584470001],
  [13.877821923000056, 123.08755137300011],
  [13.877675363000037, 123.0874051290001],
  [13.877309339000021, 123.08733263500005],
  [13.87715133000006, 123.08721705500011],
  [13.876972374000047, 123.08724300600011],
  [13.87659910800005, 123.08715809500006],
  [13.876512087000037, 123.0870094920001],
  [13.876367818000062, 123.08695524200004],
  [13.876255612000023, 123.08705431500005],
  [13.876160653000056, 123.086809],
  [13.87582478400003, 123.08679081100001],
  [13.875706695000076, 123.08655343100008],
  [13.87545718900003, 123.08631017000005],
  [13.875321964000022, 123.086282707],
  [13.875357800000074, 123.08603110500007],
  [13.875254951000045, 123.08594086300002],
  [13.875112099000035, 123.08561324100003],
  [13.874971157000061, 123.085473954],
  [13.874995911000042, 123.08524453100006],
  [13.875152082000056, 123.08506796200004],
  [13.875232058000051, 123.08445390700001],
  [13.875002208000069, 123.08408657300004],
  [13.874729842000022, 123.0837550330001],
  [13.874449264000077, 123.08354483300002],
  [13.87426070400005, 123.0833525810001],
  [13.873880572000076, 123.0831294940001],
  [13.873209204000034, 123.08288025600007],
  [13.873143952000078, 123.08269370200003],
  [13.87270022100006, 123.082488226],
  [13.872568509000075, 123.082318643],
  [13.872423384000058, 123.08194600400009],
  [13.872437313000034, 123.08176859200012],
  [13.872322062000023, 123.08152987100004],
  [13.872202495000067, 123.0808802040001],
  [13.872260745000062, 123.0805106580001],
  [13.87261245700006, 123.07963749200007],
  [13.872655505000068, 123.0791518640001],
  [13.87271628800005, 123.07888052800001],
  [13.87287205900003, 123.07881399300004],
  [13.872844193000049, 123.07862223200004],
  [13.872954563000064, 123.07845209700008],
  [13.872926698000072, 123.07834382400006],
  [13.87316363900004, 123.07782476700004],
  [13.873356930000057, 123.07769550000012],
  [13.873416447000068, 123.07751156300003],
  [13.873637315000053, 123.07727526700012],
  [13.87377282400007, 123.07722960500007],
  [13.873937796000064, 123.07741092600008],
  [13.873955538000075, 123.07780358100001],
  [13.873714912000025, 123.07775271300011],
  [13.873646527000062, 123.07786620700006],
  [13.873782391000077, 123.07808174700006],
  [13.873507375000031, 123.0785330870001],
  [13.873366804000057, 123.07870137200007],
  [13.873240085000077, 123.07898417800004],
  [13.87300112500003, 123.0793739500001],
  [13.873069515000054, 123.07949265700006],
  [13.872932743000035, 123.07968833600012],
  [13.872956808000026, 123.07980835],
  [13.873113849000049, 123.07985400400003],
  [13.87298777500007, 123.08006870800011],
  [13.873140766000063, 123.08030033400007],
  [13.87302268600007, 123.08062925600007],
  [13.872749050000039, 123.08077907100005],
  [13.872616341000025, 123.08090058300002],
  [13.872708508000073, 123.0813087790001],
  [13.872627413000032, 123.08178617900012],
  [13.872621887000037, 123.08205198100006],
  [13.872973941000055, 123.08206716600012],
  [13.873167848000037, 123.08227813000008],
  [13.873285814000042, 123.08228002800001],
  [13.87373924600007, 123.08250785400003],
  [13.873882319000074, 123.08249609100005],
  [13.874088761000053, 123.08263848500007],
  [13.87431547600005, 123.0826574680001],
  [13.874398420000034, 123.08259101600004],
  [13.874605495000026, 123.08258911500002],
  [13.874679078000042, 123.08266716600008],
  [13.87497214900003, 123.08263488700004],
  [13.875082734000046, 123.08272830500005],
  [13.875528791000022, 123.0825707140001],
  [13.875789564000058, 123.08254461700005],
  [13.875972114000035, 123.08242785400012],
  [13.876121201000046, 123.0825016870001],
  [13.87634196600004, 123.08252531500011],
  [13.876743359000045, 123.08242490700002],
  [13.877208203000066, 123.08240719200012],
  [13.877377361000072, 123.08236289400008],
  [13.877907771000025, 123.08241015200008],
  [13.878088397000056, 123.082510565],
  [13.878180144000055, 123.08246626700009],
  [13.878375105000032, 123.08261393300006],
  [13.878492016000052, 123.08254010200005],
  [13.879010957000048, 123.08256964000009],
  [13.87940948000005, 123.08291518200008],
  [13.879755861000035, 123.08311136100008],
  [13.880019631000039, 123.08331809700007],
  [13.880076970000061, 123.08349529800012],
  [13.880358467000065, 123.08377381300011],
  [13.880602168000053, 123.08387127700007],
  [13.880743867000035, 123.08359296600008],
  [13.880890089000047, 123.083566388],
  [13.881116588000054, 123.08363136500009],
  [13.881259943000032, 123.08358706700005],
  [13.881583927000065, 123.08335671100008],
  [13.881916509000064, 123.08334490200002],
  [13.882333799000037, 123.08317922000003],
  [13.882514425000068, 123.08326782400002],
  [13.882677842000021, 123.08376694300011],
  [13.882778189000021, 123.08387326500008],
  [13.883105035000028, 123.08391757100003],
  [13.883411814000056, 123.08388213500007],
  [13.88337167700007, 123.08373151300009],
  [13.883225455000058, 123.08374332500011],
  [13.883113638000054, 123.08384669000009],
  [13.882875670000033, 123.08384668600002],
  [13.882715115000053, 123.08373150300008],
  [13.882652041000028, 123.08356611500005],
  [13.882660647000023, 123.0831792250001],
  [13.882597572000066, 123.08313787800012],
  [13.882471425000062, 123.08268084800011],
  [13.882392201000073, 123.08297913600006],
  [13.882042414000068, 123.08321244600006],
  [13.881916261000072, 123.08325674600007],
  [13.881623819000026, 123.08322425500012],
  [13.881403052000053, 123.08329513300009],
  [13.881294101000037, 123.08342212500008],
  [13.881029569000077, 123.08349300200007],
  [13.880837475000021, 123.08343688500008],
  [13.880484822000028, 123.08350776000009],
  [13.880301330000066, 123.08340143800001],
  [13.880031827000039, 123.08309133500006],
  [13.879553027000043, 123.0827369320001],
  [13.879388679000044, 123.08254533200011],
  [13.879308404000028, 123.08216435300005],
  [13.879199456000038, 123.08193990200004],
  [13.879041767000047, 123.08183358100007],
  [13.878686251000033, 123.08138467600008],
  [13.878532799000027, 123.08105142900001],
  [13.878515597000046, 123.08082107000007],
  [13.878673287000026, 123.08050211200009],
  [13.878670420000049, 123.08035740100001],
  [13.878894052000021, 123.08000005100007],
  [13.878965496000035, 123.07951086100002],
  [13.879151856000021, 123.07931889400004],
  [13.879103115000078, 123.07919485600007],
  [13.879223531000036, 123.07887885100001],
  [13.87920632600003, 123.07860714600008],
  [13.879126044000031, 123.07807262500012],
  [13.879206319000048, 123.0778038740001],
  [13.879338203000032, 123.07757056100002],
  [13.879346802000043, 123.07739926900001],
  [13.879266517000076, 123.07684995200009],
  [13.879352528000027, 123.07674570800009],
  [13.879255043000057, 123.07648581600006],
  [13.87928084300006, 123.07627908400002],
  [13.879125271000078, 123.0760968190001],
  [13.879060889000073, 123.07591644500008],
  [13.878849726000055, 123.07582891400011],
  [13.878798220000022, 123.07566445500004],
  [13.878648861000045, 123.07559018500001],
  [13.878460876000076, 123.07561936800005],
  [13.878362028000026, 123.07510909200005],
  [13.878382627000065, 123.07493932600005],
  [13.878202367000029, 123.07493402500006],
  [13.878228109000077, 123.07454409600007],
  [13.87802724100004, 123.07422131200008],
  [13.877996336000024, 123.07409664200009],
  [13.877630231000069, 123.07403726000007],
  [13.87765704700007, 123.07383137800002],
  [13.877581490000068, 123.07363553800008],
  [13.877276815000073, 123.07325891800008],
  [13.87720856900006, 123.0730555450001],
  [13.877180840000051, 123.07266677000007],
  [13.876902984000026, 123.071649911],
  [13.876997971000037, 123.0712999530001],
  [13.87705647100006, 123.07123718500009],
  [13.87684441700003, 123.07079027000009],
  [13.876756668000041, 123.07071996800005],
  [13.876252729000043, 123.07050685500008],
  [13.876143046000038, 123.0701126670001],
  [13.876333169000077, 123.07005743400009],
  [13.876451928000051, 123.06992420100005],
  [13.876478741000028, 123.06977857800007],
  [13.876371493000022, 123.0697007440001],
  [13.87623499700004, 123.0692437890001],
  [13.87607412400007, 123.06919106400005],
  [13.875844580000035, 123.06891855900005],
  [13.87535125900007, 123.06841316900011],
  [13.87498217600006, 123.06810702400003],
  [13.874679212000046, 123.06811254700006],
  [13.874443276000022, 123.0678280950001],
  [13.87448885300006, 123.06763201600006],
  [13.874711385000069, 123.06767067900012],
  [13.874679211000057, 123.06737518000011],
  [13.874547836000033, 123.06727576000003],
  [13.874503361000052, 123.06702245100007],
  [13.874388073000034, 123.06680427900005],
  [13.874417563000065, 123.06658610600005],
  [13.874495315000047, 123.0665170640001],
  [13.874267418000045, 123.06610834000003],
  [13.874101191000022, 123.06621052100002],
  [13.874128004000056, 123.06650602000002],
  [13.873916198000074, 123.06657230100006],
  [13.873779461000026, 123.06647012000008],
  [13.873736561000044, 123.06620776300008],
  [13.873551563000035, 123.06584598800009],
  [13.873379971000077, 123.06568305100006],
  [13.873412141000074, 123.06543726200005],
  [13.873315620000028, 123.06526623600007],
  [13.873216420000062, 123.06533251700012],
  [13.873232510000037, 123.06566943900009],
  [13.87351939000007, 123.06591522500003],
  [13.873615911000059, 123.06612234800002],
  [13.873575697000035, 123.06640403900008],
  [13.873564533000035, 123.0668917800001],
  [13.873462651000068, 123.06703262500002],
  [13.873301785000024, 123.06698567700005],
  [13.873272292000024, 123.06682550100004],
  [13.873041718000025, 123.06692492200011],
  [13.872835275000057, 123.0671624260001],
  [13.872884154000076, 123.06730547900008],
  [13.87276350600007, 123.06737176000001],
  [13.872546337000074, 123.06735242900004],
  [13.872128085000043, 123.06720054000004],
  [13.872130766000055, 123.06733309900005],
  [13.871916277000025, 123.06739109700004],
  [13.871835844000032, 123.06723644400006],
  [13.871879749000072, 123.0668540150001],
  [13.871635769000022, 123.06689267900003],
  [13.871440048000068, 123.06672146000005],
  [13.87161700300004, 123.06723512500002],
  [13.871324763000075, 123.06737320900004],
  [13.871217519000027, 123.06708875900006],
  [13.87111599800005, 123.0672264420001],
  [13.870920278000028, 123.06723748800005],
  [13.870874698000023, 123.0670193200001],
  [13.870732601000043, 123.06711045400004],
  [13.870445723000046, 123.06691161900005],
  [13.870368046000067, 123.06665736500008],
  [13.870274206000033, 123.06653585400011],
  [13.869885448000048, 123.06662699000003],
  [13.869880085000034, 123.06640606100007],
  [13.869644191000077, 123.06632045300012],
  [13.869486004000066, 123.06614647100002],
  [13.869258112000068, 123.06635911700005],
  [13.869032900000036, 123.06636740500005],
  [13.868629089000024, 123.06619341500004],
  [13.868167939000045, 123.06604705400002],
  [13.867062651000026, 123.06561073400007],
  [13.866781851000042, 123.0655412750001],
  [13.866606023000031, 123.06554915100003],
  [13.865925650000065, 123.0657263270001],
  [13.865509017000022, 123.06571452000003],
  [13.865130607000026, 123.06586807000008],
  [13.865038871000024, 123.06580507800004],
  [13.864740727000026, 123.0657893340001],
  [13.86439540400005, 123.06595862900008],
  [13.864136749000068, 123.06597442200007],
  [13.864010610000037, 123.06579331800003],
  [13.864022074000047, 123.06554922200007],
  [13.863964735000025, 123.06526969400011],
  [13.863842418000047, 123.06505316100004],
  [13.863704813000027, 123.06492717700007],
  [13.863714402000028, 123.06472356100005],
  [13.863821185000063, 123.06449779200011],
  [13.863877381000066, 123.06410125000002],
  [13.863950442000032, 123.06393626600004],
  [13.863807119000057, 123.0636034050001],
  [13.863460438000061, 123.06339032500011],
  [13.862909647000038, 123.06314720400007],
  [13.862693265000075, 123.06308063600011],
  [13.862356051000063, 123.0631906320001],
  [13.861867087000064, 123.06313854400003],
  [13.861959819000049, 123.06300539800009],
  [13.861735002000046, 123.06271595900012],
  [13.861498951000044, 123.06277385300007],
  [13.86144211800007, 123.06297357100004],
  [13.861155483000061, 123.06292726600009],
  [13.86103183800003, 123.06295331900003],
  [13.860899755000048, 123.06270440100002],
  [13.86108241200003, 123.06262045800008],
  [13.860893159000057, 123.06247034900002],
  [13.860803230000045, 123.06232563100002],
  [13.860659916000031, 123.06237483900009],
  [13.860402758000077, 123.06223879700008],
  [13.860268313000063, 123.06196968300003],
  [13.860080598000025, 123.06178417800004],
  [13.859791416000064, 123.06171102300004],
  [13.859616385000038, 123.06160912500002],
  [13.859354211000039, 123.06159932700007],
  [13.859067567000068, 123.06170906400007],
  [13.85881389900004, 123.06166203700002],
  [13.858676653000032, 123.06129267800009],
  [13.858324053000047, 123.06117510900003],
  [13.85770691700003, 123.06113376400003],
  [13.857303585000068, 123.06124872600003],
  [13.856849520000026, 123.06124611700011],
  [13.856781029000047, 123.06108674200004],
  [13.856527361000076, 123.06116512500012],
  [13.85642589300005, 123.0611102580001],
  [13.85634979100007, 123.0609143050001],
  [13.856443647000049, 123.0607888940001],
  [13.856383553000057, 123.06032518100005],
  [13.856408918000056, 123.06012922800005],
  [13.856315059000053, 123.06009003800011],
  [13.856208517000027, 123.059883635],
  [13.856051242000035, 123.05979480500002],
  [13.855679143000032, 123.0598104500001],
  [13.855572602000052, 123.0597399080001],
  [13.855115999000077, 123.05963279500008],
  [13.854738768000061, 123.05965370000001],
  [13.854277093000064, 123.05975037600001],
  [13.854233967000027, 123.05952829800003],
  [13.854070284000045, 123.05963317400006],
  [13.853631436000057, 123.05940065300001],
  [13.853415817000041, 123.05938236600002],
  [13.853250930000058, 123.05917074100012],
  [13.853283902000044, 123.05891208500009],
  [13.853184774000056, 123.05875109400006],
  [13.852957992000029, 123.05858696500002],
  [13.852731211000048, 123.05854909200002],
  [13.852216120000037, 123.05860338700006],
  [13.851922444000024, 123.05868561200009],
  [13.851186909000035, 123.05913785400003],
  [13.850961662000032, 123.05933754400007],
  [13.850556787000073, 123.0593845300001],
  [13.850021825000056, 123.05936882700007],
  [13.849401735000072, 123.05947920400001],
  [13.849031018000062, 123.05963540200003],
  [13.848677154000029, 123.05972217800002],
  [13.848188486000026, 123.05987837300006],
  [13.84801998000006, 123.05998250300001],
  [13.847590290000028, 123.06006060000004],
  [13.84727855400007, 123.06022547100008],
  [13.847034220000069, 123.06043372900001],
  [13.846781458000066, 123.06085024700008],
  [13.846732269000029, 123.0611451630001],
  [13.846803566000062, 123.06133923700008],
  [13.847143607000078, 123.06135319000009],
  [13.847135042000048, 123.06162579700003],
  [13.847059047000073, 123.06207150700004],
  [13.847093295000036, 123.06214426100007],
  [13.846835352000028, 123.06230740400008],
  [13.846712041000046, 123.0625619650001],
  [13.846496267000077, 123.06258098900003],
  [13.846251769000048, 123.06271861400012],
  [13.845750030000033, 123.06283762500004],
  [13.845474929000034, 123.06284364700002],
  [13.845283308000035, 123.06252726600007],
  [13.845235038000055, 123.06234647800011],
  [13.845309642000075, 123.06220486200004],
  [13.845531994000055, 123.06202252900005],
  [13.845814313000062, 123.06200746600007],
  [13.846001551000029, 123.06195323300005],
  [13.846100119000027, 123.06166211400011],
  [13.846051850000038, 123.06131259100005],
  [13.845943606000048, 123.0610157960001],
  [13.845633403000022, 123.0606789740001],
  [13.845408058000032, 123.06033977200002],
  [13.845243382000035, 123.06015231900005],
  [13.84513937500003, 123.05992915900003],
  [13.845200043000034, 123.05973277800001],
  [13.845312713000055, 123.05960780600003],
  [13.845286711000028, 123.05950069000005],
  [13.845078700000045, 123.05942927900003],
  [13.844966029000034, 123.05945606000012],
  [13.84488802800007, 123.05970600100011],
  [13.844723354000052, 123.05969707600002],
  [13.844532676000028, 123.059572107],
  [13.844099323000023, 123.05968815400001],
  [13.843943318000072, 123.05992916800005],
  [13.843804644000045, 123.05995594800004],
  [13.843670627000051, 123.06015233100004],
  [13.843393280000043, 123.06024159700007],
  [13.84245723600003, 123.06012556600001],
  [13.841893878000064, 123.06008093900004],
  [13.84159919800004, 123.0600898670001],
  [13.841495192000025, 123.05992919500011],
  [13.841292809000038, 123.05986942700008],
  [13.840270091000036, 123.0601104530001],
  [13.839984078000043, 123.06023542500009],
  [13.83969806600004, 123.06041395300008],
  [13.839403387000061, 123.06052999600001],
  [13.839074039000025, 123.06070852200003],
  [13.838692687000048, 123.06075315400005],
  [13.837695091000057, 123.06077152500006],
  [13.837581502000035, 123.06069556800003],
  [13.852346347000037, 123.04280070100003],
  [13.856564754000033, 123.03747165200002],
  [13.868653656000049, 123.02223443900004],
  [13.877149907000046, 123.01108489300009],
  [13.879259786000034, 123.00822191000009]
];

const polygonMer = [
  [// mercedes 
    polygonMercedes
  ],
  [// mercedes 1
    polygonMercedes1
  ],
  [// mercedes 2
    polygonMercedes2
  ],
  [// mercedes 3
    polygonMercedes3
  ],
  [// mercedes 4
    polygonMercedes4
  ],
  [// mercedes 5
    polygonMercedes5
  ],
  [// mercedes 6
    polygonMercedes6
  ],
  [// mercedes 7
    polygonMercedes7
  ],
  [// mercedes 8
    polygonMercedes8
  ],
  [// mercedes 9
    polygonMercedes9
  ],
  [// mercedes 10
    polygonMercedes10
  ],
  [// mercedes 11
    polygonMercedes11
  ]
]

const polygonCoordsLabo = [
  [14.221664167000029, 122.86633306500005],
  [14.214687022000021, 122.86740974000008],
  [14.214485343000035, 122.86731645200007],
  [14.214386123000054, 122.86720055600006],
  [14.214334283000028, 122.86692710000011],
  [14.214147726000022, 122.86693862600009],
  [14.213599282000075, 122.86678344200004],
  [14.213159203000032, 122.86648476100004],
  [14.212844285000074, 122.86619298500011],
  [14.212718856000038, 122.86602650500004],
  [14.212531653000042, 122.86545072400008],
  [14.212162312000032, 122.86532259000012],
  [14.212170625000056, 122.86514104700007],
  [14.212083910000047, 122.86481258100002],
  [14.212008465000054, 122.86474921500007],
  [14.211531069000046, 122.86452352100002],
  [14.211176064000028, 122.86407966100012],
  [14.21106704500005, 122.86383471400006],
  [14.21116448400005, 122.86346639600004],
  [14.21101337600004, 122.86310778100005],
  [14.210742870000047, 122.8628632760001],
  [14.210681479000073, 122.8626448220001],
  [14.21069813500003, 122.86247233100005],
  [14.210912638000025, 122.86226526300004],
  [14.211057109000024, 122.861992471],
  [14.211154100000044, 122.86167992000003],
  [14.211142905000031, 122.8615221120001],
  [14.211034643000062, 122.86133445900009],
  [14.205262312000059, 122.86180372400008],
  [14.202378232000058, 122.86192764100008],
  [14.20233229300004, 122.86167843300007],
  [14.202451917000076, 122.86142949500004],
  [14.202811559000054, 122.86117451100006],
  [14.20300859200006, 122.86113094100006],
  [14.203091847000053, 122.86101817100007],
  [14.203069596000034, 122.86075836900011],
  [14.202961549000065, 122.86042620100011],
  [14.20270368100006, 122.860112275],
  [14.20238555900005, 122.85991283400006],
  [14.20205538600004, 122.85987203800005],
  [14.201712103000034, 122.85988396500011],
  [14.20180343100003, 122.86007017300005],
  [14.201831349000031, 122.86054315400008],
  [14.201778065000042, 122.86075011600008],
  [14.201554176000059, 122.86095986200007],
  [14.201205896000033, 122.86112120400003],
  [14.200930350000021, 122.86149736400012],
  [14.200186423000048, 122.86184295800001],
  [14.199726223000027, 122.86187515300003],
  [14.199392516000046, 122.86185376300011],
  [14.19921593500004, 122.86175522600001],
  [14.198983848000069, 122.86142990300004],
  [14.198839659000043, 122.86144630800004],
  [14.198519881000038, 122.86179327500008],
  [14.198182733000067, 122.86170426100011],
  [14.197977364000053, 122.86156960000005],
  [14.197745325000028, 122.86134243900005],
  [14.197308270000065, 122.86129701400012],
  [14.196902677000026, 122.86112651100007],
  [14.196555592000038, 122.86078723900005],
  [14.196396686000071, 122.86034718400003],
  [14.196217640000043, 122.86013837000007],
  [14.195982426000057, 122.86009298400006],
  [14.19583711100006, 122.86026827400008],
  [14.195665943000051, 122.86035929700006],
  [14.195172326000034, 122.8602905780001],
  [14.195014792000052, 122.86040840600003],
  [14.194562634000022, 122.86093826500007],
  [14.194292398000073, 122.86056145100008],
  [14.193460385000037, 122.8595431870001],
  [14.192568946000051, 122.85808980400009],
  [14.192051669000023, 122.85673715300004],
  [14.191696233000073, 122.85566611200011],
  [14.191503202000035, 122.8546169230001],
  [14.191321260000052, 122.85397383300005],
  [14.190832648000026, 122.85334005000004],
  [14.19023760600004, 122.85296559500011],
  [14.189633763000074, 122.85287558000005],
  [14.189029571000049, 122.85273641700007],
  [14.188473007000027, 122.85288316200001],
  [14.18792039400006, 122.85323484000003],
  [14.187278680000077, 122.85407394800006],
  [14.186903193000035, 122.85533059900001],
  [14.186838006000073, 122.85656529100004],
  [14.187001286000054, 122.85717889000011],
  [14.187385235000022, 122.85798981900007],
  [14.187971350000055, 122.85882986000001],
  [14.188383086000044, 122.859187259],
  [14.188717702000076, 122.85938574500005],
  [14.188900644000057, 122.85976125400009],
  [14.189133857000058, 122.8604042830001],
  [14.189544481000041, 122.86103343800005],
  [14.189973758000065, 122.86162163300003],
  [14.190318911000077, 122.86240314100007],
  [14.190501209000047, 122.86326920600004],
  [14.19045847800004, 122.86425845700012],
  [14.190165298000068, 122.86528818300008],
  [14.189725637000038, 122.86621535300003],
  [14.18902584600005, 122.86664950300008],
  [14.185944910000046, 122.86696853500007],
  [14.180187769000042, 122.86746432300004],
  [14.177895893000027, 122.86763479400008],
  [14.17593043100004, 122.86742178200006],
  [14.174532327000065, 122.86701757000003],
  [14.173637519000067, 122.86708850900004],
  [14.173197383000058, 122.86697656600006],
  [14.173022478000064, 122.86697445700008],
  [14.17255861700005, 122.8673319720001],
  [14.17233292700007, 122.86758476500006],
  [14.172047321000036, 122.86767608500008],
  [14.171920577000037, 122.86763269000005],
  [14.171846128000027, 122.86747984500005],
  [14.171696273000066, 122.86745780100011],
  [14.171500931000026, 122.86757442800001],
  [14.171360742000047, 122.86773302400002],
  [14.171154949000027, 122.86771294800008],
  [14.171118310000054, 122.867517098],
  [14.170933281000032, 122.86747767700001],
  [14.170826761000058, 122.86756124800002],
  [14.170570809000026, 122.86754741700008],
  [14.170475562000036, 122.86734054900012],
  [14.170651793000047, 122.86695775400005],
  [14.170675174000053, 122.86683064400006],
  [14.170573580000053, 122.86660589100006],
  [14.170389912000076, 122.86655557600011],
  [14.17010988100003, 122.86661286200001],
  [14.169976035000047, 122.86671381300005],
  [14.169817052000042, 122.8670906100001],
  [14.169250142000067, 122.86741510900004],
  [14.168930739000075, 122.8678424960001],
  [14.168718622000029, 122.86803853800006],
  [14.168561345000057, 122.86808242300003],
  [14.16814753500006, 122.868038866],
  [14.167966314000068, 122.86813630000006],
  [14.167879693000032, 122.86852877100011],
  [14.167704404000062, 122.86873016100003],
  [14.167548521000072, 122.8685768690001],
  [14.16710951500005, 122.86867190600003],
  [14.167011066000043, 122.86878105900007],
  [14.166643092000072, 122.86883430900002],
  [14.166354094000042, 122.869008172],
  [14.166088844000058, 122.86903723700004],
  [14.16594817500004, 122.86924658500004],
  [14.165783052000052, 122.86937549200002],
  [14.16548315600005, 122.86940510600004],
  [14.16525660800005, 122.86889738100001],
  [14.165151371000036, 122.86860600900002],
  [14.165200822000031, 122.86837009400006],
  [14.165131834000022, 122.86824554900011],
  [14.164964444000077, 122.86825456400004],
  [14.164902952000034, 122.86853686000006],
  [14.164604453000038, 122.86844672000007],
  [14.16443141800005, 122.868469935],
  [14.164235218000044, 122.86866679900004],
  [14.164145941000072, 122.86861498700011],
  [14.16420968500006, 122.86822909200009],
  [14.164059555000051, 122.86815740400004],
  [14.163784373000055, 122.8682013350001],
  [14.163755946000038, 122.86800727600007],
  [14.16382266900007, 122.86784199600004],
  [14.16347643000006, 122.8675360090001],
  [14.163336842000035, 122.86754179600007],
  [14.163145665000059, 122.86771965900004],
  [14.162901113000032, 122.86785874500004],
  [14.16275534700003, 122.86786256100004],
  [14.16268274500004, 122.86774409400005],
  [14.162722952000024, 122.86753350000004],
  [14.162585653000065, 122.86738217300001],
  [14.162499770000068, 122.86750236000012],
  [14.162479482000037, 122.86769133900009],
  [14.16229174600005, 122.867699291],
  [14.162197483000057, 122.86738094300006],
  [14.162048263000031, 122.86708959500004],
  [14.161906529000078, 122.86699847900002],
  [14.161580494000077, 122.86711823500002],
  [14.161325047000048, 122.86707304800007],
  [14.160962374000064, 122.86644209600001],
  [14.160814680000044, 122.866090127],
  [14.16068365600006, 122.86596970900007],
  [14.160499952000066, 122.86593705200005],
  [14.160330654000063, 122.86612154400007],
  [14.160115068000039, 122.86607806400002],
  [14.160113577000061, 122.86564621100001],
  [14.159976818000075, 122.86552584600008],
  [14.159717078000028, 122.86549338900011],
  [14.159588965000069, 122.86536253400004],
  [14.159296467000047, 122.86488055000007],
  [14.159242990000052, 122.86470879],
  [14.159196809000036, 122.86423062000006],
  [14.15912234700005, 122.8640973360001],
  [14.158929856000043, 122.86393763700005],
  [14.158533846000068, 122.86371137700007],
  [14.15835628700006, 122.86352056100009],
  [14.158367309000027, 122.8633218220001],
  [14.158657036000022, 122.8634593810001],
  [14.158971394000048, 122.86335688100007],
  [14.159065596000062, 122.86323259000005],
  [14.158926763000068, 122.86295220400007],
  [14.158912861000033, 122.86273397100001],
  [14.158757801000036, 122.86249878800004],
  [14.158390262000069, 122.86256043100002],
  [14.158155987000043, 122.86254698500011],
  [14.15780908000005, 122.86236406300009],
  [14.157461221000062, 122.86235933300009],
  [14.157302108000067, 122.86249584300003],
  [14.157239588000039, 122.86271441200006],
  [14.157318632000056, 122.86321336700007],
  [14.157413494000025, 122.86333696400004],
  [14.157579446000057, 122.86335654000004],
  [14.157726366000077, 122.8634893730001],
  [14.157779507000043, 122.86363045300004],
  [14.157707105000043, 122.86410570900011],
  [14.157629475000022, 122.86430952100011],
  [14.157732667000062, 122.86462404000008],
  [14.157898791000036, 122.86499817700007],
  [14.157892460000028, 122.86527860800004],
  [14.15769139300005, 122.86551111100005],
  [14.157518050000022, 122.86549389600009],
  [14.156999122000059, 122.86530803200003],
  [14.157028853000043, 122.86547001800011],
  [14.157193515000074, 122.86560979100011],
  [14.157487261000028, 122.865781534],
  [14.157673214000056, 122.86603177600011],
  [14.157480488000033, 122.86611894100008],
  [14.157296880000047, 122.86600781900006],
  [14.157060254000044, 122.86609514500003],
  [14.157094186000052, 122.86634453600004],
  [14.157024233000072, 122.86655056500001],
  [14.156714813000065, 122.86666301100001],
  [14.156718376000072, 122.86692974900006],
  [14.156890550000071, 122.86719123800003],
  [14.156922695000048, 122.86749593000002],
  [14.156763773000023, 122.86769297700005],
  [14.15624335800004, 122.86785401100008],
  [14.156149148000054, 122.86820997200005],
  [14.155931468000063, 122.868503615],
  [14.155398602000048, 122.86880623500008],
  [14.155134204000035, 122.86869452000008],
  [14.15500509900005, 122.86817747700002],
  [14.155188503000034, 122.86800607300006],
  [14.155151355000044, 122.86782938600004],
  [14.154910825000059, 122.86781073100008],
  [14.154552343000034, 122.86837635000006],
  [14.15438639100006, 122.86849621100009],
  [14.15402044900003, 122.86850628800005],
  [14.153550605000078, 122.86839100100008],
  [14.153264901000057, 122.86857525300002],
  [14.153194730000052, 122.8687878390001],
  [14.153295122000031, 122.86889248200009],
  [14.153590445000077, 122.86883655300005],
  [14.15373020800007, 122.86900942300008],
  [14.153828247000035, 122.86935236600004],
  [14.153824174000022, 122.86956372100008],
  [14.153666633000057, 122.86968382700002],
  [14.153248274000077, 122.86960957400004],
  [14.152982231000067, 122.86951968100004],
  [14.152487768000071, 122.86963542100011],
  [14.152326753000068, 122.86958443600008],
  [14.152160766000065, 122.86942980300012],
  [14.151783192000039, 122.8697101250001],
  [14.151516541000035, 122.86971462100007],
  [14.151391842000066, 122.86984501400002],
  [14.151179745000036, 122.86985745400011],
  [14.151070953000044, 122.86969458500005],
  [14.150952055000062, 122.86925873300004],
  [14.150772919000076, 122.86907901100005],
  [14.150516321000055, 122.86903234100009],
  [14.150463220000063, 122.86913733600011],
  [14.150453971000047, 122.86957158900009],
  [14.150528940000072, 122.86975917000007],
  [14.15077163500007, 122.86997754800007],
  [14.150760112000057, 122.87014763400009],
  [14.150436656000068, 122.87016270700008],
  [14.150373690000038, 122.8702254000001],
  [14.150465316000066, 122.87043752500006],
  [14.150472713000056, 122.87060091],
  [14.150387611000042, 122.87074126100003],
  [14.150172402000067, 122.8708647740001],
  [14.149836508000021, 122.87132136500009],
  [14.14958815700004, 122.87171035600011],
  [14.149399558000027, 122.87167411200005],
  [14.149167229000057, 122.8714803040001],
  [14.149026186000071, 122.87151864500004],
  [14.14899586200005, 122.87179171900004],
  [14.149055761000056, 122.87204642000006],
  [14.148741864000044, 122.87218051100001],
  [14.148528782000028, 122.87211932800005],
  [14.14854144700007, 122.872001339],
  [14.148831373000064, 122.87193337300005],
  [14.14876874500004, 122.87171582600001],
  [14.148404310000046, 122.8716651100001],
  [14.148287337000056, 122.87177053800008],
  [14.148071937000054, 122.871714988],
  [14.148018734000061, 122.87144132000003],
  [14.147803667000062, 122.87142636300007],
  [14.147636631000069, 122.87152706800009],
  [14.147377965000032, 122.87150344000008],
  [14.147356865000063, 122.87118809200001],
  [14.14779033700006, 122.87105991900012],
  [14.147591388000023, 122.87054849700007],
  [14.147408848000055, 122.87059146500008],
  [14.147329239000044, 122.8707747310001],
  [14.14691771300005, 122.87088884900004],
  [14.14677041300007, 122.87067457500007],
  [14.146783420000077, 122.87049450900008],
  [14.147114230000057, 122.8705098370001],
  [14.147159696000074, 122.87037829700012],
  [14.147001012000032, 122.87023955900008],
  [14.146933336000075, 122.86995344600007],
  [14.14670291300007, 122.869968416],
  [14.146312648000048, 122.87010077100001],
  [14.146376162000024, 122.86978091600008],
  [14.146517886000026, 122.8697416330001],
  [14.146454737000056, 122.86943938000002],
  [14.146311730000036, 122.8694528530001],
  [14.146240462000037, 122.86964259900003],
  [14.146124968000038, 122.86972433200003],
  [14.145964452000044, 122.86970964500006],
  [14.145628444000067, 122.86944755200011],
  [14.145514453000033, 122.86914442500006],
  [14.145636158000059, 122.8689477800001],
  [14.145891229000028, 122.86881940700005],
  [14.146191108000039, 122.86858678300007],
  [14.146455178000053, 122.86876304300006],
  [14.146611289000077, 122.8687749070001],
  [14.146699760000047, 122.8685380710001],
  [14.14661574300004, 122.86832490400002],
  [14.146213678000038, 122.86810254800002],
  [14.146167309000077, 122.86780605400008],
  [14.145936605000031, 122.86778008400006],
  [14.14561615200006, 122.86809965000009],
  [14.145492521000051, 122.86839183300003],
  [14.145319811000036, 122.86844529200005],
  [14.144848163000063, 122.86820654400003],
  [14.144467962000022, 122.86785379500009],
  [14.144338216000051, 122.86768416600012],
  [14.144123668000077, 122.86750728900006],
  [14.14384386200004, 122.86750060000008],
  [14.14374321400004, 122.86763790500004],
  [14.143708627000024, 122.86786640900004],
  [14.143387725000025, 122.86792734200003],
  [14.143157712000061, 122.86822176800001],
  [14.143135813000072, 122.86834990300008],
  [14.143231324000055, 122.86866194200002],
  [14.143021339000029, 122.86886878000007],
  [14.142778455000041, 122.86887237600001],
  [14.14268869700004, 122.86880240500011],
  [14.142611948000024, 122.86858842900006],
  [14.142485626000052, 122.86775815400006],
  [14.142515771000035, 122.8676355990001],
  [14.142214028000069, 122.86761084500006],
  [14.141871263000041, 122.86774423400004],
  [14.141602474000024, 122.86775297200006],
  [14.141270452000072, 122.867620224],
  [14.141213428000071, 122.86740871800009],
  [14.141047706000052, 122.86726099700002],
  [14.140784932000031, 122.86721835600008],
  [14.140554204000068, 122.86710120500004],
  [14.140407395000068, 122.86696956900005],
  [14.140156151000042, 122.86703406900006],
  [14.140141440000036, 122.86712033000003],
  [14.140270065000038, 122.86747217600009],
  [14.140168576000065, 122.86759440500009],
  [14.139501533000043, 122.867552036],
  [14.138705455000036, 122.86765349500001],
  [14.138553416000036, 122.86762480700008],
  [14.138378484000043, 122.86742711200009],
  [14.137753001000021, 122.86721161000003],
  [14.137381333000064, 122.86719616100004],
  [14.137153432000048, 122.86731270900009],
  [14.136931557000025, 122.86725044100001],
  [14.13682805700006, 122.86745686900008],
  [14.136638616000027, 122.86749541800009],
  [14.136475978000021, 122.86762886300005],
  [14.136480543000062, 122.86787999700005],
  [14.136799153000027, 122.86807645200008],
  [14.137145417000056, 122.86809445900008],
  [14.137215431000072, 122.86819514800004],
  [14.137241482000036, 122.86849090500004],
  [14.137013642000056, 122.86867408400008],
  [14.136937235000062, 122.86881956200011],
  [14.136649480000074, 122.8688533610001],
  [14.136497332000033, 122.86894791600002],
  [14.136453227000061, 122.86917555000002],
  [14.136484701000029, 122.86938270500002],
  [14.136613931000056, 122.869523436],
  [14.136771274000068, 122.86956310700009],
  [14.13675354000003, 122.86994956700005],
  [14.13686173900004, 122.87010070100007],
  [14.137133130000052, 122.87010620100011],
  [14.137175770000056, 122.87032421700007],
  [14.137435207000067, 122.87048590200004],
  [14.137774379000064, 122.87045539500002],
  [14.137893306000024, 122.87052720400004],
  [14.13790926300004, 122.87070156900006],
  [14.137700883000036, 122.87083609000001],
  [14.13750384900004, 122.8706555550001],
  [14.137367115000075, 122.87066108400006],
  [14.137154413000076, 122.87081680200004],
  [14.137242980000053, 122.87096437100001],
  [14.137255222000022, 122.871145124],
  [14.137079122000046, 122.87132729900009],
  [14.136824408000052, 122.87120216500011],
  [14.136804558000051, 122.8710997820001],
  [14.136906357000043, 122.87092344000007],
  [14.136750930000062, 122.8708371680001],
  [14.136472526000034, 122.87083270000005],
  [14.136399028000028, 122.87101655800006],
  [14.136232977000077, 122.87109426800009],
  [14.135836397000048, 122.87103074100003],
  [14.135650586000054, 122.87110330200005],
  [14.135399286000052, 122.87098005100006],
  [14.135051556000064, 122.87089844000002],
  [14.134760312000026, 122.87073517400006],
  [14.134505199000046, 122.87082577600006],
  [14.134383816000025, 122.87107662400001],
  [14.134386649000021, 122.87122854800009],
  [14.13471030200003, 122.8712822870001],
  [14.134785737000072, 122.87148621100005],
  [14.134750595000071, 122.87171567100006],
  [14.134620222000024, 122.8717411660001],
  [14.134352547000049, 122.8715848170001],
  [14.134175824000067, 122.8716491890001],
  [14.13413416000003, 122.87185437500011],
  [14.134252893000053, 122.8720538660001],
  [14.134100271000023, 122.87220284600005],
  [14.133919228000025, 122.87221134900005],
  [14.133697443000074, 122.87200886200003],
  [14.133623563000071, 122.87180841800011],
  [14.133423974000038, 122.87183564500003],
  [14.133365982000043, 122.87197982200007],
  [14.133469776000027, 122.87220719900006],
  [14.133447918000059, 122.87232964100008],
  [14.133219962000055, 122.87235851900004],
  [14.133115799000052, 122.87215717700008],
  [14.13288114200003, 122.87230304700006],
  [14.132750113000043, 122.87227278300008],
  [14.132469843000024, 122.87210295200009],
  [14.132412931000033, 122.8719211230001],
  [14.13258187300005, 122.87184122000008],
  [14.132580554000072, 122.87165292500003],
  [14.13281816600005, 122.87165024100011],
  [14.132930472000055, 122.87154956900008],
  [14.132624239000052, 122.871300141],
  [14.132463749000067, 122.87126575000002],
  [14.132309204000023, 122.87136522100002],
  [14.132389526000054, 122.8714783900001],
  [14.132399564000025, 122.87166124100008],
  [14.132248725000068, 122.87172720300009],
  [14.132036352000057, 122.8716687320001],
  [14.131977512000049, 122.87148264000007],
  [14.13208807500007, 122.8713445190001],
  [14.13202485200003, 122.87098266200007],
  [14.131913720000057, 122.87090213800002],
  [14.13147045200003, 122.87112661300011],
  [14.131168420000051, 122.87116286000003],
  [14.130901019000078, 122.8711208310001],
  [14.130556859000023, 122.87100420600007],
  [14.130483187000038, 122.87090906700007],
  [14.13052863400003, 122.87072278800008],
  [14.130451422000021, 122.87060143800011],
  [14.130158347000076, 122.87065625900004],
  [14.130019172000061, 122.87061677000008],
  [14.12939652800003, 122.8701546850001],
  [14.129149994000045, 122.869784019],
  [14.12904689000004, 122.8695652130001],
  [14.128705495000077, 122.8692933000001],
  [14.128627037000058, 122.86918955700003],
  [14.128630931000032, 122.86890742500009],
  [14.128686032000076, 122.86858137000002],
  [14.128704690000063, 122.8682357140001],
  [14.128656251000052, 122.86790801200004],
  [14.128430023000021, 122.86762414500004],
  [14.128303979000066, 122.86756286900004],
  [14.128051982000045, 122.86760119400003],
  [14.12791145400007, 122.86755969300009],
  [14.127762120000057, 122.86729114500008],
  [14.127676179000048, 122.86723510600007],
  [14.127400574000035, 122.86730821800006],
  [14.127070993000075, 122.86759237900003],
  [14.126877953000076, 122.86750640000002],
  [14.126684442000055, 122.86722775100009],
  [14.126509145000057, 122.86732676000008],
  [14.126374122000072, 122.86747835100005],
  [14.12622159800003, 122.86745505300007],
  [14.125920381000071, 122.86716906800007],
  [14.125790313000039, 122.86708741300004],
  [14.12558105100004, 122.86716326200008],
  [14.125413509000055, 122.86735599200006],
  [14.125459239000065, 122.86760359300001],
  [14.125361696000027, 122.867895384],
  [14.125155256000028, 122.86801841500005],
  [14.124861882000062, 122.86806288000002],
  [14.12469851800006, 122.86798440900009],
  [14.124675686000046, 122.86777159100006],
  [14.124816107000072, 122.86755846200003],
  [14.124879650000025, 122.86714213900007],
  [14.124770392000073, 122.86709707700004],
  [14.124640200000044, 122.86723230200005],
  [14.124314378000065, 122.86735606700006],
  [14.123901701000023, 122.86726613000008],
  [14.123641469000063, 122.86703033200001],
  [14.123367313000074, 122.86641817800012],
  [14.123323774000028, 122.86587962800002],
  [14.12344356500006, 122.86547546000008],
  [14.123606765000034, 122.86519438500011],
  [14.123802717000046, 122.86516075100008],
  [14.124149946000045, 122.8649580880001],
  [14.124268208000046, 122.86472233900008],
  [14.124279649000073, 122.8642072130001],
  [14.124432393000063, 122.86400530800006],
  [14.12463771800003, 122.86392661500008],
  [14.124964660000046, 122.86364486100001],
  [14.125204582000038, 122.86358863200007],
  [14.125488191000045, 122.86362242300004],
  [14.125520894000033, 122.86347621200002],
  [14.125237296000023, 122.86322877300006],
  [14.125073712000074, 122.86322877400005],
  [14.124910128000067, 122.86313881],
  [14.124801071000036, 122.86293639300004],
  [14.124659337000026, 122.86288019900007],
  [14.12421226300006, 122.862160291],
  [14.123906588000068, 122.86205883000002],
  [14.123797314000058, 122.86234031900005],
  [14.12361142900005, 122.86259947000008],
  [14.123458549000077, 122.86259943400012],
  [14.123240038000063, 122.86241917600012],
  [14.123184822000042, 122.86200208000002],
  [14.123303711000062, 122.86159755000006],
  [14.122968851000053, 122.8616130050001],
  [14.122833060000062, 122.86155637700006],
  [14.122761613000023, 122.86134337400006],
  [14.122841527000048, 122.86112972300009],
  [14.12284151600005, 122.86085932500009],
  [14.122482603000037, 122.86066602200003],
  [14.122212152000031, 122.86059499900011],
  [14.122116744000039, 122.860245125],
  [14.12188965100006, 122.85989993600003],
  [14.121698002000073, 122.85970388300007],
  [14.121616176000032, 122.85948896600007],
  [14.121469956000055, 122.85953616800009],
  [14.121332832000064, 122.85967673700009],
  [14.121204896000052, 122.85968650400002],
  [14.121122100000036, 122.8599294920001],
  [14.120858009000074, 122.85986482600003],
  [14.120786275000057, 122.85938002900002],
  [14.120904734000021, 122.85935147400005],
  [14.120923492000031, 122.85912718700001],
  [14.120823673000075, 122.85894090200009],
  [14.120532809000053, 122.85911015600004],
  [14.120334284000023, 122.85895146900009],
  [14.120117639000057, 122.85925872400003],
  [14.120117656000048, 122.85960359400008],
  [14.120054462000041, 122.85994843300011],
  [14.119855732000076, 122.85973390100003],
  [14.119720182000037, 122.85950068800003],
  [14.119620697000073, 122.8592116210001],
  [14.11939458200004, 122.85890404400004],
  [14.119141392000074, 122.85882021500004],
  [14.118779725000024, 122.85883891800006],
  [14.118635072000075, 122.858736442],
  [14.118743582000036, 122.85844758000007],
  [14.118635114000028, 122.85827991400004],
  [14.118418160000033, 122.85829859300009],
  [14.118255404000024, 122.85853153000005],
  [14.118092654000066, 122.85866200100008],
  [14.11774411500005, 122.85870911200004],
  [14.117535805000045, 122.85866323000005],
  [14.11740020600007, 122.85847706100003],
  [14.11730102100006, 122.85809455100002],
  [14.116984451000064, 122.85775125100008],
  [14.11680375800006, 122.85776106300011],
  [14.116496741000049, 122.85811628600004],
  [14.116244153000025, 122.85820101500008],
  [14.115679248000049, 122.85664855000005],
  [14.114270164000061, 122.85426670600009],
  [14.110025354000072, 122.85113620200002],
  [14.102272543000026, 122.84843957200007],
  [14.090193887000055, 122.84265305300005],
  [14.069198399000072, 122.83518928800004],
  [14.065872630000058, 122.83282927000005],
  [14.06274847700007, 122.83122105400003],
  [14.052930011000058, 122.8249274640001],
  [14.046138970000072, 122.82351315900007],
  [14.044432090000043, 122.82200915100009],
  [14.042721204000031, 122.82150914100009],
  [14.043384188000061, 122.8169181500001],
  [14.038758505000033, 122.81428312600008],
  [14.035228751000034, 122.81150910600002],
  [14.032484943000043, 122.80919909300007],
  [14.028001258000074, 122.80536106900001],
  [14.023405579000041, 122.80164004500011],
  [14.020245801000044, 122.79893402900007],
  [14.013752262000025, 122.79251799700012],
  [14.021161902000074, 122.771600067],
  [14.024993706000032, 122.7623691010001],
  [14.03083341000007, 122.74807215400006],
  [14.038559019000047, 122.72894622500007],
  [14.042740808000076, 122.71850226100003],
  [14.046433639000043, 122.70631229800006],
  [14.051106407000077, 122.69412834100001],
  [14.054057260000036, 122.68644336800003],
  [14.055478196000024, 122.68168738300005],
  [14.056025183000031, 122.67783639000004],
  [14.05866504200003, 122.672533412],
  [14.060908934000054, 122.66616243300007],
  [14.062600850000024, 122.66173744800005],
  [14.066666033000047, 122.65076631800002],
  [14.070068482000067, 122.6416175170001],
  [14.072751347000064, 122.63507254100011],
  [14.077083125000058, 122.62490957900002],
  [14.07948499500003, 122.62040159900005],
  [14.080955915000061, 122.61770861100001],
  [14.082416848000037, 122.61303162500008],
  [14.085097564000023, 122.6076238710001],
  [14.089548259000026, 122.59823870900004],
  [14.098454755000034, 122.57883867100009],
  [14.108189217000074, 122.55774726700008],
  [14.116739880000068, 122.538911374],
  [14.120067085000073, 122.53049367000006],
  [14.132230097000047, 122.49940088100004],
  [14.134777580000048, 122.49312744000008],
  [14.141713138000057, 122.47516974300004],
  [14.145637043000022, 122.4641192040001],
  [14.150213563000023, 122.45199363600011],
  [14.184827489000043, 122.45451911500004],
  [14.187142085000062, 122.45712814900003],
  [14.187398236000035, 122.45764506000012],
  [14.187557241000036, 122.4578180630001],
  [14.187768214000073, 122.45794412700002],
  [14.188379673000043, 122.4581753010001],
  [14.206962575000034, 122.4595700750001],
  [14.206814018000046, 122.46684939800002],
  [14.208623439000064, 122.4812175510001],
  [14.206204466000031, 122.50317451300009],
  [14.205929541000046, 122.50853140400011],
  [14.205104626000036, 122.52463061900005],
  [14.205028081000023, 122.52887826400001],
  [14.204047822000064, 122.5435695860001],
  [14.203958898000053, 122.54569360800008],
  [14.205222478000053, 122.55716303300005],
  [14.208562133000044, 122.57588411200004],
  [14.208865803000037, 122.57743821000008],
  [14.21022522900006, 122.57802311000012],
  [14.210977182000022, 122.58915474100002],
  [14.209265986000048, 122.59661536300007],
  [14.205743668000025, 122.60505927500003],
  [14.203297832000032, 122.61076052600004],
  [14.200016143000028, 122.62501934300008],
  [14.200295095000058, 122.64313284800005],
  [14.200532336000038, 122.64509071900011],
  [14.19744849600005, 122.65827921300001],
  [14.194016261000058, 122.66192003800006],
  [14.189896889000067, 122.67392250600005],
  [14.18979074200007, 122.67433834000008],
  [14.188864098000067, 122.67780906000007],
  [14.202263662000064, 122.71099503800008],
  [14.205796082000063, 122.71974359500007],
  [14.20137582600006, 122.74717182100005],
  [14.201790496000058, 122.75420378700005],
  [14.202224495000053, 122.76160759100003],
  [14.201607504000037, 122.7805323450001],
  [14.201756983000053, 122.78097481100008],
  [14.20207416900007, 122.781232842],
  [14.202462875000037, 122.78204945100003],
  [14.202842797000073, 122.78242655400004],
  [14.203139116000045, 122.783519178],
  [14.20380562400004, 122.78443713200011],
  [14.203880512000069, 122.78514422500007],
  [14.203622147000033, 122.78596546200004],
  [14.203308201000027, 122.78661438600011],
  [14.203291696000065, 122.78770076100011],
  [14.203496229000052, 122.78796656300005],
  [14.20427434800007, 122.78836445900004],
  [14.204626270000063, 122.78866752300007],
  [14.204830042000026, 122.78908505000004],
  [14.204978833000041, 122.7895567380001],
  [14.20511044400007, 122.79018962700002],
  [14.205662546000042, 122.79009796500009],
  [14.206196575000035, 122.7906675380001],
  [14.206693417000054, 122.79137839300006],
  [14.207900575000053, 122.79150225900003],
  [14.208529483000063, 122.79191522200006],
  [14.209314107000068, 122.79276123000011],
  [14.209721375000072, 122.79370609300008],
  [14.21003089900006, 122.79429255900004],
  [14.21100834300006, 122.79491160700002],
  [14.211024634000069, 122.79535145800003],
  [14.211236414000041, 122.79584017900004],
  [14.211969497000041, 122.796866496],
  [14.213105776000077, 122.7973012550001],
  [14.214095438000072, 122.79785106700001],
  [14.215414960000032, 122.79834884800005],
  [14.219222438000031, 122.80303992100005],
  [14.220008093000047, 122.8159349230001],
  [14.220328867000035, 122.822709959],
  [14.220830694000028, 122.83321243700004],
  [14.221078055000078, 122.83792551600004],
  [14.220598205000044, 122.84630779200006],
  [14.216895057000045, 122.85860253600003],
  [14.216931061000025, 122.86285370300004],
  [14.221664167000029, 122.86633306500005]



];

const polygonCoordsSTE = [
  [14.206962575000034, 122.4595700750001],
  [14.188379673000043, 122.4581753010001],
  [14.187768214000073, 122.45794412700002],
  [14.187557241000036, 122.4578180630001],
  [14.187398236000035, 122.45764506000012],
  [14.187142085000062, 122.45712814900003],
  [14.184827489000043, 122.45451911500004],
  [14.150213563000023, 122.45199363600011],
  [14.141958726000041, 122.44579914100007],
  [14.14111649800003, 122.44549226600009],
  [14.139554277000059, 122.44549771900006],
  [14.13883571100007, 122.4462437630001],
  [14.138116739000054, 122.44686588200011],
  [14.13649569200004, 122.44724329300004],
  [14.135111727000037, 122.44662851400005],
  [14.133966268000052, 122.44545528100002],
  [14.132697987000029, 122.44347703100004],
  [14.131009325000036, 122.44156225100005],
  [14.128101405000052, 122.43849375400009],
  [14.125974370000051, 122.43667871000002],
  [14.124279698000066, 122.43494321800006],
  [14.122941232000073, 122.434016487],
  [14.121447172000046, 122.43353581000008],
  [14.119837467000025, 122.43370352000011],
  [14.11885521000005, 122.43358551600011],
  [14.118188364000048, 122.43383087000007],
  [14.117475720000073, 122.43387029500002],
  [14.11661648300003, 122.43352574300002],
  [14.116430180000066, 122.43279960200005],
  [14.116092022000032, 122.43248480200009],
  [14.115020168000058, 122.43270981500007],
  [14.113516736000065, 122.43217796400006],
  [14.11326987800004, 122.43167324500007],
  [14.113053557000057, 122.43113681800003],
  [14.112715605000062, 122.43088522200003],
  [14.112652723000053, 122.43041144800009],
  [14.112497794000035, 122.42990640100004],
  [14.11191552200006, 122.42990847500005],
  [14.111302282000054, 122.42981585900009],
  [14.110982998000054, 122.42921870000009],
  [14.110822173000031, 122.42842437600007],
  [14.110859427000037, 122.42775164900002],
  [14.11099647900005, 122.42736391100004],
  [14.110995304000028, 122.42701743000009],
  [14.11069819100004, 122.42683505600007],
  [14.11012462900004, 122.42673519400012],
  [14.109452518000069, 122.4267172110001],
  [14.10870148600003, 122.42674027100009],
  [14.108206861000042, 122.42659937200006],
  [14.107850184000029, 122.42633569700001],
  [14.107829110000068, 122.42594853700007],
  [14.10796347400003, 122.42476598000007],
  [14.108000937000043, 122.42415443100003],
  [14.108097764000036, 122.4235630500001],
  [14.10809583200006, 122.42299240700004],
  [14.107956368000032, 122.422666821],
  [14.10763963900007, 122.42252530000007],
  [14.106809491000035, 122.42252828700009],
  [14.106571546000055, 122.42230496200011],
  [14.106273616000067, 122.42187805100002],
  [14.105818252000063, 122.42165551000005],
  [14.105502904000048, 122.42192158800003],
  [14.105286866000029, 122.42232997000008],
  [14.105110427000056, 122.4227585860001],
  [14.104735367000046, 122.42290259600009],
  [14.104418982000027, 122.42286297500004],
  [14.104318637000063, 122.42241497600003],
  [14.104335985000034, 122.4217016120001],
  [14.104213916000049, 122.42116944700001],
  [14.103787656000065, 122.42066412800011],
  [14.103205772000024, 122.42038976300012],
  [14.10240099300006, 122.42027747400005],
  [14.10170848100006, 122.42032605200006],
  [14.10130534700005, 122.42005104200007],
  [14.100453365000021, 122.41920168500008],
  [14.099960298000042, 122.418765731],
  [14.099400901000024, 122.41853736200005],
  [14.098661083000025, 122.41863092200003],
  [14.098055014000067, 122.41867885200008],
  [14.097566713000049, 122.41857390000007],
  [14.097196182000062, 122.41831607100005],
  [14.096957946000032, 122.41782908400012],
  [14.096971279000059, 122.41740216900007],
  [14.09725065300006, 122.41694379800003],
  [14.097633840000071, 122.41657652300012],
  [14.097928935000027, 122.41639250800006],
  [14.097957779000069, 122.41617896900004],
  [14.09792737400005, 122.41593515500006],
  [14.097615984000072, 122.41567711500011],
  [14.097259871000063, 122.41531252100003],
  [14.096843673000024, 122.41467372900001],
  [14.09635348200004, 122.41400287600004],
  [14.095970601000033, 122.41314732100011],
  [14.09587288800003, 122.41235194],
  [14.095894787000077, 122.41181320900012],
  [14.096190192000051, 122.41139589700003],
  [14.096581298000046, 122.41118635200007],
  [14.09697278300007, 122.41108698500011],
  [14.09707859200006, 122.41078054200011],
  [14.096958850000021, 122.41048716500006],
  [14.096553893000078, 122.41012137500002],
  [14.096126324000068, 122.41008621200001],
  [14.095770796000068, 122.41028338600006],
  [14.095250387000021, 122.41087291300005],
  [14.094646055000055, 122.4109992650001],
  [14.094200114000046, 122.41020396200008],
  [14.093747682000071, 122.40914085100007],
  [14.09374723600007, 122.40874220700005],
  [14.093806234000056, 122.4083436740001],
  [14.093746494000072, 122.40803727000002],
  [14.093538311000032, 122.40773143800004],
  [14.093003367000051, 122.40769917400007],
  [14.092825374000029, 122.40785207400006],
  [14.092617898000071, 122.40821969300009],
  [14.092232318000072, 122.408741168],
  [14.091521224000076, 122.40895202900003],
  [14.090986776000022, 122.40889235400005],
  [14.090570176000028, 122.40846578100002],
  [14.087283764000063, 122.40054705900002],
  [14.084713977000035, 122.39616586800003],
  [14.07864634300006, 122.39190568300012],
  [14.066641330000039, 122.38376856000002],
  [14.062866015000054, 122.36204661600004],
  [14.06343742000007, 122.36034661100007],
  [14.064244632000054, 122.34078479700008],
  [14.063428475000023, 122.33877974500001],
  [14.063300109000068, 122.3385332250001],
  [14.06334098800005, 122.33818080900005],
  [14.063179576000039, 122.33790013700002],
  [14.063187066000069, 122.33779023800003],
  [14.063327182000023, 122.33745690600006],
  [14.063415561000056, 122.3373395320001],
  [14.063695196000026, 122.33715419200007],
  [14.063764351000032, 122.3368564000001],
  [14.063729172000023, 122.3366677350001],
  [14.063378287000035, 122.33643302000007],
  [14.06324849200007, 122.33584381700007],
  [14.06326064500007, 122.33556389700004],
  [14.063700611000058, 122.33527596700003],
  [14.06387124500003, 122.33498038800008],
  [14.063852678000046, 122.33469431200001],
  [14.063758216000053, 122.3345869200001],
  [14.063499691000061, 122.33456057700005],
  [14.063427373000025, 122.33439470300004],
  [14.063723903000039, 122.33428457100001],
  [14.063566562000062, 122.3340275700001],
  [14.063204122000059, 122.33388732200001],
  [14.06301970100003, 122.33377110300012],
  [14.062759432000064, 122.33350211000004],
  [14.062756665000052, 122.3333433570001],
  [14.06311508500005, 122.33326444800002],
  [14.063420044000054, 122.33331369200005],
  [14.06360680000006, 122.33315823900011],
  [14.063509340000053, 122.33294135800008],
  [14.063195698000072, 122.33272725000006],
  [14.063169229000039, 122.33257762800008],
  [14.06340635600003, 122.33239781700001],
  [14.063365210000029, 122.33214132500007],
  [14.063137600000061, 122.33180216500011],
  [14.062924674000044, 122.33156376700003],
  [14.062490860000025, 122.33127566900009],
  [14.062399483000036, 122.33095330700007],
  [14.062419944000055, 122.3306340470001],
  [14.062296667000055, 122.33060432500008],
  [14.062006507000035, 122.33102966500007],
  [14.061871647000032, 122.33109157400008],
  [14.061541885000054, 122.33106749700005],
  [14.061315501000024, 122.3309282570001],
  [14.06123261700003, 122.33069755500003],
  [14.061273310000047, 122.330248245],
  [14.06144852600005, 122.33001492000005],
  [14.061790064000036, 122.32978772000001],
  [14.061815959000057, 122.32971680100002],
  [14.061590344000024, 122.3290105320001],
  [14.061334094000074, 122.32860278600003],
  [14.061340104000067, 122.32840354700011],
  [14.061600514000077, 122.32775260800008],
  [14.061644110000032, 122.32748204300003],
  [14.061921329000029, 122.32724449000011],
  [14.062259049000033, 122.3270724350001],
  [14.062454521000063, 122.32680726400008],
  [14.062448607000022, 122.32646841300004],
  [14.062489795000033, 122.32638375600004],
  [14.062704842000073, 122.32626999400009],
  [14.063226330000077, 122.32625762400005],
  [14.063526638000042, 122.32620261800002],
  [14.063694273000067, 122.32609205500012],
  [14.063634575000037, 122.3257987500001],
  [14.063236876000076, 122.32536492000008],
  [14.063047608000034, 122.32506493500011],
  [14.063028828000029, 122.32491178100008],
  [14.063277716000073, 122.32442189100004],
  [14.06335148200003, 122.32419719900008],
  [14.063356764000048, 122.32387392100009],
  [14.063185581000027, 122.3236085100001],
  [14.06262465900005, 122.32350930400003],
  [14.062522030000025, 122.3232818670001],
  [14.06272707000005, 122.32290001600006],
  [14.062762676000034, 122.32274820600003],
  [14.062602534000064, 122.32256731700011],
  [14.062335823000069, 122.32263041500005],
  [14.062117693000062, 122.32260707500006],
  [14.061882899000068, 122.32248376000007],
  [14.061776765000047, 122.32235868400005],
  [14.061821762000022, 122.32214922100002],
  [14.062153789000035, 122.32204007200005],
  [14.062342242000057, 122.32193074400004],
  [14.06261991100007, 122.3216271660001],
  [14.062918926000066, 122.32151570300005],
  [14.063153919000058, 122.32124896100004],
  [14.063103143000035, 122.32112565000011],
  [14.062720218000038, 122.32104985400008],
  [14.06266512600007, 122.32083068000009],
  [14.063008264000075, 122.3206141280001],
  [14.063159068000061, 122.32045903900007],
  [14.063329577000047, 122.32018096400009],
  [14.063447284000063, 122.31989072400006],
  [14.063548084000047, 122.31943010900011],
  [14.063530180000043, 122.31915770500007],
  [14.063427230000059, 122.31889459000001],
  [14.06316632200003, 122.31866258800005],
  [14.063136287000077, 122.31837451800004],
  [14.063512899000045, 122.31834993700011],
  [14.063792204000038, 122.31843168300009],
  [14.064053196000032, 122.31859167700009],
  [14.064244293000058, 122.31879228500009],
  [14.064392809000026, 122.31904606700004],
  [14.064499370000021, 122.31912488300009],
  [14.064835817000073, 122.3192409610001],
  [14.06508911900005, 122.31927485500012],
  [14.065338929000063, 122.31920054400007],
  [14.065657635000036, 122.31901438700004],
  [14.06565971300006, 122.31869813100002],
  [14.065612273000056, 122.3186350960001],
  [14.065273071000036, 122.31848773200011],
  [14.065154397000072, 122.31839662000004],
  [14.065008727000077, 122.3181725290001],
  [14.064907249000044, 122.31788551800003],
  [14.064758839000035, 122.31709815400006],
  [14.064603646000023, 122.31667558000004],
  [14.064308131000075, 122.31625681700007],
  [14.064222993000044, 122.31599246300004],
  [14.064299470000037, 122.31582897200008],
  [14.064571146000048, 122.31575065900006],
  [14.065407291000042, 122.31572966400006],
  [14.065465491000054, 122.31553783700008],
  [14.065319270000032, 122.31531744900008],
  [14.065008227000021, 122.31513146400005],
  [14.064101329000039, 122.31489202400007],
  [14.063920518000032, 122.31480601300007],
  [14.063804890000029, 122.31467159200008],
  [14.063740041000074, 122.31442906400002],
  [14.063812563000056, 122.31429114200012],
  [14.064265698000042, 122.31380456200009],
  [14.06458986900003, 122.3135834740001],
  [14.064854380000043, 122.31350542400003],
  [14.065039476000038, 122.31350564900004],
  [14.065684157000021, 122.31361528700006],
  [14.06597860200003, 122.3137701280001],
  [14.066387605000045, 122.31404140000006],
  [14.066553534000036, 122.31394126500004],
  [14.06662603500007, 122.31358460400008],
  [14.066655318000073, 122.3131944480001],
  [14.066540737000025, 122.31252169800007],
  [14.066616857000042, 122.31215018300009],
  [14.066829814000073, 122.31190146300003],
  [14.06729867200005, 122.31167906300004],
  [14.067410539000036, 122.31157143100006],
  [14.067613425000047, 122.31125801900009],
  [14.067683894000027, 122.31096886000012],
  [14.067598291000024, 122.31085627200002],
  [14.067251697000074, 122.31066302500005],
  [14.06651934000007, 122.31045726500008],
  [14.066285676000064, 122.31033245200001],
  [14.066145720000065, 122.31004304400005],
  [14.066219931000035, 122.30989047900005],
  [14.066512436000039, 122.30966988300008],
  [14.06678534300005, 122.309549693],
  [14.068151800000066, 122.30928133700002],
  [14.068395420000058, 122.30919988700009],
  [14.068605125000033, 122.30906001300002],
  [14.068809343000055, 122.30876248900006],
  [14.068826523000041, 122.3085931920001],
  [14.068600418000074, 122.30820757700008],
  [14.068221335000032, 122.30788016500003],
  [14.068051680000053, 122.30765809800005],
  [14.067989630000056, 122.30743615800009],
  [14.068046479000031, 122.30724355200005],
  [14.06817126900006, 122.30706854300001],
  [14.068669836000026, 122.30690565300006],
  [14.069103324000025, 122.306867269],
  [14.069278042000064, 122.30675497900006],
  [14.069350973000041, 122.30659007000008],
  [14.069322033000049, 122.30644754000002],
  [14.06915495000004, 122.30623735000006],
  [14.068907787000057, 122.30607956400002],
  [14.068456984000022, 122.30587654200008],
  [14.068108008000024, 122.30568864000008],
  [14.067788290000067, 122.30535828200004],
  [14.067548559000045, 122.3050580150001],
  [14.067374391000044, 122.3046753320001],
  [14.067287493000038, 122.30431525100005],
  [14.067287775000068, 122.30406026600008],
  [14.067382637000037, 122.30379789200003],
  [14.067593810000062, 122.3036106510001],
  [14.067790326000022, 122.3035208870001],
  [14.06837229000007, 122.30352906900009],
  [14.068765170000063, 122.3034845310001],
  [14.069107185000064, 122.30338743700008],
  [14.069864075000055, 122.30308833800007],
  [14.07030807500007, 122.30286386700004],
  [14.070690882000065, 122.30270084800009],
  [14.071055361000049, 122.30242594800006],
  [14.071213446000058, 122.30217583700005],
  [14.071262306000051, 122.30190057200002],
  [14.071129108000036, 122.30160007000006],
  [14.070789655000056, 122.30119921300002],
  [14.07048665800005, 122.30076085700011],
  [14.070341468000038, 122.3003226840001],
  [14.070317696000075, 122.29985962300009],
  [14.070548733000066, 122.29949697300003],
  [14.07077963300003, 122.2992594640001],
  [14.071180533000074, 122.29897209500007],
  [14.071617833000062, 122.29869728200003],
  [14.072115583000027, 122.29864779700006],
  [14.072661698000047, 122.29877357100008],
  [14.074239247000037, 122.29923842300002],
  [14.075248276000025, 122.29954976300007],
  [14.076609447000067, 122.29999253100004],
  [14.078644761000078, 122.30060988600007],
  [14.080144591000021, 122.30118726100011],
  [14.080744096000046, 122.30170124800009],
  [14.081550814000025, 122.30218510300006],
  [14.081819792000033, 122.30227789000003],
  [14.082168542000034, 122.30232967200004],
  [14.082447608000052, 122.30230944900006],
  [14.083225394000067, 122.30189936800002],
  [14.083713878000026, 122.30175608900004],
  [14.084292, 122.30166428700011],
  [14.084911648000059, 122.30164379000007],
  [14.085722588000067, 122.30167689400002],
  [14.086580257000037, 122.30175828900008],
  [14.087203955000064, 122.30187157000012],
  [14.088747471000033, 122.3022753460001],
  [14.089183793000075, 122.30259743900001],
  [14.089557698000021, 122.30295161900005],
  [14.089869044000068, 122.30346652100002],
  [14.090553624000052, 122.30494663000002],
  [14.090962340000033, 122.30548107900006],
  [14.091265709000027, 122.30604860100004],
  [14.091728404000037, 122.3065342220001],
  [14.092883793000055, 122.3075546120001],
  [14.09302088100003, 122.3071880330001],
  [14.095307239000022, 122.30699250000009],
  [14.100043253000024, 122.3080357230001],
  [14.10203033700003, 122.30786651100004],
  [14.102011034000043, 122.31021425600011],
  [14.102558900000076, 122.31021414300005],
  [14.103045916000042, 122.31033957500006],
  [14.103965307000067, 122.31079136300002],
  [14.104222199000048, 122.31101841200007],
  [14.104368992000047, 122.31126437900002],
  [14.104589177000037, 122.31179415100007],
  [14.104827718000024, 122.3120968830001],
  [14.105084605000059, 122.31255098000008],
  [14.105194695000023, 122.31291047200011],
  [14.10528643400005, 122.31332672600001],
  [14.10541487300003, 122.31372406300011],
  [14.105580016000033, 122.31398896000007],
  [14.105690107000044, 122.31432953700005],
  [14.105787176000035, 122.31495198900006],
  [14.105726333000064, 122.3151403060001],
  [14.105513303000066, 122.31532865100007],
  [14.105482915000039, 122.31561111400003],
  [14.105665565000038, 122.31576800100004],
  [14.105909068000074, 122.31579934000001],
  [14.105969963000064, 122.31592486500006],
  [14.105970067000044, 122.31655254500004],
  [14.10590925400004, 122.31692916200006],
  [14.105726712000035, 122.31743133400005],
  [14.10572675700007, 122.31771378700012],
  [14.10581810900004, 122.31796484000006],
  [14.106183384000076, 122.31815307900001],
  [14.106305172000077, 122.31840412600002],
  [14.106670418000022, 122.31840406300012],
  [14.10676176800007, 122.31865511700005],
  [14.106609655000057, 122.31912589800004],
  [14.106609694000042, 122.31937696500006],
  [14.106457542000044, 122.31959667600006],
  [14.10609230700004, 122.31965950200004],
  [14.105970573000036, 122.31975367300004],
  [14.105879300000026, 122.32000475600012],
  [14.105848901000059, 122.3202558260001],
  [14.105757628000049, 122.3205069070001],
  [14.105605475000061, 122.32072661500001],
  [14.10560554500006, 122.32119736400011],
  [14.105818628000065, 122.32135424800003],
  [14.105849083000066, 122.32147977500006],
  [14.105666493000058, 122.32169948700005],
  [14.105423002000066, 122.32173090700007],
  [14.105057785000042, 122.32191926300004],
  [14.10484475800007, 122.32213897800011],
  [14.10469260900004, 122.32239006600003],
  [14.104692649000071, 122.32267251400003],
  [14.104814415000021, 122.32279802900007],
  [14.105270992000044, 122.32295487700003],
  [14.105636270000048, 122.32320589000005],
  [14.105758046000062, 122.32339417100002],
  [14.105758189000028, 122.32442982400005],
  [14.105910459000029, 122.32505747400012],
  [14.105868573000066, 122.32545978900009],
  [14.10586861400003, 122.32577362900008],
  [14.105746914000065, 122.32615024900008],
  [14.105746934000024, 122.32630716800008],
  [14.106020924000063, 122.3267465030001],
  [14.106020960000023, 122.32702895500006],
  [14.105777517000035, 122.32746835600005],
  [14.105777556000078, 122.3277821910001],
  [14.105838445000074, 122.32790771600003],
  [14.10608196000004, 122.32806460300003],
  [14.106203735000065, 122.32828427200002],
  [14.10620381900003, 122.32900609100011],
  [14.106142978000037, 122.32928854800002],
  [14.106143014000054, 122.32960238200008],
  [14.106234349000033, 122.32982205400003],
  [14.106508310000038, 122.33007308900005],
  [14.106599649000032, 122.33032414400009],
  [14.106599714000026, 122.33092042900012],
  [14.106660614000077, 122.3311714890001],
  [14.107113557000048, 122.33155932900002],
  [14.107302225000069, 122.33161916300003],
  [14.10737479900007, 122.33172390000004],
  [14.10776665700007, 122.33194831300011],
  [14.107911799000021, 122.33212786100012],
  [14.108477811000057, 122.33242707300008],
  [14.108593924000047, 122.33257669700004],
  [14.108898701000044, 122.3327562290001],
  [14.109087380000062, 122.33295073700003],
  [14.109609836000061, 122.3330853550001],
  [14.10997160100004, 122.33331860100009],
  [14.11019669500007, 122.33352854700001],
  [14.110405122000031, 122.33360783400008],
  [14.110587338000073, 122.33378463200006],
  [14.110823160000052, 122.33416034400011],
  [14.110930364000069, 122.33445871000004],
  [14.11109114900006, 122.33469076600011],
  [14.111198347000027, 122.33493387900012],
  [14.111434162000023, 122.33523223700001],
  [14.111669963000054, 122.33537587900003],
  [14.112098674000038, 122.3354310960001],
  [14.112377348000052, 122.33558578800012],
  [14.112645299000064, 122.33567417400002],
  [14.112795367000047, 122.33587308300002],
  [14.113106208000033, 122.33616039100002],
  [14.113256293000063, 122.33651402200007],
  [14.113320671000054, 122.336780814],
  [14.113256383000078, 122.33699079200005],
  [14.113267117000078, 122.33718971300004],
  [14.113460041000053, 122.337278104],
  [14.11366369600006, 122.33754331500006],
  [14.114028105000045, 122.33767589900003],
  [14.114124576000052, 122.33783060700011],
  [14.114446101000055, 122.33777532400006],
  [14.114623952000045, 122.33788091000008],
  [14.114666836000026, 122.33804667600009],
  [14.114634704000025, 122.33831190700005],
  [14.114881212000057, 122.33835609200003],
  [14.114956248000055, 122.33853290600007],
  [14.114741904000027, 122.3386213330001],
  [14.114731193000068, 122.33873184700008],
  [14.114859817000024, 122.33888655300007],
  [14.115267093000057, 122.33899703400004],
  [14.115620773000046, 122.33899700600011],
  [14.115995904000044, 122.33920695000006],
  [14.116092379000065, 122.3394390200001],
  [14.11609240200005, 122.33975950700005],
  [14.116049546000056, 122.33996948600009],
  [14.116124597000066, 122.34036732800007],
  [14.116285369000025, 122.34047782900006],
  [14.116403260000027, 122.34043361500005],
  [14.116542572000071, 122.34020152700009],
  [14.11662831500007, 122.34022362300004],
  [14.11678910300003, 122.34055515300008],
  [14.116789142000073, 122.34111877400005],
  [14.116735599000037, 122.34178186500003],
  [14.116767763000041, 122.34193658400011],
  [14.116939256000023, 122.34209129600004],
  [14.116896397000062, 122.34225707100006],
  [14.116714197000022, 122.34227918600004],
  [14.116456967000033, 122.34219078800004],
  [14.116199742000049, 122.34217975100012],
  [14.11604969900003, 122.34223501800011],
  [14.115996118000055, 122.34235658800003],
  [14.115774801000043, 122.3423891220001],
  [14.115431827000066, 122.34220127200001],
  [14.115217475000065, 122.34220128600009],
  [14.115196050000065, 122.34236705600006],
  [14.115271089000032, 122.34263228100008],
  [14.115121050000027, 122.34277595800006],
  [14.114853109000023, 122.34273176900001],
  [14.113942871000063, 122.34225859900005],
  [14.113443625000059, 122.34214943800009],
  [14.112883862000047, 122.34196228200005],
  [14.112672062000058, 122.34193109800003],
  [14.112293836000049, 122.34171273100003],
  [14.11143151400006, 122.34169718800001],
  [14.111355943000035, 122.34280473900003],
  [14.112021600000048, 122.34291389400005],
  [14.112475461000031, 122.34306985800004],
  [14.112823427000023, 122.34330382600001],
  [14.113050368000074, 122.34356900000012],
  [14.11311089000003, 122.34372498800008],
  [14.113112976000025, 122.34403578500007],
  [14.112901198000031, 122.34439458300005],
  [14.112976856000046, 122.34467536800003],
  [14.112916347000066, 122.34476896800004],
  [14.112674296000023, 122.34487817400009],
  [14.112613791000058, 122.34503417000008],
  [14.112659182000073, 122.34515896300002],
  [14.112931508000031, 122.34537734000003],
  [14.113037434000034, 122.34590771100011],
  [14.113234128000045, 122.34642247800002],
  [14.113370291000024, 122.34656286600011],
  [14.113551835000067, 122.34662525600004],
  [14.114141843000027, 122.3466252290001],
  [14.114625959000023, 122.34679679900012],
  [14.114656227000069, 122.34706198600009],
  [14.114973927000051, 122.34717116800005],
  [14.115306752000038, 122.34717115300009],
  [14.115775729000063, 122.34710873600011],
  [14.11596841200003, 122.3471864920001],
  [14.116014993000022, 122.34731135900006],
  [14.115875269000071, 122.34741702200006],
  [14.116089525000064, 122.34755148700003],
  [14.116844057000037, 122.34756106200007],
  [14.117346932000032, 122.34774684400008],
  [14.117792733000044, 122.34773472900008],
  [14.118003908000048, 122.34791617500002],
  [14.11828547400006, 122.3480976190001],
  [14.118461442000068, 122.34795244800011],
  [14.118543565000039, 122.34800083300001],
  [14.11861396300003, 122.34821857600002],
  [14.118719552000073, 122.34836373700011],
  [14.118798766000054, 122.34870277200002],
  [14.118798771000058, 122.34886153000002],
  [14.11892842900005, 122.34903699400002],
  [14.119171529000027, 122.34912889700001],
  [14.119195843000057, 122.3492625880001],
  [14.119333600000061, 122.34934613900009],
  [14.119495662000077, 122.3492625770001],
  [14.119544276000056, 122.34908710600007],
  [14.11964961800004, 122.34907039100005],
  [14.119803586000046, 122.3492959890001],
  [14.120223445000022, 122.34927803300002],
  [14.120739618000073, 122.34929636800007],
  [14.120846413000038, 122.34933307200004],
  [14.121496079000053, 122.34933305000004],
  [14.121754168000052, 122.34941563300004],
  [14.121825336000029, 122.34955809900009],
  [14.12186871700004, 122.3499829220001],
  [14.12178921900005, 122.35026614100002],
  [14.121796456000027, 122.35055680900007],
  [14.121847054000057, 122.3506760570001],
  [14.122049437000044, 122.35086982900009],
  [14.122107263000032, 122.35103379400005],
  [14.122107270000072, 122.3513244620001],
  [14.122302428000069, 122.35169710800005],
  [14.122461439000062, 122.35168965100002],
  [14.122548169000027, 122.35151823000001],
  [14.122338554000066, 122.35112322400005],
  [14.122345774000053, 122.35080274400002],
  [14.12241804200005, 122.35042263700007],
  [14.122620514000062, 122.34997705800004],
  [14.122945254000058, 122.34985822400006],
  [14.122987152000064, 122.34971779700004],
  [14.122987142000056, 122.34938293300002],
  [14.123070940000048, 122.34921009700008],
  [14.123238548000074, 122.34914527900003],
  [14.12360519300006, 122.34914526700004],
  [14.124013742000045, 122.34922086800009],
  [14.124296581000067, 122.34922085800008],
  [14.124453712000047, 122.34915604000003],
  [14.12460036300007, 122.34895079400007],
  [14.12478892300004, 122.34895078800002],
  [14.124967011000024, 122.34903720000011],
  [14.124977492000028, 122.3492208350001],
  [14.124568951000072, 122.349393684],
  [14.124631815000043, 122.34973934900006],
  [14.124914663000027, 122.35003099700009],
  [14.125134650000064, 122.35003099100004],
  [14.12558967800004, 122.34992329700003],
  [14.12624938700003, 122.34997843500003],
  [14.126569575000076, 122.35008544400011],
  [14.12680686300007, 122.35000073800006],
  [14.12697114100007, 122.35001014300008],
  [14.127153676000034, 122.35017953800002],
  [14.127358838000077, 122.35021068500009],
  [14.127557467000031, 122.34993756900008],
  [14.127665817000036, 122.34998722300008],
  [14.12776815500007, 122.35034722800003],
  [14.127756125000076, 122.35068861700006],
  [14.127822342000059, 122.35084999800006],
  [14.128039041000022, 122.35099896300005],
  [14.128008111000042, 122.35130573000004],
  [14.127845595000053, 122.35159125900009],
  [14.127905792000035, 122.35175264100008],
  [14.128104435000068, 122.35201954100012],
  [14.128405400000076, 122.35198849800008],
  [14.12865219400004, 122.35206918600011],
  [14.129068808000056, 122.35199883200005],
  [14.130074401000059, 122.35202517200003],
  [14.130650477000074, 122.35206910000011],
  [14.131553802000042, 122.35206908000009],
  [14.131903204000025, 122.3521569510001],
  [14.13231225900006, 122.35215694200008],
  [14.132525310000062, 122.35222724100004],
  [14.132915067000056, 122.35254960800012],
  [14.133025858000053, 122.35283960700008],
  [14.133221864000063, 122.3529714230001],
  [14.133920662000037, 122.35297141100011],
  [14.13449163100006, 122.35290988500003],
  [14.134900682000023, 122.35277805700002],
  [14.135292689000039, 122.3525583490001],
  [14.135485152000058, 122.35255284900006],
  [14.135645429000022, 122.35261641600005],
  [14.135990647000028, 122.35285797600011],
  [14.136150925000038, 122.3529215420001],
  [14.136409834000062, 122.35292153800003],
  [14.136434491000045, 122.3528198250001],
  [14.136274208000032, 122.3525528350001],
  [14.136298861000057, 122.35223498300002],
  [14.136705715000062, 122.3519806920001],
  [14.136850120000076, 122.35182543100007],
  [14.136837782000043, 122.35139315300012],
  [14.136936412000068, 122.35131686600005],
  [14.137466560000064, 122.35134228200002],
  [14.137824097000077, 122.35115156000006],
  [14.138119988000028, 122.35090998300007],
  [14.138428212000065, 122.35078283200005],
  [14.13866246400005, 122.3507319680001],
  [14.13903233700006, 122.35073195700011],
  [14.139513173000068, 122.35065565500008],
  [14.140252929000042, 122.35065562900002],
  [14.140709117000029, 122.35077004300001],
  [14.141131317000031, 122.35094650700012],
  [14.141360348000035, 122.3512223890001],
  [14.141883389000043, 122.35095920200001],
  [14.141809408000029, 122.35071763100007],
  [14.141673783000044, 122.3505269200001],
  [14.14125458500007, 122.35013278800011],
  [14.14099566300007, 122.34971322500007],
  [14.140810711000029, 122.34914108900011],
  [14.14081069100007, 122.34854351800004],
  [14.140958621000038, 122.34798408000006],
  [14.14115587200007, 122.34760263900012],
  [14.141357969000069, 122.34748997600002],
  [14.141568991000042, 122.34727651300011],
  [14.141884839000056, 122.34720171800006],
  [14.142133795000063, 122.34705827300002],
  [14.142320532000042, 122.34669036100001],
  [14.142792506000035, 122.34608153400006],
  [14.143335680000064, 122.3457685620001],
  [14.143575976000022, 122.34569135600009],
  [14.143781318000038, 122.34574148200011],
  [14.143955370000072, 122.34587088800004],
  [14.14412511200004, 122.34577308400003],
  [14.144473970000035, 122.345414634],
  [14.144672680000042, 122.34516547500004],
  [14.144896102000075, 122.344961925],
  [14.14516147200004, 122.34480901600011],
  [14.145605153000076, 122.34446725700002],
  [14.146215245000064, 122.3439062430001],
  [14.146399315000053, 122.34371040400003],
  [14.146813300000076, 122.34345689700001],
  [14.147218510000073, 122.34313363100011],
  [14.147704385000054, 122.34258432900003],
  [14.148053839000056, 122.34225255700005],
  [14.148598570000047, 122.34162445000004],
  [14.149012236000033, 122.34123645300008],
  [14.150140050000061, 122.34058655800004],
  [14.15048555900006, 122.34035986100002],
  [14.150894507000032, 122.34021353700007],
  [14.151237947000027, 122.340156997],
  [14.151569982000069, 122.34004790100005],
  [14.15178837600007, 122.33992283000009],
  [14.152154222000036, 122.33946181100009],
  [14.152695451000056, 122.33908367300012],
  [14.153016714000046, 122.33889589900002],
  [14.153400661000035, 122.33873585900005],
  [14.153681426000048, 122.33865157600007],
  [14.154031209000038, 122.33849545800001],
  [14.15443889900007, 122.33840136100002],
  [14.154627474000051, 122.33793580100007],
  [14.154777435000028, 122.33749538400002],
  [14.155013515000064, 122.33716170000002],
  [14.155219873000021, 122.33701018400006],
  [14.155423720000044, 122.33696313300004],
  [14.156453029000033, 122.33697640200012],
  [14.156704694000041, 122.33704817400007],
  [14.157071571000074, 122.33707062500002],
  [14.157548636000058, 122.33725269400009],
  [14.15779873100007, 122.3373897570001],
  [14.158189048000054, 122.33749120900006],
  [14.158326444000068, 122.33757312300008],
  [14.158865878000029, 122.33779599000002],
  [14.159308731000067, 122.33782038800007],
  [14.15979909400005, 122.33797666900011],
  [14.160242266000068, 122.33798800900001],
  [14.160717952000027, 122.3379174480001],
  [14.161946779000061, 122.33792275600001],
  [14.162838143000045, 122.33773649300008],
  [14.163559882000072, 122.33775495700002],
  [14.163881160000074, 122.3376194540001],
  [14.164416413000026, 122.33748941300007],
  [14.164812071000028, 122.33736886600002],
  [14.165015290000042, 122.33734793000008],
  [14.165299292000043, 122.3372362990001],
  [14.165303060000042, 122.337079635],
  [14.165107747000036, 122.33677417700005],
  [14.16488617500005, 122.33650724000006],
  [14.164905741000041, 122.33622034500002],
  [14.16500889300005, 122.3361446030001],
  [14.165250659000037, 122.33609853400003],
  [14.16553605300004, 122.33581843800005],
  [14.165812656000071, 122.33590389400001],
  [14.165918733000069, 122.33623319500009],
  [14.166086119000056, 122.33611990500003],
  [14.16612462300003, 122.33557222200011],
  [14.16621480200007, 122.33550921100004],
  [14.166625389000046, 122.33543203500005],
  [14.166891217000057, 122.3354388350001],
  [14.167063727000027, 122.33563920100005],
  [14.167092912000044, 122.33600572700004],
  [14.167355286000031, 122.33615613800009],
  [14.167531563000068, 122.33619983800008],
  [14.167685034000044, 122.33613844600006],
  [14.167651141000022, 122.3359677520001],
  [14.167378305000057, 122.33572562900008],
  [14.167256744000042, 122.33551350200003],
  [14.167263336000076, 122.33523933700008],
  [14.167353829000035, 122.33516326900008],
  [14.167556365000053, 122.3351684490001],
  [14.16779499200004, 122.3352529340001],
  [14.16801939100003, 122.33540237300008],
  [14.168179870000074, 122.33557630500002],
  [14.168493192000028, 122.33571495700005],
  [14.168746675000023, 122.33570837600007],
  [14.168981538000025, 122.33554420900009],
  [14.169145625000056, 122.335569641],
  [14.169365822000032, 122.33583007200002],
  [14.169507293000038, 122.33593985700008],
  [14.170066381000026, 122.33580552500007],
  [14.170216018000076, 122.33557578300008],
  [14.17028437700003, 122.33530149600006],
  [14.170537908000028, 122.3350319430001],
  [14.170866084000068, 122.3350828020001],
  [14.170944810000037, 122.33523345100002],
  [14.170997858000021, 122.33559577800008],
  [14.171392378000064, 122.33571925500007],
  [14.171594553000034, 122.33587306000004],
  [14.171631109000032, 122.33606509700007],
  [14.171815261000063, 122.33611227400002],
  [14.171981900000048, 122.33603160300004],
  [14.17211147200004, 122.33578011500003],
  [14.172486918000061, 122.33557738500008],
  [14.17275642900006, 122.33549934300004],
  [14.173105687000032, 122.33552951100012],
  [14.173482152000076, 122.33528433700008],
  [14.173645218000047, 122.33535221000011],
  [14.173846371000025, 122.33554845700007],
  [14.174276906000046, 122.33562317100007],
  [14.174373655000068, 122.3358804500001],
  [14.17449404100006, 122.33601093200002],
  [14.17468023300006, 122.33597322700007],
  [14.17483395000005, 122.33557371900008],
  [14.174999567000043, 122.33553548800012],
  [14.175347295000051, 122.33562931900008],
  [14.175923384000043, 122.33564405400011],
  [14.176106515000072, 122.33573367500003],
  [14.17625724800007, 122.33600049000006],
  [14.176250096000047, 122.33624732400006],
  [14.176441688000068, 122.33639118000008],
  [14.176708055000063, 122.33647213000006],
  [14.177285070000039, 122.33653256000002],
  [14.177663340000038, 122.33669596800007],
  [14.177972002000047, 122.33686976900003],
  [14.178143173000024, 122.33704685700002],
  [14.17885595000007, 122.33712150600002],
  [14.179187680000041, 122.3369695350001],
  [14.17944413400005, 122.33690941000009],
  [14.179744026000037, 122.33692946600002],
  [14.180034008000064, 122.33702537800002],
  [14.18028474600004, 122.33698608400005],
  [14.181430590000048, 122.33690437400003],
  [14.18160778400005, 122.33685485800004],
  [14.181881764000025, 122.33688984800006],
  [14.182220161000032, 122.33708477700009],
  [14.182345590000068, 122.33720402300003],
  [14.182540798000048, 122.33751495600006],
  [14.182569210000054, 122.33765886800006],
  [14.182716050000067, 122.33786824700007],
  [14.182834476000039, 122.33793371200011],
  [14.183164582000074, 122.33770012600007],
  [14.183493159000022, 122.33801298800006],
  [14.183645270000056, 122.33819867400007],
  [14.184088687000042, 122.3382164090001],
  [14.184325370000067, 122.33818517700001],
  [14.184454178000067, 122.33804109100004],
  [14.184416752000061, 122.33772044700004],
  [14.184577378000029, 122.33759637000003],
  [14.184776967000062, 122.3375688210001],
  [14.184813894000058, 122.33740297200006],
  [14.184997490000057, 122.33728433700003],
  [14.185273135000045, 122.33734962900007],
  [14.18532775600005, 122.33721291300003],
  [14.185327422000057, 122.336888584],
  [14.185433260000025, 122.33673905600006],
  [14.185800452000024, 122.33650178200003],
  [14.185925687000065, 122.33631943500006],
  [14.18610043600006, 122.33618623000007],
  [14.186255619000065, 122.33592367800009],
  [14.186616042000026, 122.33567751300006],
  [14.186872874000073, 122.33577499600005],
  [14.187108757000033, 122.33555136100006],
  [14.187705873000027, 122.33568069300009],
  [14.187844574000053, 122.33581118900008],
  [14.188013381000076, 122.33587172300008],
  [14.18810902000007, 122.33607964900011],
  [14.188169213000037, 122.33653597900002],
  [14.18838031000007, 122.33680011500007],
  [14.188454604000071, 122.33712556000012],
  [14.188854270000036, 122.33690778500011],
  [14.189211079000074, 122.33688006200009],
  [14.189518207000049, 122.33664102800003],
  [14.189648718000058, 122.33643498600009],
  [14.18990072300005, 122.33584616300004],
  [14.190174331000037, 122.33564907100003],
  [14.190577082000061, 122.335632228],
  [14.190901220000057, 122.33557149900003],
  [14.191283465000026, 122.33544418100007],
  [14.191579382000043, 122.33529145000011],
  [14.191684531000021, 122.3351563980001],
  [14.191762032000042, 122.3347694260001],
  [14.191968990000078, 122.33471598800008],
  [14.192292437000049, 122.33488934700006],
  [14.192551131000073, 122.33480920600005],
  [14.192641639000044, 122.33466240400003],
  [14.192848559000026, 122.33447553300005],
  [14.193068450000055, 122.33440874700011],
  [14.193107152000039, 122.3340484680001],
  [14.19332696600003, 122.33371481600011],
  [14.193533926000043, 122.33366137500002],
  [14.193637371000023, 122.33351456600008],
  [14.193831389000025, 122.333434441],
  [14.194249419000073, 122.33339645100011],
  [14.194495220000022, 122.33346308800003],
  [14.194766860000072, 122.33342297200011],
  [14.194986701000062, 122.33319606700002],
  [14.195193629000073, 122.33304922500008],
  [14.195685150000031, 122.33291563400007],
  [14.195853315000022, 122.33291557900009],
  [14.196060252000052, 122.3327954240001],
  [14.196306032000052, 122.33279534400003],
  [14.19646121900007, 122.33264851700005],
  [14.196512857000073, 122.33230157100002],
  [14.196732763000057, 122.33228815600012],
  [14.196939761000067, 122.33236814800011],
  [14.19713175000004, 122.33267655200007],
  [14.197131864000028, 122.33305243600012],
  [14.197249469000042, 122.33323427800008],
  [14.197555124000075, 122.33331905800003],
  [14.197402357000044, 122.33347673600008],
  [14.197037934000036, 122.33341622600005],
  [14.196861613000067, 122.33342840700004],
  [14.196732360000055, 122.33359820300007],
  [14.196814717000052, 122.33384068300006],
  [14.197237959000063, 122.33404668100002],
  [14.197402518000047, 122.33401025300009],
  [14.198283974000049, 122.33344008100005],
  [14.198695402000055, 122.33343995100006],
  [14.198942286000033, 122.33352475200002],
  [14.199142124000048, 122.33352468900011],
  [14.199573850000036, 122.33360941700005],
  [14.199714939000046, 122.333706376],
  [14.199914856000078, 122.33398519700006],
  [14.199914940000042, 122.33426408200012],
  [14.199844471000063, 122.33447023600002],
  [14.199562421000053, 122.33470070900012],
  [14.199562471000036, 122.33487046500011],
  [14.199750600000073, 122.33504016200004],
  [14.200244309000027, 122.33504000800008],
  [14.200491146000047, 122.33497930400006],
  [14.200984856000048, 122.33497915100008],
  [14.20112589100006, 122.33489422800005],
  [14.201340937000055, 122.33462082200003],
  [14.20163902400003, 122.33444987300004],
  [14.201892936000036, 122.33425615800002],
  [14.202146794000043, 122.33388019800009],
  [14.202400753000063, 122.333845945],
  [14.202511192000031, 122.33390286200006],
  [14.20263273300003, 122.33416480100004],
  [14.202610700000037, 122.33433566300005],
  [14.202367803000072, 122.33443825200004],
  [14.20177164100005, 122.334825711],
  [14.201407311000025, 122.33503084900008],
  [14.201252785000065, 122.3352473110001],
  [14.20120868600003, 122.33548652000002],
  [14.201263933000064, 122.33561179500009],
  [14.20145167000004, 122.33568007800011],
  [14.201738832000046, 122.33590779500003],
  [14.202070073000073, 122.33582796000007],
  [14.202257703000043, 122.33553175500003],
  [14.202445423000029, 122.33554308600003],
  [14.202589014000068, 122.33569111600002],
  [14.202578005000078, 122.33580502200005],
  [14.202423474000057, 122.336010095],
  [14.201776644000063, 122.33639277000009],
  [14.201788441000076, 122.33725051700003],
  [14.20191561100006, 122.33770318200004],
  [14.202204479000045, 122.33819154200012],
  [14.202308496000057, 122.33845360500004],
  [14.202331726000068, 122.33891822100009],
  [14.202447320000033, 122.33927559200004],
  [14.202678399000035, 122.33960910600001],
  [14.20286330600004, 122.3400260310001],
  [14.203113501000075, 122.34078111000008],
  [14.203263801000048, 122.34137673300006],
  [14.203275551000047, 122.3421342800001],
  [14.203229411000052, 122.34234873200012],
  [14.203113951000034, 122.342467897],
  [14.20280217000004, 122.34263477000002],
  [14.202398102000075, 122.34320671800003],
  [14.202224955000077, 122.34355224800004],
  [14.202248216000044, 122.34418364200008],
  [14.202363770000034, 122.34443378800006],
  [14.202560105000032, 122.34443373500005],
  [14.202652477000072, 122.34435031700002],
  [14.202571566000074, 122.34408824800005],
  [14.202606140000057, 122.34380232100011],
  [14.202790894000032, 122.34368313700008],
  [14.203148937000037, 122.34376643200005],
  [14.203229799000042, 122.34383789000003],
  [14.203368502000046, 122.34427864300005],
  [14.203739531000053, 122.34438435800007],
  [14.20385508000004, 122.34461068200005],
  [14.204051548000052, 122.34513481600004],
  [14.204063172000076, 122.34543264700005],
  [14.204190278000056, 122.34569470500003],
  [14.20415575100003, 122.34618316000001],
  [14.204213536000054, 122.34633801700011],
  [14.204687128000046, 122.346659552],
  [14.204652516000067, 122.34680252100009],
  [14.204271396000024, 122.34680262200004],
  [14.204075026000055, 122.34665971200002],
  [14.203982587000041, 122.346469124],
  [14.20369379500005, 122.34620710900003],
  [14.20327368900007, 122.34593622900002],
  [14.203129854000053, 122.3459574640001],
  [14.20306824000005, 122.34608466000009],
  [14.203109409000035, 122.34637080100003],
  [14.203222476000064, 122.34657213900005],
  [14.203202060000024, 122.34711265400006],
  [14.203263796000044, 122.34748357800004],
  [14.203150845000039, 122.34775916000001],
  [14.202811896000071, 122.34818317800011],
  [14.202688627000043, 122.34827859400002],
  [14.202235040000062, 122.34842253400006],
  [14.201926853000032, 122.34859218400004],
  [14.20163919600003, 122.3486876400001],
  [14.201012570000046, 122.34913292200008],
  [14.200581057000022, 122.34916482300002],
  [14.200375558000076, 122.34911188500007],
  [14.199892659000056, 122.34909080900002],
  [14.19947101200006, 122.34888787600005],
  [14.198864770000057, 122.34863367600008],
  [14.198556533000044, 122.34860196000011],
  [14.197962112000027, 122.34819306700001],
  [14.197807947000058, 122.34798114700004],
  [14.197735976000047, 122.34776920500008],
  [14.197366028000033, 122.34746196100002],
  [14.197109153000042, 122.34738784000001],
  [14.196597212000029, 122.34703024600003],
  [14.19635063000004, 122.34703031000004],
  [14.196093791000067, 122.34710456200003],
  [14.195867751000037, 122.34708342500005],
  [14.195734137000045, 122.34688210000002],
  [14.195720167000047, 122.34599264000008],
  [14.195627688000059, 122.3459502720001],
  [14.195432521000043, 122.34613048800009],
  [14.19514483100005, 122.346088172],
  [14.194980403000045, 122.34592924900005],
  [14.194908441000052, 122.34575970100002],
  [14.194528261000073, 122.34563262800009],
  [14.19438438700007, 122.34549489500012],
  [14.193911730000025, 122.34533605400009],
  [14.193439113000068, 122.34533618000012],
  [14.193213059000072, 122.34526205600002],
  [14.192913564000037, 122.34524472400005],
  [14.191763063000053, 122.34479791000001],
  [14.191115083000057, 122.344572577],
  [14.19086496400007, 122.34443506900004],
  [14.190439775000073, 122.34425461700005],
  [14.190289724000024, 122.34425465700008],
  [14.18964457800007, 122.34453850600005],
  [14.18940682300007, 122.34458991500003],
  [14.188862530000051, 122.34454600400011],
  [14.18864450600006, 122.34464924400004],
  [14.188094308000075, 122.34458920500003],
  [14.187952564000057, 122.34446886700005],
  [14.187835862000043, 122.3444860940001],
  [14.18775253900003, 122.34463229000005],
  [14.18771096000006, 122.34503642400011],
  [14.18743813900005, 122.3452267450001],
  [14.187029719000066, 122.34542461800004],
  [14.186721287000069, 122.34544189700011],
  [14.186529574000076, 122.34551933300008],
  [14.186562948000073, 122.34563110400006],
  [14.186729686000035, 122.3456912480001],
  [14.186746441000025, 122.34602658000006],
  [14.186604745000068, 122.34609540300005],
  [14.18637966500006, 122.34607826600006],
  [14.186146184000052, 122.34580318000008],
  [14.18600447700004, 122.34582901300007],
  [14.186096256000042, 122.34615572500002],
  [14.186066087000029, 122.34648486100002],
  [14.186278926000057, 122.34675374100004],
  [14.186377863000075, 122.34677286200008],
  [14.186641240000029, 122.3469865400001],
  [14.187200787000052, 122.34698385500008],
  [14.187593210000045, 122.34724498800006],
  [14.18775665000004, 122.3475467180001],
  [14.187844976000065, 122.34784605300001],
  [14.187769502000037, 122.34822455900007],
  [14.18736642500005, 122.34876140300003],
  [14.187127673000077, 122.34882516700009],
  [14.18675379900003, 122.3488252630001],
  [14.186482713000032, 122.34870964000004],
  [14.186421249000034, 122.34862669900008],
  [14.186168859000077, 122.34853035300011],
  [14.185757594000052, 122.34853045900002],
  [14.185505258000035, 122.34865585600005],
  [14.185355757000025, 122.34886799500009],
  [14.185112779000065, 122.34904159400003],
  [14.18476931300006, 122.3490799870001],
  [14.184690344000046, 122.34929701900012],
  [14.184909901000026, 122.34945116800009],
  [14.185145410000075, 122.34929985600002],
  [14.185350740000047, 122.34939983200002],
  [14.185411705000035, 122.34970774800001],
  [14.18549620400006, 122.3499091760001],
  [14.185673832000077, 122.35007302600002],
  [14.185655258000054, 122.3505936360001],
  [14.185646260000055, 122.35212653300005],
  [14.185487490000071, 122.3526953810001],
  [14.18519782800007, 122.35311000800004],
  [14.185106276000056, 122.3533417970001],
  [14.184966091000035, 122.35342859700006],
  [14.184984815000064, 122.35356356700004],
  [14.185630407000076, 122.35344071600002],
  [14.186087910000026, 122.35330742500003],
  [14.186346122000032, 122.35336618800011],
  [14.18672781500004, 122.35332784800005],
  [14.187003749000041, 122.35358238700007],
  [14.187181417000033, 122.35393906000002],
  [14.187181456000076, 122.35412223700007],
  [14.187050654000075, 122.35437293100006],
  [14.186845147000042, 122.35496107200004],
  [14.186845214000073, 122.35526958000003],
  [14.186920031000056, 122.35547202200007],
  [14.186929434000035, 122.3557419660001],
  [14.187216375000048, 122.35562783400007],
  [14.187382789000026, 122.35565808800004],
  [14.187441548000038, 122.3557893310001],
  [14.18744166700003, 122.35636484000008],
  [14.187363380000022, 122.3564759190001],
  [14.187148059000037, 122.35661731800008],
  [14.187234494000052, 122.35686074000012],
  [14.18751026800004, 122.356758592],
  [14.18770601500006, 122.35662729300009],
  [14.188107314000035, 122.35644546600008],
  [14.18874353800004, 122.35623329500004],
  [14.18943854500003, 122.35624323900004],
  [14.189770520000025, 122.35630067000011],
  [14.190543883000032, 122.35658321000005],
  [14.190847344000076, 122.35664372600002],
  [14.19234502300003, 122.35665349200008],
  [14.192575390000059, 122.356781135],
  [14.192595035000068, 122.35711432800008],
  [14.192692967000028, 122.35733643800006],
  [14.19291811000005, 122.35735658300007],
  [14.193035536000025, 122.35716471700005],
  [14.192947375000074, 122.3568517330001],
  [14.193270351000024, 122.35659923800006],
  [14.193495420000033, 122.35624579500006],
  [14.193717685000024, 122.35599425300006],
  [14.194079793000071, 122.35564077900005],
  [14.194441943000072, 122.35548924300008],
  [14.195215252000025, 122.35548906800011],
  [14.195401224000022, 122.35541834700007],
  [14.195508867000058, 122.3552567700001],
  [14.195645916000046, 122.35528702800002],
  [14.195792814000072, 122.35561010200001],
  [14.195978789000037, 122.35554947700007],
  [14.195890616000042, 122.3551860010001],
  [14.19587862700007, 122.35493993800003],
  [14.195976484000028, 122.3547985570001],
  [14.19619183900005, 122.35481870400008],
  [14.196338719000039, 122.35505090100003],
  [14.196476443000051, 122.35564896200003],
  [14.19653519700006, 122.35574991900012],
  [14.196799483000063, 122.35570947300005],
  [14.196975631000043, 122.35547720200009],
  [14.197073509000063, 122.3554367910001],
  [14.197396536000042, 122.35543672000006],
  [14.197709807000024, 122.35559820300011],
  [14.198091670000053, 122.35610297300002],
  [14.198209151000071, 122.35618372400006],
  [14.198483232000058, 122.35617356700004],
  [14.198688769000057, 122.35605235700007],
  [14.198913915000048, 122.35608259800006],
  [14.199070582000047, 122.35631479900007],
  [14.199231329000042, 122.35670117900008],
  [14.199231546000021, 122.3577828220001],
  [14.199127882000028, 122.35799679700006],
  [14.198690069000065, 122.35830593600008],
  [14.198540296000033, 122.35844860400005],
  [14.198563363000062, 122.35855557700006],
  [14.198851459000025, 122.358662492],
  [14.199093469000047, 122.35880507800005],
  [14.19923178700003, 122.35901900500005],
  [14.19935855600005, 122.3591021850001],
  [14.199565950000022, 122.35899516200004],
  [14.199565837000023, 122.35842461300001],
  [14.199611860000061, 122.35806801300009],
  [14.199761608000074, 122.35781836700005],
  [14.199842209000053, 122.35752119300003],
  [14.199842130000036, 122.35712894800008],
  [14.200060921000045, 122.35642761800011],
  [14.200129858000025, 122.35546482900008],
  [14.20012975000003, 122.35495372900004],
  [14.200303689000066, 122.35458393500005],
  [14.200644269000065, 122.3544531230001],
  [14.20100067800007, 122.35425694100002],
  [14.20116697900005, 122.35406080300004],
  [14.201420452000036, 122.3540444030001],
  [14.20173731400007, 122.35411786800012],
  [14.202006612000048, 122.35401975500008],
  [14.201879822000024, 122.35378283000011],
  [14.20191148300006, 122.35367660100007],
  [14.202133265000043, 122.3536275240001],
  [14.202235117000043, 122.35341370900005],
  [14.202488570000071, 122.35329108400003],
  [14.202583592000053, 122.353143982],
  [14.202718161000064, 122.35272723000003],
  [14.202884460000064, 122.3525229170001],
  [14.202995281000028, 122.35218788300006],
  [14.20300308700007, 122.35167311200007],
  [14.203169324000044, 122.35120733000008],
  [14.203185090000034, 122.35087231900002],
  [14.203256346000046, 122.350725226],
  [14.203454346000058, 122.35060261300009],
  [14.203715743000032, 122.35060255000008],
  [14.204523769000048, 122.35090467500004],
  [14.204587159000027, 122.3509945400001],
  [14.204636704000052, 122.35160172500002],
  [14.204565466000076, 122.351830532],
  [14.204359559000068, 122.35201851300008],
  [14.204114012000048, 122.3520594260001],
  [14.20411404500004, 122.35220650400004],
  [14.204502227000035, 122.35240251700009],
  [14.204502286000036, 122.35266398700003],
  [14.20439145000006, 122.35293365500002],
  [14.204423160000033, 122.35304804100008],
  [14.204803353000045, 122.3529499010001],
  [14.205040987000075, 122.35294984400002],
  [14.205270729000063, 122.3530805260001],
  [14.205342048000034, 122.35321124500001],
  [14.205342149000046, 122.35366882000005],
  [14.205207527000027, 122.3538404410001],
  [14.204794539000034, 122.35396345800007],
  [14.204636140000048, 122.35407789100009],
  [14.204533199000025, 122.3542331640001],
  [14.204327270000022, 122.35433126400005],
  [14.204200623000077, 122.35475618600003],
  [14.204034335000074, 122.35501769600012],
  [14.203852253000036, 122.35550799400005],
  [14.203860334000069, 122.35626788900004],
  [14.20394927500007, 122.35650929200006],
  [14.204050112000061, 122.35661326400009],
  [14.203982943000028, 122.35680971300008],
  [14.203635706000057, 122.35687911700006],
  [14.203557314000022, 122.35697157300001],
  [14.20360220300006, 122.35737598300011],
  [14.20346780500006, 122.35748000600006],
  [14.203221368000072, 122.35748005900007],
  [14.203086979000034, 122.35763030100009],
  [14.203087030000063, 122.35788450700011],
  [14.203165548000072, 122.35841601300001],
  [14.203176836000068, 122.35885509600007],
  [14.203154484000038, 122.35912086200005],
  [14.20401707700006, 122.35945577300004],
  [14.204453882000053, 122.35915525400003],
  [14.204733875000045, 122.3589125420001],
  [14.204868260000069, 122.35873919000005],
  [14.204980226000032, 122.35848495800008],
  [14.20502493500004, 122.3579996420001],
  [14.20521317400005, 122.35780502900002],
  [14.205523506000077, 122.35733015500011],
  [14.205801780000058, 122.35714238000003],
  [14.205930226000078, 122.35710922600003],
  [14.20637980500004, 122.35710912700006],
  [14.206379760000061, 122.35688828700006],
  [14.206058583000072, 122.35664543500002],
  [14.205726640000023, 122.35611549600003],
  [14.205726574000039, 122.35579528000005],
  [14.20579076000007, 122.35560755300003],
  [14.205951282000058, 122.35540876300001],
  [14.206079719000059, 122.35534248200008],
  [14.206540003000043, 122.35534238000002],
  [14.206741514000043, 122.3552964160001],
  [14.206894311000042, 122.35535699700006],
  [14.20718819800004, 122.35570850800002],
  [14.207223486000032, 122.35585398100011],
  [14.207435065000027, 122.35601153700009],
  [14.207587851000028, 122.35602362500003],
  [14.207811134000053, 122.35595083400005],
  [14.207940390000033, 122.35584169300012],
  [14.20794034100004, 122.35561134800002],
  [14.207999070000028, 122.3554416070001],
  [14.208116575000076, 122.35534459300004],
  [14.20871591100007, 122.35515048200011],
  [14.209103694000078, 122.35492005000003],
  [14.20927994300007, 122.35473815800003],
  [14.20936217600007, 122.35458053500008],
  [14.209362124000052, 122.35433806800006],
  [14.209221026000023, 122.35402289300009],
  [14.20871558500005, 122.35363506300007],
  [14.208445088000076, 122.35277436900003],
  [14.208233456000073, 122.35238647100005],
  [14.208033595000074, 122.35208343500005],
  [14.207862612000042, 122.35176233200002],
  [14.207674533000045, 122.35159264900005],
  [14.207462922000047, 122.35130173700009],
  [14.207286510000074, 122.35075622700003],
  [14.207286371000066, 122.35015005800005],
  [14.207333336000033, 122.34995607300004],
  [14.207568264000031, 122.34943471200006],
  [14.207662269000025, 122.34937407200005],
  [14.207662123000034, 122.3487557850001],
  [14.207450540000025, 122.34859823500005],
  [14.207497480000029, 122.34830726400003],
  [14.207673729000021, 122.34814961600011],
  [14.208026250000046, 122.34793130600008],
  [14.208178965000059, 122.34765243100003],
  [14.208390468000061, 122.34748264900009],
  [14.208519739000053, 122.34745836900004],
  [14.208801748000042, 122.3472521970001],
  [14.209103130000074, 122.34696068400001],
  [14.20930454300003, 122.3468913690001],
  [14.209599928000046, 122.34671120900009],
  [14.209908787000074, 122.34671112800004],
  [14.21016389700003, 122.34657253500006],
  [14.210378673000037, 122.34624001800012],
  [14.210324872000058, 122.34589371900006],
  [14.210083083000029, 122.34560288000011],
  [14.210123299000031, 122.3453258180001],
  [14.210284419000061, 122.34522880700001],
  [14.210512731000051, 122.34532571400007],
  [14.210700830000064, 122.34571353500007],
  [14.211009731000047, 122.34587968300002],
  [14.211305163000077, 122.34587960300007],
  [14.211439475000077, 122.34597653500009],
  [14.211666219000051, 122.34644684500006],
  [14.21217654700007, 122.34659909000004],
  [14.212257160000036, 122.34676530200011],
  [14.212310978000062, 122.34718086700002],
  [14.21236471900005, 122.34729167400008],
  [14.212566176000053, 122.34740244200009],
  [14.212633397000047, 122.34772103600005],
  [14.212821392000023, 122.34769328200002],
  [14.21294221200003, 122.34754087200008],
  [14.21302273300006, 122.3473330600001],
  [14.213264439000056, 122.34729143900006],
  [14.213425603000076, 122.34737451300009],
  [14.213653890000046, 122.34737445400003],
  [14.214137277000077, 122.34719424200011],
  [14.214459436000027, 122.34666775100004],
  [14.214513109000052, 122.34650150300001],
  [14.214739536000025, 122.34620440300012],
  [14.215307190000033, 122.34592473400005],
  [14.215513618000045, 122.34585812800003],
  [14.21608135300005, 122.34589790700011],
  [14.21639099500004, 122.34579134000012],
  [14.216661958000032, 122.34580457800007],
  [14.216829713000038, 122.3458710860001],
  [14.217139392000036, 122.34591093400002],
  [14.217513618000055, 122.34607056100003],
  [14.217900714000052, 122.34609708000005],
  [14.218197548000035, 122.34634990300003],
  [14.218416987000069, 122.34669592300008],
  [14.218610570000067, 122.34684229000004],
  [14.21891624400007, 122.34725690900007],
  [14.219042922000028, 122.34735121400001],
  [14.219291120000037, 122.3473255450001],
  [14.21958900800007, 122.34750470300003],
  [14.219589039000027, 122.34763272700002],
  [14.219415358000049, 122.3478888200001],
  [14.219142371000032, 122.34804252000004],
  [14.219216887000073, 122.3482729430001],
  [14.219465146000061, 122.34850332400003],
  [14.219787926000038, 122.34898973500003],
  [14.219936922000045, 122.34929695800008],
  [14.220334098000023, 122.34950169900003],
  [14.221123247000037, 122.34974546800004],
  [14.221368405000021, 122.34974540900009],
  [14.221561922000035, 122.34962556300002],
  [14.221860450000065, 122.34913060600002],
  [14.222144264000065, 122.348917561],
  [14.222686107000072, 122.34859796300009],
  [14.222866728000042, 122.34851805200003],
  [14.223073173000046, 122.34851799900002],
  [14.223344152000038, 122.34859779500005],
  [14.223511927000061, 122.3487574830001],
  [14.223615228000028, 122.34909022800002],
  [14.223615295000059, 122.34936975700009],
  [14.223692759000073, 122.34956940100005],
  [14.223925029000043, 122.34964920800007],
  [14.224260499000025, 122.34963581300008],
  [14.224441127000034, 122.34958252500007],
  [14.224918450000075, 122.34923632000005],
  [14.225251150000076, 122.34921394300011],
  [14.225378662000026, 122.34924426700002],
  [14.225702395000042, 122.34951738000007],
  [14.225790730000028, 122.34978043700005],
  [14.225790777000043, 122.34998280500008],
  [14.22571238300003, 122.3502863760001],
  [14.225477047000027, 122.35053939400007],
  [14.22505529800003, 122.3505394980001],
  [14.224466800000073, 122.35048905200006],
  [14.224192175000042, 122.35049923800011],
  [14.224035273000027, 122.35062069600008],
  [14.223947044000056, 122.35081296700002],
  [14.223942929000032, 122.35152311000002],
  [14.223884136000038, 122.35176596600002],
  [14.223884354000063, 122.35273732400003],
  [14.223727494000059, 122.35305102900008],
  [14.223207742000056, 122.35340529100006],
  [14.223129314000062, 122.35357732000011],
  [14.223129474000075, 122.35431595600005],
  [14.22307067500003, 122.3545486910001],
  [14.22280592800007, 122.35489277600004],
  [14.222659832000033, 122.35544192800012],
  [14.222552048000068, 122.35594786500008],
  [14.222355923000066, 122.35613003800006],
  [14.221934175000058, 122.3561301310001],
  [14.22169880100006, 122.3562313650001],
  [14.221453638000071, 122.35642366600007],
  [14.221209630000033, 122.35650120200012],
  [14.22090561400006, 122.35668339800009],
  [14.220532917000071, 122.3567441890001],
  [14.219483448000062, 122.35674441900005],
  [14.218918846000065, 122.35689639800012],
  [14.218455170000027, 122.35709347700003],
  [14.21826429400005, 122.35741712200002],
  [14.218141556000035, 122.35747342800005],
  [14.21785514800007, 122.35750162800002],
  [14.217623337000077, 122.35772679400009],
  [14.217555192000077, 122.35796599300011],
  [14.21745973700007, 122.35805043000005],
  [14.217064239000024, 122.35817714100006],
  [14.21675054700006, 122.35817720800003],
  [14.216375608000021, 122.35824412400007],
  [14.216143767000062, 122.35832859100003],
  [14.215966489000039, 122.35845525500008],
  [14.215489178000041, 122.3586804680001],
  [14.215134588000069, 122.35876495900004],
  [14.214793650000047, 122.358919796],
  [14.214343633000055, 122.35924348800006],
  [14.214180033000048, 122.3595811890001],
  [14.214180139000064, 122.36012989900007],
  [14.214133082000046, 122.36043858200003],
  [14.21389853900007, 122.36061147300006],
  [14.213881809000043, 122.36074975000008],
  [14.214066162000051, 122.36099169300007],
  [14.214166696000063, 122.36123494800006],
  [14.214166756000054, 122.36154448200011],
  [14.21405767500005, 122.36169926900004],
  [14.21377127900007, 122.36181188000012],
  [14.21351220200006, 122.36213553000005],
  [14.213498603000062, 122.36234657800003],
  [14.213621380000063, 122.36248725000007],
  [14.21398963100006, 122.36248718000002],
  [14.214548811000043, 122.3624167270001],
  [14.214944302000049, 122.36223374700012],
  [14.215598963000048, 122.36223362200008],
  [14.21604904700007, 122.36226167400002],
  [14.216402211000059, 122.3623277370001],
  [14.216740240000036, 122.36228205800012],
  [14.216938374000051, 122.36235314400005],
  [14.217136928000059, 122.36260925500005],
  [14.217266573000074, 122.36306976100002],
  [14.217282202000035, 122.36324602200011],
  [14.217222200000037, 122.36349957900006],
  [14.217222302000039, 122.36407643300004],
  [14.21715415500006, 122.36434377000012],
  [14.21703145500004, 122.36462518500002],
  [14.216636135000044, 122.36580710400006],
  [14.216486135000025, 122.36596189700003],
  [14.216049745000078, 122.36625743700006],
  [14.215626970000073, 122.36641228000008],
  [14.215313306000041, 122.36658117000002],
  [14.214969448000033, 122.36710426000002],
  [14.214669445000027, 122.36741384200002],
  [14.214410334000036, 122.36756865100006],
  [14.213496590000034, 122.36787833500011],
  [14.213278423000077, 122.36820196800011],
  [14.213128443000073, 122.36849745000006],
  [14.212732950000031, 122.36868041800005],
  [14.212637492000056, 122.36876485000005],
  [14.212692116000028, 122.3692150600001],
  [14.212842179000063, 122.369454216],
  [14.212951329000077, 122.36972151500004],
  [14.21295135500003, 122.36989034800001],
  [14.212869564000073, 122.37017175100004],
  [14.212605093000036, 122.3704571190001],
  [14.212275629000032, 122.37062711900012],
  [14.21215210400004, 122.37085019100005],
  [14.212152503000027, 122.37366492400008],
  [14.212029021000035, 122.3742273900001],
  [14.212080548000074, 122.37456727900008],
  [14.212379186000021, 122.37485402300001],
  [14.212605718000077, 122.37491772200008],
  [14.212749883000072, 122.37502391900011],
  [14.213058859000057, 122.3756505560001],
  [14.213161865000075, 122.37597981400006],
  [14.213131032000035, 122.37643655000011],
  [14.212993020000056, 122.37651925600005],
  [14.212708190000058, 122.37657114600006],
  [14.212339572000076, 122.37651934400003],
  [14.212121763000027, 122.37658851000003],
  [14.212121782000054, 122.37676135000004],
  [14.212205571000027, 122.37686504400006],
  [14.212607716000036, 122.37705511700005],
  [14.212892576000058, 122.37726248900003],
  [14.212945834000038, 122.37758371300004],
  [14.212966471000072, 122.3779448470001],
  [14.213079758000049, 122.378157267],
  [14.213296013000047, 122.37838029700004],
  [14.213460787000031, 122.3786245770001],
  [14.213460859000065, 122.37923001900003],
  [14.213526139000066, 122.37961448900012],
  [14.213660035000032, 122.37998623300007],
  [14.214216081000075, 122.38030481900012],
  [14.214257306000036, 122.38066595200007],
  [14.214360287000034, 122.3808146450001],
  [14.214792760000023, 122.38100578700005],
  [14.214864843000043, 122.3810695090001],
  [14.214854584000022, 122.38143065000008],
  [14.214957565000077, 122.38157934300011],
  [14.21520468600005, 122.38162180300003],
  [14.215359147000072, 122.38173862700012],
  [14.215348864000077, 122.38186608900003],
  [14.215153261000069, 122.38217414200005],
  [14.215266532000044, 122.3822591060001],
  [14.215862413000025, 122.38246121200007],
  [14.216222345000062, 122.38252456700002],
  [14.216617412000062, 122.3827871520001],
  [14.216854443000045, 122.38285052000003],
  [14.217021225000053, 122.3827237160001],
  [14.217021201000023, 122.38247920200001],
  [14.216828044000067, 122.38221659600003],
  [14.216362756000024, 122.38204458100006],
  [14.216248612000072, 122.38182724900003],
  [14.216169577000073, 122.38156463300004],
  [14.216169551000064, 122.38131106500009],
  [14.216266079000036, 122.38095787000009],
  [14.216529421000075, 122.38078577700003],
  [14.216815688000054, 122.38073436500008],
  [14.218120948000035, 122.38073421700005],
  [14.218381992000047, 122.38064132400007],
  [14.218685287000028, 122.38059507700007],
  [14.219306368000048, 122.38018641000008],
  [14.219927467000048, 122.37997275300006],
  [14.220044481000059, 122.37988916200004],
  [14.220242490000032, 122.37961983600007],
  [14.220469321000053, 122.379229871],
  [14.220469305000051, 122.37908916000004],
  [14.220356955000057, 122.37888224800008],
  [14.220295087000068, 122.37861140100006],
  [14.220411513000045, 122.37831167700006],
  [14.220402727000021, 122.37811361900003],
  [14.22049213200006, 122.37796054400008],
  [14.22066096900005, 122.37786326000003],
  [14.220739896000055, 122.37769083100011],
  [14.221163086000047, 122.3774723790001],
  [14.221381610000037, 122.3771941220001],
  [14.221848402000035, 122.3768743710001],
  [14.222048673000074, 122.3768351440001],
  [14.222330572000033, 122.3769012250001],
  [14.222545208000042, 122.37675215700006],
  [14.22252205500007, 122.3766639370001],
  [14.222079305000022, 122.37616123600003],
  [14.221980606000045, 122.37588803200003],
  [14.222224481000069, 122.375747509],
  [14.222399909000046, 122.37579876600012],
  [14.22251297300005, 122.37596213100005],
  [14.222680119000074, 122.37604355200006],
  [14.222809239000071, 122.37591837000002],
  [14.222854533000032, 122.37563836600009],
  [14.222994130000075, 122.37553253600004],
  [14.22309232200007, 122.37557751800011],
  [14.223353772000053, 122.3758331140001],
  [14.223520918000077, 122.3759145350001],
  [14.224202888000036, 122.37584871000001],
  [14.224576314000046, 122.37572024300005],
  [14.224811424000052, 122.37556325600008],
  [14.225157184000068, 122.37542052300012],
  [14.225696553000034, 122.37506373200006],
  [14.225890157000038, 122.37482113800002],
  [14.226152928000033, 122.374664144],
  [14.226443376000077, 122.37462129700009],
  [14.226747648000071, 122.37452137200012],
  [14.227245522000032, 122.37415031000012],
  [14.227466810000067, 122.37406466400012],
  [14.22774182300003, 122.3740464010001],
  [14.227941114000032, 122.37397440900008],
  [14.228259999000045, 122.37397436300012],
  [14.22849915300003, 122.373912646],
  [14.228738290000024, 122.3737275630001],
  [14.228847882000025, 122.37355277800009],
  [14.228947493000021, 122.3732649100001],
  [14.228947448000042, 122.3729359350001],
  [14.228887617000055, 122.3726480900001],
  [14.22890284600004, 122.37195781100002],
  [14.229133801000046, 122.37130890500009],
  [14.229364770000075, 122.37076593400002],
  [14.229685591000077, 122.37022294500002],
  [14.230057757000054, 122.36967994300005],
  [14.230655392000074, 122.3688436650001],
  [14.230777614000033, 122.36870362500008],
  [14.23145785500003, 122.36826651000001],
  [14.232138129000077, 122.36804127200003],
  [14.232369176000077, 122.3680279890001],
  [14.23322917300004, 122.36788217100002],
  [14.233575745000053, 122.36785562400007],
  [14.234024996000073, 122.36774960200012],
  [14.234564090000049, 122.36755086300002],
  [14.235035684000025, 122.367237767],
  [14.235240992000058, 122.3668272110001],
  [14.235356506000073, 122.36676097600002],
  [14.236023941000042, 122.36653573400008],
  [14.236481629000025, 122.36648814900002],
  [14.238601611000036, 122.36646278],
  [14.239706234000039, 122.36577626500002],
  [14.239873068000065, 122.36556434700003],
  [14.240001386000074, 122.36531270700004],
  [14.240155344000073, 122.36487566200003],
  [14.240219467000031, 122.36454457800005],
  [14.240322104000029, 122.36425321400009],
  [14.240493916000048, 122.36408302600012],
  [14.240732810000054, 122.36398827900007],
  [14.24109945500004, 122.36401532800005],
  [14.24145868900007, 122.3642966750001],
  [14.241727402000038, 122.36428731800004],
  [14.241950780000025, 122.36411783400001],
  [14.242033241000058, 122.3639137450001],
  [14.242303483000057, 122.36378848400011],
  [14.242454620000046, 122.36352849600007],
  [14.242723358000035, 122.36335513900008],
  [14.242992065000067, 122.36300847300004],
  [14.243328, 122.36286976400004],
  [14.243462365000028, 122.36276575300008],
  [14.243579914000065, 122.36255776100006],
  [14.24369749300007, 122.3625230770001],
  [14.243825401000038, 122.36262201200009],
  [14.24389227200004, 122.36270312900001],
  [14.244098954000037, 122.36264617800009],
  [14.244164514000033, 122.36262811300003],
  [14.242988211000068, 122.39476837400002],
  [14.242922345000068, 122.39655393700002],
  [14.242176679000067, 122.39639855700011],
  [14.24196574900003, 122.39616164100005],
  [14.241524540000057, 122.39576699600002],
  [14.241062767000074, 122.39557024700002],
  [14.240599122000049, 122.395531602],
  [14.239958112000068, 122.39571357000011],
  [14.239525306000075, 122.39579678000007],
  [14.238693528000056, 122.39566021000007],
  [14.238140826000063, 122.39554017700004],
  [14.237568357000043, 122.39558171600004],
  [14.237094382000066, 122.39598151700011],
  [14.236745332000055, 122.3961715160001],
  [14.236529876000077, 122.39634005300002],
  [14.236691908000068, 122.39669792300003],
  [14.236877460000073, 122.3974134990001],
  [14.237224462000029, 122.3980338240001],
  [14.237686419000056, 122.39839179700004],
  [14.23796365800007, 122.39870190400006],
  [14.23808034800004, 122.39925024400009],
  [14.237943864000044, 122.39991802700001],
  [14.237574593000033, 122.40025230600008],
  [14.236973601000045, 122.4004435920001],
  [14.236257846000058, 122.40077825000003],
  [14.235703528000045, 122.40123249800001],
  [14.235426382000071, 122.40160290800009],
  [14.235056473000043, 122.40193694000004],
  [14.235126252000043, 122.40231918300003],
  [14.23547344900004, 122.40241494600002],
  [14.235703811000064, 122.40277452300006],
  [14.235610420000057, 122.40310971000008],
  [14.235426949000043, 122.4032996410001],
  [14.235054554000044, 122.40339705500003],
  [14.234776367000052, 122.40358873900004],
  [14.23468289400006, 122.40392367200002],
  [14.234775480000053, 122.40411436400007],
  [14.235007604000032, 122.4042807510001],
  [14.234844769000063, 122.4045913430001],
  [14.23456649800005, 122.40471103000004],
  [14.23440428300006, 122.404973347],
  [14.234311510000055, 122.40542583500007],
  [14.234497160000046, 122.40592598100011],
  [14.234777068000028, 122.40654681900003],
  [14.235073035000028, 122.40712009600009],
  [14.235349861000032, 122.407526329],
  [14.235602820000054, 122.40771827600008],
  [14.235785129000021, 122.40831486500008],
  [14.23569378600007, 122.40878946500004],
  [14.235464876000037, 122.4088602270001],
  [14.235027158000037, 122.40869354300003],
  [14.234542495000028, 122.40838355000005],
  [14.234150305000071, 122.40824184500002],
  [14.233897288000037, 122.40826684500007],
  [14.233851429000026, 122.40855224800009],
  [14.233989529000041, 122.40909879600008],
  [14.234404749000078, 122.40959832300007],
  [14.234935215000064, 122.40995429500003],
  [14.235187449000023, 122.41033195700004],
  [14.23530015700004, 122.41075364300002],
  [14.235161990000051, 122.41115239700002],
  [14.234793414000023, 122.41144058400005],
  [14.234184149000043, 122.41165372400008],
  [14.233736172000022, 122.41148806600006],
  [14.233157163000044, 122.41110863600011],
  [14.232810594000057, 122.41096683],
  [14.232580416000076, 122.41106193200005],
  [14.232512201000077, 122.41137020700012],
  [14.232490320000068, 122.41177369200011],
  [14.23249293300006, 122.41267747400002],
  [14.232510328000046, 122.41331221100006],
  [14.232709969000041, 122.41375256600008],
  [14.233000940000068, 122.41393345200004],
  [14.233064221000063, 122.41425548300003],
  [14.232880279000028, 122.41467357300007],
  [14.232383004000042, 122.415193713],
  [14.231839541000056, 122.41553096400003],
  [14.23234661500004, 122.4287058110001],
  [14.216051253000046, 122.44378814700008],
  [14.206962575000034, 122.4595700750001]



];

const polygonCoordsCPL = [
  [14.323115596000036, 122.57784604300002],
  [14.310706864000053, 122.57747833100007],
  [14.283341053000072, 122.57961644800002],
  [14.27811261100004, 122.58473818800007],
  [14.271002329000055, 122.58520244400006],
  [14.258566656000028, 122.58535285700009],
  [14.245527464000077, 122.58531010600007],
  [14.222815410000067, 122.58301783900004],
  [14.21022522900006, 122.57802311000012],
  [14.208865803000037, 122.57743821000008],
  [14.208562133000044, 122.57588411200004],
  [14.205222478000053, 122.55716303300005],
  [14.203958898000053, 122.54569360800008],
  [14.204047822000064, 122.5435695860001],
  [14.205028081000023, 122.52887826400001],
  [14.205104626000036, 122.52463061900005],
  [14.205929541000046, 122.50853140400011],
  [14.206204466000031, 122.50317451300009],
  [14.208623439000064, 122.4812175510001],
  [14.206814018000046, 122.46684939800002],
  [14.206962575000034, 122.4595700750001],
  [14.216051253000046, 122.44378814700008],
  [14.23234661500004, 122.4287058110001],
  [14.231839541000056, 122.41553096400003],
  [14.232383004000042, 122.415193713],
  [14.232880279000028, 122.41467357300007],
  [14.233064221000063, 122.41425548300003],
  [14.233000940000068, 122.41393345200004],
  [14.232709969000041, 122.41375256600008],
  [14.232510328000046, 122.41331221100006],
  [14.23249293300006, 122.41267747400002],
  [14.232490320000068, 122.41177369200011],
  [14.232512201000077, 122.41137020700012],
  [14.232580416000076, 122.41106193200005],
  [14.232810594000057, 122.41096683],
  [14.233157163000044, 122.41110863600011],
  [14.233736172000022, 122.41148806600006],
  [14.234184149000043, 122.41165372400008],
  [14.234793414000023, 122.41144058400005],
  [14.235161990000051, 122.41115239700002],
  [14.23530015700004, 122.41075364300002],
  [14.235187449000023, 122.41033195700004],
  [14.234935215000064, 122.40995429500003],
  [14.234404749000078, 122.40959832300007],
  [14.233989529000041, 122.40909879600008],
  [14.233851429000026, 122.40855224800009],
  [14.233897288000037, 122.40826684500007],
  [14.234150305000071, 122.40824184500002],
  [14.234542495000028, 122.40838355000005],
  [14.235027158000037, 122.40869354300003],
  [14.235464876000037, 122.4088602270001],
  [14.23569378600007, 122.40878946500004],
  [14.235785129000021, 122.40831486500008],
  [14.235602820000054, 122.40771827600008],
  [14.235349861000032, 122.407526329],
  [14.235073035000028, 122.40712009600009],
  [14.234777068000028, 122.40654681900003],
  [14.234497160000046, 122.40592598100011],
  [14.234311510000055, 122.40542583500007],
  [14.23440428300006, 122.404973347],
  [14.23456649800005, 122.40471103000004],
  [14.234844769000063, 122.4045913430001],
  [14.235007604000032, 122.4042807510001],
  [14.234775480000053, 122.40411436400007],
  [14.23468289400006, 122.40392367200002],
  [14.234776367000052, 122.40358873900004],
  [14.235054554000044, 122.40339705500003],
  [14.235426949000043, 122.4032996410001],
  [14.235610420000057, 122.40310971000008],
  [14.235703811000064, 122.40277452300006],
  [14.23547344900004, 122.40241494600002],
  [14.235126252000043, 122.40231918300003],
  [14.235056473000043, 122.40193694000004],
  [14.235426382000071, 122.40160290800009],
  [14.235703528000045, 122.40123249800001],
  [14.236257846000058, 122.40077825000003],
  [14.236973601000045, 122.4004435920001],
  [14.237574593000033, 122.40025230600008],
  [14.237943864000044, 122.39991802700001],
  [14.23808034800004, 122.39925024400009],
  [14.23796365800007, 122.39870190400006],
  [14.237686419000056, 122.39839179700004],
  [14.237224462000029, 122.3980338240001],
  [14.236877460000073, 122.3974134990001],
  [14.236691908000068, 122.39669792300003],
  [14.236529876000077, 122.39634005300002],
  [14.236745332000055, 122.3961715160001],
  [14.237094382000066, 122.39598151700011],
  [14.237568357000043, 122.39558171600004],
  [14.238140826000063, 122.39554017700004],
  [14.238693528000056, 122.39566021000007],
  [14.239525306000075, 122.39579678000007],
  [14.239958112000068, 122.39571357000011],
  [14.240599122000049, 122.395531602],
  [14.241062767000074, 122.39557024700002],
  [14.241524540000057, 122.39576699600002],
  [14.24196574900003, 122.39616164100005],
  [14.242176679000067, 122.39639855700011],
  [14.242922345000068, 122.39655393700002],
  [14.242988211000068, 122.39476837400002],
  [14.244164514000033, 122.36262811300003],
  [14.244098954000037, 122.36264617800009],
  [14.244335811000042, 122.36248829300007],
  [14.244453347000047, 122.36221097700002],
  [14.24477249000006, 122.3621069300001],
  [14.244873238000025, 122.36188160900008],
  [14.244923578000055, 122.36158697300004],
  [14.245041145000073, 122.36148296500005],
  [14.245265719000031, 122.36139921100005],
  [14.245432898000047, 122.36125254300009],
  [14.245499748000043, 122.36107139300009],
  [14.245548863000067, 122.36074996400009],
  [14.245757278000042, 122.36054875500008],
  [14.246152041000073, 122.36059731300008],
  [14.246416322000073, 122.3605973760001],
  [14.24663531300007, 122.36053250900011],
  [14.247065777000046, 122.36025735100009],
  [14.247249528000054, 122.36020805500004],
  [14.247642183000039, 122.36020255200003],
  [14.248214008000048, 122.36014296300004],
  [14.248767781000026, 122.35995872100011],
  [14.25040073100007, 122.35997067000005],
  [14.250828600000034, 122.36003050200009],
  [14.251642420000053, 122.36030707900011],
  [14.252070496000044, 122.36052805100007],
  [14.252625069000032, 122.36071893600001],
  [14.252809919000072, 122.36080933500011],
  [14.252965550000056, 122.36103023700002],
  [14.253656060000026, 122.36239573500006],
  [14.253636497000059, 122.36282741000002],
  [14.253305436000062, 122.36382119100006],
  [14.25317890100007, 122.36400186100002],
  [14.253003725000042, 122.36415240000008],
  [14.252877166000076, 122.3644334600001],
  [14.25294522400003, 122.36464429700004],
  [14.253227292000076, 122.36505597200005],
  [14.253272855000034, 122.36525531200004],
  [14.253081199000064, 122.3655737140001],
  [14.252775357000075, 122.36582890500006],
  [14.252372997000066, 122.36602865300006],
  [14.252381426000056, 122.36636687400005],
  [14.252128292000066, 122.36683732000006],
  [14.252123766000068, 122.36709496200001],
  [14.252239625000072, 122.36740250700007],
  [14.252435736000052, 122.36775682100006],
  [14.252359819000048, 122.3681846720001],
  [14.252230399000041, 122.36858030200005],
  [14.253225354000051, 122.36858978200007],
  [14.25350181500005, 122.36861746500006],
  [14.253751506000071, 122.36870034900005],
  [14.253913469000054, 122.36886781400005],
  [14.254112527000075, 122.36898224700008],
  [14.254284398000038, 122.369241396],
  [14.254362044000061, 122.369616704],
  [14.254468367000072, 122.36964941400004],
  [14.254547483000067, 122.36989920000008],
  [14.254771452000057, 122.36991793800007],
  [14.254970554000067, 122.36986664200003],
  [14.255199502000039, 122.36971285800007],
  [14.255509646000064, 122.36969076900004],
  [14.255681585000048, 122.36975735100009],
  [14.255881053000053, 122.37022313800003],
  [14.255825671000025, 122.370628656],
  [14.255865542000038, 122.37080292100006],
  [14.256037455000069, 122.3709708880001],
  [14.256250124000076, 122.371451481],
  [14.256483370000069, 122.37190143900011],
  [14.256655300000034, 122.37199970600011],
  [14.256848753000043, 122.37199976400007],
  [14.257235678000029, 122.37193017700008],
  [14.257432263000055, 122.37209963600003],
  [14.257613350000042, 122.37239433700006],
  [14.257733098000074, 122.37242288800007],
  [14.258162527000025, 122.37238620000005],
  [14.258469627000068, 122.37226589700003],
  [14.258656927000061, 122.372300805],
  [14.258798826000032, 122.37251421000008],
  [14.258798722000051, 122.37288173200011],
  [14.258660421000059, 122.37330623900004],
  [14.258638848000032, 122.37358187100006],
  [14.258789221000029, 122.37389874400003],
  [14.258835353000052, 122.37415690900002],
  [14.258784112000058, 122.37445750800009],
  [14.258519302000025, 122.37496686100008],
  [14.258307939000076, 122.37523298700012],
  [14.258165527000074, 122.37547848100007],
  [14.258220423000068, 122.37564619800003],
  [14.258095783000044, 122.375962834],
  [14.258100117000026, 122.3763506470001],
  [14.258182834000024, 122.37652606600011],
  [14.258112683000036, 122.37681521400009],
  [14.257886873000075, 122.37696374200004],
  [14.257781718000047, 122.37715648800008],
  [14.257781669000053, 122.3773171360001],
  [14.257894496000063, 122.37750593500004],
  [14.258026812000026, 122.377602367],
  [14.258007313000064, 122.37772284700009],
  [14.25783179800004, 122.37801408900009],
  [14.25773050500004, 122.37830322500008],
  [14.257559169000046, 122.37851602900002],
  [14.257321536000063, 122.3791384640001],
  [14.25701007400005, 122.37933515600002],
  [14.257157927000037, 122.3795360150001],
  [14.257375911000054, 122.37952805400005],
  [14.257718515000022, 122.37932334000004],
  [14.257999509000058, 122.37911057300005],
  [14.258256451000022, 122.37899418600011],
  [14.259047399000053, 122.37902044100008],
  [14.259680677000063, 122.37899020700002],
  [14.25987979000007, 122.37892821600008],
  [14.260209879000058, 122.37891976500009],
  [14.260669259000053, 122.37903546600012],
  [14.260860097000034, 122.37891141700004],
  [14.261183784000025, 122.37890082400008],
  [14.261364196000045, 122.3789565190001],
  [14.261726703000022, 122.3789605180001],
  [14.261862019000034, 122.37908978900009],
  [14.262197099000048, 122.37920845000008],
  [14.262260870000034, 122.37940605500012],
  [14.262445969000055, 122.37944892700011],
  [14.262548149000054, 122.37928759800002],
  [14.26255141200005, 122.37905708300002],
  [14.262612097000044, 122.37891220800009],
  [14.262938362000057, 122.3787892470001],
  [14.26313619900003, 122.37892432900003],
  [14.263397908000059, 122.37892770700012],
  [14.263519162000023, 122.37900678100004],
  [14.263650039000026, 122.37894096200012],
  [14.263672450000058, 122.37871045100007],
  [14.26381612800003, 122.37852608500009],
  [14.263974138000037, 122.37854560200003],
  [14.264136381000071, 122.37870735800004],
  [14.26441993800006, 122.37853833500003],
  [14.264769238000042, 122.37856587300007],
  [14.264979684000025, 122.37846310100008],
  [14.265308439000023, 122.37814474600009],
  [14.265554315000031, 122.37806712400004],
  [14.26572713400003, 122.37783807400001],
  [14.266105716000027, 122.37785730900009],
  [14.266276767000022, 122.3778331100001],
  [14.266715227000077, 122.37803911300011],
  [14.26673115400007, 122.37819562300001],
  [14.266940032000036, 122.37822661200005],
  [14.26723828400003, 122.37841512300008],
  [14.267663035000055, 122.37862867600006],
  [14.267725076000033, 122.37852728500002],
  [14.268093522000072, 122.37858576300005],
  [14.268225337000047, 122.37855835100004],
  [14.268252804000042, 122.37828865400002],
  [14.268476405000058, 122.37848340100004],
  [14.26883496000005, 122.37823628400008],
  [14.268964507000021, 122.37821817100007],
  [14.269255625000028, 122.37830250900004],
  [14.269356483000024, 122.37848747800001],
  [14.269633977000069, 122.37832176300003],
  [14.269694723000043, 122.37838697600012],
  [14.269645699000023, 122.378708071],
  [14.269860065000046, 122.3785363500001],
  [14.269883377000042, 122.37871804400004],
  [14.27007584300003, 122.3786476460001],
  [14.270344626000053, 122.3788716140001],
  [14.270526534000055, 122.37885605100007],
  [14.270702654000047, 122.37897973000008],
  [14.270669992000023, 122.37918061700009],
  [14.270900707000067, 122.37914981600011],
  [14.271011221000037, 122.3793032530001],
  [14.27114924600005, 122.37912100900007],
  [14.271368979000044, 122.37916063400007],
  [14.271352353000054, 122.37936510600002],
  [14.271648822000031, 122.3795869270001],
  [14.271820481000077, 122.37942853900006],
  [14.271981175000064, 122.37941444700004],
  [14.272188302000075, 122.37954734200002],
  [14.272319322000044, 122.37977271800003],
  [14.272422635000055, 122.37982979500009],
  [14.272693311000069, 122.3798352020001],
  [14.272943302000044, 122.38002343300002],
  [14.273318836000044, 122.38021105700011],
  [14.273297828000068, 122.3805027950001],
  [14.273407320000047, 122.38059230300007],
  [14.273512273000051, 122.38036540900009],
  [14.273799402000066, 122.38037368700009],
  [14.273973237000064, 122.38043279900012],
  [14.27418920100007, 122.3804255020001],
  [14.27451005000006, 122.380314526],
  [14.27469000700006, 122.38032041100007],
  [14.27505930600006, 122.38069777100009],
  [14.275353429000063, 122.38071248300002],
  [14.275729597000065, 122.38112900800002],
  [14.275791750000053, 122.38134317400011],
  [14.27573570900006, 122.38162111300005],
  [14.275779225000065, 122.3818211470001],
  [14.275866842000028, 122.38185074900002],
  [14.27576233800005, 122.38209973000005],
  [14.27577881700006, 122.38222599200003],
  [14.275673599000072, 122.38249818300005],
  [14.275439709000068, 122.38275020600008],
  [14.275310120000029, 122.38277103200005],
  [14.275201140000036, 122.38299533000009],
  [14.275427941000032, 122.38312706300007],
  [14.275303081000061, 122.38332394100007],
  [14.275437687000021, 122.38334644000008],
  [14.275584533000028, 122.38357132300007],
  [14.275776278000023, 122.38397464600007],
  [14.275732486000038, 122.38410350400011],
  [14.275845314000037, 122.38421552600005],
  [14.275826550000033, 122.38449353200008],
  [14.275963725000054, 122.3845179010001],
  [14.27620287700006, 122.38412836400005],
  [14.27672453300005, 122.38364704900005],
  [14.276979506000032, 122.38363407800011],
  [14.27716583800003, 122.3837497500001],
  [14.277205191000064, 122.38397082200004],
  [14.277334782000025, 122.38394999600007],
  [14.277382319000026, 122.38377624300006],
  [14.277578123000069, 122.38382737900008],
  [14.27766912800007, 122.38371103200006],
  [14.278145025000072, 122.38357077500007],
  [14.27837852700003, 122.38354547300003],
  [14.278541821000033, 122.38365828000008],
  [14.278572197000074, 122.383785525],
  [14.278797505000057, 122.383582337],
  [14.279009372000075, 122.3836094940001],
  [14.279139959000076, 122.38339297000005],
  [14.279343385000061, 122.3834357610001],
  [14.279446734000032, 122.38336608700001],
  [14.279497133000064, 122.38318251100009],
  [14.279604652000046, 122.38311222300001],
  [14.279909195000073, 122.38319231300011],
  [14.279926467000053, 122.38298255500001],
  [14.280019796000033, 122.382901865],
  [14.28019862800005, 122.38301921000004],
  [14.280261460000077, 122.38290043800009],
  [14.280235794000077, 122.38272397900005],
  [14.280599365000057, 122.38293660900001],
  [14.280632754000067, 122.38280647800002],
  [14.280926330000057, 122.3827502360001],
  [14.281126633000042, 122.38281327100003],
  [14.281401411000047, 122.38308597700006],
  [14.281468233000055, 122.3827436250001],
  [14.281375052000044, 122.38261251800009],
  [14.281471689000057, 122.38250185200002],
  [14.281808880000028, 122.38248257900011],
  [14.282143498000039, 122.38255575500011],
  [14.282360690000075, 122.38264927000012],
  [14.282462898000063, 122.38279856400004],
  [14.282656820000057, 122.38278724700001],
  [14.282940556000028, 122.38286059100005],
  [14.283091781000053, 122.38284357000009],
  [14.28324155200005, 122.3829253130001],
  [14.283310320000055, 122.38318418300003],
  [14.283440086000041, 122.382953283],
  [14.28371915100007, 122.38303759100006],
  [14.283962121000059, 122.3831927980001],
  [14.283959510000045, 122.38301050100006],
  [14.284088324000038, 122.382978234],
  [14.28418123800003, 122.3831065710001],
  [14.284316470000022, 122.38303336800004],
  [14.284476382000037, 122.383110342],
  [14.284623515000021, 122.38309625700003],
  [14.28473751000007, 122.38323122700001],
  [14.284879302000036, 122.38322723700003],
  [14.285097375000078, 122.38335269600009],
  [14.285196661000043, 122.38349510500007],
  [14.28516707700004, 122.38365664800006],
  [14.285422047000054, 122.38355392800008],
  [14.285398686000065, 122.38383166500012],
  [14.285288861000026, 122.38400566400003],
  [14.285325113000056, 122.38413877300002],
  [14.285561785000027, 122.3839602270001],
  [14.28569384900004, 122.38377779200005],
  [14.285868402000062, 122.3836926680001],
  [14.285996999000076, 122.38378083100008],
  [14.285816710000063, 122.38400162000005],
  [14.286060955000039, 122.3840632030001],
  [14.286096584000063, 122.38423825300004],
  [14.286225513000034, 122.38430077400005],
  [14.286578976000044, 122.3842279050001],
  [14.286956624000027, 122.38439486300001],
  [14.287057448000041, 122.38475790000007],
  [14.287341192000042, 122.38485943500007],
  [14.287373904000049, 122.38498252600004],
  [14.287608200000022, 122.38501678000011],
  [14.28784028800004, 122.38558508900007],
  [14.288050385000076, 122.38618728800009],
  [14.287807534000024, 122.38646781800003],
  [14.287694589000068, 122.38668724700005],
  [14.287673889000075, 122.38684947800004],
  [14.287990664000063, 122.3870277640001],
  [14.288081503000058, 122.38718273200004],
  [14.28820818500003, 122.38720077100004],
  [14.288253952000048, 122.38750522700002],
  [14.288067628000022, 122.38750104200005],
  [14.287852850000036, 122.38738794300002],
  [14.287589984000022, 122.3871232670001],
  [14.287436023000055, 122.38706409600002],
  [14.287266223000074, 122.38738817500007],
  [14.286985793000042, 122.3872699850001],
  [14.286770196000077, 122.3873145010001],
  [14.286698198000067, 122.38764824700002],
  [14.286559054000065, 122.38775580800007],
  [14.286373699000023, 122.38756564200003],
  [14.28623736000003, 122.38764211800003],
  [14.285894660000054, 122.38745580700004],
  [14.285860402000026, 122.38758171200004],
  [14.286046463000048, 122.38763633200006],
  [14.286028767000062, 122.38780803500003],
  [14.286223139000072, 122.38802662300009],
  [14.286126414000023, 122.38812223200011],
  [14.285897144000046, 122.38815248500009],
  [14.285868683000047, 122.38833831300008],
  [14.285984238000026, 122.38843982900005],
  [14.285845849000054, 122.38867072000005],
  [14.286039555000059, 122.388723808],
  [14.28604811200006, 122.38884049100011],
  [14.28589351000005, 122.38890425600005],
  [14.285793573000035, 122.38873662900005],
  [14.28568159100007, 122.38882900200008],
  [14.285703836000039, 122.38895679400002],
  [14.28583014000003, 122.3890473350001],
  [14.285690190000025, 122.38919730400005],
  [14.28564308600005, 122.38944292400004],
  [14.285436422000032, 122.38953161800009],
  [14.285335730000043, 122.38950899300005],
  [14.28502055000007, 122.38917352300007],
  [14.284843915000067, 122.38906851800004],
  [14.284704340000076, 122.38917965000007],
  [14.28450913200004, 122.38912182600006],
  [14.284424362000038, 122.3892679380001],
  [14.28412792100005, 122.3892915130001],
  [14.283981727000025, 122.38950032800005],
  [14.284125456000027, 122.38976434100005],
  [14.284069140000042, 122.38992915400001],
  [14.283797398000047, 122.38990242700004],
  [14.283826673000021, 122.39019865700004],
  [14.283464560000027, 122.39022028900001],
  [14.283228309000037, 122.39041757300004],
  [14.283106480000072, 122.39034754500005],
  [14.282731785000067, 122.39081916000009],
  [14.282541946000038, 122.39090321600008],
  [14.281758507000063, 122.39085369300005],
  [14.281739820000041, 122.3907059390001],
  [14.282067299000062, 122.39041259800001],
  [14.281974650000052, 122.39032056100007],
  [14.281612538000047, 122.39046679400008],
  [14.281337455000028, 122.39069762500003],
  [14.280993946000024, 122.39107779000005],
  [14.280867347000026, 122.3913012700001],
  [14.28059476900006, 122.39189882900007],
  [14.280431919000023, 122.39217833600003],
  [14.280258924000066, 122.39258413200002],
  [14.279967285000055, 122.39299524000012],
  [14.279554714000028, 122.39342785100007],
  [14.279170495000074, 122.3937339360001],
  [14.279170352000051, 122.39412182],
  [14.279633240000067, 122.39416335100009],
  [14.279840599000067, 122.3943202370001],
  [14.279941840000049, 122.394651371],
  [14.279903405000027, 122.39564975000008],
  [14.279967129000056, 122.39656643700005],
  [14.28011592200005, 122.3973544380001],
  [14.280230672000073, 122.39769173700006],
  [14.280413119000059, 122.39798977600003],
  [14.280560130000026, 122.39834910400009],
  [14.281043926000052, 122.39918682000007],
  [14.281362181000077, 122.39958957600004],
  [14.281738209000025, 122.39998084800004],
  [14.28250526000005, 122.40059084800009],
  [14.28292073800003, 122.40078613300011],
  [14.283153778000042, 122.40086379800005],
  [14.283653451000077, 122.40111778100004],
  [14.284304004000035, 122.40154850500005],
  [14.284751321000044, 122.40188749800006],
  [14.285068006000074, 122.40225381800008],
  [14.28513418700004, 122.40243630400005],
  [14.285130296000034, 122.40335392100008],
  [14.285103003000074, 122.40411456800007],
  [14.285049702000038, 122.40474419500003],
  [14.284923347000074, 122.40523231300006],
  [14.28475826600004, 122.40545988300005],
  [14.28483983700005, 122.405595618],
  [14.284864197000047, 122.40579464100006],
  [14.284881907000056, 122.40665349500011],
  [14.284969751000062, 122.4076027000001],
  [14.285149657000034, 122.40866009500007],
  [14.285279295000066, 122.4090815830001],
  [14.285441565000042, 122.40946898100003],
  [14.285641449000025, 122.4098475940001],
  [14.285689258000048, 122.41025689900005],
  [14.285814851000055, 122.4106062950001],
  [14.286012757000037, 122.41100904500001],
  [14.286243291000062, 122.41130997300002],
  [14.286508841000057, 122.4117235760001],
  [14.286645657000065, 122.41204492300005],
  [14.28702041400004, 122.41265499800011],
  [14.286979013000064, 122.41277968200006],
  [14.28714265700006, 122.41289908600004],
  [14.287251476000051, 122.41271391600003],
  [14.28744398300006, 122.41266881600006],
  [14.287373699000057, 122.41244352400008],
  [14.287657394000064, 122.41233848700006],
  [14.287566557000048, 122.41258124800004],
  [14.287703187000034, 122.41269359400007],
  [14.287765105000062, 122.41248528100004],
  [14.287907158000053, 122.41236838400005],
  [14.288220530000046, 122.41233492700007],
  [14.288379058000032, 122.41259609000008],
  [14.288717826000038, 122.41267372200002],
  [14.288818735000063, 122.41277189400012],
  [14.288810420000061, 122.41296439700011],
  [14.288935992000063, 122.413124863],
  [14.288954739000076, 122.4133047570001],
  [14.28912689200007, 122.41336675600007],
  [14.289387562000059, 122.41316716800009],
  [14.289346117000036, 122.41302117300006],
  [14.289569823000022, 122.41303210600006],
  [14.289446481000027, 122.41335354500006],
  [14.289618699000073, 122.41351390900002],
  [14.28969230000007, 122.41331914000011],
  [14.289898275000041, 122.41354684400005],
  [14.289973774000032, 122.41333367400011],
  [14.29018866000007, 122.41326113000002],
  [14.29033217500006, 122.4133501020001],
  [14.290123244000029, 122.4137214750001],
  [14.290206269000066, 122.41391787900011],
  [14.290372988000058, 122.41403045800007],
  [14.290510677000043, 122.41380051300007],
  [14.290727494000066, 122.41366364800001],
  [14.290858233000051, 122.41376239800002],
  [14.291154437000046, 122.4134742230001],
  [14.291207502000077, 122.41359226700001],
  [14.291360442000041, 122.41348429100003],
  [14.291481046000058, 122.41353794000008],
  [14.291890714000033, 122.41331782600002],
  [14.292006322000077, 122.41358009400005],
  [14.292127166000057, 122.41342909100001],
  [14.292241696000076, 122.41344885900003],
  [14.292425680000065, 122.41370607100009],
  [14.292231343000026, 122.41378526100004],
  [14.292236898000056, 122.41387474500004],
  [14.292490671000053, 122.41388557400012],
  [14.292748903000074, 122.41395434700007],
  [14.29304010900006, 122.41409137900007],
  [14.29320602200005, 122.41403678900008],
  [14.29345776200006, 122.4141264640001],
  [14.293545644000062, 122.4142221950001],
  [14.293498490000047, 122.41442711000002],
  [14.293567198000062, 122.4145522010001],
  [14.293425539000054, 122.414621237],
  [14.293435413000054, 122.41476022800009],
  [14.293750443000022, 122.41481891],
  [14.293776842000057, 122.414997663],
  [14.293964697000035, 122.41502848800008],
  [14.293948643000022, 122.41518406500006],
  [14.293772489000048, 122.41512676100001],
  [14.293639404000032, 122.41496497500009],
  [14.29351580100007, 122.41507634000004],
  [14.293600685000058, 122.41534019000005],
  [14.293735022000021, 122.41542899500007],
  [14.294134505000045, 122.41552102300011],
  [14.294143198000029, 122.41569666500004],
  [14.294277398000077, 122.41574562700009],
  [14.294535722000035, 122.4156070470001],
  [14.294844796000064, 122.41574776200002],
  [14.294896765000033, 122.41557829400006],
  [14.294878099000073, 122.41530936900006],
  [14.295025807000059, 122.41531738900005],
  [14.294973048000031, 122.41557162200002],
  [14.29510318000007, 122.41555718300003],
  [14.29514929100003, 122.41538022800012],
  [14.295388914000057, 122.41529433800008],
  [14.295469113000024, 122.41518545200006],
  [14.295434813000043, 122.41500376700003],
  [14.295675077000055, 122.4145509970001],
  [14.295730173000038, 122.41436407900005],
  [14.295837994000067, 122.41424454300011],
  [14.295993624000062, 122.41426575100002],
  [14.296101173000068, 122.41408459800004],
  [14.296054942000069, 122.4137743230001],
  [14.296402241000067, 122.41384349500004],
  [14.296389158000068, 122.41359683400003],
  [14.296440112000028, 122.41343757100003],
  [14.296631671000057, 122.41345613500005],
  [14.296600948000048, 122.41328706800005],
  [14.296745944000065, 122.4131033430001],
  [14.296996393000029, 122.41308456100012],
  [14.297142731000065, 122.41300454000009],
  [14.297296677000077, 122.41320868800005],
  [14.297183947000065, 122.41331311300007],
  [14.29703029500007, 122.41321504000007],
  [14.296956869000041, 122.413475392],
  [14.297038913000051, 122.41352831600011],
  [14.297333905000073, 122.4135325630001],
  [14.297481125000047, 122.41377076700007],
  [14.297662173000049, 122.41384451300007],
  [14.29777652000007, 122.41378665800005],
  [14.297601462000046, 122.41359261700006],
  [14.297645397000053, 122.41336133400011],
  [14.297587731000021, 122.41322184500007],
  [14.297626336000064, 122.41290215900005],
  [14.29777107600006, 122.41293877500004],
  [14.297829324000077, 122.4127943960001],
  [14.29814414200007, 122.4128165620001],
  [14.298316224000075, 122.4126971170001],
  [14.298439742000028, 122.4128351280001],
  [14.298736388000066, 122.41285446900008],
  [14.29881488600006, 122.4132607570001],
  [14.298752485000023, 122.41338314100005],
  [14.29907855700003, 122.41369118400007],
  [14.29893942900003, 122.41397324400009],
  [14.29911553100004, 122.4142095420001],
  [14.29923558400003, 122.41414734900002],
  [14.299254869000038, 122.41391325500001],
  [14.299408827000036, 122.41393938700003],
  [14.299737458000038, 122.41411051000011],
  [14.299824658000034, 122.41429431100005],
  [14.300036187000046, 122.41428522600006],
  [14.300277911000023, 122.41401723500007],
  [14.300435322000055, 122.41400130400007],
  [14.300672643000041, 122.41420609200009],
  [14.300727218000077, 122.4144024740001],
  [14.300898758000073, 122.41463434900004],
  [14.300859380000077, 122.41480514000011],
  [14.300322393000044, 122.41464554800007],
  [14.300364697000077, 122.41487650400006],
  [14.30077082300005, 122.41500554800007],
  [14.300647929000036, 122.4151339340001],
  [14.300749928000073, 122.41525021300004],
  [14.300992588000042, 122.41515025600006],
  [14.30114043900005, 122.41517638200003],
  [14.30129647700005, 122.41497979600001],
  [14.301807762000067, 122.41533828400009],
  [14.302031041000077, 122.41535024900008],
  [14.302189051000028, 122.41521045600007],
  [14.302251494000075, 122.41506914100012],
  [14.302597150000054, 122.41500834200008],
  [14.302311571000075, 122.4145912570001],
  [14.302417614000035, 122.41454184200006],
  [14.302861113000063, 122.41452352700003],
  [14.303192186000047, 122.41457819800007],
  [14.303331009000033, 122.41468632200008],
  [14.303382588000034, 122.41490779700007],
  [14.303545082000028, 122.41493370100011],
  [14.303752893000024, 122.41485704900003],
  [14.30385100500007, 122.41503974400007],
  [14.303999693000037, 122.41504145400006],
  [14.304130131000022, 122.41528295800003],
  [14.304106391000062, 122.41554736500007],
  [14.304031981000037, 122.41576011500001],
  [14.30419801100004, 122.41591662500002],
  [14.30392112900006, 122.4160962410001],
  [14.304012297000043, 122.4162225770001],
  [14.303853649000075, 122.41678418700008],
  [14.30398228000007, 122.41689781500008],
  [14.304083738000031, 122.41674583000008],
  [14.304291328000033, 122.41628965300004],
  [14.304488417000073, 122.41607648500008],
  [14.30466595200005, 122.41601845100001],
  [14.30481952200006, 122.41610336300005],
  [14.305007016000047, 122.41636099200002],
  [14.305269672000065, 122.41664986700005],
  [14.305307052000046, 122.41686466400006],
  [14.305488547000039, 122.41699989500012],
  [14.305648727000062, 122.41704743800005],
  [14.305776445000049, 122.41686407400005],
  [14.306037261000029, 122.41678369600004],
  [14.306194907000076, 122.41679955400002],
  [14.306404719000057, 122.41667511100002],
  [14.306693026000062, 122.41667891100008],
  [14.306448922000072, 122.41706579200002],
  [14.306704659000047, 122.41699249500004],
  [14.306923403000042, 122.417092378],
  [14.306975488000035, 122.41736293800011],
  [14.307228167000062, 122.41763720800009],
  [14.307203164000043, 122.41780723900001],
  [14.30707387600006, 122.41787326800011],
  [14.306908763000024, 122.41775022400009],
  [14.306849760000034, 122.41794637600003],
  [14.307153773000039, 122.41802618000008],
  [14.30735110300003, 122.41829891600003],
  [14.307239999000046, 122.41874693900002],
  [14.307327416000021, 122.41882964400008],
  [14.307478479000054, 122.4186982220001],
  [14.307642145000045, 122.41884575000006],
  [14.307890245000067, 122.41870952300008],
  [14.30828109600003, 122.41879782800004],
  [14.308544677000043, 122.41865277200009],
  [14.308504669000058, 122.41884961900007],
  [14.308553781000057, 122.41894956500005],
  [14.308764886000063, 122.41895796600011],
  [14.308920998000076, 122.41915559100005],
  [14.309115791000067, 122.41925468400007],
  [14.309131509000053, 122.41905605200009],
  [14.30952220000006, 122.41878506600005],
  [14.309758466000062, 122.41895330800003],
  [14.310049680000077, 122.41892461600003],
  [14.310201145000065, 122.41895649700007],
  [14.310457087000032, 122.41862731000003],
  [14.310578287000055, 122.41858997500003],
  [14.310608818000048, 122.41836812300005],
  [14.310800757000038, 122.4182900730001],
  [14.310970109000039, 122.41806178600007],
  [14.311020365000047, 122.41789342200002],
  [14.311402220000048, 122.417582605],
  [14.311217483000064, 122.41736551000008],
  [14.311352044000046, 122.41723924700011],
  [14.311447311000052, 122.417397047],
  [14.311607870000046, 122.41742200500005],
  [14.311575060000052, 122.41722511700004],
  [14.311396338000065, 122.416982974],
  [14.311460405000048, 122.41689589500004],
  [14.311784302000035, 122.4169555200001],
  [14.311932454000043, 122.41702648100011],
  [14.311975575000076, 122.41715082700011],
  [14.312285068000051, 122.41688128900012],
  [14.312158952000061, 122.41670861300008],
  [14.312077540000075, 122.41683158900003],
  [14.311893310000073, 122.41682756700004],
  [14.311772294000036, 122.41671561400005],
  [14.311576264000053, 122.41670252100005],
  [14.311536836000073, 122.41658259300004],
  [14.311378295000054, 122.41648393300011],
  [14.311375189000046, 122.4163729920001],
  [14.311214139000072, 122.4161961850001],
  [14.311298111000042, 122.41611585200008],
  [14.311448325000072, 122.41617668000004],
  [14.311439084000028, 122.4159449660001],
  [14.311725328000023, 122.41610326300008],
  [14.31182650200003, 122.41611304100002],
  [14.311878514000057, 122.41579971300007],
  [14.312053427000023, 122.41585402800001],
  [14.312201797000057, 122.41557834100001],
  [14.312167085000056, 122.41507392500012],
  [14.312042407000035, 122.41485655800011],
  [14.31213583400006, 122.4146879760001],
  [14.31216457100004, 122.41447209900002],
  [14.312425872000063, 122.41439855700003],
  [14.312609688000066, 122.41446335600006],
  [14.312694801000077, 122.41464047600005],
  [14.312834114000054, 122.41471477800008],
  [14.31313418700006, 122.41503056700003],
  [14.31322478800007, 122.41521312000009],
  [14.313475977000053, 122.4153964840001],
  [14.313671322000062, 122.41547335900009],
  [14.313887562000048, 122.41569156100002],
  [14.314229700000055, 122.415825358],
  [14.31454198800003, 122.41608408000002],
  [14.314738764000026, 122.41650132500001],
  [14.314842055000042, 122.41665689100012],
  [14.314795917000026, 122.4168003640001],
  [14.314549082000042, 122.41697714400004],
  [14.314557654000055, 122.41715234000003],
  [14.314740924000034, 122.41722792400003],
  [14.314704780000056, 122.4174987770001],
  [14.314563279000026, 122.41761368300001],
  [14.31486790300005, 122.4176785730001],
  [14.315028226000038, 122.41792648400008],
  [14.315180851000036, 122.41805589300009],
  [14.315346802000022, 122.41794769000012],
  [14.315520468000045, 122.41801710300001],
  [14.315534521000075, 122.41812100200002],
  [14.315371241000037, 122.41830017500001],
  [14.315600033000067, 122.41850747900003],
  [14.315758919000075, 122.41853893200005],
  [14.316249038000024, 122.41851938200011],
  [14.31631838800007, 122.41880558200012],
  [14.316461521000065, 122.41882786700012],
  [14.316518707000057, 122.41910736700004],
  [14.316408859000035, 122.41919759000007],
  [14.316515425000034, 122.41933423300009],
  [14.316467694000039, 122.41948168800002],
  [14.316666995000048, 122.41974901700007],
  [14.31667496800003, 122.41996743900006],
  [14.31644912400003, 122.42018800200003],
  [14.31631959200007, 122.4200998340001],
  [14.316255923000028, 122.42026832200008],
  [14.316019520000054, 122.42024096000011],
  [14.315867814000057, 122.42047278600012],
  [14.315714064000076, 122.42031650800004],
  [14.315550519000055, 122.42051998600004],
  [14.31569126100004, 122.42077152200011],
  [14.315383927000028, 122.42092306000006],
  [14.315145165000047, 122.42125087200009],
  [14.31525386100003, 122.42134962800003],
  [14.31537146100004, 122.42130882100003],
  [14.315421853000032, 122.421472977],
  [14.315742191000027, 122.42146370400008],
  [14.315716989000066, 122.42177823500003],
  [14.315519273000064, 122.42203142400001],
  [14.315525039000022, 122.42226814300011],
  [14.315237832000037, 122.42229141900009],
  [14.31528312000006, 122.42260869600011],
  [14.314855850000072, 122.42245808300004],
  [14.31472947900005, 122.42273706100002],
  [14.314609830000052, 122.42282280500001],
  [14.314272522000067, 122.42272084300009],
  [14.314179121000052, 122.42253362200006],
  [14.313837220000039, 122.42258892100006],
  [14.313797891000036, 122.42277583600003],
  [14.313556615000039, 122.42273717500007],
  [14.31349702700004, 122.42261771800008],
  [14.31317901400007, 122.42291453900009],
  [14.31299408700005, 122.4228130780001],
  [14.312839986000029, 122.42262354700006],
  [14.312710457000037, 122.42274318600005],
  [14.312532433000058, 122.42253563200006],
  [14.312437165000063, 122.42233946600004],
  [14.312171040000067, 122.42241263800008],
  [14.312036042000045, 122.42237342100009],
  [14.311980912000024, 122.42220716500003],
  [14.311808675000066, 122.4221608260001],
  [14.311684224000032, 122.4222800330001],
  [14.311519177000037, 122.42224863600006],
  [14.311232460000042, 122.42234570700009],
  [14.310753977000047, 122.42246112100008],
  [14.310541982000075, 122.4224178720001],
  [14.310287132000042, 122.42274735400008],
  [14.310171948000061, 122.42276449000008],
  [14.30996755600006, 122.42259820900006],
  [14.309558888000026, 122.42260565100003],
  [14.309366615000044, 122.42275908600004],
  [14.309134850000078, 122.4228272360001],
  [14.309026703000029, 122.42276570100012],
  [14.308837022000034, 122.42281024400006],
  [14.308766193000054, 122.42298033200007],
  [14.308601054000064, 122.42299224600004],
  [14.308486646000063, 122.42329001200005],
  [14.308227171000055, 122.42324795700006],
  [14.308090058000062, 122.4233607540001],
  [14.307840929000065, 122.42326915000001],
  [14.307582914000022, 122.42344690300001],
  [14.307576618000041, 122.42364465300011],
  [14.307293597000069, 122.4235581260001],
  [14.306761739000024, 122.42372739100006],
  [14.306724681000048, 122.42389057200012],
  [14.30652845000003, 122.42380083000012],
  [14.306507641000053, 122.42408034400012],
  [14.306325574000027, 122.4242105940001],
  [14.306208082000069, 122.42409604300008],
  [14.305971338000063, 122.42407540900001],
  [14.305900305000023, 122.42396390300007],
  [14.305911508000065, 122.42375252000011],
  [14.305759852000051, 122.42379213200002],
  [14.30574621900007, 122.42393892500002],
  [14.305628144000025, 122.42413053000007],
  [14.305625834000068, 122.42444025100008],
  [14.305816905000029, 122.42475765300003],
  [14.305698177000068, 122.4250228410001],
  [14.305507868000063, 122.42501017400002],
  [14.30550682300003, 122.42513746100008],
  [14.305626966000034, 122.42527865300008],
  [14.30547075100003, 122.42541277200007],
  [14.30562216900006, 122.4254888270001],
  [14.305778067000062, 122.42530773200008],
  [14.305948620000038, 122.42545947000008],
  [14.305636200000038, 122.42558940300012],
  [14.305606822000072, 122.42584799500003],
  [14.305247444000031, 122.42583492000006],
  [14.305178711000053, 122.4259661970001],
  [14.30499982300006, 122.42587239600005],
  [14.30483176000007, 122.42599674300004],
  [14.304930728000045, 122.4261462820001],
  [14.304818086000068, 122.42628855500004],
  [14.304660822000073, 122.42632183300009],
  [14.304553837000071, 122.42664467800012],
  [14.304423586000041, 122.42670663500007],
  [14.304348990000051, 122.4268650460001],
  [14.30405857200003, 122.42710407700008],
  [14.304125978000059, 122.42718421300003],
  [14.30383352900003, 122.4273892870001],
  [14.304190877000053, 122.42753029100004],
  [14.304201479000028, 122.42774912200002],
  [14.303999574000045, 122.42783117700003],
  [14.303974063000055, 122.42798461200005],
  [14.30380389100003, 122.42831808400001],
  [14.30361330200003, 122.42837113000007],
  [14.30345042600004, 122.42823349100001],
  [14.30313751500006, 122.42843523500005],
  [14.302979638000068, 122.4288179990001],
  [14.302915315000064, 122.4291857180001],
  [14.302681049000057, 122.42935632600006],
  [14.302527251000072, 122.4296227960001],
  [14.302420846000075, 122.42971014500006],
  [14.302220749000071, 122.42970110700003],
  [14.30197671700006, 122.42989003800005],
  [14.301926691000062, 122.4297724160001],
  [14.301761275000047, 122.42971138300004],
  [14.301757655000074, 122.42946770300011],
  [14.30159211800003, 122.4292334700001],
  [14.301377754000043, 122.42904004900004],
  [14.301402640000049, 122.4289091070001],
  [14.301281295000024, 122.42879246200005],
  [14.301053550000063, 122.42899388700005],
  [14.30095749700007, 122.42882886200005],
  [14.300589596000066, 122.4291035660001],
  [14.300420926000072, 122.42917846700004],
  [14.300294489000066, 122.4293857340001],
  [14.300342022000052, 122.42952308000008],
  [14.300222882000071, 122.42965507400004],
  [14.300489178000078, 122.42979095700002],
  [14.300429096000073, 122.429917552],
  [14.300099071000034, 122.42992796100009],
  [14.299951985000064, 122.42984662100002],
  [14.299715814000024, 122.42958296000006],
  [14.299333621000073, 122.42989591300011],
  [14.299432223000053, 122.43012223500011],
  [14.299587510000038, 122.43022568300012],
  [14.299727602000075, 122.43041700300012],
  [14.300044102000072, 122.43058253700008],
  [14.300308184000073, 122.43049238200001],
  [14.30037227300005, 122.43063496000002],
  [14.300729588000024, 122.43045619500003],
  [14.300917241000036, 122.43052318500008],
  [14.301042566000035, 122.43069877700009],
  [14.301269752000053, 122.43138684000007],
  [14.301441454000042, 122.43162701800009],
  [14.30144714100004, 122.43175907300008],
  [14.301687205000064, 122.4321692960001],
  [14.301759253000057, 122.432206],
  [14.302875853000046, 122.43403161000003],
  [14.30317427500006, 122.43446006900001],
  [14.303518707000023, 122.43458063000003],
  [14.303694169000039, 122.434507488],
  [14.303922571000044, 122.43490108200001],
  [14.304063499000051, 122.43489192900006],
  [14.304148492000024, 122.43460509500005],
  [14.304321647000052, 122.4345365260001],
  [14.304683405000048, 122.43465395200008],
  [14.305001831000027, 122.43481383800008],
  [14.305184877000045, 122.43482615200003],
  [14.305167437000023, 122.4346575080001],
  [14.305345577000026, 122.4346974130001],
  [14.305392304000065, 122.4345690560001],
  [14.30554378200003, 122.4345992860001],
  [14.305503909000038, 122.43485921000001],
  [14.305856922000032, 122.43490791300007],
  [14.30574619400005, 122.43468131400004],
  [14.30605833800007, 122.43443565200005],
  [14.306346584000039, 122.43471527400004],
  [14.306333300000063, 122.43452575000003],
  [14.306746062000059, 122.43427035600007],
  [14.306851120000033, 122.43435060400009],
  [14.306971599000065, 122.43417045100011],
  [14.307070629000066, 122.43389592400001],
  [14.30727212000005, 122.43380445600008],
  [14.307510756000056, 122.43385044500008],
  [14.307666810000057, 122.43377545400006],
  [14.308023881000054, 122.43404761400006],
  [14.308430910000027, 122.43411992300003],
  [14.308699255000022, 122.4337318040001],
  [14.308829598000045, 122.43367613200007],
  [14.308897923000075, 122.43379401300001],
  [14.309547649000024, 122.43414017500004],
  [14.309692462000044, 122.43418092700006],
  [14.309788642000058, 122.43406631700009],
  [14.310056160000045, 122.43396403800011],
  [14.310207708000064, 122.43397735600001],
  [14.310401522000063, 122.43389849200003],
  [14.310418062000053, 122.43370695200008],
  [14.310564291000048, 122.43351660400003],
  [14.310598646000074, 122.43334153900003],
  [14.311201405000077, 122.43254022500003],
  [14.31143697500005, 122.43255407400011],
  [14.31161297400007, 122.43229784700009],
  [14.311840876000076, 122.4320925510001],
  [14.311945421000075, 122.43187114200009],
  [14.312082856000075, 122.43189463400006],
  [14.312368433000074, 122.4317850220001],
  [14.312570305000065, 122.43230607300006],
  [14.312672368000051, 122.43224883000005],
  [14.312738247000027, 122.43257654100012],
  [14.31288087400003, 122.43245665200004],
  [14.313059200000055, 122.432440358],
  [14.313238907000027, 122.43262966800012],
  [14.313460177000024, 122.43265596000003],
  [14.313697614000034, 122.43275445100005],
  [14.313883453000074, 122.43304932600006],
  [14.314121551000028, 122.43297892300006],
  [14.314210953000043, 122.43283380700007],
  [14.314415752000059, 122.43272486800004],
  [14.314687827000057, 122.4323020710001],
  [14.314702242000067, 122.43205482000008],
  [14.314959916000078, 122.431895327],
  [14.314996077000046, 122.43179735000001],
  [14.315242123000075, 122.43180943900006],
  [14.315149805000033, 122.4319705040001],
  [14.31529081800005, 122.43212411500008],
  [14.315419490000068, 122.43206114000009],
  [14.315582453000047, 122.4322309800001],
  [14.315491210000062, 122.43235766900011],
  [14.315497769000046, 122.43253604900008],
  [14.315598048000027, 122.43284594500005],
  [14.315444361000061, 122.43299197700003],
  [14.315490470000043, 122.43329175300005],
  [14.31575192300005, 122.43378371600011],
  [14.31586053500007, 122.43457480200004],
  [14.316054027000064, 122.434879582],
  [14.31622019100007, 122.43475478900007],
  [14.31638781600003, 122.43511515800003],
  [14.316356850000034, 122.43541812500007],
  [14.316232962000072, 122.4356112700001],
  [14.316024604000063, 122.43574228500006],
  [14.316008899000053, 122.43588774200009],
  [14.315781194000067, 122.43609925900012],
  [14.315477964000024, 122.43688603200007],
  [14.31535943700004, 122.43746770500002],
  [14.315332798000043, 122.4378269130001],
  [14.315540567000028, 122.43802425700005],
  [14.315483287000063, 122.43817491700008],
  [14.31559207600003, 122.43824534200007],
  [14.315579267000032, 122.43846884200002],
  [14.315664782000056, 122.43861492200006],
  [14.315570539000078, 122.43874904100005],
  [14.31560786600005, 122.4392621930001],
  [14.315592753000033, 122.43945124600009],
  [14.315131896000025, 122.43962797400002],
  [14.315089428000022, 122.4398359810001],
  [14.315216650000025, 122.43987943400009],
  [14.315050171000053, 122.44008363700004],
  [14.315198230000021, 122.44012739700008],
  [14.315045489000056, 122.4403724980001],
  [14.314920570000027, 122.44036349200007],
  [14.314869935000047, 122.4407659530001],
  [14.314716573000055, 122.44085475000009],
  [14.314760675000059, 122.4410819530001],
  [14.314937212000075, 122.44092467100006],
  [14.31498681100004, 122.4411562570001],
  [14.31470104300007, 122.44127450700012],
  [14.314454498000032, 122.44121926100001],
  [14.314485901000069, 122.4413693360001],
  [14.314854250000053, 122.44134488100008],
  [14.314900351000063, 122.44170086800011],
  [14.31500457900006, 122.44193853600007],
  [14.315185580000048, 122.44210620600006],
  [14.315081128000031, 122.4424607950001],
  [14.315097384000069, 122.4426310230001],
  [14.314987104000068, 122.44273181900007],
  [14.314967208000041, 122.44297477100008],
  [14.31517156800004, 122.44284833100005],
  [14.315294309000024, 122.44309267200003],
  [14.315418560000069, 122.44312793000006],
  [14.315467614000056, 122.44296933200008],
  [14.315679148000072, 122.44312180600002],
  [14.315865282000061, 122.4431174340001],
  [14.315930598000023, 122.44297898900004],
  [14.315964632000032, 122.44268910800008],
  [14.316057502000035, 122.44270114400001],
  [14.316180140000029, 122.44310266900004],
  [14.316497230000039, 122.44284773000004],
  [14.316595466000024, 122.44257107600004],
  [14.316825618000053, 122.4427008770001],
  [14.317378144000031, 122.44283615400002],
  [14.317412317000048, 122.44308742100009],
  [14.317634273000067, 122.44314572000008],
  [14.318254920000072, 122.44309201600004],
  [14.318384839000032, 122.44310099100005],
  [14.319000919000075, 122.44329420500003],
  [14.319227798000043, 122.44344896100006],
  [14.319651350000072, 122.44345731300007],
  [14.31987444300006, 122.44350933600003],
  [14.319975923000072, 122.4434465490001],
  [14.320494662000044, 122.44346501400003],
  [14.320783432000042, 122.44354998100005],
  [14.320934683000075, 122.44337342600011],
  [14.321343007000053, 122.44346382000003],
  [14.321634248000066, 122.44367328600003],
  [14.321801728000025, 122.44404711400011],
  [14.321750520000023, 122.44413515100007],
  [14.321426132000056, 122.44417897100004],
  [14.32128291400005, 122.44454891600003],
  [14.320915598000056, 122.44501464200005],
  [14.320786141000042, 122.44523406100006],
  [14.320598509000035, 122.44537127600006],
  [14.320628320000026, 122.44552844800012],
  [14.320406810000065, 122.44558116300004],
  [14.320388112000046, 122.44573043000003],
  [14.320148560000064, 122.4458594570001],
  [14.319970030000036, 122.44605726300006],
  [14.319691679000073, 122.4461269310001],
  [14.31958836900003, 122.4460903480001],
  [14.31935525700004, 122.44622855500006],
  [14.319276340000044, 122.44640011800004],
  [14.319000390000042, 122.44650317700007],
  [14.318941840000036, 122.44669345900002],
  [14.31866787000007, 122.44731866300003],
  [14.318825106000077, 122.4475490430001],
  [14.318813454000065, 122.44769175700003],
  [14.318929668000067, 122.44788424100011],
  [14.318834838000043, 122.44805202600003],
  [14.318942632000073, 122.44828095000003],
  [14.319098824000037, 122.44840968800008],
  [14.319261951000044, 122.44865463200006],
  [14.319706702000076, 122.4485487070001],
  [14.319777196000075, 122.44869461200005],
  [14.31972389300006, 122.44888842500006],
  [14.319620831000066, 122.4489413340001],
  [14.319601290000037, 122.44921192700008],
  [14.319385388000057, 122.44952983400003],
  [14.31932701200003, 122.44979436300002],
  [14.31918085500007, 122.44982142800006],
  [14.318796840000061, 122.44979500500006],
  [14.318704874000048, 122.44985469400001],
  [14.31885077000004, 122.450115062],
  [14.318546514000047, 122.45018800500009],
  [14.318215636000048, 122.45006077900007],
  [14.31808823800003, 122.45016096900008],
  [14.318305787000043, 122.45033059700006],
  [14.318479495000076, 122.45060904900004],
  [14.318362056000069, 122.45133098600002],
  [14.31827086900006, 122.45157707500005],
  [14.318391976000044, 122.4518117770001],
  [14.318681748000074, 122.451773322],
  [14.318761058000064, 122.45192858100006],
  [14.318728344000021, 122.45213205800007],
  [14.318551976000037, 122.45260551600006],
  [14.318533088000038, 122.45290101400008],
  [14.318650303000027, 122.45307212800003],
  [14.318616012000064, 122.45327977500006],
  [14.31876419200006, 122.45341601700011],
  [14.318867337000029, 122.45330028300009],
  [14.319133298000054, 122.45328068900005],
  [14.31920232300007, 122.45357883400004],
  [14.31943426600003, 122.453734456],
  [14.319371893000039, 122.45385653500011],
  [14.319407173000059, 122.454081513],
  [14.319259143000068, 122.45413291700004],
  [14.319180654000036, 122.45425389800005],
  [14.319226265000054, 122.45445681400008],
  [14.318831266000075, 122.45468329500011],
  [14.31878245400003, 122.4547557730001],
  [14.318517514000064, 122.454756588],
  [14.318390610000051, 122.45498665600007],
  [14.318152306000059, 122.4551832410001],
  [14.31802996700003, 122.45517058900009],
  [14.317943319000051, 122.4553626930001],
  [14.31742584500006, 122.4556429370001],
  [14.317229049000048, 122.45589609600006],
  [14.31699022500004, 122.45595943800004],
  [14.316924495000023, 122.45610040500003],
  [14.317109444000039, 122.4565527960001],
  [14.317276907000064, 122.45650064600011],
  [14.317782433000048, 122.45661826000003],
  [14.317906939000068, 122.45657499700008],
  [14.317658722000033, 122.45627443100011],
  [14.317883686000073, 122.45619607700007],
  [14.318108779000056, 122.45634166700006],
  [14.318083468000054, 122.45655608900006],
  [14.31837616200005, 122.45655969600011],
  [14.318555355000058, 122.45667377100006],
  [14.318675690000077, 122.45690850900007],
  [14.318676476000064, 122.45720382900004],
  [14.318906640000023, 122.45716334200006],
  [14.319183187000021, 122.45690158800005],
  [14.31937855700005, 122.45696863700005],
  [14.319506052000065, 122.45681109600002],
  [14.320078289000037, 122.45699021000007],
  [14.320175493000022, 122.45711633400003],
  [14.320352502000048, 122.45718139700011],
  [14.320495427000026, 122.45741334500008],
  [14.320666438000046, 122.45747362400004],
  [14.320724644000052, 122.4577980790001],
  [14.320850797000048, 122.45792906500003],
  [14.321182141000065, 122.45788267300009],
  [14.321136485000068, 122.45746260900012],
  [14.321167625000044, 122.45723062800005],
  [14.321335649000048, 122.45714884300003],
  [14.32146162500004, 122.45721354800003],
  [14.321651802000076, 122.45689850200006],
  [14.321788556000058, 122.45638996900004],
  [14.32203935900003, 122.45599139400008],
  [14.321634427000049, 122.45592801400005],
  [14.321470744000067, 122.45608164700002],
  [14.321303684000043, 122.45594703100005],
  [14.321104943000023, 122.45595456500007],
  [14.320792682000047, 122.45606576600005],
  [14.320670455000027, 122.45615774300006],
  [14.320167793000053, 122.45632321700009],
  [14.320047079000062, 122.4561913440001],
  [14.320201767000071, 122.4560649870001],
  [14.320547084000054, 122.45600305200003],
  [14.320813992000069, 122.45591205500011],
  [14.321133796000026, 122.45586683300007],
  [14.321217885000067, 122.45576415500011],
  [14.321480081000061, 122.45581171000003],
  [14.321874377000029, 122.45567955500007],
  [14.321583977000046, 122.45555412800002],
  [14.321615388000055, 122.45531982800003],
  [14.321759448000023, 122.45514751200005],
  [14.322148896000044, 122.45498422900005],
  [14.322300231000042, 122.45499884900005],
  [14.322720039000046, 122.45492032400011],
  [14.323217749000037, 122.45477876400003],
  [14.323589257000037, 122.45470642700002],
  [14.32395679800004, 122.45470219300012],
  [14.32441915100003, 122.45479785300006],
  [14.324621118000039, 122.45479643300007],
  [14.324634727000046, 122.45470055600003],
  [14.32439228800007, 122.45468451500005],
  [14.324020611000037, 122.4544685950001],
  [14.324167295000052, 122.45428665800011],
  [14.324045562000038, 122.45412392800006],
  [14.323708328000066, 122.45403685600002],
  [14.323820254000054, 122.45385232800004],
  [14.324240711000073, 122.45371900200007],
  [14.324365477000072, 122.45401517500011],
  [14.324587798000039, 122.45378591400004],
  [14.324773656000048, 122.45390448],
  [14.325171018000049, 122.453983117],
  [14.325603714000067, 122.4540261730001],
  [14.325829469000041, 122.45419305400003],
  [14.32551158900003, 122.45424957900002],
  [14.325577276000047, 122.45437717200002],
  [14.32571826700007, 122.45441357600009],
  [14.326069884000049, 122.45465480000007],
  [14.326149524000073, 122.45493167300003],
  [14.326317760000052, 122.45497521300001],
  [14.326382323000075, 122.45483613800002],
  [14.326540077000061, 122.45495351700004],
  [14.326679816000023, 122.454957551],
  [14.326844782000023, 122.45473549500002],
  [14.327118890000065, 122.4547196200001],
  [14.327338015000066, 122.45502931100009],
  [14.327491828000063, 122.45516765600007],
  [14.327976105000062, 122.45529116600005],
  [14.328029663000052, 122.45552813100005],
  [14.328248169000062, 122.45562251700005],
  [14.328392191000034, 122.45576799300011],
  [14.328813464000064, 122.45572115900006],
  [14.328946508000058, 122.45557724800005],
  [14.328814045000058, 122.45546498200008],
  [14.328979373000038, 122.45527880500003],
  [14.329177795000021, 122.4552745950001],
  [14.32933115000003, 122.455370302],
  [14.329481790000045, 122.455613158],
  [14.329634933000023, 122.45570254800009],
  [14.32985198700004, 122.45596350900007],
  [14.33001220500006, 122.45608110800003],
  [14.330018895000023, 122.4562918690001],
  [14.32994167000004, 122.45641006400001],
  [14.329717973000072, 122.45654615],
  [14.32985929800003, 122.45664861400007],
  [14.329957303000072, 122.45684111100002],
  [14.330066396000063, 122.45671387200002],
  [14.330235034000054, 122.45671732100004],
  [14.330280754000057, 122.45684692600003],
  [14.330111832000057, 122.45693311100001],
  [14.330050449000055, 122.45711240500009],
  [14.330164142000058, 122.45730440500006],
  [14.330409218000057, 122.4574853790001],
  [14.330339502000072, 122.45769027100005],
  [14.329900760000044, 122.45762078200005],
  [14.32988811100006, 122.45770029500011],
  [14.330060269000057, 122.45789969200007],
  [14.330007102000025, 122.4580043740001],
  [14.32977261800005, 122.45796585700009],
  [14.329810474000055, 122.45781586600003],
  [14.329637140000045, 122.45753587000002],
  [14.329415503000064, 122.45741414400004],
  [14.329349997000065, 122.45756196100001],
  [14.329558464000058, 122.45779326200011],
  [14.32951386600007, 122.458016229],
  [14.329543655000066, 122.45817328600003],
  [14.329449160000024, 122.4583462600001],
  [14.329835367000044, 122.4584152440001],
  [14.329658847000076, 122.4585875890001],
  [14.32982434400003, 122.4587255560001],
  [14.329950102000055, 122.45898261000002],
  [14.330179261000069, 122.459097885],
  [14.330198133000067, 122.45928964200004],
  [14.33045028500004, 122.45941892400003],
  [14.330589025000052, 122.45961761500007],
  [14.330522624000025, 122.45995354000001],
  [14.330375262000075, 122.46000692100006],
  [14.330259549000061, 122.45989239500011],
  [14.330089948000023, 122.46007005000001],
  [14.329759308000064, 122.45981435400006],
  [14.329587969000045, 122.45984774500005],
  [14.329550337000057, 122.4600687840001],
  [14.329083541000045, 122.45993148600007],
  [14.32908477500007, 122.46024574500007],
  [14.328999288000034, 122.46038350600008],
  [14.329004776000033, 122.46054687500009],
  [14.328816453000059, 122.46076849500002],
  [14.328628089000063, 122.460757596],
  [14.32862405800006, 122.46096139600002],
  [14.328862272000038, 122.46083741400003],
  [14.328980013000034, 122.46091979500011],
  [14.32912275700005, 122.4607751740001],
  [14.329225725000072, 122.46083403000011],
  [14.329232818000037, 122.460998947],
  [14.328901262000045, 122.46102684700008],
  [14.328827602000047, 122.46111165200011],
  [14.328305258000057, 122.46133399700011],
  [14.328144179000049, 122.46145275700007],
  [14.327907762000052, 122.46144517900007],
  [14.32758784400005, 122.46131070000001],
  [14.327399563000029, 122.46116349200008],
  [14.327279614000076, 122.4613771060001],
  [14.327400416000046, 122.46147378500007],
  [14.327454558000056, 122.46163206500012],
  [14.327978108000025, 122.46209082000007],
  [14.32816555200003, 122.46209524800008],
  [14.328041494000047, 122.462364739],
  [14.328254607000076, 122.46233090200008],
  [14.328477244000055, 122.46258842000009],
  [14.328793043000076, 122.46259946500004],
  [14.328972328000077, 122.46281518700005],
  [14.329055965000066, 122.4628336510001],
  [14.328982920000044, 122.46319060000008],
  [14.328784867000024, 122.46331998200003],
  [14.328794673000061, 122.4636607430001],
  [14.32844612200006, 122.46390765000001],
  [14.328495598000075, 122.464119589],
  [14.328437687000076, 122.46425658200008],
  [14.328246290000038, 122.46436133300006],
  [14.328212946000065, 122.46458063500006],
  [14.328043966000052, 122.4647458290001],
  [14.328116177000027, 122.46487227700004],
  [14.328110982000055, 122.4655129040001],
  [14.328169616000025, 122.46559386700005],
  [14.328207319000057, 122.46587747],
  [14.328290214000049, 122.46603622500004],
  [14.328224084000055, 122.46619952900005],
  [14.328319387000022, 122.46634205500004],
  [14.328451011000027, 122.46630020300006],
  [14.328564398000026, 122.46614127300006],
  [14.328651878000073, 122.46618244100011],
  [14.328666250000026, 122.46658745600007],
  [14.328424961000053, 122.46675872300011],
  [14.328480618000071, 122.46686821300011],
  [14.328741101000048, 122.46685221500002],
  [14.328642242000058, 122.46701083700009],
  [14.328766582000071, 122.46712651100006],
  [14.328936773000066, 122.4669969470001],
  [14.328878004000046, 122.46681914500004],
  [14.32890470500007, 122.466364214],
  [14.32899308900005, 122.46618006500012],
  [14.329153057000042, 122.4660795960001],
  [14.329247667000061, 122.46577550600011],
  [14.32935216900006, 122.46571157000005],
  [14.329633899000044, 122.465704662],
  [14.329920912000034, 122.4657548670001],
  [14.330096092000076, 122.46593538000002],
  [14.330254159000049, 122.46592055000008],
  [14.330489469000042, 122.46607249300007],
  [14.330346055000064, 122.46614407800007],
  [14.330240698000068, 122.46605947300009],
  [14.330128500000058, 122.46618963300011],
  [14.33018146300003, 122.46635940100009],
  [14.330383435000044, 122.46636131900004],
  [14.330467417000023, 122.46663500700004],
  [14.330767356000024, 122.46674277900001],
  [14.330621386000075, 122.46686714300006],
  [14.330666779000069, 122.46700388700003],
  [14.330889307000064, 122.4669213200001],
  [14.33099280700003, 122.46674668100002],
  [14.331229043000064, 122.46656763100009],
  [14.331358364000039, 122.46666351700003],
  [14.331482976000075, 122.46662861800007],
  [14.331492732000072, 122.46646961400006],
  [14.331650145000026, 122.46642192400009],
  [14.33183845700006, 122.46644708300005],
  [14.331897532000028, 122.46662199500008],
  [14.332216907000031, 122.46734389200003],
  [14.332319045000077, 122.46746571200003],
  [14.332905156000038, 122.46782397900006],
  [14.333224512000072, 122.46794802300008],
  [14.33320844800005, 122.46773370000005],
  [14.333350279000058, 122.46773348400006],
  [14.333379911000065, 122.46786343000008],
  [14.333555334000039, 122.46781473800002],
  [14.333691231000046, 122.467863652],
  [14.33384116700006, 122.46805928100002],
  [14.333969930000023, 122.46793093800011],
  [14.334081587000071, 122.46810566800002],
  [14.334031457000037, 122.46829282200008],
  [14.33499284100003, 122.4687223200001],
  [14.335903770000073, 122.4688490960001],
  [14.336588905000042, 122.4690175290001],
  [14.337244719000068, 122.46906563800007],
  [14.33769275900005, 122.46901766400003],
  [14.338116271000047, 122.46889008800008],
  [14.338077016000057, 122.46874494800011],
  [14.338222912000049, 122.4686521640001],
  [14.338413676000073, 122.46862840300003],
  [14.338465279000047, 122.46843482800011],
  [14.33860577300004, 122.46821324000007],
  [14.338772215000063, 122.46835371200007],
  [14.338995732000058, 122.46836886400001],
  [14.338797212000031, 122.46864053400009],
  [14.339005082000028, 122.46867752000003],
  [14.33933397800007, 122.46867631600003],
  [14.33950132800004, 122.46876603400005],
  [14.339656734000073, 122.46904584000004],
  [14.339606022000055, 122.46965680300002],
  [14.339815078000072, 122.46985106600005],
  [14.339795657000025, 122.47025247700003],
  [14.339637179000022, 122.47027863000005],
  [14.339639530000056, 122.47043240000005],
  [14.339472836000027, 122.47068073100002],
  [14.339621115000057, 122.47079349100011],
  [14.339972799000066, 122.47096952700008],
  [14.339879884000027, 122.47117641000011],
  [14.340150661000052, 122.47138021400008],
  [14.34014105700004, 122.47149242900002],
  [14.339898341000037, 122.47153994100006],
  [14.339793489000044, 122.47167711400004],
  [14.339856759000043, 122.47193498800004],
  [14.339732641000069, 122.47193413000002],
  [14.339543306000053, 122.47174748000009],
  [14.339517834000048, 122.47207614700005],
  [14.339287134000074, 122.47197601300002],
  [14.339258782000059, 122.47234931100002],
  [14.339420963000066, 122.472616388],
  [14.339376190000053, 122.47272401900011],
  [14.339045498000075, 122.47252915900003],
  [14.338924087000066, 122.47238710400006],
  [14.338678278000032, 122.47225020000008],
  [14.33848542800007, 122.4725343770001],
  [14.338549219000072, 122.47279950500001],
  [14.338722105000045, 122.47251896800003],
  [14.338850794000052, 122.47255475300005],
  [14.338899698000034, 122.47281838900005],
  [14.33873445100005, 122.47279827000011],
  [14.338640085000065, 122.47305498800006],
  [14.338644549000037, 122.47327918100007],
  [14.338539364000042, 122.47339061600007],
  [14.338597359000062, 122.47355254800004],
  [14.338535247000038, 122.47365624500003],
  [14.338612899000054, 122.47379008900009],
  [14.338745342000038, 122.47379968900009],
  [14.338783987000056, 122.47403412500012],
  [14.338973108000062, 122.47403664],
  [14.338957297000036, 122.47424003100002],
  [14.338733423000065, 122.47429910500011],
  [14.338644742000042, 122.474106086],
  [14.338515591000032, 122.47403363400008],
  [14.338228210000068, 122.47401178500002],
  [14.338023803000056, 122.47406005900007],
  [14.338088276000065, 122.47443501700002],
  [14.338059970000074, 122.47461918400006],
  [14.337940377000052, 122.47475399500001],
  [14.337963500000058, 122.47493578500007],
  [14.338155314000062, 122.47508706400004],
  [14.338177439000049, 122.47525453600008],
  [14.338055502000032, 122.47544828000002],
  [14.338136835000057, 122.47561495500008],
  [14.337993984000036, 122.475816258],
  [14.338192972000058, 122.47590053300007],
  [14.338306186000068, 122.47621665100007],
  [14.338459340000043, 122.47641250100003],
  [14.338786165000045, 122.47643919100005],
  [14.339061310000034, 122.47679386900006],
  [14.338934309000024, 122.47721772500006],
  [14.338647623000043, 122.47711747800008],
  [14.338364368000043, 122.47695629500004],
  [14.338149271000077, 122.47677979200012],
  [14.337832149000064, 122.47685811400004],
  [14.337666278000029, 122.4766653160001],
  [14.337430392000044, 122.47661047100007],
  [14.337388629000031, 122.47633552000002],
  [14.337237279000021, 122.47628283200004],
  [14.337184490000027, 122.47664849500006],
  [14.337086978000059, 122.47672386600004],
  [14.337254309000059, 122.47693762800009],
  [14.337307356000053, 122.47735629300007],
  [14.337233146000074, 122.4774144810001],
  [14.336904705000052, 122.47716420600011],
  [14.336830017000068, 122.47734254400007],
  [14.336504645000048, 122.47768348000011],
  [14.33627858400007, 122.47742771600008],
  [14.336108307000075, 122.47740085500004],
  [14.336112742000068, 122.47715186800008],
  [14.336683469000036, 122.47695287700003],
  [14.33641466000006, 122.47681618500008],
  [14.336450141000057, 122.47656349100009],
  [14.336377559000027, 122.47637149800005],
  [14.33620079800005, 122.47650373700003],
  [14.336299103000044, 122.47689096200008],
  [14.336126617000048, 122.47673028000008],
  [14.33598956000003, 122.4767666250001],
  [14.33602353300006, 122.47691931100007],
  [14.335894511000049, 122.47697496800004],
  [14.33566324800006, 122.47688608400006],
  [14.335525334000067, 122.47722883900008],
  [14.335620420000055, 122.47733842800005],
  [14.335668737000049, 122.47761437600002],
  [14.335452123000039, 122.47757313400007],
  [14.33506518200005, 122.47766227800003],
  [14.334945344000062, 122.47785720800005],
  [14.334694679000052, 122.47785893800005],
  [14.334593877000032, 122.47773205800002],
  [14.334616184000026, 122.47750334600005],
  [14.334500822000052, 122.47746221500006],
  [14.334350176000044, 122.47770205900008],
  [14.334106098000063, 122.47764096600008],
  [14.334027362000029, 122.4775210470001],
  [14.333821929000067, 122.47740546600005],
  [14.333709838000061, 122.47745743200005],
  [14.333533524000075, 122.47717035200003],
  [14.333692349000046, 122.47716324600003],
  [14.333838735000029, 122.4769504510001],
  [14.333934127000077, 122.47698442100011],
  [14.334207702000072, 122.4769051080001],
  [14.334113622000075, 122.47670205300005],
  [14.333280673000047, 122.47662087300012],
  [14.33288499200006, 122.47662629000001],
  [14.331675736000022, 122.47653552800011],
  [14.331049819000043, 122.47658537200005],
  [14.330629964000025, 122.47668945300006],
  [14.329938136000067, 122.47704127200006],
  [14.329554599000062, 122.47740588200008],
  [14.329425356000058, 122.47758233800005],
  [14.329554511000026, 122.47781096000006],
  [14.329575154000054, 122.47822989600002],
  [14.329696169000044, 122.4784734210001],
  [14.329618634000042, 122.47884068500002],
  [14.329617639000048, 122.47903668200001],
  [14.329501988000061, 122.47915798600002],
  [14.329283271000065, 122.47916937900004],
  [14.329209844000047, 122.4794690110001],
  [14.32921574900007, 122.47989426800007],
  [14.32928896900006, 122.48029525100003],
  [14.329375717000062, 122.48039702200003],
  [14.329517785000064, 122.48038926400011],
  [14.329552485000022, 122.48056448000011],
  [14.32926761300007, 122.480763421],
  [14.328980112000067, 122.4816136930001],
  [14.328807167000036, 122.48229283600006],
  [14.328732354000067, 122.48266167600002],
  [14.328566522000074, 122.48316948800004],
  [14.328492819000076, 122.48350255500009],
  [14.328444134000051, 122.48397650600009],
  [14.328364351000062, 122.48526083500008],
  [14.32846648800006, 122.48563591000004],
  [14.32848404200007, 122.48590311600003],
  [14.328632154000047, 122.4864149120001],
  [14.328529231000061, 122.48665850800012],
  [14.328384825000057, 122.48682268100004],
  [14.328097534000051, 122.48705990400003],
  [14.328023830000063, 122.487208],
  [14.328011008000033, 122.48759087600001],
  [14.327914659000044, 122.487614086],
  [14.327780232000066, 122.48748980300002],
  [14.327478953000025, 122.48758216800002],
  [14.327677748000042, 122.48802882600012],
  [14.327857214000062, 122.48801991300002],
  [14.32813851800006, 122.48784078000006],
  [14.328333988000054, 122.48767139900008],
  [14.328605774000039, 122.48762419000002],
  [14.328657686000042, 122.48755141700008],
  [14.32888719500005, 122.4875087040001],
  [14.328926018000061, 122.48741318500004],
  [14.329167151000036, 122.4874982560001],
  [14.329467124000075, 122.48749040700011],
  [14.329963322000026, 122.48767638800007],
  [14.330101289000027, 122.48782763800011],
  [14.330390308000062, 122.48802181600001],
  [14.33069372500006, 122.48840766900003],
  [14.330910643000038, 122.4885436830001],
  [14.33131200400004, 122.48906326400004],
  [14.331537272000048, 122.48938650600007],
  [14.331926593000048, 122.48985469400009],
  [14.332122481000056, 122.49032765700008],
  [14.332344873000068, 122.49069667700007],
  [14.332447900000034, 122.49010643300005],
  [14.332254512000077, 122.489978059],
  [14.332159438000076, 122.48978748400009],
  [14.332214841000052, 122.4896064730001],
  [14.33214021200007, 122.48948891900011],
  [14.332217756000034, 122.48934520900002],
  [14.332537837000075, 122.48909067300008],
  [14.332650956000066, 122.48894698800007],
  [14.332995906000065, 122.48899044200004],
  [14.33312773800003, 122.48909476200004],
  [14.333212999000068, 122.48927107400004],
  [14.333094600000038, 122.489531123],
  [14.332985721000057, 122.48987732700004],
  [14.33282581800006, 122.49000346500009],
  [14.332821809000052, 122.49029804700001],
  [14.33288359200003, 122.49050004800006],
  [14.333047429000032, 122.49195733700003],
  [14.333238179000034, 122.49425520900002],
  [14.333042024000065, 122.49436722400003],
  [14.33310403300004, 122.49461243200005],
  [14.333371683000053, 122.49456194200002],
  [14.333531252000057, 122.49444734500003],
  [14.333718132000058, 122.49442755700011],
  [14.333928126000046, 122.4942938590001],
  [14.334219449000045, 122.49436493400003],
  [14.334160293000025, 122.49400088800007],
  [14.334076759000027, 122.49377524500005],
  [14.334367989000043, 122.49358098400012],
  [14.334688750000055, 122.49318733000007],
  [14.334875284000077, 122.49317088700002],
  [14.335149521000062, 122.49323377700011],
  [14.335356603000037, 122.493344653],
  [14.335632931000077, 122.49366606700005],
  [14.335894447000044, 122.4937253810001],
  [14.336065973000075, 122.49382181300007],
  [14.336136856000053, 122.49407335300009],
  [14.336421048000034, 122.49412763700002],
  [14.336679752000066, 122.49433782800008],
  [14.33673463100007, 122.4946487850001],
  [14.336590319000038, 122.49459907000005],
  [14.336588490000054, 122.49478803800002],
  [14.33648899700006, 122.49502590100008],
  [14.336355378000064, 122.49512526300009],
  [14.336281031000055, 122.4952882770001],
  [14.336315616000036, 122.4956528140001],
  [14.33625257500006, 122.49580697700003],
  [14.336473401000035, 122.49596684900007],
  [14.33664918900007, 122.49539897400007],
  [14.336626047000038, 122.49518451300003],
  [14.336740122000037, 122.49501980800005],
  [14.336929431000044, 122.49486949000004],
  [14.33705023500005, 122.49503505700011],
  [14.337027644000045, 122.49518972100009],
  [14.337207067000065, 122.49556954800005],
  [14.337143111000046, 122.49567769500004],
  [14.337165687000038, 122.49591925800007],
  [14.337318109000023, 122.49615935200006],
  [14.337536853000074, 122.49620369900003],
  [14.337927585000045, 122.49655596200012],
  [14.337744385000065, 122.49679869300007],
  [14.337816931000077, 122.49691840300011],
  [14.337967577000029, 122.49695856200003],
  [14.337930697000047, 122.49722829400002],
  [14.338047854000024, 122.4973755740001],
  [14.33785713800006, 122.497810479],
  [14.337652904000038, 122.49796763900008],
  [14.337675926000031, 122.49816013000009],
  [14.337620809000043, 122.4983462570001],
  [14.33743105900004, 122.49842496700012],
  [14.33749741400004, 122.49862673300004],
  [14.337334819000034, 122.49860788],
  [14.337336221000044, 122.49887153600002],
  [14.337038420000056, 122.49927081800001],
  [14.337065661000054, 122.49953747300003],
  [14.336859145000062, 122.49966608000011],
  [14.336575690000075, 122.4996039670001],
  [14.33656974400003, 122.49968676200001],
  [14.336792203000073, 122.49988764300008],
  [14.336639164000076, 122.5001670370001],
  [14.336524131000033, 122.50028560500004],
  [14.336384730000077, 122.50062549300003],
  [14.33608801400004, 122.5009295750001],
  [14.336054268000055, 122.50112238600002],
  [14.335948183000028, 122.50118890200008],
  [14.335866012000054, 122.50159335800004],
  [14.335625299000071, 122.50196384600008],
  [14.335629760000074, 122.50213593400008],
  [14.335460935000071, 122.50257364800007],
  [14.335467248000043, 122.5026937240001],
  [14.33502253100005, 122.50294015600002],
  [14.33493575500006, 122.503243515],
  [14.334777637000059, 122.50329403400008],
  [14.334529750000058, 122.5036532790001],
  [14.33429929600004, 122.50366333700003],
  [14.334173209000028, 122.5037564590001],
  [14.33394286400005, 122.50405405800007],
  [14.333805803000075, 122.50407526600009],
  [14.333586682000032, 122.50432750200002],
  [14.333330720000049, 122.50446103900003],
  [14.333260594000024, 122.50457090400005],
  [14.333098882000058, 122.50461049600005],
  [14.332966001000045, 122.50448645100005],
  [14.333116121000046, 122.50426082600006],
  [14.333468826000058, 122.504101149],
  [14.333417631000032, 122.50378032600008],
  [14.333100040000033, 122.50362594300009],
  [14.333206724000036, 122.5034764620001],
  [14.333653703000039, 122.50362938400008],
  [14.333717135000029, 122.50336435200006],
  [14.333912044000044, 122.5033180040001],
  [14.333982728000024, 122.50344677800001],
  [14.334128065000073, 122.50338611300003],
  [14.334164607000048, 122.50321002600003],
  [14.334306427000058, 122.50315302900003],
  [14.33488920600007, 122.50275997200004],
  [14.334830993000026, 122.50258295900005],
  [14.334581720000074, 122.50237783500006],
  [14.334328428000049, 122.50252009400003],
  [14.33423461500007, 122.502470474],
  [14.334048220000057, 122.50260326300008],
  [14.333834485000068, 122.50237714500008],
  [14.333593647000043, 122.50222656200003],
  [14.33351979300005, 122.50238491200002],
  [14.333348317000059, 122.50244248700005],
  [14.333280478000063, 122.50264121900011],
  [14.333044721000022, 122.50249135800004],
  [14.332947518000026, 122.50262289200009],
  [14.332588619000035, 122.50263614800008],
  [14.332495917000074, 122.50279609900008],
  [14.332366062000062, 122.50264163000008],
  [14.332223438000028, 122.50268659700009],
  [14.332075212000063, 122.50290599200002],
  [14.331866029000025, 122.5028443010001],
  [14.33172599900007, 122.50258559300005],
  [14.331553790000044, 122.5026584200001],
  [14.33156328800004, 122.50294503600003],
  [14.331702720000067, 122.50311864600008],
  [14.331663371000047, 122.50321991300007],
  [14.331265028000075, 122.50328175700008],
  [14.331020443000057, 122.50324496500002],
  [14.330928666000034, 122.50303216600003],
  [14.330678096000042, 122.50279425300005],
  [14.33054548900003, 122.50282640700004],
  [14.330500364000045, 122.50305435200005],
  [14.330314332000057, 122.50318388100004],
  [14.330396629000063, 122.5033147470001],
  [14.330413265000061, 122.5035830280001],
  [14.33036553100004, 122.50370790800002],
  [14.330199163000032, 122.50376380700004],
  [14.330247261000068, 122.50396624400003],
  [14.330060248000052, 122.50400792300002],
  [14.329785253000068, 122.50392311000007],
  [14.329657398000052, 122.50380820300006],
  [14.329449252000074, 122.50394071000005],
  [14.329421120000063, 122.503721745],
  [14.329237030000058, 122.50369566200004],
  [14.32922843800003, 122.503901272],
  [14.329429624000056, 122.50402147500006],
  [14.329436806000047, 122.50425398100003],
  [14.329609537000067, 122.50422445600009],
  [14.329734766000058, 122.50438171200005],
  [14.329624325000054, 122.50455149600009],
  [14.329827009000041, 122.50482372200008],
  [14.329642701000068, 122.50530259400011],
  [14.329440373000068, 122.5052280760001],
  [14.329200969000055, 122.5052289570001],
  [14.329100066000024, 122.50537156400003],
  [14.329213311000046, 122.50547013800008],
  [14.329220747000022, 122.50600455000006],
  [14.328989961000048, 122.50609777500006],
  [14.329061265000064, 122.50627084200005],
  [14.329293654000026, 122.50607308200006],
  [14.329385031000072, 122.50610296100001],
  [14.329544820000024, 122.50678589300003],
  [14.329897226000071, 122.5068728660001],
  [14.33006744200003, 122.50714249100008],
  [14.330049677000034, 122.50731457800009],
  [14.329924318000053, 122.50737829700006],
  [14.329720105000035, 122.50706364300004],
  [14.32939325600006, 122.50682420300006],
  [14.329290381000021, 122.50665724400005],
  [14.329126960000053, 122.50672191800004],
  [14.329035308000073, 122.50705662100006],
  [14.329108406000046, 122.50748425100005],
  [14.329336056000045, 122.50728434100006],
  [14.32940859200005, 122.50741994900011],
  [14.329645077000066, 122.50757262600007],
  [14.32982108400006, 122.5075343100001],
  [14.329798396000058, 122.5077273070001],
  [14.329582451000022, 122.50789908400009],
  [14.329785782000044, 122.50797926000007],
  [14.329878716000053, 122.50814857900002],
  [14.329814032000058, 122.50828739600001],
  [14.32991495500005, 122.50842340800011],
  [14.329623945000037, 122.50848947400004],
  [14.329459056000076, 122.50840750600003],
  [14.329123725000045, 122.50835097200002],
  [14.329074337000065, 122.5081695660001],
  [14.328570034000052, 122.50828109800011],
  [14.328542818000074, 122.50816451600008],
  [14.328890392000062, 122.5080861780001],
  [14.328854947000025, 122.50783447700007],
  [14.328976821000026, 122.5076276750001],
  [14.328861344000074, 122.50715183500006],
  [14.328824449000024, 122.50689040100008],
  [14.328619867000043, 122.50661473500008],
  [14.328509428000075, 122.50639615800003],
  [14.32846795100005, 122.5061954140001],
  [14.328282233000039, 122.50621162900006],
  [14.327791235000063, 122.50613374300008],
  [14.32740764500005, 122.5061505330001],
  [14.327290899000047, 122.50622949100011],
  [14.326942993000046, 122.50626453200005],
  [14.326674235000041, 122.50635867900007],
  [14.326257976000022, 122.50663054800009],
  [14.326036712000075, 122.50699231200008],
  [14.325556304000031, 122.50725531800003],
  [14.325378786000044, 122.5074464710001],
  [14.325175605000027, 122.50744198300004],
  [14.32499273600007, 122.50759515300001],
  [14.324903802000051, 122.50735885200004],
  [14.324642489000041, 122.50734314500005],
  [14.324526616000071, 122.50742416300011],
  [14.324274566000042, 122.50773713500007],
  [14.324020211000061, 122.5082388410001],
  [14.324057910000022, 122.50835627100003],
  [14.323906247000025, 122.50863268400008],
  [14.323784406000073, 122.50869376600008],
  [14.323373339000057, 122.50900623000007],
  [14.322447484000065, 122.50952492700003],
  [14.322030864000055, 122.50962613400009],
  [14.321645428000068, 122.5096183930001],
  [14.321377948000077, 122.50978639700008],
  [14.321216076000042, 122.50995696100006],
  [14.321056601000066, 122.51021320300003],
  [14.320803958000056, 122.51022657400006],
  [14.320519786000034, 122.51042625700006],
  [14.320310653000035, 122.51063884200005],
  [14.319982414000037, 122.51083987000004],
  [14.319779418000053, 122.51112829600004],
  [14.319565386000022, 122.51127289600004],
  [14.318973658000061, 122.51160018900009],
  [14.318715945000065, 122.51185241400003],
  [14.318437305000032, 122.51221631300007],
  [14.318313298000078, 122.51271480300011],
  [14.318281571000057, 122.51310855300005],
  [14.318331110000031, 122.5134882540001],
  [14.318313408000051, 122.51391843500005],
  [14.318339700000024, 122.51435628200011],
  [14.318448017000037, 122.51501922400007],
  [14.31879410700003, 122.5160462120001],
  [14.319046274000073, 122.51694090900003],
  [14.319233046000022, 122.51727184800006],
  [14.319211882000047, 122.51757640300002],
  [14.318935964000048, 122.51803174100007],
  [14.318720678000034, 122.51826449700002],
  [14.31843426000006, 122.51834383100004],
  [14.318632998000055, 122.51799447200005],
  [14.318688905000045, 122.51781879000009],
  [14.318644144000075, 122.5173382810001],
  [14.318307976000028, 122.51743234300011],
  [14.318220677000056, 122.51750441900003],
  [14.31826895100005, 122.51787958700004],
  [14.31828058800005, 122.51828037200005],
  [14.31813297900004, 122.51850951900008],
  [14.317982461000042, 122.5185962920001],
  [14.317751754000028, 122.51862488800009],
  [14.317666673000076, 122.51842045],
  [14.317533054000023, 122.51830940800005],
  [14.317581157000063, 122.51805492100004],
  [14.317550149000056, 122.51770196000007],
  [14.317174587000068, 122.51770323300002],
  [14.317052056000023, 122.5179485330001],
  [14.31714782000006, 122.51807022100002],
  [14.317230141000039, 122.51858254600006],
  [14.317235579000055, 122.51912403600011],
  [14.317105066000067, 122.51927873600005],
  [14.316980350000051, 122.51931139400006],
  [14.317051071000037, 122.51952695200009],
  [14.317221317000076, 122.5194911640001],
  [14.317201754000052, 122.51934507600004],
  [14.317351175000056, 122.51930439500006],
  [14.317369825000071, 122.51964272500004],
  [14.317422716000067, 122.51974637900003],
  [14.317518532000065, 122.5202266120001],
  [14.317536406000045, 122.52052913600005],
  [14.317512209000029, 122.52086653600009],
  [14.317991364000022, 122.52134378100004],
  [14.318190768000022, 122.5214623920001],
  [14.31844701700004, 122.52147713800002],
  [14.318282860000068, 122.52125291700008],
  [14.318565185000068, 122.52112379100004],
  [14.318705094000052, 122.5212065290001],
  [14.318835314000069, 122.52143667100006],
  [14.318828299000074, 122.52163845500002],
  [14.319124926000029, 122.52178871400008],
  [14.319434828000055, 122.52223681800001],
  [14.31968340900005, 122.52248512800008],
  [14.31985140200004, 122.52274040900011],
  [14.320006658000068, 122.52313491000007],
  [14.320285451000075, 122.52374645800012],
  [14.32047443600004, 122.5241068150001],
  [14.320745346000024, 122.52470292400005],
  [14.320974989000035, 122.52509284200005],
  [14.321356500000036, 122.52554153900007],
  [14.321316496000065, 122.52569974900007],
  [14.321113326000045, 122.52572157400004],
  [14.32107110100003, 122.525859147],
  [14.320928841000068, 122.52595925800006],
  [14.322167554000032, 122.52685645600002],
  [14.32218662300005, 122.52658640200002],
  [14.322273873000029, 122.52619092900011],
  [14.32236930700003, 122.52599895700007],
  [14.322720722000042, 122.52545666300011],
  [14.322950467000055, 122.52520338600004],
  [14.323172499000066, 122.52502512100011],
  [14.323385874000053, 122.524760506],
  [14.323546369000042, 122.52448431200003],
  [14.323767445000044, 122.52433991300006],
  [14.323990512000023, 122.52436305900005],
  [14.324008357000025, 122.52417984100009],
  [14.324096261000022, 122.52408916600007],
  [14.32436965100004, 122.52345590700008],
  [14.32464791700005, 122.52302361600005],
  [14.324974032000057, 122.52291853700001],
  [14.325197100000025, 122.52274012200007],
  [14.325486842000032, 122.52256483500003],
  [14.32553782900004, 122.52239865700005],
  [14.325491470000031, 122.52211731800003],
  [14.325578649000022, 122.52194296400012],
  [14.32559023500005, 122.52173725500006],
  [14.325689461000024, 122.52134744800003],
  [14.325953691000052, 122.52095691700003],
  [14.326053332000072, 122.52088906800009],
  [14.326212699000052, 122.52060225900004],
  [14.326528752000058, 122.52032697300001],
  [14.326744917000042, 122.52040680200002],
  [14.32694525900007, 122.52036518500006],
  [14.327254109000023, 122.52037738400008],
  [14.327749296000036, 122.52032338900005],
  [14.327849456000024, 122.5201833210001],
  [14.328097298000046, 122.51997391600003],
  [14.328145903000063, 122.51987996600008],
  [14.328150712000024, 122.51955846200008],
  [14.328209485000059, 122.51934250500005],
  [14.328338527000028, 122.51926464900009],
  [14.328607789000046, 122.51880347200006],
  [14.328881190000061, 122.51860557000009],
  [14.328978515000074, 122.51837436700009],
  [14.329260349000037, 122.51807518200008],
  [14.32930288700004, 122.51766178800005],
  [14.329207143000076, 122.51741349400004],
  [14.329040145000022, 122.51741096600006],
  [14.32903477700006, 122.51725543500004],
  [14.329200524000044, 122.51709209700005],
  [14.329478035000022, 122.51696207500004],
  [14.329667549000021, 122.51676885100005],
  [14.329863365000051, 122.51644261800004],
  [14.329974795000055, 122.51604420600006],
  [14.329942704000075, 122.51573158500003],
  [14.330017819000034, 122.51550258800012],
  [14.330222150000054, 122.5154635560001],
  [14.330684786000063, 122.51517510300005],
  [14.330736376000061, 122.51533331200005],
  [14.331646633000048, 122.51539832300011],
  [14.331876968000074, 122.51534540500006],
  [14.332138864000058, 122.51514049200011],
  [14.332539932000032, 122.51496716300005],
  [14.333114340000066, 122.5148535400001],
  [14.334616939000057, 122.51495844100009],
  [14.335220545000027, 122.51515332100007],
  [14.33600130700006, 122.51525087400012],
  [14.336188621000076, 122.51523315700001],
  [14.336308768000038, 122.5150951820001],
  [14.33634309100006, 122.51481186800004],
  [14.336490408000031, 122.51472844300008],
  [14.336642011000038, 122.51475628700007],
  [14.337195911000038, 122.51449765000007],
  [14.33765706500003, 122.51416334300006],
  [14.337707479000073, 122.51406245700002],
  [14.337949907000052, 122.51392949100011],
  [14.337744323000038, 122.51373146300011],
  [14.337598823000064, 122.51368702900004],
  [14.33729707200007, 122.513857217],
  [14.337210797000068, 122.5137632420001],
  [14.33736476000007, 122.5135368760001],
  [14.33753136200005, 122.51352741800008],
  [14.337662928000043, 122.51334532500005],
  [14.338002064000023, 122.513273937],
  [14.337949278000053, 122.51309164600002],
  [14.338345713000024, 122.51316493800005],
  [14.338281868000024, 122.512967763],
  [14.338453806000075, 122.51285280500008],
  [14.338633781000055, 122.51304303400002],
  [14.338726842000028, 122.51321842100003],
  [14.338919557000054, 122.51343899100004],
  [14.33898877200005, 122.51327047200004],
  [14.33912831300006, 122.51313272800007],
  [14.339085069000078, 122.51297857500003],
  [14.338898670000049, 122.5128674660001],
  [14.339043719000074, 122.51255636500002],
  [14.33918647400003, 122.51259227700007],
  [14.339228134000052, 122.51278754500004],
  [14.339437916000065, 122.51274158900003],
  [14.33951808200004, 122.51233881900009],
  [14.33961720700006, 122.51218280500007],
  [14.339545826000062, 122.51196222100009],
  [14.33961581400007, 122.51147235500002],
  [14.339949650000051, 122.51138216600009],
  [14.340237879000028, 122.51153931300007],
  [14.340428331000055, 122.51158822900004],
  [14.340432218000046, 122.51169835200005],
  [14.340716979000035, 122.51176220400009],
  [14.340746945000035, 122.51202994800008],
  [14.341009715000041, 122.51180260600006],
  [14.341113655000072, 122.51182697700006],
  [14.341200152000056, 122.51199248900002],
  [14.341324534000023, 122.51173916900007],
  [14.341458131000024, 122.51175480200004],
  [14.341393784000047, 122.51141078600006],
  [14.341687550000074, 122.5113794340001],
  [14.341545593000035, 122.51108667400001],
  [14.341631214000074, 122.51080501500007],
  [14.341782774000023, 122.51076499800001],
  [14.342058090000023, 122.510393668],
  [14.34206880000005, 122.51029199000004],
  [14.341946681000024, 122.51010169900007],
  [14.341975661000049, 122.50985854500004],
  [14.34214759100007, 122.50979922300007],
  [14.342263611000021, 122.50992742000005],
  [14.342414661000078, 122.50988322100011],
  [14.342577680000034, 122.50992266700007],
  [14.342562324000028, 122.50973896200003],
  [14.342687983000076, 122.50960080700008],
  [14.343015353000055, 122.50947839900005],
  [14.34332964400005, 122.50949377000006],
  [14.343532992000064, 122.50963771500005],
  [14.343786269000077, 122.50926936500002],
  [14.343943998000043, 122.50935699500008],
  [14.344164729000056, 122.50921708900012],
  [14.344450388000041, 122.50931740800002],
  [14.344660194000028, 122.50963687600006],
  [14.344724584000062, 122.50989621700012],
  [14.344602356000053, 122.5101406230001],
  [14.34477240700005, 122.51025639500006],
  [14.344792776000077, 122.51035470500005],
  [14.344624299000031, 122.510453417],
  [14.34483523700004, 122.51054983600011],
  [14.345093426000062, 122.51042727200002],
  [14.345223323000027, 122.51069726100002],
  [14.345451699000023, 122.51104686500003],
  [14.345630068000048, 122.51107588900004],
  [14.34573148800007, 122.51125631000002],
  [14.345733037000059, 122.51149024100005],
  [14.345890475000033, 122.51159121900002],
  [14.345840491000047, 122.51176329100008],
  [14.346131570000068, 122.51208868100002],
  [14.346065044000056, 122.51217268100004],
  [14.346012977000044, 122.51244410900006],
  [14.345809938000059, 122.51254831300002],
  [14.34579806000005, 122.51276500200004],
  [14.345691301000045, 122.5128430210001],
  [14.345687615000031, 122.51315535700007],
  [14.346091516000058, 122.51320462300009],
  [14.34614328500004, 122.51313791100006],
  [14.346452050000039, 122.51304505400003],
  [14.346523647000026, 122.51312068400011],
  [14.346806335000053, 122.51310297500004],
  [14.346857537000062, 122.51294788400003],
  [14.347088017000033, 122.51294304700002],
  [14.34724998200005, 122.512841008],
  [14.347301591000075, 122.51309405200004],
  [14.347125642000037, 122.51301230700005],
  [14.346991970000033, 122.51304501100003],
  [14.346921251000026, 122.51328727100008],
  [14.34678685800003, 122.51327282800003],
  [14.346495151000056, 122.51334430700001],
  [14.346661380000057, 122.51348977200007],
  [14.34657681400006, 122.51362995800002],
  [14.346411814000078, 122.51370127100006],
  [14.346148637000056, 122.51371383800006],
  [14.346233575000042, 122.51385051900002],
  [14.345793314000048, 122.51393147500005],
  [14.345482040000036, 122.5139128830001],
  [14.34538763200004, 122.5137917290001],
  [14.345087234000061, 122.51372287800007],
  [14.34492537400007, 122.51394093400006],
  [14.34461525100005, 122.513858045],
  [14.344585620000032, 122.51407986600009],
  [14.344708839000077, 122.51413125900001],
  [14.34483096300005, 122.51405745000011],
  [14.344906644000048, 122.5142569190001],
  [14.344848085000024, 122.51439280700004],
  [14.344469807000053, 122.51429636300008],
  [14.34470639500006, 122.51457228400011],
  [14.344775495000022, 122.5147635730001],
  [14.344603239000037, 122.515055845],
  [14.344348060000073, 122.51506405600003],
  [14.34421463800004, 122.51530223600003],
  [14.34399122800005, 122.51543295300007],
  [14.344246457000054, 122.51552714800005],
  [14.344364175000067, 122.51550316400005],
  [14.34452714300005, 122.51570002200003],
  [14.344691884000042, 122.51563344200008],
  [14.344871355000066, 122.51573494900003],
  [14.345075704000067, 122.51597533200004],
  [14.345170390000021, 122.5161489950001],
  [14.345109738000076, 122.51629161000005],
  [14.344913563000034, 122.51617260500007],
  [14.344547800000043, 122.51617160500007],
  [14.344759835000048, 122.51657859000011],
  [14.344972062000068, 122.51660915600007],
  [14.344766942000035, 122.51681556000005],
  [14.344877711000038, 122.51720681400002],
  [14.34463878500003, 122.51738038200006],
  [14.34452718700004, 122.51771097900007],
  [14.344290171000068, 122.51776741200001],
  [14.344133142000032, 122.5176429920001],
  [14.344060393000063, 122.51780355100004],
  [14.34415129000007, 122.51820318900002],
  [14.344024671000057, 122.51827206500002],
  [14.344114083000022, 122.51845508600002],
  [14.344318477000058, 122.51858840600005],
  [14.344119526000043, 122.51873952000005],
  [14.343716665000045, 122.51844977300004],
  [14.343662280000046, 122.51856524700008],
  [14.34380806400003, 122.51869430300007],
  [14.343855555000061, 122.51895665600011],
  [14.343756214000052, 122.51903849300004],
  [14.343759256000055, 122.51924998600009],
  [14.343831880000039, 122.51933962300006],
  [14.343993769000065, 122.51992220400007],
  [14.343901580000022, 122.52019366900004],
  [14.343975791000048, 122.52023555300002],
  [14.344230651000032, 122.5198802330001],
  [14.34426565700005, 122.52004615900012],
  [14.344434664000062, 122.52009463900004],
  [14.344388282000068, 122.52024835700001],
  [14.344462274000023, 122.52050646500004],
  [14.344584739000027, 122.5203485940001],
  [14.344747256000062, 122.52036804300008],
  [14.344901520000064, 122.52048285500007],
  [14.344829745000027, 122.52063761500006],
  [14.345011903000056, 122.5206696790001],
  [14.34511356400003, 122.5203958410001],
  [14.345376244000022, 122.52050647500005],
  [14.345363640000073, 122.52094592500009],
  [14.345506212000032, 122.52093483200008],
  [14.34565496700003, 122.52118739100001],
  [14.345649603000027, 122.52138434900007],
  [14.345779913000058, 122.52138192300004],
  [14.345837103000065, 122.52119228200002],
  [14.346052024000073, 122.52091685000005],
  [14.346155775000057, 122.52103689500007],
  [14.346072462000052, 122.52114947900009],
  [14.346117124000045, 122.52129303900006],
  [14.346309784000027, 122.52120020000007],
  [14.34636385400006, 122.52105792500004],
  [14.346334865000074, 122.52077797100003],
  [14.346406990000048, 122.52069520300006],
  [14.34682607700006, 122.5206144770001],
  [14.346913080000036, 122.52054064300012],
  [14.346979746000045, 122.52032594600007],
  [14.347167427000045, 122.5202438450001],
  [14.347321631000057, 122.52034887000002],
  [14.34775376600004, 122.5204048490001],
  [14.347887953000054, 122.52049502700004],
  [14.348095158000035, 122.52047809700002],
  [14.348337860000072, 122.52096236500006],
  [14.348800263000044, 122.52132433200006],
  [14.34881249600005, 122.52161987600005],
  [14.348951275000047, 122.52215043000001],
  [14.349215177000076, 122.5223377750001],
  [14.349104132000036, 122.52241718900007],
  [14.348856385000033, 122.52235032300007],
  [14.348745232000056, 122.52242978800007],
  [14.34859528000004, 122.52275854600009],
  [14.348332517000074, 122.52306069200006],
  [14.348328430000038, 122.52317124300009],
  [14.348018696000054, 122.52383857400002],
  [14.347829085000058, 122.5239452730001],
  [14.34759295300006, 122.52383259800001],
  [14.347180360000038, 122.523312615],
  [14.347116672000027, 122.52309157500008],
  [14.346965202000035, 122.52301966700009],
  [14.346828938000044, 122.52275022300012],
  [14.346836383000038, 122.52257299500002],
  [14.346735755000054, 122.5223017400001],
  [14.34663037200005, 122.52228180400004],
  [14.346665031000043, 122.52206250600011],
  [14.346345752000047, 122.5220363310001],
  [14.346318792000034, 122.52219944500007],
  [14.346405541000024, 122.52246287000003],
  [14.34612426600006, 122.52233763600009],
  [14.346010525000054, 122.52210454700003],
  [14.345777120000037, 122.52201694000007],
  [14.345679095000037, 122.52214681200007],
  [14.345952111000031, 122.52243934500007],
  [14.346009836000064, 122.52266365000003],
  [14.34614067800004, 122.52286707500002],
  [14.346083752000027, 122.52305526500004],
  [14.34589386500005, 122.52322591600011],
  [14.34576394800007, 122.52325473100007],
  [14.345470829000021, 122.5229756660001],
  [14.34539346400004, 122.52310028200009],
  [14.345221590000051, 122.52299835000008],
  [14.34503998100007, 122.5227671770001],
  [14.34493360600004, 122.52252534600007],
  [14.344780271000047, 122.522533038],
  [14.34443499400004, 122.52243761600005],
  [14.344271098000036, 122.52222619400004],
  [14.344121244000064, 122.52224529300008],
  [14.343802985000025, 122.52240900700008],
  [14.34378597500006, 122.52256639500001],
  [14.343447792000063, 122.52273004200003],
  [14.343407292000052, 122.52255237800011],
  [14.343192438000074, 122.52269390700008],
  [14.343239719000053, 122.52290542800006],
  [14.342988724000065, 122.52284102300007],
  [14.342954809000048, 122.52294333600003],
  [14.342760700000042, 122.52293686300004],
  [14.342520437000076, 122.52304087100003],
  [14.342214101000025, 122.52328459700004],
  [14.34208880600005, 122.52342969500012],
  [14.342203916000074, 122.5235096240001],
  [14.342500658000063, 122.52338716200006],
  [14.342548966000038, 122.52370132900012],
  [14.342356240000072, 122.52386435800008],
  [14.34222144100005, 122.52375057600011],
  [14.342036092000058, 122.5238910490001],
  [14.342177265000032, 122.52408998600004],
  [14.342032465000045, 122.52419607400009],
  [14.34203881600007, 122.52440152500003],
  [14.342338384000072, 122.52464580900005],
  [14.342370997000046, 122.5247918010001],
  [14.342221799000072, 122.52521306200003],
  [14.341910312000039, 122.52541570400001],
  [14.341658920000043, 122.52544286600005],
  [14.341553778000048, 122.525215978],
  [14.341466430000025, 122.52546817000007],
  [14.34127237000007, 122.52551424700005],
  [14.341165853000064, 122.52571038100007],
  [14.34102692600004, 122.52579960700007],
  [14.340919854000049, 122.52567166500012],
  [14.340766124000027, 122.52566800700004],
  [14.34018055100006, 122.52591280400009],
  [14.340101188000062, 122.5257573],
  [14.339986917000033, 122.52584683400005],
  [14.340209797000057, 122.52608483000006],
  [14.340165714000022, 122.52624565400004],
  [14.339922892000061, 122.52604019700004],
  [14.339952526000047, 122.52584063200004],
  [14.339855924000062, 122.52565558200001],
  [14.339656649000062, 122.52568769800007],
  [14.339353998000036, 122.5260409660001],
  [14.339250460000073, 122.5263459680001],
  [14.339351532000023, 122.52644588300006],
  [14.339103494000028, 122.52671152000005],
  [14.338632841000049, 122.52652751200003],
  [14.338696176000042, 122.527117169],
  [14.338619406000078, 122.52744722700004],
  [14.338470594000057, 122.52740791200006],
  [14.338183272000038, 122.5270658720001],
  [14.338089583000055, 122.527169737],
  [14.337637354000037, 122.52723561100004],
  [14.337541560000034, 122.52733521100004],
  [14.337634570000034, 122.52761530500004],
  [14.337483058000032, 122.52770505900003],
  [14.337224327000058, 122.52769899900011],
  [14.336793063000073, 122.52753874600012],
  [14.336786158000052, 122.52740721200007],
  [14.33658514700005, 122.527279023],
  [14.336259349000045, 122.52746770200008],
  [14.33622895600007, 122.5276941940001],
  [14.33641730100004, 122.52788188500006],
  [14.336640372000033, 122.52823146900005],
  [14.336402122000038, 122.52860763400008],
  [14.335936170000025, 122.52875467700005],
  [14.335900010000046, 122.52908542500006],
  [14.335571723000044, 122.5290309080001],
  [14.335418984000057, 122.52928574200007],
  [14.335203455000055, 122.52925210300009],
  [14.335012888000051, 122.52936402700004],
  [14.335119913000028, 122.5295104710001],
  [14.335401844000046, 122.52944274200001],
  [14.335669702000075, 122.52949465400002],
  [14.335653124000032, 122.52965571000004],
  [14.335753529000044, 122.5298175260001],
  [14.335999623000077, 122.52995925200003],
  [14.33598702200004, 122.53012712800012],
  [14.336273787000039, 122.53023288700001],
  [14.336282261000065, 122.5303357900001],
  [14.33609927200007, 122.53046966400007],
  [14.33599169200005, 122.53040181200004],
  [14.335753887000067, 122.53045612400001],
  [14.335775031000026, 122.53061022600002],
  [14.335986888000036, 122.53058163800006],
  [14.335951624000074, 122.53072713800009],
  [14.336252261000027, 122.53087033300005],
  [14.336175930000024, 122.53105064800002],
  [14.336348249000025, 122.53110013300011],
  [14.336409799000023, 122.53122947100007],
  [14.336405746000025, 122.5314341610001],
  [14.336579719000042, 122.53141734700012],
  [14.336686722000024, 122.53156233800007],
  [14.33672933500003, 122.5318529110001],
  [14.336864418000062, 122.531888019],
  [14.337019067000028, 122.53220812500001],
  [14.33708092300003, 122.53198907800004],
  [14.337286263000067, 122.531988984],
  [14.337468573000024, 122.5318778300001],
  [14.337588465000067, 122.53192773700005],
  [14.337641293000047, 122.53212659600001],
  [14.337798218000046, 122.53199786700009],
  [14.337929853000048, 122.53201571500006],
  [14.337926960000061, 122.53231336100009],
  [14.337987303000034, 122.53244937400007],
  [14.33797001000005, 122.53269630300008],
  [14.338233665000075, 122.53247039900009],
  [14.338183786000059, 122.5324037800001],
  [14.338340897000023, 122.53220599500003],
  [14.33875740700006, 122.53240038400008],
  [14.33934756900004, 122.53178510100008],
  [14.339530507000063, 122.53180103900002],
  [14.339755815000046, 122.53189195300001],
  [14.340091456000039, 122.53230608100012],
  [14.340270677000035, 122.5322814970001],
  [14.340618598000049, 122.53239399900008],
  [14.340808784000046, 122.53232089900007],
  [14.340987088000077, 122.53264824200005],
  [14.34097637900004, 122.53278894900006],
  [14.341242430000023, 122.53313995200006],
  [14.341116725000063, 122.53336709900009],
  [14.340957098000047, 122.53354668400004],
  [14.341029665000065, 122.53378939600009],
  [14.341164970000023, 122.53389174400002],
  [14.340953459000048, 122.53410501100007],
  [14.341174955000042, 122.53427092600009],
  [14.341200142000048, 122.53458264000005],
  [14.340980786000046, 122.53468],
  [14.340854886000045, 122.53493731200001],
  [14.340917744000024, 122.53522900700011],
  [14.341165045000025, 122.53548438500002],
  [14.341282371000034, 122.53578235700002],
  [14.341064747000075, 122.53620387700005],
  [14.340921903000037, 122.53626553700008],
  [14.340697219000049, 122.53648612300003],
  [14.340533512000036, 122.53699893900011],
  [14.340471857000068, 122.53734955100003],
  [14.340523249000057, 122.53757186700011],
  [14.340198238000028, 122.53756474700003],
  [14.340125326000077, 122.537666607],
  [14.340147731000059, 122.53807742200001],
  [14.339976208000053, 122.53816810300009],
  [14.339775313000075, 122.53812690000007],
  [14.339638948000072, 122.53823829600003],
  [14.339755948000061, 122.53835875700008],
  [14.339577123000026, 122.53844944600007],
  [14.33928484200004, 122.53832920800005],
  [14.339069341000027, 122.53828802400005],
  [14.338865099000031, 122.5384993560001],
  [14.338719361000074, 122.53877468900009],
  [14.338821894000034, 122.53901201300005],
  [14.338815150000073, 122.53947939500006],
  [14.338775148000025, 122.539618904],
  [14.338526835000039, 122.53962299200009],
  [14.338486549000038, 122.53952504600011],
  [14.33820675100003, 122.53949335700008],
  [14.337563450000061, 122.53899288700006],
  [14.337384584000063, 122.53904965200002],
  [14.337016167000058, 122.53938934200005],
  [14.336875357000054, 122.53968134000002],
  [14.337138681000056, 122.54001645300002],
  [14.337065792000033, 122.54013715700012],
  [14.336905022000053, 122.54005821100009],
  [14.336755320000066, 122.54007347800007],
  [14.336407676000022, 122.53992911700004],
  [14.336159098000053, 122.54007385400007],
  [14.336125149000054, 122.54020259900005],
  [14.336285471000053, 122.54037166900002],
  [14.336288957000022, 122.54056592000006],
  [14.336095527000055, 122.54056772000001],
  [14.336105984000028, 122.5409832040001],
  [14.335935247000066, 122.54095236],
  [14.335926535000056, 122.54113222100011],
  [14.335792354000034, 122.54124193900009],
  [14.336282029000074, 122.54126891600004],
  [14.33622626500005, 122.54114661000006],
  [14.336348247000046, 122.54080666800007],
  [14.336538191000045, 122.54056205300003],
  [14.336600775000022, 122.54082105600003],
  [14.336504932000025, 122.54107286500005],
  [14.336284482000053, 122.54144088700002],
  [14.336002178000058, 122.54164233300003],
  [14.33593944300003, 122.54181859900007],
  [14.335743441000034, 122.54167650700003],
  [14.335504703000026, 122.5416675140001],
  [14.33556395200003, 122.54182939000009],
  [14.335515158000021, 122.54210278200003],
  [14.335360065000032, 122.54225926300012],
  [14.33517186000006, 122.5423006310001],
  [14.335021705000031, 122.5426334440001],
  [14.335236045000045, 122.54293021700005],
  [14.33524650000004, 122.54305252300003],
  [14.335385909000024, 122.5431694350001],
  [14.335521834000076, 122.54311907500005],
  [14.336039925000023, 122.54327346700006],
  [14.336355883000067, 122.54355048100001],
  [14.336476564000066, 122.54378792700004],
  [14.337017592000052, 122.54410685100004],
  [14.337108948000036, 122.5442455220001],
  [14.337098196000056, 122.54456168900003],
  [14.337178806000054, 122.54461715800005],
  [14.337200299000074, 122.54483348500003],
  [14.337157302000037, 122.54509972900007],
  [14.336899340000059, 122.54554901600011],
  [14.336802968000029, 122.54586509100011],
  [14.336571229000072, 122.5459211540001],
  [14.336440995000032, 122.54605901500008],
  [14.336253988000067, 122.54645536700002],
  [14.336093700000049, 122.54664492400002],
  [14.335900022000033, 122.54675865500008],
  [14.335576106000076, 122.5471653400001],
  [14.335301371000071, 122.54745074100003],
  [14.335129075000054, 122.54784825200011],
  [14.335183130000075, 122.54804352100007],
  [14.335046306000038, 122.54832422000004],
  [14.335118941000076, 122.54864676400007],
  [14.335366309000051, 122.54880889700007],
  [14.335267823000038, 122.54898730700006],
  [14.335048229000051, 122.54898730700006],
  [14.334840675000066, 122.5491505650001],
  [14.334688646000075, 122.54968232400006],
  [14.334572091000041, 122.54990319300009],
  [14.334582324000053, 122.55004640700008],
  [14.334492156000067, 122.55022217700002],
  [14.33424240200003, 122.55054732400004],
  [14.33394968500005, 122.550805996],
  [14.333712054000046, 122.55110191300002],
  [14.33351156200007, 122.55124263000005],
  [14.333146664000026, 122.55135023900004],
  [14.332704488000047, 122.55112882200001],
  [14.331806010000037, 122.55081015000007],
  [14.331483218000074, 122.55103157000008],
  [14.331382972000029, 122.55115573100011],
  [14.331027962000064, 122.5514641420001],
  [14.330845514000032, 122.55167728300012],
  [14.330544243000077, 122.55190713500008],
  [14.330241539000042, 122.55223424000008],
  [14.329918519000046, 122.55245440500005],
  [14.329760056000055, 122.55263263400002],
  [14.329654413000071, 122.5528528000001],
  [14.329890076000027, 122.55294925500004],
  [14.330021208000062, 122.55318772400005],
  [14.32995236100004, 122.55335575300012],
  [14.330089228000077, 122.55355859600002],
  [14.330372872000055, 122.55366259100003],
  [14.330292744000076, 122.55382585200005],
  [14.330136620000076, 122.55372136200003],
  [14.330063521000056, 122.55380602700006],
  [14.330158556000072, 122.55414752900003],
  [14.330011930000069, 122.55432347300007],
  [14.329621495000026, 122.55436693900003],
  [14.329612293000025, 122.55457829500006],
  [14.329516685000044, 122.55472523700007],
  [14.329383552000024, 122.55462366400002],
  [14.329175978000023, 122.55482596500008],
  [14.329100039000025, 122.55498419800006],
  [14.328736211000034, 122.55524282200008],
  [14.328659071000061, 122.55540961000008],
  [14.328541366000024, 122.55545729900007],
  [14.328602408000052, 122.55570740200005],
  [14.328507767000076, 122.5557965590001],
  [14.328271909000023, 122.55571729000008],
  [14.328120023000054, 122.55574600200009],
  [14.32770277700007, 122.55569656800003],
  [14.327602729000034, 122.55556139700002],
  [14.327479751000055, 122.55593407100002],
  [14.327519261000077, 122.55620624900007],
  [14.327432639000051, 122.55638503900002],
  [14.327156548000062, 122.55647163200001],
  [14.327075342000057, 122.55658058100005],
  [14.32689397400003, 122.55700241400007],
  [14.326891261000071, 122.55717841400008],
  [14.32709967900007, 122.55724903200007],
  [14.327202530000022, 122.5573915120001],
  [14.327110490000052, 122.55766528400011],
  [14.326647627000057, 122.55785243900004],
  [14.326714330000073, 122.55792145400005],
  [14.326945567000053, 122.55792146400006],
  [14.327141776000076, 122.55779129400003],
  [14.327253885000061, 122.55795763800006],
  [14.327362486000027, 122.55825777900009],
  [14.327029630000027, 122.55854343500005],
  [14.326843935000056, 122.55861574800008],
  [14.326840423000021, 122.55881101900002],
  [14.326428326000041, 122.55891070500002],
  [14.326349306000054, 122.55902408000009],
  [14.326506919000053, 122.55919904500001],
  [14.326676439000039, 122.55915301100003],
  [14.326735911000071, 122.55931569600011],
  [14.32691731600005, 122.55953056800001],
  [14.327083868000045, 122.55934333900007],
  [14.32722355900006, 122.55927581500009],
  [14.327280058000042, 122.55943543],
  [14.327977413000042, 122.55937803200004],
  [14.32832181200007, 122.55933108200009],
  [14.328453748000072, 122.55937334400005],
  [14.328622081000049, 122.55927943600011],
  [14.32879495900005, 122.55945787100006],
  [14.329063376000022, 122.55953770000008],
  [14.329227156000059, 122.55965978800009],
  [14.329409136000038, 122.55961753100007],
  [14.329531974000076, 122.55949075300009],
  [14.329759448000061, 122.55944849800005],
  [14.329932333000045, 122.55917146200011],
  [14.330413752000027, 122.55885719100002],
  [14.330568436000021, 122.55880084500006],
  [14.330623027000058, 122.5589745850001],
  [14.33096424100006, 122.55887598000004],
  [14.331178066000064, 122.55899807000003],
  [14.330936939000026, 122.55912954500002],
  [14.330691255000033, 122.55972118800003],
  [14.330868682000073, 122.55991840600007],
  [14.330905075000032, 122.56005457900005],
  [14.330800432000046, 122.56029874800004],
  [14.330704891000039, 122.56037857100011],
  [14.330459222000059, 122.56023300200002],
  [14.330263589000026, 122.560495951],
  [14.330145298000048, 122.56072603100006],
  [14.330199890000074, 122.56080116200008],
  [14.330127091000065, 122.56108759000006],
  [14.329828644000031, 122.56154937000008],
  [14.329719447000059, 122.56183579700007],
  [14.329369131000021, 122.56199543700006],
  [14.329214449000062, 122.56199073700009],
  [14.328950576000068, 122.56209872900001],
  [14.328759492000074, 122.56230532600011],
  [14.328864120000048, 122.56262462600012],
  [14.328968757000041, 122.56267158500009],
  [14.329123441000036, 122.56260585400003],
  [14.329332711000063, 122.56279837900001],
  [14.329287204000025, 122.56319750000011],
  [14.32918255800007, 122.56345105700007],
  [14.328955074000078, 122.5637374800001],
  [14.328818592000061, 122.56366234400002],
  [14.328545612000028, 122.56395346200009],
  [14.32852740800007, 122.56411780700012],
  [14.328390152000054, 122.56429875000003],
  [14.328104192000069, 122.56425990600007],
  [14.328040223000073, 122.56436863800002],
  [14.327829511000061, 122.56450843300001],
  [14.327825738000058, 122.56475697300004],
  [14.327697802000046, 122.56492395300006],
  [14.327493396000023, 122.56500609600005],
  [14.32734417200004, 122.56516437400012],
  [14.327112038000053, 122.5655750520001],
  [14.327020834000052, 122.56593439700009],
  [14.32708714100005, 122.56620819200009],
  [14.326942055000075, 122.56649053400008],
  [14.32682184600003, 122.566631701],
  [14.32689229400006, 122.56696111400004],
  [14.326784513000064, 122.56721779200006],
  [14.32638148500007, 122.56776855900011],
  [14.326170958000034, 122.56790167200006],
  [14.325979398000072, 122.5681620250001],
  [14.32587674000007, 122.56846419200008],
  [14.325990011000044, 122.56873927400011],
  [14.326093207000042, 122.5688083330001],
  [14.325877328000047, 122.56919701800007],
  [14.325547820000054, 122.56947210100009],
  [14.325110485000039, 122.5694747010001],
  [14.32455703100004, 122.56979085800003],
  [14.324475977000077, 122.56994109900006],
  [14.324290205000068, 122.56992946000003],
  [14.32410472500004, 122.57005573900005],
  [14.32372947600004, 122.57041810700002],
  [14.323570962000076, 122.57050910600003],
  [14.323241581000048, 122.57087087000002],
  [14.323076282000045, 122.57091675100003],
  [14.322710813000072, 122.57134721800003],
  [14.322491890000038, 122.57173615500005],
  [14.322256970000069, 122.57247061200007],
  [14.322236212000064, 122.57291979100012],
  [14.322385405000034, 122.57324064900001],
  [14.322399831000041, 122.57350263700005],
  [14.322324108000032, 122.57370972700005],
  [14.322433895000074, 122.57400278600005],
  [14.32238846100006, 122.57415126500007],
  [14.322767050000039, 122.57441306900012],
  [14.322835200000043, 122.57426849700005],
  [14.323100220000072, 122.57411611500004],
  [14.323323595000033, 122.57393247000005],
  [14.323440958000049, 122.57400280600007],
  [14.32343338000004, 122.57431149100012],
  [14.323505312000066, 122.574342752],
  [14.323796837000032, 122.57412394300002],
  [14.323893149000071, 122.57398327900012],
  [14.324033228000076, 122.5740145420001],
  [14.32403701000004, 122.57421772700002],
  [14.32414680100004, 122.57428806300004],
  [14.324339885000029, 122.57426853100003],
  [14.324457246000065, 122.57443655300006],
  [14.32416192900007, 122.57498358500004],
  [14.32425278900007, 122.57510054800002],
  [14.324086201000057, 122.57534280400012],
  [14.323983974000043, 122.57559287600009],
  [14.323817387000076, 122.57578824200004],
  [14.323753021000073, 122.57596618100001],
  [14.323991531000047, 122.5761263930001],
  [14.324029385000074, 122.57631004200005],
  [14.32398773500006, 122.57647805900001],
  [14.323855225000045, 122.57652885300001],
  [14.323575043000062, 122.57718919700005],
  [14.323495532000038, 122.5773259550001],
  [14.323298659000045, 122.57745098700002],
  [14.323287296000046, 122.57757993300004],
  [14.323115596000036, 122.57784604300002]


];

const polygonCoordsJpg = [
  [14.311826454000027, 122.7249688610001],
  [14.311684702000036, 122.7249543590001],
  [14.29965308100003, 122.72664085200006],
  [14.284766423000065, 122.72351408700001],
  [14.27515007900007, 122.72803175000001],
  [14.260157668000033, 122.73507441200002],
  [14.25841639600003, 122.73482720900006],
  [14.25713810700006, 122.73432364200005],
  [14.256305283000074, 122.73292914400008],
  [14.255289810000022, 122.73205188800011],
  [14.250871214000028, 122.73120754100012],
  [14.247626449000052, 122.72694952600011],
  [14.245308614000066, 122.72458351400007],
  [14.243608729000073, 122.72367450400009],
  [14.239956966000022, 122.72367948100009],
  [14.233304443000065, 122.7159314480001],
  [14.23303095700004, 122.69029214400007],
  [14.216103105000059, 122.68557268900008],
  [14.188864098000067, 122.67780906000007],
  [14.18979074200007, 122.67433834000008],
  [14.189896889000067, 122.67392250600005],
  [14.194016261000058, 122.66192003800006],
  [14.19744849600005, 122.65827921300001],
  [14.200532336000038, 122.64509071900011],
  [14.200295095000058, 122.64313284800005],
  [14.200016143000028, 122.62501934300008],
  [14.203297832000032, 122.61076052600004],
  [14.205743668000025, 122.60505927500003],
  [14.209265986000048, 122.59661536300007],
  [14.210977182000022, 122.58915474100002],
  [14.21022522900006, 122.57802311000012],
  [14.222815410000067, 122.58301783900004],
  [14.245527464000077, 122.58531010600007],
  [14.258566656000028, 122.58535285700009],
  [14.271002329000055, 122.58520244400006],
  [14.27811261100004, 122.58473818800007],
  [14.283341053000072, 122.57961644800002],
  [14.310706864000053, 122.57747833100007],
  [14.323115596000036, 122.57784604300002],
  [14.322909466000056, 122.57810311500009],
  [14.322822108000025, 122.57863815100006],
  [14.322746379000023, 122.57886868600008],
  [14.322723645000053, 122.57931022200012],
  [14.322560835000047, 122.57965015800005],
  [14.322284445000037, 122.58001353300006],
  [14.32223900400004, 122.58020499300005],
  [14.32243586800007, 122.58031831700009],
  [14.322534208000036, 122.58053801900007],
  [14.322469831000035, 122.58088186500004],
  [14.322231302000034, 122.58116318600003],
  [14.322034428000052, 122.58125304600003],
  [14.321770939000032, 122.58187071200007],
  [14.321596768000063, 122.58219501500002],
  [14.321252230000027, 122.58249977000003],
  [14.32121815000005, 122.58260917500002],
  [14.320972181000059, 122.58273909200011],
  [14.321228230000031, 122.58290511000007],
  [14.321379234000062, 122.58294576900005],
  [14.321395642000027, 122.58315582600005],
  [14.321162566000055, 122.5833692650001],
  [14.321106754000027, 122.58358948500006],
  [14.321004987000038, 122.58372500300004],
  [14.320757161000074, 122.58356938300005],
  [14.320597727000063, 122.58393684700002],
  [14.32043562900003, 122.58457305400009],
  [14.320106130000056, 122.58529975300007],
  [14.320161921000022, 122.5855638060001],
  [14.32004235200003, 122.58559122400004],
  [14.320026403000043, 122.58580512200001],
  [14.320164563000048, 122.58602725100002],
  [14.320233636000069, 122.58635907100006],
  [14.320204402000059, 122.58653732000005],
  [14.320015737000062, 122.58683348300008],
  [14.319993984000064, 122.5870961600001],
  [14.319722949000038, 122.58744716100011],
  [14.319616655000061, 122.58772686900011],
  [14.319398757000044, 122.58812723500012],
  [14.319377495000026, 122.58824241100001],
  [14.319526284000062, 122.58843969700001],
  [14.31965116400005, 122.58850003300006],
  [14.319752120000032, 122.58878797700004],
  [14.319926407000025, 122.58904693000011],
  [14.320064557000023, 122.58941988700008],
  [14.319857292000052, 122.58970507400011],
  [14.319854624000072, 122.58989977500005],
  [14.31997947800005, 122.59048937500006],
  [14.319971739000039, 122.59081532700009],
  [14.319799016000047, 122.5910758330001],
  [14.319862773000068, 122.59130618800009],
  [14.319751160000067, 122.59157766800001],
  [14.319703312000058, 122.59193964700012],
  [14.319725585000072, 122.59220567900002],
  [14.319802183000036, 122.59234898500006],
  [14.319816528000047, 122.59264053000004],
  [14.319778211000028, 122.59285300800002],
  [14.319510072000071, 122.59313959300005],
  [14.319553140000039, 122.59350525700006],
  [14.31938075200003, 122.59388079100006],
  [14.319107829000075, 122.5940932520001],
  [14.31892586600003, 122.59444407800004],
  [14.31901681000005, 122.59481673800008],
  [14.319012, 122.5951231030001],
  [14.319169975000023, 122.59547395300001],
  [14.319136437000054, 122.5957852570001],
  [14.319026292000046, 122.59611138100001],
  [14.319016687000044, 122.59649186800004],
  [14.318911224000033, 122.59685224100008],
  [14.318738838000058, 122.59713388600005],
  [14.318734028000051, 122.59742542900005],
  [14.318786681000063, 122.59759344100007],
  [14.318256168000062, 122.59773730600011],
  [14.317945408000071, 122.59795776300007],
  [14.317898814000046, 122.59777736],
  [14.317681289000063, 122.597833465],
  [14.317444309000052, 122.5982984640001],
  [14.317141273000061, 122.59904005800001],
  [14.316978086000063, 122.59957722000001],
  [14.316640091000068, 122.60024265100003],
  [14.316109206000021, 122.60134970600006],
  [14.315573786000073, 122.60254900900009],
  [14.315593787000068, 122.60281783800008],
  [14.315456382000036, 122.60289167300004],
  [14.315436294000051, 122.60327971400011],
  [14.315513563000025, 122.60340084400002],
  [14.315181434000067, 122.60407433600005],
  [14.315158480000036, 122.60447904700004],
  [14.315270098000042, 122.60459132100004],
  [14.315135541000075, 122.60476559500012],
  [14.31513837500006, 122.60496647800005],
  [14.315063937000048, 122.60508167900002],
  [14.315052451000042, 122.60533970000006],
  [14.314881068000034, 122.6055496670001],
  [14.314873082000076, 122.6057483840001],
  [14.314684971000077, 122.60603135000008],
  [14.314688812000043, 122.60613430100011],
  [14.314849082000023, 122.6062997570001],
  [14.314729224000075, 122.60677647400007],
  [14.314855168000065, 122.60678535600005],
  [14.314872305000051, 122.60703941300005],
  [14.314749174000042, 122.60735843700002],
  [14.314643250000074, 122.60745886000007],
  [14.314628909000078, 122.60765382900001],
  [14.314812076000067, 122.60782519700001],
  [14.314843536000069, 122.60799949500006],
  [14.314817720000065, 122.608356952],
  [14.31474612900007, 122.60855486500009],
  [14.31482337400007, 122.60880302400005],
  [14.31477753400003, 122.60908070500011],
  [14.314622924000048, 122.60933769000007],
  [14.314677301000074, 122.60939087400004],
  [14.314593063000075, 122.60964253000009],
  [14.31463024900006, 122.60980205800001],
  [14.31449279900005, 122.61014471100009],
  [14.314378273000045, 122.61032784600002],
  [14.314378236000039, 122.61055531300008],
  [14.314177108000024, 122.61050838000006],
  [14.314050011000063, 122.6106971910001],
  [14.314058024000076, 122.61088537800003],
  [14.313811298000076, 122.61104307800008],
  [14.313274978000038, 122.61114537800006],
  [14.313167750000048, 122.61126402800005],
  [14.312870109000073, 122.61122523100005],
  [14.312599356000021, 122.61073535100002],
  [14.31223680000005, 122.61024388800001],
  [14.31212860200003, 122.60993084300003],
  [14.31219025400003, 122.60984588500003],
  [14.312025731000062, 122.60961891700003],
  [14.312164495000047, 122.609496755],
  [14.311948261000055, 122.60911744900011],
  [14.31193458100006, 122.60892040700003],
  [14.311525619000065, 122.60878708700011],
  [14.311655966000046, 122.6083901080001],
  [14.311772806000022, 122.60832857500009],
  [14.31184580200005, 122.60792113500008],
  [14.311704449000047, 122.60786879700004],
  [14.311513269000045, 122.60762669100006],
  [14.31139494100006, 122.60707293200005],
  [14.311469359000057, 122.60698210100009],
  [14.311323153000046, 122.60658997900009],
  [14.31099580800003, 122.60626213600005],
  [14.311059586000056, 122.60621434400002],
  [14.310942027000067, 122.605980939],
  [14.31101103900005, 122.6057188310001],
  [14.310975148000068, 122.60558492900009],
  [14.311066245000063, 122.60525729400001],
  [14.311066243000027, 122.60509490100003],
  [14.310936490000074, 122.60469889300009],
  [14.310995425000044, 122.60459246200003],
  [14.310413427000071, 122.60343954900009],
  [14.310150308000061, 122.60282640700007],
  [14.310000514000023, 122.60270025200009],
  [14.30996779800006, 122.6025634340001],
  [14.309718644000043, 122.60243026600006],
  [14.309650046000058, 122.6022277300001],
  [14.309717456000044, 122.60185207200004],
  [14.309641234000026, 122.60156301400002],
  [14.309614594000038, 122.6010287690001],
  [14.309507886000063, 122.60077904100001],
  [14.309473480000065, 122.60044474500012],
  [14.30930397000003, 122.6002782490001],
  [14.309350935000055, 122.60007802400003],
  [14.309238612000058, 122.60006327400004],
  [14.309321659000034, 122.59976613600008],
  [14.30915827800004, 122.59967129900008],
  [14.309127639000053, 122.59951112200008],
  [14.309276715000067, 122.59932354000011],
  [14.309109030000059, 122.59915711100007],
  [14.30911407800005, 122.59887415600008],
  [14.309044638000046, 122.59874137800011],
  [14.30885139000003, 122.59859467700005],
  [14.308917875000077, 122.59841038900004],
  [14.308813386000054, 122.59816924900008],
  [14.308720306000055, 122.5983358950001],
  [14.308583529000032, 122.59837118700011],
  [14.308385433000069, 122.59800040100004],
  [14.30815177200003, 122.59801805300003],
  [14.308174549000057, 122.59734824600002],
  [14.308423401000027, 122.59714826700008],
  [14.308159341000078, 122.59704829000009],
  [14.308100469000067, 122.59759527100005],
  [14.307872504000045, 122.59750901600012],
  [14.307563282000046, 122.59730534300002],
  [14.30709026900007, 122.59720537400005],
  [14.306822472000022, 122.59728518200006],
  [14.306573721000063, 122.59711405200005],
  [14.30605993200004, 122.597064724],
  [14.306037608000054, 122.59695284100007],
  [14.305881361000047, 122.59688703200004],
  [14.305425379000042, 122.59677516400006],
  [14.305339273000072, 122.59644280600003],
  [14.30541260900003, 122.59633750100011],
  [14.305272970000033, 122.59573225800011],
  [14.305060081000022, 122.59575451500007],
  [14.304690909000044, 122.5962690230001],
  [14.304294460000051, 122.5964160960001],
  [14.303880654000068, 122.59644512500006],
  [14.303670969000052, 122.59663819400009],
  [14.303353774000072, 122.59661675200005],
  [14.303051579000055, 122.5968077010001],
  [14.302932342000076, 122.59676739400004],
  [14.302895343000046, 122.596949848],
  [14.30261895500007, 122.59705632600003],
  [14.302643629000045, 122.59724090000009],
  [14.302440110000077, 122.59741487200006],
  [14.302512066000077, 122.59755064900003],
  [14.30242805000006, 122.59769954600006],
  [14.302529290000052, 122.5977846610001],
  [14.302515921000065, 122.59795545000009],
  [14.302391250000028, 122.59810045100005],
  [14.302386137000042, 122.59837772300011],
  [14.302440173000036, 122.59851551800011],
  [14.30220190700004, 122.59873421900011],
  [14.302049487000033, 122.59912386600001],
  [14.301885726000023, 122.59933512200007],
  [14.301888286000064, 122.59945131300003],
  [14.301696378000031, 122.59973914900002],
  [14.301600367000049, 122.59996659500007],
  [14.301646427000037, 122.60007486100005],
  [14.301564548000044, 122.60034421000012],
  [14.301029758000027, 122.60076408100008],
  [14.300662929000055, 122.60125268200011],
  [14.300432748000048, 122.60196324900005],
  [14.300631621000036, 122.60210410400009],
  [14.300599047000048, 122.60230579800009],
  [14.300362435000068, 122.60269256100003],
  [14.300387966000073, 122.602997705],
  [14.300159644000075, 122.60325818900003],
  [14.300006202000077, 122.60332338900002],
  [14.29969574300003, 122.60335120600007],
  [14.299650202000066, 122.60351645300011],
  [14.299187355000072, 122.60315023800001],
  [14.298953871000037, 122.60272758500003],
  [14.298819931000025, 122.60272306900004],
  [14.298786389000043, 122.6023448520001],
  [14.298609326000076, 122.60244580900007],
  [14.298554894000063, 122.60292649700011],
  [14.29843051100005, 122.6029005360001],
  [14.298338271000034, 122.60301303200004],
  [14.298161134000054, 122.60308797400012],
  [14.297903981000047, 122.6033144050001],
  [14.297468307000031, 122.60393375700005],
  [14.297503221000056, 122.60405848700009],
  [14.297311227000023, 122.60435228900008],
  [14.29739163000005, 122.60454086000004],
  [14.29760518300003, 122.60475130200007],
  [14.297584786000073, 122.6051746920001],
  [14.297460124000054, 122.60526017000007],
  [14.297456019000037, 122.60542252900007],
  [14.297346091000065, 122.60548158900008],
  [14.296891799000036, 122.60559237500001],
  [14.296633541000062, 122.60580942900003],
  [14.296484887000076, 122.60572069500006],
  [14.296415970000055, 122.60596099000009],
  [14.296275058000049, 122.60611117700012],
  [14.296157550000032, 122.6065023000001],
  [14.296060477000026, 122.60653418000004],
  [14.295893116000059, 122.6068604730001],
  [14.296034743000064, 122.6070466970001],
  [14.295868787000074, 122.60732273500003],
  [14.295661425000048, 122.60737503100006],
  [14.29534657000005, 122.6076992180001],
  [14.295224335000057, 122.6080001150001],
  [14.295107940000037, 122.60809501800009],
  [14.295049022000057, 122.60835007100002],
  [14.295284681000055, 122.60851342],
  [14.295224327000028, 122.60873379000009],
  [14.295599319000075, 122.60907558500003],
  [14.295694529000059, 122.60930587200005],
  [14.295496663000051, 122.60946553400004],
  [14.295114326000032, 122.60983680000004],
  [14.294767690000072, 122.6099903170001],
  [14.294544535000057, 122.60999184900004],
  [14.294219224000074, 122.61015565500009],
  [14.293910912000058, 122.61022934000005],
  [14.293699656000058, 122.61039360500001],
  [14.293552370000043, 122.6105839700001],
  [14.293262199000026, 122.61064426900009],
  [14.293195737000076, 122.61089843000002],
  [14.293020825000042, 122.61112767700001],
  [14.292809715000033, 122.61131325400004],
  [14.292819486000042, 122.61155934700002],
  [14.29275498000004, 122.611619861],
  [14.292741617000047, 122.61217959600003],
  [14.292827578000072, 122.61269557700007],
  [14.292909264000059, 122.61254049000001],
  [14.292906904000063, 122.61223198300002],
  [14.292839568000034, 122.61204180300001],
  [14.293027404000043, 122.61202230000004],
  [14.29313374700007, 122.61218809900004],
  [14.293137289000072, 122.61232098100004],
  [14.29329812900005, 122.61252840500003],
  [14.293331202000047, 122.61292095500005],
  [14.293159974000048, 122.61333561800006],
  [14.292957743000045, 122.61335262300008],
  [14.29300984200006, 122.6135131530001],
  [14.292830081000034, 122.61365691700007],
  [14.29277165700006, 122.61383314400007],
  [14.292622694000045, 122.6137272630001],
  [14.29250135500007, 122.61387411800001],
  [14.292300622000027, 122.61388648400009],
  [14.292188224000029, 122.61399160000008],
  [14.29221368900005, 122.61423429900003],
  [14.292105422000077, 122.61437258100011],
  [14.291997567000067, 122.61423963700008],
  [14.291749004000053, 122.61431101200003],
  [14.29176368900005, 122.61443574800012],
  [14.291645072000051, 122.61453833400003],
  [14.29147167900004, 122.61450736300003],
  [14.291411995000033, 122.61462476800011],
  [14.291171396000038, 122.6146382390001],
  [14.290942613000027, 122.6144978320001],
  [14.290908825000031, 122.61464647500009],
  [14.290754113000048, 122.61458958500009],
  [14.290578061000076, 122.61466115300004],
  [14.290494555000066, 122.6146079350001],
  [14.290252705000057, 122.61468867700012],
  [14.290378965000059, 122.61481346300002],
  [14.290535456000043, 122.61469968900008],
  [14.290681475000042, 122.6147198760001],
  [14.290804177000041, 122.6148813640001],
  [14.290668124000035, 122.61500247900005],
  [14.290852947000076, 122.61509997400003],
  [14.290739135000024, 122.61525228500011],
  [14.29064666100004, 122.61549451600001],
  [14.290469664000057, 122.61558042000001],
  [14.29038074600004, 122.61571804900007],
  [14.290552673000036, 122.6161450830001],
  [14.290492209000035, 122.61629923100008],
  [14.29049813000006, 122.61663283000007],
  [14.290432331000034, 122.61681083200006],
  [14.290281174000029, 122.61695029500004],
  [14.290277615000036, 122.61704938900004],
  [14.290772007000044, 122.61718237200012],
  [14.29099543600006, 122.61718054100004],
  [14.291457789000049, 122.6174870100001],
  [14.29160642100004, 122.61749635300009],
  [14.291629536000073, 122.6176523370001],
  [14.291860710000037, 122.6179349460001],
  [14.292046519000053, 122.61803469100005],
  [14.292243909000035, 122.61806589200012],
  [14.292371944000024, 122.618210868],
  [14.292243901000063, 122.61846781800011],
  [14.291868677000025, 122.61851552300004],
  [14.291678907000062, 122.61868985100011],
  [14.291148705000069, 122.6189494240001],
  [14.290839282000036, 122.61887601300009],
  [14.290618529000028, 122.61892922400011],
  [14.29038201800006, 122.61879525800009],
  [14.290358898000022, 122.61891453700002],
  [14.289793580000037, 122.6190154520001],
  [14.28960907100003, 122.61911465700007],
  [14.289610760000073, 122.61926138700005],
  [14.289471950000063, 122.6194430490001],
  [14.289188908000028, 122.61947985400002],
  [14.289082262000022, 122.61957592400006],
  [14.289097231000028, 122.620094719],
  [14.289007780000077, 122.62042295000003],
  [14.288858817000062, 122.62049107000007],
  [14.288855427000044, 122.62063081300005],
  [14.288660201000027, 122.62067901400007],
  [14.288834270000052, 122.62100270600001],
  [14.288972842000021, 122.62106629200002],
  [14.288971262000075, 122.62128801500012],
  [14.289269240000067, 122.6214077940001],
  [14.289118063000046, 122.62185529900012],
  [14.289240396000025, 122.62181160900002],
  [14.289354880000076, 122.6218990100001],
  [14.28931093600005, 122.62209597800006],
  [14.289168773000029, 122.62224198000001],
  [14.289210765000064, 122.62241147400005],
  [14.28926135100005, 122.62300674500011],
  [14.28939801000007, 122.623892866],
  [14.289540787000021, 122.62449273700008],
  [14.289783549000049, 122.62488847300006],
  [14.28991056700005, 122.62494680800012],
  [14.289990243000034, 122.62512806400002],
  [14.290124242000047, 122.62507200700009],
  [14.290158648000045, 122.62478236900006],
  [14.29044656700006, 122.62486458800004],
  [14.290488216000028, 122.62504584500005],
  [14.290671109000073, 122.62502342200003],
  [14.290862968000056, 122.62524392000012],
  [14.290996968000059, 122.62532053400002],
  [14.290935400000023, 122.625542902],
  [14.291300346000071, 122.6257582610001],
  [14.291562519000024, 122.62599123400003],
  [14.292146545000037, 122.6268821760001],
  [14.292496106000044, 122.62748340000007],
  [14.293065249000051, 122.6282331430001],
  [14.293741587000056, 122.62890312700006],
  [14.293920224000033, 122.62900682300005],
  [14.29398880900004, 122.62889490000009],
  [14.293896302000064, 122.62870890900001],
  [14.29417542400006, 122.628735247],
  [14.294468679000033, 122.62854940300008],
  [14.294677620000073, 122.62867943600008],
  [14.294958338000072, 122.62861689200008],
  [14.295040244000063, 122.62850990700008],
  [14.295356050000066, 122.62862018800001],
  [14.295400710000024, 122.62852637000003],
  [14.29570439500003, 122.62843792300009],
  [14.295878250000044, 122.62829143400006],
  [14.296098953000069, 122.6282832070001],
  [14.296235929000034, 122.62848526900007],
  [14.296339276000026, 122.62838656500003],
  [14.296869524000044, 122.62823423700002],
  [14.297260588000029, 122.62833019900006],
  [14.297676964000061, 122.62849804500001],
  [14.297914235000064, 122.62864737600012],
  [14.298084107000022, 122.62880878200008],
  [14.298346146000029, 122.62895133200004],
  [14.298661973000037, 122.62906359500005],
  [14.299103079000076, 122.6292930840001],
  [14.299402744000076, 122.62932578300001],
  [14.299714307000045, 122.62943840800006],
  [14.29959628000006, 122.62914890400009],
  [14.299884126000052, 122.629327826],
  [14.299971509000045, 122.62928504900003],
  [14.300564103000056, 122.6291517620001],
  [14.300823108000031, 122.62915803100009],
  [14.300910692000059, 122.62897223900006],
  [14.301104116000033, 122.62902178200011],
  [14.301222430000053, 122.62898131500003],
  [14.301360128000056, 122.62913554600004],
  [14.301506285000073, 122.62888694100002],
  [14.301816203000044, 122.6287509330001],
  [14.301900251000063, 122.6290009490001],
  [14.302087641000071, 122.62910587500005],
  [14.302245009000046, 122.62928338600011],
  [14.302591456000073, 122.62919102400008],
  [14.30269816200007, 122.62935973000003],
  [14.302857174000053, 122.62917406000008],
  [14.302956246000065, 122.62928554300004],
  [14.302751425000054, 122.62953628500009],
  [14.302813674000049, 122.62959845400007],
  [14.303080744000056, 122.62923995400001],
  [14.303235362000066, 122.62917364400005],
  [14.303357335000044, 122.62903373300003],
  [14.303748899000027, 122.62908347000007],
  [14.303811149000069, 122.62890940000011],
  [14.30431421700007, 122.62911870300002],
  [14.304496947000075, 122.62914564300002],
  [14.304637507000052, 122.62934872700009],
  [14.304551161000063, 122.62956424300012],
  [14.304663610000034, 122.62959532800005],
  [14.304675660000044, 122.62935701700007],
  [14.304591325000047, 122.62897364500009],
  [14.304943741000045, 122.62890111800004],
  [14.305104381000035, 122.62912285300001],
  [14.305329881000034, 122.62902960100007],
  [14.30553068200004, 122.62917259000005],
  [14.305592931000035, 122.6292844940001],
  [14.305937563000043, 122.62961394400008],
  [14.306305124000062, 122.62950311100008],
  [14.306393386000025, 122.62973993900005],
  [14.306375819000039, 122.6299016590001],
  [14.307138628000075, 122.62996265400011],
  [14.307395038000038, 122.62993054200001],
  [14.307680145000063, 122.62977594800009],
  [14.307455754000046, 122.62951438000005],
  [14.307492983000031, 122.62914647100001],
  [14.307748763000063, 122.62883111500003],
  [14.307796594000024, 122.62869106700009],
  [14.308005420000029, 122.62844851000011],
  [14.308087862000036, 122.62823567200007],
  [14.30818520400004, 122.62831185800007],
  [14.308420994000073, 122.6283079530001],
  [14.308583360000057, 122.62814071600008],
  [14.30871568200007, 122.6283360430001],
  [14.308869852000043, 122.62828325100008],
  [14.308892411000045, 122.62815811400003],
  [14.309061971000062, 122.62815782200005],
  [14.309308370000053, 122.62834753700008],
  [14.309733258000051, 122.62810554200007],
  [14.30997005100005, 122.6282772720001],
  [14.310045160000072, 122.62826232400005],
  [14.31013998700007, 122.62773539900002],
  [14.310243617000026, 122.62792613600004],
  [14.310352537000028, 122.6277658570001],
  [14.310493952000058, 122.627722059],
  [14.310574103000022, 122.62814470500007],
  [14.310702499000058, 122.62805933000004],
  [14.310900396000022, 122.62808791500004],
  [14.311072201000059, 122.62773765700001],
  [14.311240350000048, 122.62769871500007],
  [14.311357912000062, 122.62813000800008],
  [14.311543765000067, 122.62820822100002],
  [14.31167726600006, 122.62841134500002],
  [14.311840462000077, 122.62842762700006],
  [14.312147135000032, 122.62826840200012],
  [14.312393858000064, 122.62835910800004],
  [14.312543943000037, 122.62825745100008],
  [14.31269650300004, 122.6284467800001],
  [14.312836453000045, 122.62848985700009],
  [14.312850659000048, 122.62831384800006],
  [14.312950956000066, 122.62826651900002],
  [14.313031928000044, 122.62806333600008],
  [14.313174941000057, 122.62804642300011],
  [14.313132663000033, 122.62782421100007],
  [14.313229411000066, 122.62774945000001],
  [14.313445285000057, 122.62779814200007],
  [14.313551921000055, 122.62770739700011],
  [14.313738067000031, 122.62735214000008],
  [14.314033583000025, 122.62703261900003],
  [14.314301775000047, 122.62707438100006],
  [14.314467447000027, 122.62702281500003],
  [14.31457526500003, 122.62707438100006],
  [14.315040726000063, 122.62751133300003],
  [14.315511448000052, 122.62804328000004],
  [14.315950851000025, 122.62848449000012],
  [14.316543765000063, 122.62914608800008],
  [14.316884730000027, 122.62947420400008],
  [14.317278241000054, 122.62979358900009],
  [14.318434273000037, 122.63061608600003],
  [14.319159265000053, 122.6310451170001],
  [14.319447652000065, 122.6312386510001],
  [14.31962928200005, 122.63128222500006],
  [14.320192445000032, 122.63107514],
  [14.32108147300005, 122.63110205400005],
  [14.321335199000032, 122.6309194050001],
  [14.321690972000056, 122.63088898],
  [14.321790302000068, 122.63097585300011],
  [14.321773078000035, 122.63122962900002],
  [14.321479662000058, 122.6314016120001],
  [14.320998049000025, 122.63172616300005],
  [14.320355698000071, 122.63206368500005],
  [14.32028187000003, 122.63213333100009],
  [14.319806565000022, 122.63235679200011],
  [14.31936680900003, 122.63260486600007],
  [14.319263470000067, 122.63256984800012],
  [14.318981873000041, 122.63259975500011],
  [14.318768077000072, 122.6327201690001],
  [14.318426830000021, 122.6328227350001],
  [14.318035487000031, 122.633018579],
  [14.31769698000005, 122.63335367400009],
  [14.31726014700007, 122.63387699500004],
  [14.31699324400006, 122.63400662100003],
  [14.316914588000031, 122.63409547100002],
  [14.31661107800005, 122.634263658],
  [14.316417830000034, 122.63429398800008],
  [14.316044973000032, 122.63448090000009],
  [14.315425769000058, 122.63488856000004],
  [14.315046524000024, 122.635030604],
  [14.314876836000053, 122.63496202300007],
  [14.314862196000036, 122.63481090500011],
  [14.31510819600004, 122.63454040800002],
  [14.31512284200005, 122.63433488700002],
  [14.314982274000045, 122.63426839300007],
  [14.314996918000077, 122.63414598700001],
  [14.31485049400004, 122.63394440000002],
  [14.314783143000056, 122.63354393500003],
  [14.314963251000052, 122.63321601200005],
  [14.31480364600003, 122.63319636400001],
  [14.314733363000073, 122.63307093500009],
  [14.314791936000063, 122.63280774100008],
  [14.314718723000055, 122.63271102500005],
  [14.314296410000054, 122.63258257200005],
  [14.313819717000058, 122.63225703100011],
  [14.313215753000065, 122.6319993300001],
  [14.312987328000077, 122.63195550400008],
  [14.312804296000024, 122.63180136500011],
  [14.312646156000028, 122.6317968300001],
  [14.312387989000058, 122.63166562600009],
  [14.312152243000071, 122.63165051400006],
  [14.311854209000046, 122.63175629400007],
  [14.31157014300004, 122.63177291500006],
  [14.310842025000056, 122.63198598500003],
  [14.310401282000043, 122.63216127700002],
  [14.310202852000032, 122.63231516500002],
  [14.309334904000025, 122.6328712610001],
  [14.308354743000052, 122.63321424500009],
  [14.308020960000022, 122.63316196100004],
  [14.30765384800003, 122.6334218720001],
  [14.307331710000028, 122.63345662400002],
  [14.307008016000054, 122.633590464],
  [14.30692016300003, 122.63334566100002],
  [14.306347730000027, 122.63310085300009],
  [14.306051053000033, 122.63323836200004],
  [14.305462415000022, 122.63371586900007],
  [14.304950511000072, 122.63419683300003],
  [14.30403982100006, 122.63514929100006],
  [14.303716154000028, 122.63562857000011],
  [14.303503213000056, 122.63602633100004],
  [14.303376226000069, 122.63677210500009],
  [14.303447969000047, 122.63701690700009],
  [14.303597327000034, 122.63687184500009],
  [14.303762789000075, 122.63688847200001],
  [14.304006019000042, 122.63720971300006],
  [14.304008779000071, 122.63751114400009],
  [14.304060952000043, 122.63768016300003],
  [14.304276505000075, 122.63794536500006],
  [14.30465853100003, 122.63790294400007],
  [14.304807635000031, 122.63782225800003],
  [14.305057809000061, 122.6380270630001],
  [14.305101157000024, 122.63836148400003],
  [14.304947779000031, 122.63851577200012],
  [14.304631560000075, 122.63858146600012],
  [14.304438782000034, 122.63866308000001],
  [14.304128992000074, 122.63872306000007],
  [14.303993178000042, 122.63884976700001],
  [14.303935878000061, 122.63906822900003],
  [14.303582121000034, 122.63919290500007],
  [14.303302089000056, 122.63904494400003],
  [14.303268778000074, 122.63885916100003],
  [14.303110813000046, 122.63881808800011],
  [14.30291821000003, 122.63867299700007],
  [14.302618259000042, 122.63870029800012],
  [14.302318307000064, 122.63881335700012],
  [14.30185454900004, 122.63915379000002],
  [14.301530382000067, 122.63922737200005],
  [14.301427712000077, 122.63919697800009],
  [14.301043506000042, 122.63927913300006],
  [14.30086728200007, 122.63937594600009],
  [14.300670084000046, 122.63960508000002],
  [14.300568416000033, 122.6396422900001],
  [14.300333986000055, 122.63945363100004],
  [14.300182689000053, 122.63909768500002],
  [14.300306078000062, 122.63878930600004],
  [14.300245544000063, 122.63868380800011],
  [14.300317753000058, 122.63845492000007],
  [14.300235801000042, 122.63832786600005],
  [14.299314509000055, 122.63837939900009],
  [14.299016662000042, 122.63874915500003],
  [14.298865893000027, 122.63871915200002],
  [14.298662699000033, 122.6387709930001],
  [14.298366439000063, 122.63863111700005],
  [14.298246938000034, 122.63852209400011],
  [14.29810081100004, 122.6385536040001],
  [14.297953303000043, 122.6386798200001],
  [14.297856581000076, 122.63888852600007],
  [14.297692211000026, 122.63863331900006],
  [14.29758643200006, 122.6380035730001],
  [14.297608645000025, 122.63772573400001],
  [14.297791052000036, 122.63748176400009],
  [14.297832982000045, 122.63702470300007],
  [14.298123148000059, 122.63704197900006],
  [14.298073024000075, 122.63682288100006],
  [14.297768017000067, 122.636549392],
  [14.298029023000026, 122.63636527200003],
  [14.297792221000066, 122.63608975600005],
  [14.297649837000051, 122.63615303600011],
  [14.297581294000054, 122.63599798000007],
  [14.29773166900003, 122.63552025700005],
  [14.297517578000054, 122.6353570760001],
  [14.297569156000066, 122.63488768600007],
  [14.297748615000046, 122.63472378100005],
  [14.297736732000033, 122.63445634100003],
  [14.297459210000056, 122.63454126700003],
  [14.29728903800003, 122.63427502600007],
  [14.297204328000078, 122.63422383800003],
  [14.29717578900005, 122.63402581200012],
  [14.297003719000031, 122.63384952000001],
  [14.296870712000043, 122.6334263650001],
  [14.29672622000004, 122.63313140900004],
  [14.296687735000035, 122.63295710600005],
  [14.296409790000041, 122.63274309000008],
  [14.296446135000053, 122.63237683300008],
  [14.296437581000077, 122.631864532],
  [14.296499582000024, 122.63166595900009],
  [14.29628791600004, 122.63155123000001],
  [14.296172462000072, 122.63162183400004],
  [14.29591872800006, 122.6315512330001],
  [14.295664302000034, 122.631639489],
  [14.295788310000034, 122.63196161700012],
  [14.295446223000056, 122.63176966600008],
  [14.295104135000031, 122.631507112],
  [14.294695752000052, 122.63132381200012],
  [14.294475534000071, 122.63129292600001],
  [14.294225381000047, 122.63105905500004],
  [14.294013961000076, 122.63094297300006],
  [14.293771397000057, 122.6309010550001],
  [14.293527660000052, 122.63097827900003],
  [14.293303166000044, 122.6309518060001],
  [14.292948253000077, 122.63103123700012],
  [14.292961079000065, 122.63091871400002],
  [14.29274551800006, 122.63074483000003],
  [14.292685654000024, 122.63090148000003],
  [14.292463299000076, 122.63098532400011],
  [14.292424812000036, 122.63072497700011],
  [14.292127623000056, 122.63068967900006],
  [14.292013428000075, 122.63040875000002],
  [14.29207328900003, 122.63006235500006],
  [14.291974939000056, 122.63005132500007],
  [14.291870177000021, 122.63025872100002],
  [14.29155802200006, 122.63014179200002],
  [14.291320712000072, 122.63033579900002],
  [14.291006418000052, 122.63015267900005],
  [14.290927311000075, 122.63020563200007],
  [14.290940145000036, 122.6307174960001],
  [14.290884557000027, 122.63086532000011],
  [14.290702129000067, 122.63081678300011],
  [14.290332248000027, 122.63080134400002],
  [14.290180449000047, 122.63092048500005],
  [14.290099327000064, 122.6306591870001],
  [14.289894075000063, 122.63068346000011],
  [14.289868420000062, 122.63080260000004],
  [14.289633234000064, 122.63070993800011],
  [14.289348873000051, 122.63050254900008],
  [14.289282591000074, 122.63029957000003],
  [14.289374525000028, 122.63012747800008],
  [14.289182101000051, 122.63011424400008],
  [14.289184242000033, 122.6303304600001],
  [14.288987991000056, 122.63046170900009],
  [14.288906745000077, 122.63041537800007],
  [14.288810536000028, 122.6306382140001],
  [14.28856038500004, 122.63063159800004],
  [14.288543283000024, 122.63079927700005],
  [14.288220440000032, 122.6308963570001],
  [14.288141332000066, 122.63081693200002],
  [14.287745795000035, 122.63087650600005],
  [14.287555678000047, 122.63079740300009],
  [14.287482986000043, 122.63087241700009],
  [14.287019031000057, 122.63081505900004],
  [14.28676033000005, 122.6309981820001],
  [14.28607936000003, 122.63122337000004],
  [14.285912593000035, 122.63114394700006],
  [14.285728720000066, 122.63095420900004],
  [14.285585473000026, 122.63115056800007],
  [14.285318217000054, 122.63106452700004],
  [14.285130070000037, 122.6311439530001],
  [14.284619003000046, 122.63124384200012],
  [14.28449499900006, 122.63142475500001],
  [14.284328232000064, 122.63146226300012],
  [14.284299066000074, 122.63176217700004],
  [14.284172923000028, 122.63203354600012],
  [14.284249893000037, 122.63209532100007],
  [14.284198581000055, 122.63239095900008],
  [14.28432900200005, 122.63254980900001],
  [14.28427341400004, 122.63272189700001],
  [14.284320452000031, 122.63284544800001],
  [14.284465839000063, 122.63291604900007],
  [14.284751738000068, 122.63323776100003],
  [14.284927057000061, 122.63336351800001],
  [14.28515155100007, 122.63344073600001],
  [14.285294365000027, 122.63357542300002],
  [14.285416827000063, 122.63392342600002],
  [14.285235959000033, 122.63411006400008],
  [14.285049439000034, 122.63418782800011],
  [14.284552252000026, 122.63405368200006],
  [14.283951597000055, 122.63386121000008],
  [14.283753926000031, 122.63390009300008],
  [14.283573059000048, 122.63402062700004],
  [14.282915937000041, 122.63405173100011],
  [14.28268608600007, 122.63394091600003],
  [14.282597787000043, 122.63372193200007],
  [14.282599598000047, 122.63344160100007],
  [14.281800389000068, 122.63346963400011],
  [14.281818499000053, 122.63390134100007],
  [14.281660933000069, 122.63406393100001],
  [14.281695343000024, 122.63427137500003],
  [14.281836609000038, 122.63427137600002],
  [14.281990552000025, 122.63438163900003],
  [14.282115518000069, 122.63435921400003],
  [14.282436738000058, 122.63462272100003],
  [14.282869589000029, 122.63491426700011],
  [14.283061566000072, 122.63494977700009],
  [14.283228577000045, 122.63482269500003],
  [14.28344228800006, 122.63480961400012],
  [14.283527409000044, 122.6349796830001],
  [14.283744607000074, 122.63512307400003],
  [14.28370052300005, 122.63524574600001],
  [14.283404009000037, 122.63529852900001],
  [14.283311622000042, 122.63516304100006],
  [14.28318577500005, 122.63532136300012],
  [14.283104993000052, 122.63567997500002],
  [14.283242659000052, 122.63583757300012],
  [14.283389033000049, 122.63575414700006],
  [14.283503585000062, 122.63584461800008],
  [14.28360490700004, 122.63603739500002],
  [14.283760341000061, 122.63606193800001],
  [14.283729730000061, 122.63621114900002],
  [14.283860183000058, 122.63634750900007],
  [14.283991711000056, 122.63638098100012],
  [14.284093770000027, 122.63667167100004],
  [14.28390054600004, 122.63698591800005],
  [14.28403557300004, 122.63710993600012],
  [14.28423189700004, 122.63707747300009],
  [14.284194360000072, 122.63687166300008],
  [14.284351134000076, 122.63672978800003],
  [14.28451824800004, 122.63670800800003],
  [14.284622243000058, 122.63695277400006],
  [14.284747560000028, 122.63699939100002],
  [14.284919703000071, 122.63729953500001],
  [14.284922656000049, 122.6374025670001],
  [14.285145639000064, 122.6374594240001],
  [14.285321551000038, 122.63774063700009],
  [14.285425811000039, 122.637981437],
  [14.28569860400006, 122.63814453700002],
  [14.28594133100006, 122.63841779100005],
  [14.286187635000033, 122.63905850900005],
  [14.286441598000067, 122.63908392200005],
  [14.286513205000062, 122.6392397080001],
  [14.286680210000043, 122.63928929300005],
  [14.28697175700006, 122.6394789420001],
  [14.286949364000066, 122.6397984680001],
  [14.286892678000072, 122.63998819000005],
  [14.286955641000077, 122.64023378800005],
  [14.286917070000072, 122.6404224480001],
  [14.286799588000065, 122.64053357000012],
  [14.286830877000057, 122.6406513720001],
  [14.28702557400004, 122.64085741600002],
  [14.28687588400004, 122.64114002400004],
  [14.286942157000055, 122.64162642800011],
  [14.286883793000072, 122.6418832060001],
  [14.28654229500006, 122.64201419900007],
  [14.287317894000068, 122.64340766700002],
  [14.28765874000004, 122.64319687800003],
  [14.287874956000053, 122.64319882500001],
  [14.287997167000071, 122.64312704400004],
  [14.288220900000056, 122.64323182100009],
  [14.28828106900005, 122.64311153200003],
  [14.288156987000036, 122.64290587100004],
  [14.288243476000048, 122.64282632700008],
  [14.288405442000055, 122.64249013900007],
  [14.288776824000024, 122.64231707700003],
  [14.288920592000068, 122.64204510900004],
  [14.289064363000023, 122.64166187900003],
  [14.28937585400007, 122.64121683700012],
  [14.289639433000048, 122.64056162700001],
  [14.289603508000027, 122.639992946],
  [14.289483716000063, 122.63965915100005],
  [14.289483724000036, 122.63931299400008],
  [14.289327989000071, 122.63906573600002],
  [14.289327993000029, 122.63889265600005],
  [14.289555618000065, 122.63875666600006],
  [14.289591565000023, 122.63847232000012],
  [14.289495728000077, 122.63826214700009],
  [14.289603531000068, 122.63800996700002],
  [14.29120885900005, 122.6379976180001],
  [14.291364600000065, 122.63808415800008],
  [14.291364595000061, 122.63836849800009],
  [14.291424493000022, 122.63854157600008],
  [14.291568253000037, 122.63870229200006],
  [14.292023500000028, 122.63880119800001],
  [14.292334985000025, 122.63898664200008],
  [14.292586572000062, 122.63906082300002],
  [14.292790233000062, 122.63939462000008],
  [14.292978083000037, 122.63953061100005],
  [14.293145797000022, 122.63979022900003],
  [14.293636969000033, 122.64003749300002],
  [14.293864585000051, 122.64019821200009],
  [14.294152096000062, 122.64059382500011],
  [14.294703170000048, 122.64093999700003],
  [14.29496672700003, 122.64113781000003],
  [14.295182363000038, 122.64138507400003],
  [14.295613642000035, 122.64166943600003],
  [14.295841261000078, 122.64191670500009],
  [14.29615274400004, 122.6421268900001],
  [14.298442745000045, 122.64411990300005],
  [14.298923264000052, 122.6444422080001],
  [14.299320856000065, 122.64487092000002],
  [14.29981289500006, 122.6451093280001],
  [14.300230594000027, 122.64547],
  [14.300373312000033, 122.64575490800007],
  [14.300403342000038, 122.64620649000005],
  [14.30034697800005, 122.64666773600004],
  [14.300373255000068, 122.64700496900002],
  [14.300686879000068, 122.64723368300008],
  [14.300831491000054, 122.64719493000007],
  [14.30127596400007, 122.64677050400007],
  [14.301478817000032, 122.64626854300002],
  [14.30153142000006, 122.6458847990001],
  [14.301684816000034, 122.64589687500006],
  [14.301714857000036, 122.64607905900004],
  [14.301829412000075, 122.64619922700001],
  [14.302077315000076, 122.64621862100012],
  [14.30313161500004, 122.64616828200008],
  [14.30345839000006, 122.64628652400006],
  [14.304029289000027, 122.64689900300004],
  [14.304298249000055, 122.647153193],
  [14.304448490000027, 122.64722103600002],
  [14.304585579000047, 122.6474051670001],
  [14.304558231000044, 122.6475003380001],
  [14.304145710000057, 122.64710199900003],
  [14.304102519000025, 122.64701090400001],
  [14.303495499000064, 122.6463548370001],
  [14.303114507000032, 122.6463606320001],
  [14.302831127000047, 122.64642651600002],
  [14.30247808300004, 122.64643618800005],
  [14.30229778200004, 122.6466067340001],
  [14.302340962000073, 122.64690908200009],
  [14.30243110400005, 122.64700986900004],
  [14.302442362000022, 122.64721337200001],
  [14.302282711000032, 122.64754865800012],
  [14.302061098000024, 122.6476106660001],
  [14.301950391000048, 122.6479740640001],
  [14.301999203000037, 122.64831323700002],
  [14.302117507000048, 122.64854194100008],
  [14.302369695000039, 122.6488678830001],
  [14.302766575000021, 122.64911109400009],
  [14.302849252000044, 122.64928389200008],
  [14.302696279000031, 122.6493884140001],
  [14.302290683000024, 122.6494836390001],
  [14.301925519000065, 122.64917718400011],
  [14.301652665000063, 122.6493732140001],
  [14.301639744000056, 122.64960732200007],
  [14.301538305000065, 122.64961302800009],
  [14.301018174000035, 122.65016686600006],
  [14.301246799000069, 122.65072686600001],
  [14.301458892000028, 122.65082394700005],
  [14.30168758800005, 122.65086393100012],
  [14.302159120000056, 122.65057165000007],
  [14.30209542700004, 122.65091404100008],
  [14.302199307000024, 122.65113366300011],
  [14.302296493000028, 122.65118381900004],
  [14.302313237000021, 122.65141381300009],
  [14.302467857000067, 122.65195589700011],
  [14.302447475000065, 122.65233966300002],
  [14.302545953000049, 122.65261200100008],
  [14.302651877000073, 122.65267337800003],
  [14.30281354300007, 122.65292654600012],
  [14.303289048000067, 122.65321642000004],
  [14.303432145000045, 122.6531799920001],
  [14.303534350000064, 122.65328356200007],
  [14.303814962000047, 122.65336988400009],
  [14.304156166000041, 122.65334512700008],
  [14.304379163000021, 122.65349089800009],
  [14.304554812000049, 122.65347334700004],
  [14.30492343900005, 122.65359152000008],
  [14.305307477000042, 122.65352295200012],
  [14.305415960000062, 122.65340504300002],
  [14.30572548200007, 122.65343928900006],
  [14.305752031000054, 122.65315180400012],
  [14.305639287000076, 122.65296470300007],
  [14.305444732000069, 122.65292133900005],
  [14.305236921000073, 122.65271597900005],
  [14.305316520000076, 122.65259962000005],
  [14.305557509000039, 122.6525631290001],
  [14.305958815000054, 122.65266071300005],
  [14.306279397000026, 122.65259228500008],
  [14.306717152000033, 122.65259459300012],
  [14.30684317600003, 122.65253756000004],
  [14.307102977000056, 122.65254898600006],
  [14.307576116000064, 122.65237332800007],
  [14.307717295000032, 122.65213285200002],
  [14.30784249900006, 122.65211202100011],
  [14.307973772000025, 122.65191196],
  [14.30812121100007, 122.6515118320001],
  [14.308105064000074, 122.65136177900001],
  [14.308370066000066, 122.65121029700003],
  [14.308618464000062, 122.6509810660001],
  [14.308658860000037, 122.65084143700005],
  [14.30921752300003, 122.65042342400011],
  [14.309415434000073, 122.65021294600001],
  [14.309526512000048, 122.65001496600007],
  [14.309661812000058, 122.6499983000001],
  [14.309923248000075, 122.64971517800006],
  [14.310133927000038, 122.64957780700001],
  [14.310645965000049, 122.64941345800003],
  [14.311066431000029, 122.64948830100002],
  [14.311235165000028, 122.64957826000011],
  [14.311462775000052, 122.64957759300012],
  [14.311643687000071, 122.6496915560001],
  [14.31190844300005, 122.64964729100006],
  [14.312118973000054, 122.64967861200012],
  [14.312394385000061, 122.64957448000007],
  [14.312541659000033, 122.64962663500012],
  [14.312662767000063, 122.64977997300002],
  [14.312690149000048, 122.65009452700008],
  [14.312795089000076, 122.65015522700003],
  [14.313323467000032, 122.65025315900004],
  [14.313750661000029, 122.65025050300005],
  [14.313908656000024, 122.65015806800011],
  [14.314107831000058, 122.64948583300009],
  [14.314263811000046, 122.6494203630001],
  [14.314294051000047, 122.64884647300005],
  [14.31435470100007, 122.64885369100011],
  [14.314336407000042, 122.64928938500009],
  [14.314489739000066, 122.64927710100005],
  [14.314452103000065, 122.64974206500005],
  [14.314311073000056, 122.65008411000008],
  [14.314094321000027, 122.65038473700008],
  [14.314105708000056, 122.65056443300011],
  [14.314342518000046, 122.6510066400001],
  [14.31437868200004, 122.65125763800006],
  [14.314330854000048, 122.65150932200004],
  [14.314341979000062, 122.6517244470001],
  [14.314537232000077, 122.65259921300003],
  [14.31446586100003, 122.65304711600004],
  [14.314225104000059, 122.65321955900004],
  [14.314009605000024, 122.65364047100002],
  [14.313893058000076, 122.65373873500005],
  [14.313610065000034, 122.65382343200008],
  [14.313249140000039, 122.65384437900002],
  [14.313039459000038, 122.65394592000007],
  [14.312956557000064, 122.65405658700001],
  [14.312329910000074, 122.65434725000011],
  [14.311981328000059, 122.65440002600008],
  [14.311769483000035, 122.65461134300006],
  [14.311622185000033, 122.65469093500008],
  [14.311317097000028, 122.65468147000001],
  [14.311046401000056, 122.65451783800006],
  [14.310741313000051, 122.6545083740001],
  [14.31036326900005, 122.65457706900008],
  [14.309670960000062, 122.65495115400006],
  [14.30957625700006, 122.65506043300002],
  [14.30956145500005, 122.65547517700008],
  [14.309435155000074, 122.65558531700003],
  [14.309125994000055, 122.655541416],
  [14.308726408000041, 122.65529101200002],
  [14.308541072000025, 122.65525792100004],
  [14.308189580000032, 122.65533739200009],
  [14.307933282000022, 122.65552716400009],
  [14.307723404000058, 122.6557431330001],
  [14.307498249000048, 122.65586312200003],
  [14.307376727000076, 122.65598739300003],
  [14.307052258000056, 122.6561407370001],
  [14.306943751000063, 122.65627294700005],
  [14.306678390000059, 122.65639368200004],
  [14.30642348400005, 122.65637210200009],
  [14.306184095000049, 122.65655943400009],
  [14.30585603700007, 122.65691735200005],
  [14.305089156000065, 122.65781735900009],
  [14.304216039000039, 122.6591363230001],
  [14.303702385000065, 122.65985453200005],
  [14.303283620000059, 122.66048296000008],
  [14.303060555000059, 122.66090142900009],
  [14.302707896000072, 122.66138223900009],
  [14.30245715500007, 122.66153962300007],
  [14.302402844000028, 122.66137574400011],
  [14.302847932000077, 122.66081731700001],
  [14.303090334000046, 122.660418434],
  [14.303297206000025, 122.6601446090001],
  [14.303640015000042, 122.6596042750001],
  [14.304009762000021, 122.65906579400007],
  [14.304319712000051, 122.65864908500009],
  [14.304800958000044, 122.65790453700004],
  [14.305116402000067, 122.65748824100001],
  [14.305408743000044, 122.6569718500001],
  [14.30558201100007, 122.65672869800005],
  [14.305691526000032, 122.656418314],
  [14.305453059000058, 122.65634791100001],
  [14.305218834000073, 122.65613477000011],
  [14.304831625000077, 122.6559472460001],
  [14.304660192000028, 122.65577030600002],
  [14.30450589700007, 122.65570837300004],
  [14.304388783000036, 122.65543670600005],
  [14.304388803000052, 122.65510333100008],
  [14.304604182000048, 122.65478848700002],
  [14.305016978000026, 122.65446439300001],
  [14.305034939000052, 122.65422361900005],
  [14.304884050000055, 122.65404987800002],
  [14.304715269000042, 122.65406788900009],
  [14.304558385000064, 122.65387620000001],
  [14.304236328000059, 122.65373644300007],
  [14.30395059400007, 122.65366103100007],
  [14.303562577000037, 122.6536191240001],
  [14.303131360000066, 122.65349511900001],
  [14.303033949000053, 122.65351521800005],
  [14.30263349300003, 122.65338178600007],
  [14.302314780000074, 122.65338009400011],
  [14.302259583000023, 122.65333653000005],
  [14.301897540000027, 122.65333986200005],
  [14.30180499100004, 122.65351075100011],
  [14.301537091000057, 122.65355262300011],
  [14.301499762000049, 122.65331973600007],
  [14.301291951000053, 122.6533465330001],
  [14.30121401200006, 122.65355763200012],
  [14.301011004000031, 122.65378548000001],
  [14.30075610700004, 122.65389604500001],
  [14.30028343600003, 122.65390297500005],
  [14.299991718000058, 122.65375246600001],
  [14.299715630000037, 122.65371753400007],
  [14.299431725000034, 122.65360196600011],
  [14.29924197300005, 122.65364766800008],
  [14.299192492000032, 122.65383313200005],
  [14.298960688000022, 122.65392721500007],
  [14.29886692100007, 122.65386271000011],
  [14.29850275800004, 122.65410252700008],
  [14.29823256700007, 122.65439606100006],
  [14.298078908000036, 122.65475623800012],
  [14.298394083000062, 122.65542550200007],
  [14.298414926000078, 122.65562709000005],
  [14.29828718500005, 122.65600779800002],
  [14.29758916700007, 122.65646475100004],
  [14.297456336000039, 122.65660183600005],
  [14.297279225000068, 122.65666634900003],
  [14.297041196000066, 122.65683340600003],
  [14.296866691000048, 122.65689791800003],
  [14.29657731900005, 122.65708742900006],
  [14.29621602800006, 122.65716434100011],
  [14.295905833000063, 122.65702648900003],
  [14.29543230400003, 122.65707365900005],
  [14.295325700000035, 122.65698805500006],
  [14.29501470300005, 122.65706133700007],
  [14.294967159000066, 122.65717960100005],
  [14.29459690300007, 122.65736595400006],
  [14.294336258000044, 122.65742396600001],
  [14.294108172000051, 122.65757061900001],
  [14.293971931000044, 122.65757364600006],
  [14.293871971000044, 122.65746748800007],
  [14.29357277500003, 122.65775165900004],
  [14.293498739000029, 122.657721078],
  [14.293113705000053, 122.65776377800012],
  [14.292796776000046, 122.6578737320001],
  [14.292595386000073, 122.6578370100001],
  [14.29239397300006, 122.65790114700008],
  [14.292191869000021, 122.65780689200005],
  [14.29204081100005, 122.65785270300012],
  [14.291765375000068, 122.65783124300003],
  [14.29138490400004, 122.6580112910001],
  [14.291091852000022, 122.65801287500005],
  [14.290920928000048, 122.65808242800006],
  [14.290823201000023, 122.6582790540001],
  [14.290691859000049, 122.65841122600011],
  [14.290549248000048, 122.65839611400008],
  [14.29038588800006, 122.65848200800008],
  [14.289879141000029, 122.65845102600008],
  [14.289542880000056, 122.65860793500008],
  [14.289554012000053, 122.658964878],
  [14.289614850000078, 122.6590268000001],
  [14.289657917000056, 122.65978531100006],
  [14.289781322000067, 122.66003218500009],
  [14.289753131000055, 122.66018660200007],
  [14.289842050000061, 122.660396966],
  [14.289924464000023, 122.66074831800006],
  [14.289885428000048, 122.66078636400005],
  [14.28995049200006, 122.66113547900011],
  [14.290033964000031, 122.66123578300005],
  [14.29004481000004, 122.66145957700007],
  [14.290144571000042, 122.66173260200003],
  [14.29012939100005, 122.66186911500006],
  [14.290239994000046, 122.66196534500011],
  [14.290090356000064, 122.66209962200003],
  [14.29019879200007, 122.6623208640001],
  [14.290244335000068, 122.6626006030001],
  [14.290410632000032, 122.663158758],
  [14.29036942700003, 122.663272892],
  [14.290462681000065, 122.66343626100002],
  [14.29052490600003, 122.663742382],
  [14.29058129200007, 122.66377371300007],
  [14.290782979000028, 122.66412059400011],
  [14.29079815800003, 122.66447194900002],
  [14.29074730700006, 122.66455964800002],
  [14.290775497000027, 122.66493338200007],
  [14.290636696000035, 122.66562937900005],
  [14.290665894000028, 122.66583809100007],
  [14.290761821000046, 122.66607284500003],
  [14.29068781500007, 122.66632456800005],
  [14.290704257000073, 122.66658477800001],
  [14.29087144600004, 122.66669225900011],
  [14.291120865000039, 122.66655367100009],
  [14.291030419000037, 122.66644336400009],
  [14.291030421000073, 122.66629063200003],
  [14.29117020800004, 122.66586106600005],
  [14.29108250400003, 122.6656263100001],
  [14.290956426000037, 122.66556408400004],
  [14.291041394000047, 122.66530670500003],
  [14.291318219000061, 122.66519357300001],
  [14.291038656000069, 122.66496730100005],
  [14.291098955000052, 122.66480891200001],
  [14.291263406000041, 122.66477497300002],
  [14.291477190000023, 122.66496447600002],
  [14.291609978000054, 122.66468257600002],
  [14.291501672000038, 122.66446583200002],
  [14.291652638000073, 122.66446582900005],
  [14.291864080000039, 122.66432358300005],
  [14.292188984000063, 122.66425245500011],
  [14.292310409000038, 122.6640966650001],
  [14.29233994200007, 122.66392733000009],
  [14.292723917000046, 122.66382233400009],
  [14.29281252900006, 122.6639273190001],
  [14.293032415000027, 122.66399504700007],
  [14.293242454000051, 122.66396794900004],
  [14.293704671000057, 122.66374394800005],
  [14.294144433000042, 122.66348316000006],
  [14.294045975000074, 122.66340188200002],
  [14.293839217000027, 122.66336802],
  [14.293914694000023, 122.6631343360001],
  [14.294147247000069, 122.66298192400006],
  [14.294386823000025, 122.66295143700006],
  [14.294527940000023, 122.66284644500001],
  [14.294649370000059, 122.66287353500002],
  [14.294760950000068, 122.66277531700007],
  [14.294862690000059, 122.6628667550001],
  [14.295007088000034, 122.66270080100003],
  [14.295442141000024, 122.66262628100003],
  [14.295711254000025, 122.66262965800001],
  [14.295868785000039, 122.66269061500009],
  [14.296226507000029, 122.66267028400011],
  [14.296702373000073, 122.66251786600003],
  [14.296846773000027, 122.66241625700002],
  [14.297240388000034, 122.66228901200009],
  [14.297558724000055, 122.66213998400008],
  [14.297962383000026, 122.66184870900008],
  [14.298028018000025, 122.66176403800011],
  [14.298221649000027, 122.661808059],
  [14.298375890000045, 122.66162178000002],
  [14.298601037000026, 122.66117300500002],
  [14.29874871900006, 122.66112219900003],
  [14.299070329000074, 122.66078012100002],
  [14.299198212000022, 122.66058029500005],
  [14.299404974000026, 122.66069882400006],
  [14.29945748800003, 122.66081058700001],
  [14.299326219000022, 122.66095283700008],
  [14.299401497000076, 122.661217],
  [14.29968374300006, 122.66136262300006],
  [14.299867540000037, 122.66170468300004],
  [14.300212133000059, 122.66163693500005],
  [14.300382790000072, 122.66165386300008],
  [14.300454998000077, 122.6618773890001],
  [14.300261373000069, 122.66201286800003],
  [14.300021794000031, 122.66191127200011],
  [14.299749829000064, 122.6618704220001],
  [14.29960544100004, 122.66232087300011],
  [14.299585755000066, 122.66254778900009],
  [14.299782672000049, 122.66269680000005],
  [14.299546380000038, 122.66276454400008],
  [14.29950700300003, 122.66293049700005],
  [14.299552955000024, 122.66314725100005],
  [14.299878593000074, 122.66322123400005],
  [14.300068946000067, 122.66345153100008],
  [14.300187094000023, 122.66348200900006],
  [14.300151, 122.66373263300011],
  [14.300403708000033, 122.6639121280001],
  [14.300410278000072, 122.66417968500002],
  [14.300600635000023, 122.66455045700002],
  [14.300502183000049, 122.6647638290001],
  [14.300374189000024, 122.66468593500008],
  [14.300206815000024, 122.66477738300011],
  [14.299977092000063, 122.66513977600005],
  [14.299796593000053, 122.66532266700005],
  [14.299560307000036, 122.66577311500009],
  [14.299484824000047, 122.66580698300004],
  [14.299258380000026, 122.66614566700002],
  [14.299304924000069, 122.66621742200005],
  [14.299080356000047, 122.66655453500005],
  [14.298992871000053, 122.66696387600007],
  [14.29898412700004, 122.66716553600008],
  [14.29915328900006, 122.66715048100002],
  [14.299293289000047, 122.66724378200001],
  [14.29931954600005, 122.66749962000006],
  [14.299240807000047, 122.66783179200002],
  [14.299442054000053, 122.667904023],
  [14.299506225000073, 122.66809966200003],
  [14.29970455800003, 122.66825015100005],
  [14.299914555000043, 122.66826820400001],
  [14.299800813000047, 122.66852228000005],
  [14.29960249000004, 122.66879618200005],
  [14.299261244000036, 122.66868783500001],
  [14.29909791700004, 122.66879920500003],
  [14.298896672000069, 122.66882629800011],
  [14.298529182000038, 122.668985829],
  [14.298504811000043, 122.66911689000005],
  [14.298288984000067, 122.66920117100005],
  [14.298455234000073, 122.6693847680001],
  [14.29850482200004, 122.66966769300006],
  [14.29835607900003, 122.66992353300009],
  [14.29821316400006, 122.66984226900001],
  [14.298216077000063, 122.66963759900011],
  [14.298061494000024, 122.66946604100008],
  [14.298090670000022, 122.6700349030001],
  [14.298151920000066, 122.6702185040001],
  [14.298411500000043, 122.67020645900004],
  [14.298525249000022, 122.67025160600008],
  [14.298586499000066, 122.6704211550001],
  [14.298484421000069, 122.67058970800008],
  [14.29844650900003, 122.67084855500002],
  [14.29832692900004, 122.67089069500003],
  [14.298336234000033, 122.67107092800006],
  [14.298200308000048, 122.67139356100006],
  [14.298186718000068, 122.671618],
  [14.298216627000045, 122.67204723900011],
  [14.297874088000071, 122.67230815300002],
  [14.297724567000046, 122.67251576100011],
  [14.297357557000055, 122.67273459600005],
  [14.29706040800005, 122.67246526800011],
  [14.296793984000033, 122.672280107],
  [14.296527562000051, 122.67215386300006],
  [14.296478627000056, 122.67203042300002],
  [14.296179581000047, 122.67183965400011],
  [14.296078086000023, 122.671949562],
  [14.29591225200005, 122.67191028700006],
  [14.29570564100004, 122.67203934100007],
  [14.29549359200007, 122.67205898000009],
  [14.295417473000043, 122.67217681000011],
  [14.29519726600006, 122.67205056700004],
  [14.295189110000024, 122.6719271280001],
  [14.294943683000042, 122.67188943700012],
  [14.29479144100003, 122.67173233400001],
  [14.294622884000034, 122.67123296800003],
  [14.294666378000045, 122.67102817000011],
  [14.294563070000038, 122.67087667700002],
  [14.294379255000024, 122.6708670050001],
  [14.294352072000038, 122.67103252700008],
  [14.294392854000023, 122.67132709700002],
  [14.294314018000023, 122.6714926190001],
  [14.294468978000054, 122.67161325100005],
  [14.294501603000072, 122.67178718900004],
  [14.294362956000043, 122.67185452000001],
  [14.294221591000053, 122.67207615200005],
  [14.294112848000054, 122.67212384600009],
  [14.293743119000055, 122.67199199400011],
  [14.293376281000064, 122.67180683800007],
  [14.292941554000038, 122.67239207700004],
  [14.292764847000058, 122.6725351550001],
  [14.292552798000031, 122.67261370800009],
  [14.292066044000023, 122.67262773900006],
  [14.292055171000072, 122.67287461600006],
  [14.29172894100003, 122.6729166990001],
  [14.291437319000067, 122.67277054800002],
  [14.291484483000033, 122.67297597800007],
  [14.291322084000058, 122.67306248800003],
  [14.29111776600007, 122.6730138470001],
  [14.290808679000065, 122.6730571170001],
  [14.290735349000045, 122.67327877200012],
  [14.290572956000062, 122.67344096700003],
  [14.290557252000042, 122.67364640000005],
  [14.29041581100006, 122.6737545310001],
  [14.29030055100003, 122.67366804200003],
  [14.290033377000043, 122.67376536900008],
  [14.289755714000023, 122.67369510800006],
  [14.289740014000074, 122.67396000800011],
  [14.289561897000056, 122.67403570500005],
  [14.28968240000006, 122.67420328700007],
  [14.289258053000026, 122.67422494000004],
  [14.289240521000067, 122.67428981100011],
  [14.289622960000031, 122.67436006600008],
  [14.289743463000036, 122.6745330550001],
  [14.289596781000057, 122.67461956200009],
  [14.289486774000068, 122.67478715900006],
  [14.289361047000057, 122.67485744600003],
  [14.288868600000058, 122.67494938200002],
  [14.288837171000068, 122.67503588300008],
  [14.289402963000043, 122.67495475600003],
  [14.289769669000066, 122.6747222680001],
  [14.290073525000025, 122.67478171800008],
  [14.289843027000074, 122.6749601350001],
  [14.289685863000045, 122.67498717500007],
  [14.28974873900006, 122.67516557600004],
  [14.290036892000046, 122.6754304640001],
  [14.289808206000032, 122.67565865400002],
  [14.289673136000033, 122.67570934700007],
  [14.289390730000036, 122.6760324820001],
  [14.289261796000062, 122.67602615300007],
  [14.289175855000053, 122.67629225500002],
  [14.28896710400005, 122.67620990300009],
  [14.288905728000032, 122.67662172400003],
  [14.28891802000004, 122.6768814830001],
  [14.288758391000044, 122.67691317100002],
  [14.288611031000073, 122.67674211500002],
  [14.288586485000053, 122.6769955420001],
  [14.288420727000073, 122.67728065600011],
  [14.288310204000027, 122.67708425700005],
  [14.288168996000024, 122.67718563500011],
  [14.288347053000052, 122.67731867300006],
  [14.287978682000073, 122.67755311300004],
  [14.287831323000034, 122.6774074000001],
  [14.287649905000023, 122.6778287200001],
  [14.287686754000049, 122.6780821430001],
  [14.287435036000034, 122.67825955100011],
  [14.287276524000049, 122.6782364820001],
  [14.287093662000075, 122.67854185800002],
  [14.286967333000064, 122.67903251100006],
  [14.286831017000054, 122.67925210700002],
  [14.286904174000028, 122.679423659],
  [14.286817736000046, 122.67971874000011],
  [14.286604939000028, 122.67979080100008],
  [14.286535118000074, 122.67989006200003],
  [14.286335610000037, 122.67964989300003],
  [14.286318980000033, 122.67951608100009],
  [14.286182651000047, 122.67938227500008],
  [14.286249145000056, 122.67923816600012],
  [14.28589004500003, 122.6791695610001],
  [14.285657296000068, 122.67916613900002],
  [14.285455442000057, 122.67908723200003],
  [14.285312470000065, 122.67916958400008],
  [14.28515619500007, 122.67910783100001],
  [14.284966675000021, 122.679217633],
  [14.28493343100007, 122.6793445830001],
  [14.284650814000031, 122.67951957900004],
  [14.28456770200006, 122.67988670700004],
  [14.284358231000056, 122.67997592300003],
  [14.284106261000034, 122.6802578270001],
  [14.284159981000073, 122.68040357000007],
  [14.283968745000038, 122.68066404900003],
  [14.284087516000056, 122.6808208010001],
  [14.284007424000038, 122.68101461200001],
  [14.283844465000072, 122.68100036700002],
  [14.283728470000028, 122.6812768310001],
  [14.283769905000042, 122.68145923700001],
  [14.283670479000023, 122.6816644490001],
  [14.283689823000032, 122.68202071600001],
  [14.283193084000061, 122.68272694200004],
  [14.282991655000046, 122.68317095900011],
  [14.282748605000052, 122.6834616750001],
  [14.282177767000064, 122.68397716900006],
  [14.28206263900006, 122.68429681900011],
  [14.282035231000066, 122.68464192700003],
  [14.282075816000031, 122.68475798700001],
  [14.281785743000057, 122.68508656400002],
  [14.28155530600003, 122.68548712200004],
  [14.281476611000073, 122.68538808300002],
  [14.281267711000055, 122.68544428300004],
  [14.280962328000044, 122.68597435200002],
  [14.280850599000075, 122.68605929800003],
  [14.280874112000049, 122.68625650400008],
  [14.280744410000068, 122.68644359100006],
  [14.280550737000056, 122.68691521400001],
  [14.280421335000028, 122.68769493000002],
  [14.280283127000075, 122.68810753700006],
  [14.28003024700007, 122.68861722300005],
  [14.280103737000047, 122.68888051800002],
  [14.280250738000063, 122.68905649500005],
  [14.280400673000031, 122.68933562500001],
  [14.280585893000023, 122.68953890800003],
  [14.28115298800003, 122.69036064600004],
  [14.281661600000064, 122.69106758800001],
  [14.28256354000007, 122.69219379700007],
  [14.282715497000027, 122.6923671300001],
  [14.28350174600007, 122.69303240300007],
  [14.284050069000045, 122.69325759100002],
  [14.284489295000071, 122.6935064700001],
  [14.28476775300004, 122.69373756300001],
  [14.285063852000064, 122.69381925700009],
  [14.285141353000029, 122.69399108800008],
  [14.284954739000057, 122.69408883400001],
  [14.285003532000076, 122.69424881200007],
  [14.285218860000043, 122.69407404200001],
  [14.285379810000052, 122.69385732500007],
  [14.285580802000027, 122.69341889400005],
  [14.285732967000058, 122.69327670700011],
  [14.285916692000058, 122.69343965700011],
  [14.286079506000021, 122.69312327000011],
  [14.28634650500004, 122.693016639],
  [14.28633798800007, 122.692797143],
  [14.28683754900004, 122.69234391600003],
  [14.28704139100006, 122.6921898810001],
  [14.287098706000052, 122.69206051900005],
  [14.287405898000031, 122.69193907700003],
  [14.288341990000049, 122.69210800300004],
  [14.288756629000034, 122.69175073400004],
  [14.288921009000035, 122.69164731800004],
  [14.289582540000026, 122.69110954100006],
  [14.289614633000042, 122.69076616600012],
  [14.289750944000048, 122.69071652900004],
  [14.28995846500004, 122.690791009],
  [14.290182984000069, 122.69060071700005],
  [14.290279210000051, 122.69046006200006],
  [14.290563856000063, 122.69044766800005],
  [14.290535799000054, 122.6903276910001],
  [14.29086529600005, 122.69010728600006],
  [14.290940723000062, 122.690203601],
  [14.291309546000036, 122.69042702700006],
  [14.291784706000044, 122.69060387600007],
  [14.291955657000074, 122.69060367400004],
  [14.292266636000022, 122.690348975],
  [14.292410468000071, 122.69011231800005],
  [14.292627991000074, 122.68992777200003],
  [14.292826233000028, 122.68990972900008],
  [14.29321356500003, 122.68957267200005],
  [14.293398205000074, 122.68948042000011],
  [14.293541442000048, 122.68916096100008],
  [14.293628902000023, 122.68916898700002],
  [14.293810693000069, 122.68902577000006],
  [14.293816529000026, 122.68887735300007],
  [14.29396035700006, 122.68874498500008],
  [14.294214960000033, 122.6887730740001],
  [14.294257723000044, 122.68863067500001],
  [14.294525174000057, 122.688332177],
  [14.294927490000077, 122.68829809700003],
  [14.29503827600007, 122.68817174500009],
  [14.295480713000075, 122.68807071000003],
  [14.295680909000055, 122.68777387900002],
  [14.295816961000071, 122.68766557800006],
  [14.29591414600003, 122.68740885700004],
  [14.296136679000028, 122.68720027600011],
  [14.296325203000038, 122.68721031000007],
  [14.29650401200007, 122.68710802700002],
  [14.296722983000052, 122.68687741200006],
  [14.296899721000045, 122.68688594100001],
  [14.29712601500006, 122.68676833400002],
  [14.297269722000067, 122.68663538200008],
  [14.297463793000077, 122.68624844700003],
  [14.297645488000057, 122.68620072400006],
  [14.297888301000057, 122.68598766100001],
  [14.298315827000067, 122.68595420500003],
  [14.298918727000057, 122.68560819400011],
  [14.299258402000078, 122.685526546],
  [14.299457835000055, 122.68526095000004],
  [14.299428109000075, 122.68498140000008],
  [14.299877398000035, 122.6844427640001],
  [14.299988385000063, 122.68438870700004],
  [14.300037940000038, 122.68423359100007],
  [14.300293968000062, 122.68395404600005],
  [14.300292199000069, 122.68386766000003],
  [14.300515190000056, 122.68366481900011],
  [14.300691425000025, 122.68362732100002],
  [14.30077882200004, 122.68328575800001],
  [14.300983641000073, 122.68315109900004],
  [14.301117437000073, 122.68290564100005],
  [14.301154841000027, 122.68267763200004],
  [14.301562773000057, 122.68235178200007],
  [14.30189901600005, 122.68217036400006],
  [14.302034553000055, 122.68205365900008],
  [14.301882651000028, 122.68178416300009],
  [14.301887773000033, 122.68166438800006],
  [14.301739285000053, 122.68143188300007],
  [14.301715392000062, 122.68121699200003],
  [14.301863884000056, 122.68119585800002],
  [14.302051886000072, 122.68156575300009],
  [14.303270480000037, 122.68134251200001],
  [14.303553812000075, 122.68113290800011],
  [14.303884205000031, 122.68114887900003],
  [14.304075370000078, 122.68099740000002],
  [14.30457546200006, 122.6810009290001],
  [14.30528958900004, 122.68072102500003],
  [14.305823823000026, 122.68032823100009],
  [14.305945007000048, 122.68018731900008],
  [14.306103442000051, 122.68015737600001],
  [14.30610002900005, 122.68001117800009],
  [14.306564281000021, 122.67986322100012],
  [14.306818596000028, 122.6796958870001],
  [14.306910401000039, 122.67951723800002],
  [14.307186663000039, 122.67946137500007],
  [14.307470392000027, 122.67929378100007],
  [14.307731811000053, 122.67896770000004],
  [14.307995813000048, 122.6789601700001],
  [14.308361761000072, 122.67878913100003],
  [14.30866517100003, 122.67852951500004],
  [14.30903865700003, 122.6783532720001],
  [14.309296937000056, 122.67810589200008],
  [14.309377657000027, 122.67787046700005],
  [14.309602057000063, 122.6776405390001],
  [14.309928823000064, 122.6774050030001],
  [14.310107913000024, 122.677408033],
  [14.31027989200004, 122.6772884290001],
  [14.310628586000064, 122.67692079200003],
  [14.310908987000062, 122.67672402300002],
  [14.311144521000074, 122.67662371100005],
  [14.311312758000042, 122.67664686500007],
  [14.311652973000037, 122.67653498000004],
  [14.311720270000023, 122.6764385240001],
  [14.31200584000004, 122.67643460400006],
  [14.312230158000034, 122.67636129900006],
  [14.312529251000058, 122.67614523600002],
  [14.312607761000038, 122.67619925500003],
  [14.312839558000064, 122.67600248200006],
  [14.312895637000054, 122.67604878400005],
  [14.313262025000029, 122.67583272200011],
  [14.313351753000063, 122.67570925600012],
  [14.313674416000026, 122.67560391000006],
  [14.313191107000023, 122.6745362050001],
  [14.313849230000073, 122.67425506100005],
  [14.313801156000068, 122.67410621600004],
  [14.314930920000052, 122.67363074600007],
  [14.314966975000061, 122.67373411200003],
  [14.31416973000006, 122.6740731430001],
  [14.314173736000043, 122.67415583600007],
  [14.314468715000032, 122.67485510800009],
  [14.31481739000003, 122.67484287900004],
  [14.315133884000034, 122.67477672900009],
  [14.315903082000034, 122.67468991300007],
  [14.316119419000074, 122.67474780100008],
  [14.31625968800006, 122.6746940270001],
  [14.31634946500003, 122.67456144400012],
  [14.316722047000042, 122.67431750500009],
  [14.316922146000024, 122.67433357000004],
  [14.31765529300003, 122.67400693800005],
  [14.317959768000037, 122.67396559500003],
  [14.318007844000022, 122.67380021000008],
  [14.318236201000047, 122.67364722900004],
  [14.31868490100004, 122.67356867300009],
  [14.319016186000056, 122.67353980500002],
  [14.31931695000003, 122.67316987200002],
  [14.319683630000043, 122.67316562400003],
  [14.320042071000046, 122.67302955700006],
  [14.320581858000025, 122.6730026780001],
  [14.320886742000027, 122.67272203700009],
  [14.321356424000044, 122.67257746400003],
  [14.321521224000037, 122.67247116100009],
  [14.321908505000067, 122.67248391900011],
  [14.322027986000023, 122.67254770200009],
  [14.32231226700003, 122.67247116500005],
  [14.322407029000033, 122.67207571400002],
  [14.322584191000033, 122.67190987900005],
  [14.322631192000074, 122.67143654400002],
  [14.322610592000046, 122.6713217360001],
  [14.322676512000044, 122.67100707400004],
  [14.322672392000072, 122.67076470000006],
  [14.322725951000052, 122.67062012600002],
  [14.323018472000058, 122.6706626460001],
  [14.323104991000037, 122.67045428900008],
  [14.323183266000058, 122.66977393900004],
  [14.323310985000035, 122.66961235500003],
  [14.323236833000067, 122.67210414200008],
  [14.323549953000054, 122.67212965600004],
  [14.324044356000059, 122.6721254040001],
  [14.32425035700004, 122.6719638190001],
  [14.324209157000041, 122.67181073800009],
  [14.324373957000034, 122.6716874230001],
  [14.324894978000032, 122.67153909700005],
  [14.32547590200005, 122.67140727500009],
  [14.325636582000072, 122.67131372300003],
  [14.325636581000026, 122.6708332180001],
  [14.325768421000078, 122.67069289400001],
  [14.32620848700003, 122.67044201100009],
  [14.326608127000043, 122.670327197],
  [14.326826487000062, 122.6703144390001],
  [14.327110766000033, 122.67019537400006],
  [14.327374447000068, 122.67015285000002],
  [14.32757220800005, 122.67004654200002],
  [14.32776025000004, 122.67006984900001],
  [14.328401597000038, 122.67004308800006],
  [14.328817720000075, 122.66995378400009],
  [14.328974279000022, 122.66975817600007],
  [14.32900311800006, 122.6695965890001],
  [14.329134957000065, 122.66939247700009],
  [14.329509875000042, 122.66917135300002],
  [14.329633471000022, 122.66882266300001],
  [14.329831231000071, 122.66884392300005],
  [14.330123553000021, 122.66873335800005],
  [14.330560245000072, 122.66884667700003],
  [14.330852794000066, 122.66879288100006],
  [14.331359315000043, 122.66889067800003],
  [14.331882557000029, 122.66893744800007],
  [14.332385199000043, 122.66905650700005],
  [14.332947753000042, 122.66872056300008],
  [14.333104315000071, 122.6687503280001],
  [14.33337623500006, 122.66859723900006],
  [14.333891236000056, 122.66860148400008],
  [14.334187877000033, 122.66863550000005],
  [14.334282636000069, 122.66857596500006],
  [14.33447215700005, 122.66861423400007],
  [14.334488638000039, 122.66871629200011],
  [14.334871799000041, 122.6687588100001],
  [14.33510663800007, 122.66861422500006],
  [14.335614124000074, 122.66855468400001],
  [14.336067322000076, 122.66837607600007],
  [14.336343360000058, 122.66822298400007],
  [14.33653288000005, 122.66820171900008],
  [14.336909268000056, 122.66803338800003],
  [14.337249880000059, 122.66801242300005],
  [14.337489111000025, 122.66792031900002],
  [14.33754183800005, 122.66805005700007],
  [14.337789202000067, 122.66816303200005],
  [14.33810548300005, 122.66811695700005],
  [14.338424012000075, 122.66821318400002],
  [14.338780846000077, 122.66820895800004],
  [14.339162028000032, 122.66838469800007],
  [14.33947020900007, 122.6684474430001],
  [14.339569378000022, 122.66868059],
  [14.339967407000074, 122.66911825600005],
  [14.34014220000006, 122.6693602260001],
  [14.340511798000023, 122.66938205800011],
  [14.340530176000073, 122.66954543500003],
  [14.340427616000056, 122.66965312900004],
  [14.34058417700004, 122.66988258100002],
  [14.340937788000076, 122.6698553440001],
  [14.341208713000071, 122.66978452700005],
  [14.341351213000053, 122.66999879600007],
  [14.341361767000024, 122.6704293890001],
  [14.34122278500007, 122.67065818300011],
  [14.34106319600005, 122.67119466100007],
  [14.340559240000061, 122.67162033400007],
  [14.340425080000045, 122.67183942400004],
  [14.340150188000052, 122.67201172600005],
  [14.339855667000052, 122.67227185800004],
  [14.339892214000031, 122.672476114],
  [14.339732981000054, 122.6727500940001],
  [14.339695540000037, 122.67311753100012],
  [14.339776746000041, 122.67329801800008],
  [14.339814252000053, 122.67370735200006],
  [14.339729950000049, 122.67382983900006],
  [14.339287715000069, 122.67385356500006],
  [14.339244032000067, 122.67427257200006],
  [14.33917533600004, 122.67431448000002],
  [14.338984883000023, 122.67470771500007],
  [14.338828744000068, 122.67465293500004],
  [14.338803742000039, 122.67439186500008],
  [14.338956732000042, 122.67412433400011],
  [14.338925496000059, 122.67401153000003],
  [14.338525799000024, 122.67410503300005],
  [14.33835405800005, 122.67418562700004],
  [14.338182306000022, 122.6741469640001],
  [14.33806990100004, 122.6742887900001],
  [14.337988735000067, 122.67459176600005],
  [14.337973153000064, 122.67499143000009],
  [14.338076563000072, 122.67505465400006],
  [14.338204317000077, 122.67493563800008],
  [14.338456734000033, 122.67502567500003],
  [14.338649925000027, 122.67486805700003],
  [14.338749645000064, 122.67488091400003],
  [14.338934101000063, 122.6751253430001],
  [14.339027602000044, 122.67533440000011],
  [14.339024644000062, 122.67555746100004],
  [14.33862576100006, 122.67555427600007],
  [14.338906240000028, 122.67575046000002],
  [14.339012223000054, 122.67615894300002],
  [14.338931205000051, 122.6762168460001],
  [14.339021591000062, 122.6764162610001],
  [14.338949931000059, 122.67661568800008],
  [14.339096405000078, 122.67674433600007],
  [14.339055912000049, 122.67700165800011],
  [14.339233550000074, 122.67715925400012],
  [14.33921175200004, 122.67739115100005],
  [14.339037246000032, 122.67749730700007],
  [14.338763034000067, 122.6777803760001],
  [14.33869760600004, 122.67798301900007],
  [14.338420260000078, 122.67803771900003],
  [14.338289367000073, 122.67791228400006],
  [14.338295862000052, 122.67825164900012],
  [14.338507548000052, 122.67861346000007],
  [14.338575759000037, 122.67844616700006],
  [14.338941350000027, 122.67824085900008],
  [14.339034956000035, 122.678406891],
  [14.339268936000053, 122.67829819500002],
  [14.339485373000059, 122.67826497200008],
  [14.339564359000065, 122.67850647800003],
  [14.339508796000075, 122.67865440800006],
  [14.339225119000048, 122.67875382300008],
  [14.339251466000064, 122.67912514500006],
  [14.339584890000026, 122.67900436700006],
  [14.339804240000035, 122.67879605000007],
  [14.340006059000075, 122.67885339500003],
  [14.340047017000074, 122.67901943200002],
  [14.339786710000055, 122.67910397800006],
  [14.340064593000022, 122.67946622900001],
  [14.340448077000076, 122.67964276600003],
  [14.340810794000049, 122.68028577200005],
  [14.34082836600004, 122.68069030600009],
  [14.340915361000043, 122.68087312800003],
  [14.340839325000047, 122.68108143800009],
  [14.340669685000023, 122.68110258000002],
  [14.340564404000077, 122.68135315500001],
  [14.340757444000076, 122.68136522000009],
  [14.340874451000047, 122.6816338970001],
  [14.340704832000029, 122.68206259200008],
  [14.341108830000053, 122.68176067900004],
  [14.341430560000049, 122.68171537800004],
  [14.34157096000007, 122.68188141200005],
  [14.341790324000044, 122.68189951400007],
  [14.341816653000024, 122.68200517500009],
  [14.34165871700003, 122.68211084600011],
  [14.341696747000071, 122.68225877200007],
  [14.341532960000052, 122.68236746200012],
  [14.341509570000028, 122.68253048600002],
  [14.341641191000065, 122.68259387900002],
  [14.342000159000065, 122.68232299100009],
  [14.342096092000077, 122.68243947300004],
  [14.34235566600006, 122.68253264900011],
  [14.342361316000051, 122.68268408200004],
  [14.342694250000022, 122.68283549900002],
  [14.34285227600003, 122.68338880800002],
  [14.342773280000074, 122.68347035200009],
  [14.34252499400003, 122.68345288900002],
  [14.342135644000052, 122.68357521700011],
  [14.341835050000043, 122.68322437000006],
  [14.341626046000044, 122.68324280900003],
  [14.341734999000039, 122.68346457200005],
  [14.341783833000022, 122.68368299300005],
  [14.341767964000042, 122.68395161700005],
  [14.34184413500003, 122.68449214200007],
  [14.341991051000036, 122.68473662200006],
  [14.342088703000059, 122.68539592900004],
  [14.342261740000026, 122.68579882600011],
  [14.342470217000027, 122.68568434100007],
  [14.342567812000027, 122.68574385300008],
  [14.342603316000066, 122.68592241500005],
  [14.342723085000046, 122.68592240300006],
  [14.342798515000027, 122.68612385400002],
  [14.342794097000024, 122.68632073600008],
  [14.342963845000043, 122.68690342600007],
  [14.343083623000041, 122.68700872300008],
  [14.343425191000051, 122.68704990000003],
  [14.34358486800005, 122.68688505400007],
  [14.343722355000068, 122.68658743100002],
  [14.34385543600007, 122.68662862700012],
  [14.344077259000073, 122.68693079500008],
  [14.343978314000026, 122.68724721400008],
  [14.344000503000075, 122.68735709900011],
  [14.344164644000045, 122.68751275700004],
  [14.344027149000055, 122.68772796700011],
  [14.344195735000028, 122.68798893300004],
  [14.344235677000029, 122.68822702000011],
  [14.34412035400004, 122.68835523100006],
  [14.34392517200007, 122.68832319800003],
  [14.34384534000003, 122.68849719200011],
  [14.343605812000021, 122.6886166060001],
  [14.343348554000045, 122.68891423700006],
  [14.34308683200004, 122.68886500100007],
  [14.342789633000052, 122.68892454800005],
  [14.342665425000064, 122.68888335100007],
  [14.342479123000032, 122.68896120400007],
  [14.342727559000025, 122.6892816840001],
  [14.342732006000062, 122.68942362100006],
  [14.342476646000023, 122.68961662600009],
  [14.342445614000042, 122.68987303000006],
  [14.342330292000042, 122.69001497600004],
  [14.342325870000025, 122.690202698],
  [14.342201670000065, 122.69026223000003],
  [14.34174032900006, 122.69014780100008],
  [14.341492667000068, 122.69024719200002],
  [14.341337420000059, 122.69037998300007],
  [14.341177751000032, 122.69069133800008],
  [14.340774109000051, 122.69101644600005],
  [14.340654353000048, 122.69121333400005],
  [14.340351395000027, 122.69142569400003],
  [14.340023142000064, 122.6914669250001],
  [14.339894506000064, 122.69154477100005],
  [14.339876772000025, 122.69168212900001],
  [14.33993890000005, 122.69206672000007],
  [14.33970381000006, 122.6922544570001],
  [14.339495327000066, 122.69230025700006],
  [14.339193703000035, 122.69254751800008],
  [14.338753487000076, 122.69277165800008],
  [14.338577369000063, 122.6928955090001],
  [14.338564607000023, 122.69306943600009],
  [14.338422663000074, 122.69314728100005],
  [14.337916972000073, 122.69314273500004],
  [14.337748414000032, 122.69323889500004],
  [14.33759923100007, 122.69323432500005],
  [14.337248790000046, 122.6931244650001],
  [14.336951587000044, 122.69315195500008],
  [14.336834476000035, 122.69310313400001],
  [14.336681853000073, 122.69321697200007],
  [14.336349068000061, 122.69301452900004],
  [14.33598476800006, 122.69296393800005],
  [14.335522413000035, 122.69333635900011],
  [14.335364782000056, 122.69330021300004],
  [14.335091082000076, 122.6934593100001],
  [14.33484938600003, 122.69345209500011],
  [14.33467425200007, 122.69360395500007],
  [14.334681269000043, 122.69379557100001],
  [14.33449913800007, 122.69411374000003],
  [14.334278453000024, 122.69402698400006],
  [14.333820185000036, 122.69398365800009],
  [14.333737056000075, 122.69409732800011],
  [14.333406915000069, 122.69428363200007],
  [14.33306355700006, 122.69425639100007],
  [14.33299751800007, 122.69410191800011],
  [14.332790629000044, 122.694192799],
  [14.332971121000071, 122.6943699840001],
  [14.332918307000057, 122.69455172300002],
  [14.333033955000076, 122.69470046000004],
  [14.333198546000062, 122.69445393700005],
  [14.333524529000044, 122.6944172740001],
  [14.333676235000041, 122.69460714700006],
  [14.333572967000066, 122.69485366600009],
  [14.333556840000028, 122.69506020500012],
  [14.333392252000067, 122.69538667800009],
  [14.333508461000065, 122.69569648000004],
  [14.333640799000023, 122.69584638300012],
  [14.333789266000053, 122.69581306100008],
  [14.333779562000075, 122.69542663300001],
  [14.333831197000052, 122.69530670400002],
  [14.334141007000028, 122.69501686500007],
  [14.334412117000056, 122.69493023600012],
  [14.334586407000074, 122.69495021400007],
  [14.334780075000026, 122.6952133740001],
  [14.33495436000004, 122.69514340700005],
  [14.334941441000069, 122.69498017500007],
  [14.33516090300003, 122.69474364100006],
  [14.335406122000052, 122.69470698200007],
  [14.33568045900006, 122.69460702700007],
  [14.33577407100006, 122.69482355600007],
  [14.335867670000027, 122.6948168880001],
  [14.336254995000047, 122.69512667700008],
  [14.336549112000057, 122.69510334000006],
  [14.336529754000026, 122.69524991900005],
  [14.336707278000063, 122.69538982400002],
  [14.336813784000071, 122.69532985500007],
  [14.337028102000033, 122.6954495230001],
  [14.33725953000004, 122.69547180400002],
  [14.337213255000052, 122.69566926900006],
  [14.337256465000053, 122.69588265300001],
  [14.337071339000033, 122.69616293100012],
  [14.337182430000041, 122.69630305900012],
  [14.337308944000029, 122.69633171600003],
  [14.337370668000062, 122.69655146900004],
  [14.337453985000025, 122.69663745600008],
  [14.337447826000073, 122.69689224700005],
  [14.337890689000062, 122.6973925320001],
  [14.338060418000055, 122.69779063400006],
  [14.337943168000038, 122.69791803400005],
  [14.338097461000075, 122.69814733900012],
  [14.338054266000029, 122.6982428870001],
  [14.338100561000033, 122.69851041600009],
  [14.338190053000062, 122.69868558200005],
  [14.338149943000076, 122.6987747610001],
  [14.338294980000057, 122.69904736700005],
  [14.33833201400006, 122.69922572000007],
  [14.338563444000044, 122.6993403670001],
  [14.338443106000057, 122.6994327330001],
  [14.338273402000027, 122.69967160500005],
  [14.338295007000056, 122.69981810900003],
  [14.338214783000069, 122.69993595300002],
  [14.338307357000076, 122.70005379000008],
  [14.338295024000047, 122.70034361600005],
  [14.338335144000041, 122.70053470900007],
  [14.338785665000046, 122.7008181540001],
  [14.338843841000028, 122.70102758900009],
  [14.338590827000075, 122.701435263],
  [14.33844272500005, 122.701839747],
  [14.33831930100007, 122.70202128900007],
  [14.33839336400007, 122.70223786100007],
  [14.338529135000044, 122.70227926200005],
  [14.338634053000021, 122.70252871200012],
  [14.33876674000004, 122.70264655000005],
  [14.338689604000024, 122.70292045300005],
  [14.338816119000057, 122.70301918100006],
  [14.338840808000043, 122.70318161000012],
  [14.338714296000035, 122.70321983200006],
  [14.33856309500004, 122.70312110300006],
  [14.338437363000025, 122.70342254400009],
  [14.338127446000044, 122.70345987900009],
  [14.337734900000044, 122.7038650830001],
  [14.337605772000074, 122.70395572200005],
  [14.337337173000037, 122.7039290780001],
  [14.337254548000033, 122.7043662530001],
  [14.337678096000047, 122.70419563000007],
  [14.337765902000058, 122.7041049930001],
  [14.337812381000049, 122.70390239900007],
  [14.337987998000074, 122.70382242000005],
  [14.338396061000026, 122.70389171000011],
  [14.338432229000034, 122.70415294600002],
  [14.338644011000042, 122.70424890000004],
  [14.338845457000048, 122.70420090700009],
  [14.338984928000059, 122.70439283200005],
  [14.338897130000078, 122.70469139700003],
  [14.338922963000073, 122.704862],
  [14.339036604000057, 122.70495796000012],
  [14.338902322000024, 122.70538447700005],
  [14.33895915000005, 122.70563505000007],
  [14.338964334000025, 122.70616285800008],
  [14.339000497000029, 122.70636011800002],
  [14.338943690000065, 122.70666401100004],
  [14.339170968000076, 122.70680795200008],
  [14.33903151100003, 122.70699988900003],
  [14.339093513000023, 122.70757035500003],
  [14.339072860000044, 122.70786359100009],
  [14.339174520000029, 122.70846731400002],
  [14.339272664000077, 122.70858460200009],
  [14.339365650000047, 122.709032438],
  [14.339665250000053, 122.70958156600011],
  [14.33983571300007, 122.71002406900004],
  [14.339913199000023, 122.71032796000009],

  [14.339943732000052, 122.71339026600003],
  [14.339799103000075, 122.71342225500007],
  [14.33950468100005, 122.71383810700002],
  [14.339230920000034, 122.71417931700012],
  [14.339060465000045, 122.71447254500004],
  [14.338972655000077, 122.714712458],
  [14.338848687000052, 122.71485107300009],
  [14.338585256000044, 122.71479775900002],
  [14.338306329000034, 122.71489905500005],
  [14.338027400000044, 122.71512297400011],
  [14.337906393000026, 122.71540265500005],
  [14.33764296000004, 122.71576518600011],
  [14.33754998300003, 122.71598377200007],
  [14.337353700000051, 122.71626633200003],
  [14.337338203000058, 122.7163996160001],
  [14.337219399000048, 122.71657021900012],
  [14.337079936000066, 122.71660220500007],
  [14.336811341000043, 122.71642093700007],
  [14.336744194000062, 122.71618102500008],
  [14.336940478000031, 122.71584515200004],
  [14.33690948800006, 122.71566388600002],
  [14.336706826000068, 122.7154438980001],
  [14.336102484000037, 122.71540657600008],
  [14.335870045000036, 122.71536925600003],
  [14.335596284000076, 122.71546521900007],
  [14.335353514000076, 122.71563581900011],
  [14.33518305900003, 122.7155451860001],
  [14.334981612000036, 122.71535325800005],
  [14.334785330000045, 122.71528395000007],
  [14.334434087000034, 122.71541189900006],
  [14.33414754200004, 122.71547587300006],
  [14.333982251000066, 122.71556117300008],
  [14.333739480000077, 122.71560382200005],
  [14.33330042700004, 122.71573710100006],
  [14.332830384000033, 122.71578507900006],
  [14.332628937000038, 122.71583306000002],
  [14.33242232300006, 122.71599832600009],
  [14.33235000700006, 122.71619558200007],
  [14.332071082000027, 122.716008987],
  [14.33184897500007, 122.71574242400004],
  [14.331710412000064, 122.71565712300003],
  [14.331214542000055, 122.71559847700007],
  [14.330682516000024, 122.71547585700011],
  [14.330419086000063, 122.71524661400008],
  [14.330336441000043, 122.71506535300011],
  [14.330341607000037, 122.71481478700002],
  [14.330253796000022, 122.7147241560001],
  [14.33010400300003, 122.71479346100011],
  [14.329876728000045, 122.71455355600006],
  [14.329628791000061, 122.71453222900004],
  [14.329399949000049, 122.71444677700003],
  [14.329286312000022, 122.71412690700004],
  [14.32894023600005, 122.71393498600003],
  [14.328408208000042, 122.71381770100004],
  [14.32757674000004, 122.71368558000006],
  [14.32660797400007, 122.71354803800011],
  [14.325516425000046, 122.71353129300007],
  [14.32504369000003, 122.71358727600011],
  [14.324787299000036, 122.71372561100009],
  [14.324663089000069, 122.71370842700003],
  [14.324406492000037, 122.71352616000001],
  [14.324279500000046, 122.71331288700003],
  [14.324127223000062, 122.71323212400011],
  [14.323581185000023, 122.71334944700004],
  [14.322911086000033, 122.71340587300006],
  [14.322030085000051, 122.71330861900003],
  [14.321473673000071, 122.71344948900003],
  [14.32114748600003, 122.71370524200006],
  [14.320925373000023, 122.71370263900008],
  [14.320718404000047, 122.71395271500012],
  [14.32013283300006, 122.71436429700009],
  [14.320435714000041, 122.71477327600007],
  [14.320555031000026, 122.71506977700005],
  [14.320333704000063, 122.71493452800007],
  [14.32013573300003, 122.71494704800011],
  [14.319914252000046, 122.71481410600006],
  [14.319660087000045, 122.714961635],
  [14.319304075000048, 122.71524546100011],
  [14.31881458600003, 122.71547729100007],
  [14.318650153000021, 122.715447648],
  [14.31858958600003, 122.71556450100002],
  [14.318095251000045, 122.71569572300007],
  [14.317537937000054, 122.71594421000009],
  [14.317097138000065, 122.71619295900007],
  [14.316896084000064, 122.71634535800001],
  [14.316697736000037, 122.716599975],
  [14.31620693700006, 122.71707317700009],
  [14.315853998000023, 122.71756903700009],
  [14.31543696600005, 122.7173710510001],
  [14.315064007000046, 122.71750790200008],
  [14.31491214700003, 122.71742531400002],
  [14.314716721000025, 122.71747010500007],
  [14.314454677000072, 122.717644715],
  [14.314199899000073, 122.71795867500009],
  [14.314363616000037, 122.71797557100001],
  [14.314410003000035, 122.71807413200008],
  [14.314410004000024, 122.71836981700005],
  [14.314281760000028, 122.718783776],
  [14.314257203000068, 122.719042854],
  [14.314330876000042, 122.71949061100008],
  [14.314221731000032, 122.71955538100008],
  [14.313891566000052, 122.7194849760001],
  [14.313736034000044, 122.71956101],
  [14.313460814000052, 122.72025654300012],
  [14.313456028000076, 122.72096531200009],
  [14.313235338000027, 122.72134774300002],
  [14.312933041000065, 122.72175599000002],
  [14.312818653000022, 122.72198531100003],
  [14.312787662000062, 122.72223330400004],
  [14.312512494000032, 122.72234131700009],
  [14.312353608000024, 122.72264531100006],
  [14.312136585000076, 122.72290931200007],
  [14.31193507100005, 122.72331330400004],
  [14.311927327000035, 122.72346929800005],
  [14.311830452000038, 122.72380928900009],
  [14.311752958000056, 122.72423327800004],
  [14.311679327000036, 122.72438927700011],
  [14.311669581000046, 122.72459985400008],
  [14.311826627000073, 122.72487766800009],
  [14.311826454000027, 122.7249688610001],




];

const polygonCoordsPCL2 = [
  [14.40757138500004, 122.8137578520001],
  [14.407580121000024, 122.8139836360001],
  [14.407527627000036, 122.81416425900011],
  [14.406919431000063, 122.81474711300007],
  [14.406726976000073, 122.81500901000004],
  [14.406639484000038, 122.81537025700004],
  [14.406639470000073, 122.81559603700009],
  [14.406814363000024, 122.81646305000004],
  [14.406753114000026, 122.81675204700002],
  [14.406516931000056, 122.81686943800003],
  [14.405965846000072, 122.81696874400006],
  [14.40551098700007, 122.81697774500003],
  [14.405274806000023, 122.817031917],
  [14.404951143000062, 122.81720348800002],
  [14.404771166000046, 122.81725628400011],
  [14.404597182000032, 122.81741593600009],
  [14.404258894000066, 122.81752568000002],
  [14.403862612000069, 122.81771525200008],
  [14.403360022000072, 122.81779504800011],
  [14.403099058000066, 122.81791477400009],
  [14.402751112000033, 122.81797462100008],
  [14.402354846000037, 122.8179546340001],
  [14.402142211000069, 122.81800451200002],
  [14.401939237000022, 122.81811426100012],
  [14.401726603000043, 122.81814418200008],
  [14.401301340000032, 122.81811421200007],
  [14.400827743000036, 122.81817404700007],
  [14.400547451000023, 122.81817402400009],
  [14.400296152000067, 122.81822389700005],
  [14.40006476700006, 122.81816401600008],
  [14.399745819000032, 122.81817397300006],
  [14.399146565000024, 122.81846330700012],
  [14.398653643000046, 122.8185331200001],
  [14.398160712000049, 122.81873265200011],
  [14.39761946300007, 122.8188224160001],
  [14.39726054700003, 122.81903823300001],
  [14.397191319000058, 122.81922197200004],
  [14.396973782000032, 122.81939549000003],
  [14.39665737300004, 122.81950775300004],
  [14.396301424000058, 122.8195281400001],
  [14.395994917000053, 122.81950770000003],
  [14.395579636000036, 122.81965057700006],
  [14.395253352000054, 122.81968117400004],
  [14.395055597000066, 122.81978323700002],
  [14.394719423000026, 122.8198546640001],
  [14.394472239000038, 122.81985464400009],
  [14.394225062000032, 122.81977296200012],
  [14.394056990000024, 122.81961983200006],
  [14.393973119000066, 122.81945650000011],
  [14.393636963000063, 122.81922169400002],
  [14.393409555000062, 122.81918084600011],
  [14.393073382000068, 122.81920123600003],
  [14.392747094000072, 122.81926245700004],
  [14.392381262000072, 122.81926242800012],
  [14.391906676000076, 122.81920114500008],
  [14.391343096000071, 122.81922151600008],
  [14.390710307000063, 122.8192112590001],
  [14.39026536700004, 122.81931330000009],
  [14.390044623000051, 122.81926224500012],
  [14.389372279000042, 122.81933364500003],
  [14.389085547000036, 122.8193234150001],
  [14.388601078000022, 122.8191804710001],
  [14.388274790000025, 122.81927231300006],
  [14.387859495000043, 122.81958871300003],
  [14.387701279000055, 122.81982347200005],
  [14.387581933000035, 122.81987854200008],
  [14.387305067000057, 122.82012350200011],
  [14.386949111000035, 122.82025617300008],
  [14.386167998000076, 122.82031735900011],
  [14.385861499000043, 122.82016422200002],
  [14.385515480000038, 122.81965381900011],
  [14.385347423000042, 122.81928633600012],
  [14.385396897000021, 122.81882700300002],
  [14.385654010000053, 122.81829623500005],
  [14.386000115000058, 122.81765319200008],
  [14.386158331000047, 122.81739801800006],
  [14.386860390000038, 122.81651001800003],
  [14.387305359000038, 122.81586697400007],
  [14.387789888000043, 122.81502998200006],
  [14.387976543000036, 122.8147600640001],
  [14.387935779000031, 122.81427998700008],
  [14.388107126000023, 122.81379992200004],
  [14.38838452300007, 122.81342093100011],
  [14.388743501000022, 122.81305036600008],
  [14.388904793000052, 122.81294010700003],
  [14.38925561900004, 122.81256953400009],
  [14.389761452000073, 122.81211474400004],
  [14.390414128000032, 122.81160100500006],
  [14.390748622000046, 122.81139888100006],
  [14.391490869000052, 122.81123046400012],
  [14.391841673000044, 122.81123047900007],
  [14.392168010000034, 122.81104519700011],
  [14.392641190000063, 122.8109273010001],
  [14.393098056000042, 122.81068306700001],
  [14.39331833500006, 122.8104809350001],
  [14.39369361200005, 122.81046410400006],
  [14.393987310000057, 122.8103714660001],
  [14.394354437000061, 122.8101609150001],
  [14.394558393000068, 122.81013565300009],
  [14.394902210000055, 122.81015590000004],
  [14.395200479000039, 122.80992670300009],
  [14.395531628000072, 122.8095123270001],
  [14.395872803000032, 122.80932586300003],
  [14.396525036000071, 122.809356965],
  [14.396725726000057, 122.80926373400007],
  [14.397076941000023, 122.80883899900005],
  [14.397408076000033, 122.8087872110001],
  [14.397799423000038, 122.80855931000008],
  [14.398150630000032, 122.80845572300007],
  [14.398261010000056, 122.80837284800009],
  [14.398411536000026, 122.80804134000005],
  [14.398572089000027, 122.80795846600006],
  [14.398705514000028, 122.8077928780001],
  [14.398999041000025, 122.80757215100004],
  [14.399256537000042, 122.80744501600009],
  [14.399637182000049, 122.80732944400006],
  [14.399917066000057, 122.80732945200009],
  [14.400454441000022, 122.80726011600007],
  [14.40128289100005, 122.807225463],
  [14.401786677000075, 122.80724859200006],
  [14.40239122500003, 122.80706366900006],
  [14.40261513300004, 122.80704055600006],
  [14.402973380000049, 122.80712147300005],
  [14.403164366000055, 122.80710220700007],
  [14.403412682000067, 122.80693557200004],
  [14.403657102000068, 122.80667227000004],
  [14.403836647000048, 122.80661775200008],
  [14.404491452000059, 122.8066395730001],
  [14.40469211900006, 122.80661776900001],
  [14.404966717000036, 122.8065087330001],
  [14.405272999000033, 122.80627975200002],
  [14.405610964000061, 122.80618162000007],
  [14.405790509000042, 122.8060616780001],
  [14.405875002000073, 122.80593082900009],
  [14.406381955000029, 122.80554919100007],
  [14.406878343000074, 122.80533111400007],
  [14.40724799800006, 122.80500399100004],
  [14.407510276000039, 122.80492766500004],
  [14.407911615000046, 122.80470958400008],
  [14.408450251000033, 122.80450241000005],
  [14.409136743000033, 122.80438247100005],
  [14.409443022000062, 122.80438247400002],
  [14.410034458000041, 122.80433886500009],
  [14.41050972000005, 122.80427344300006],
  [14.410900491000064, 122.80410988100004],
  [14.411333511000066, 122.80383727000003],
  [14.411597546000053, 122.80386998600011],
  [14.411822244000064, 122.80381546000001],
  [14.412033474000054, 122.80384817700008],
  [14.412635479000073, 122.80383728200002],
  [14.412846706000039, 122.80380457100011],
  [14.413490952000075, 122.80361920000007],
  [14.413913407000052, 122.80358649000004],
  [14.414420353000025, 122.80359739900007],
  [14.414684388000069, 122.80347744900007],
  [14.41489561700007, 122.8033247840001],
  [14.415095997000037, 122.80328455200004],
  [14.415769827000076, 122.80358272800004],
  [14.416067363000025, 122.80368212000008],
  [14.41639421900004, 122.80373633900001],
  [14.41670050600004, 122.80372730800002],
  [14.416893028000061, 122.8037995950001],
  [14.417120552000029, 122.80414295000003],
  [14.417295571000068, 122.80432366200012],
  [14.417925637000053, 122.80482062700003],
  [14.418529447000026, 122.80540794900003],
  [14.418730719000052, 122.80554348600003],
  [14.418888234000065, 122.80578745100001],
  [14.418707625000025, 122.80632148200004],
  [14.41833131900006, 122.80660158300009],
  [14.41815629100006, 122.80685457800007],
  [14.417990017000022, 122.80699011000002],
  [14.41777998300006, 122.80733345800002],
  [14.417508694000048, 122.80762259000005],
  [14.417359924000039, 122.80772197700003],
  [14.41681735900005, 122.80784846000006],
  [14.416152278000027, 122.80804722300002],
  [14.415767230000029, 122.80820985100002],
  [14.415531843000053, 122.80814769800008],
  [14.415216798000074, 122.80825611900002],
  [14.414971759000025, 122.80850007400011],
  [14.414630459000023, 122.80877113000008],
  [14.414131643000076, 122.80897893100007],
  [14.413457811000058, 122.80914154800007],
  [14.413125269000034, 122.80926803200009],
  [14.412302666000073, 122.80953002800004],
  [14.411847604000059, 122.80975589600007],
  [14.411480046000065, 122.8101263310001],
  [14.41130709500004, 122.81015238600003],
  [14.41083452600003, 122.81055895800012],
  [14.410423216000027, 122.81086614000003],
  [14.41015193100003, 122.81098358800011],
  [14.409987133000072, 122.81098444400004],
  [14.409497541000064, 122.81126424100012],
  [14.409373438000046, 122.81129237300001],
  [14.408944804000043, 122.81166264600006],
  [14.408743607000076, 122.8119155170001],
  [14.408498664000035, 122.81233998100004],
  [14.408358694000071, 122.8126470410001],
  [14.40813999900007, 122.81291797000006],
  [14.40782508500007, 122.81317986200008],
  [14.40764137800005, 122.81345982300002],
  [14.40757138500004, 122.8137578520001]
]

const polygonCoordsPCL = [
  [14.268860634000077, 122.85500040400007],
  [14.268346636000047, 122.85483733800004],
  [14.268009208000024, 122.8545981310001],
  [14.267656049000038, 122.85452528200005],
  [14.267226386000061, 122.85439665000001],
  [14.266770965000035, 122.85394564700005],
  [14.266371943000024, 122.8533867220001],
  [14.266074655000068, 122.85306262200004],
  [14.265842540000051, 122.85274541000001],
  [14.265745055000025, 122.85239077900007],
  [14.265198296000051, 122.85196021200011],
  [14.265034809000042, 122.85176818200011],
  [14.265017964000037, 122.85149466300004],
  [14.265063178000048, 122.85109906500008],
  [14.264836760000037, 122.85040590000006],
  [14.26459651500005, 122.85031427400008],
  [14.264418406000061, 122.85011208000003],
  [14.264248973000065, 122.84980305900001],
  [14.264074693000055, 122.8493480940001],
  [14.263829330000021, 122.84909598400009],
  [14.263663466000025, 122.84865646800006],
  [14.26311905600005, 122.84835003600006],
  [14.262907658000074, 122.84841562200006],
  [14.262489316000028, 122.84835379100002],
  [14.26228694100007, 122.84847451900009],
  [14.26222496500003, 122.84869204900008],
  [14.26147233200004, 122.8487013570001],
  [14.261319718000038, 122.84874026900002],
  [14.26092456300006, 122.8487542680001],
  [14.260514327000067, 122.84886179400007],
  [14.260248988000058, 122.84912367200002],
  [14.260112055000036, 122.84964879400002],
  [14.259757508000064, 122.84990342200001],
  [14.259679086000062, 122.85015422000004],
  [14.259951139000066, 122.85040097900003],
  [14.259955152000032, 122.85080056000004],
  [14.259905662000051, 122.85090258500009],
  [14.259543976000032, 122.85110171200006],
  [14.259455974000048, 122.8512248190001],
  [14.259229621000031, 122.85126971400007],
  [14.258971428000052, 122.85139253800003],
  [14.258800270000052, 122.85153501200011],
  [14.25868151800006, 122.85171521400002],
  [14.25858508500005, 122.8521219480001],
  [14.258466420000047, 122.85221913100008],
  [14.257999964000021, 122.85246631900009],
  [14.257720250000034, 122.85257135900008],
  [14.257159674000036, 122.85298665800008],
  [14.257148734000054, 122.85310045500012],
  [14.256981343000064, 122.85330328600003],
  [14.256922575000033, 122.85364790500012],
  [14.256760929000052, 122.85393503600005],
  [14.256571430000065, 122.85366291800005],
  [14.256424945000049, 122.8536615160001],
  [14.256165654000029, 122.85426050900003],
  [14.25595746600004, 122.8542735210001],
  [14.255830466000077, 122.854615953],
  [14.255581508000034, 122.85470476000012],
  [14.255625257000077, 122.85446560700007],
  [14.255505818000074, 122.85424321900007],
  [14.255332287000044, 122.85436519400002],
  [14.255365695000023, 122.85464095700002],
  [14.255180137000025, 122.85471634700002],
  [14.254825222000022, 122.85450652400004],
  [14.254974151000056, 122.85415964200001],
  [14.25493836000004, 122.85402313500003],
  [14.25476747600004, 122.85404014500011],
  [14.254585561000056, 122.85431021300008],
  [14.254280080000058, 122.85417938300009],
  [14.254139810000027, 122.85399471000005],
  [14.254153621000057, 122.8537702970001],
  [14.25403542500004, 122.85365107400003],
  [14.253801295000073, 122.85394074700002],
  [14.253658424000037, 122.85394064600007],
  [14.25352102000005, 122.85368812600007],
  [14.25333409600006, 122.85353488200008],
  [14.252989521000075, 122.8533475480001],
  [14.25267879200004, 122.85347511200007],
  [14.252447852000046, 122.85340979600005],
  [14.252324093000027, 122.85352323200004],
  [14.252179019000039, 122.85354034300008],
  [14.25200847700006, 122.8534126720001],
  [14.252082832000042, 122.85291375500003],
  [14.252022090000025, 122.85278977000007],
  [14.251838196000051, 122.85294864600007],
  [14.251611105000052, 122.85285922100002],
  [14.251455344000021, 122.85288519900007],
  [14.251286196000024, 122.85280390700007],
  [14.250987805000022, 122.85277577200009],
  [14.25079028600004, 122.85312951500009],
  [14.250281891000043, 122.8551287460001],
  [14.25020446600007, 122.85568639200005],
  [14.25001018000006, 122.85604797000008],
  [14.249759793000067, 122.85640411300005],
  [14.24931612000006, 122.85676369300006],
  [14.24672141800005, 122.8577277280001],
  [14.24576432200007, 122.85810820600011],
  [14.244270210000025, 122.85871653100003],
  [14.243870574000027, 122.85884980300011],
  [14.243297787000074, 122.85910801900002],
  [14.241531935000069, 122.85993350600006],
  [14.240727650000053, 122.86027003000004],
  [14.239038794000066, 122.86085889000003],
  [14.23869140000005, 122.86099050500002],
  [14.23451250100004, 122.86281425600009],
  [14.232762240000056, 122.86352062700007],
  [14.224441899000055, 122.86434295700008],
  [14.221664167000029, 122.86633306500005],
  [14.216931061000025, 122.86285370300004],
  [14.216895057000045, 122.85860253600003],
  [14.220598205000044, 122.84630779200006],
  [14.221078055000078, 122.83792551600004],
  [14.220830694000028, 122.83321243700004],
  [14.220328867000035, 122.822709959],
  [14.220008093000047, 122.8159349230001],
  [14.219222438000031, 122.80303992100005],
  [14.215414960000032, 122.79834884800005],
  [14.214095438000072, 122.79785106700001],
  [14.213105776000077, 122.7973012550001],
  [14.211969497000041, 122.796866496],
  [14.211236414000041, 122.79584017900004],
  [14.211024634000069, 122.79535145800003],
  [14.21100834300006, 122.79491160700002],
  [14.21003089900006, 122.79429255900004],
  [14.209721375000072, 122.79370609300008],
  [14.209314107000068, 122.79276123000011],
  [14.208529483000063, 122.79191522200006],
  [14.207900575000053, 122.79150225900003],
  [14.206693417000054, 122.79137839300006],
  [14.206196575000035, 122.7906675380001],
  [14.205662546000042, 122.79009796500009],
  [14.20511044400007, 122.79018962700002],
  [14.204978833000041, 122.7895567380001],
  [14.204830042000026, 122.78908505000004],
  [14.204626270000063, 122.78866752300007],
  [14.20427434800007, 122.78836445900004],
  [14.203496229000052, 122.78796656300005],
  [14.203291696000065, 122.78770076100011],
  [14.203308201000027, 122.78661438600011],
  [14.203622147000033, 122.78596546200004],
  [14.203880512000069, 122.78514422500007],
  [14.20380562400004, 122.78443713200011],
  [14.203139116000045, 122.783519178],
  [14.202842797000073, 122.78242655400004],
  [14.202462875000037, 122.78204945100003],
  [14.20207416900007, 122.781232842],
  [14.201756983000053, 122.78097481100008],
  [14.201607504000037, 122.7805323450001],
  [14.202224495000053, 122.76160759100003],
  [14.201790496000058, 122.75420378700005],
  [14.20137582600006, 122.74717182100005],
  [14.205796082000063, 122.71974359500007],
  [14.202263662000064, 122.71099503800008],
  [14.188864098000067, 122.67780906000007],
  [14.216103105000059, 122.68557268900008],
  [14.23303095700004, 122.69029214400007],
  [14.233304443000065, 122.7159314480001],
  [14.239956966000022, 122.72367948100009],
  [14.243608729000073, 122.72367450400009],
  [14.245308614000066, 122.72458351400007],
  [14.247626449000052, 122.72694952600011],
  [14.250871214000028, 122.73120754100012],
  [14.255289810000022, 122.73205188800011],
  [14.256305283000074, 122.73292914400008],
  [14.25713810700006, 122.73432364200005],
  [14.25841639600003, 122.73482720900006],
  [14.260157668000033, 122.73507441200002],
  [14.27515007900007, 122.72803175000001],
  [14.284766423000065, 122.72351408700001],
  [14.29965308100003, 122.72664085200006],
  [14.311684702000036, 122.7249543590001],
  [14.311826454000027, 122.7249688610001],
  [14.31165998800003, 122.72520166200002],
  [14.311396451000064, 122.72542166500011],
  [14.311400336000077, 122.7256576530001],
  [14.311570880000033, 122.72600963200011],
  [14.311869319000039, 122.72628560900012],
  [14.31190033100006, 122.72646560300007],
  [14.31190035800006, 122.72714841800007],
  [14.312055401000066, 122.72759239100003],
  [14.312055405000024, 122.72772838500009],
  [14.312233695000032, 122.72798836700008],
  [14.312144562000071, 122.72821636100002],
  [14.312067048000074, 122.72821236300001],
  [14.312032178000038, 122.7285923500001],
  [14.311919799000066, 122.7291883370001],
  [14.31182291500005, 122.72940654500007],
  [14.311621399000046, 122.73019051300003],
  [14.311567149000041, 122.73066649300006],
  [14.311581817000047, 122.73146803700001],
  [14.311566946000028, 122.73214639800005],
  [14.311482366000064, 122.73291726800005],
  [14.311467465000021, 122.73331811900005],
  [14.311502357000052, 122.73370354800011],
  [14.311467533000041, 122.73407247700004],
  [14.311502411000049, 122.73430887300003],
  [14.311527386000023, 122.7352030730001],
  [14.311512537000056, 122.73630283700004],
  [14.311582294000061, 122.73684243800005],
  [14.31174169600007, 122.73755162900011],
  [14.311860112000033, 122.73793947900003],
  [14.312064317000022, 122.73846365500003],
  [14.312656950000076, 122.7393115650001],
  [14.312796387000049, 122.73943489400006],
  [14.312881051000033, 122.73960961700004],
  [14.312811344000067, 122.73972268200009],
  [14.31284621900005, 122.73999505300003],
  [14.312736684000072, 122.74024687800011],
  [14.312940852000054, 122.74032909000005],
  [14.313304359000028, 122.74025711800005],
  [14.313463709000075, 122.7403084990001],
  [14.313762480000037, 122.74022111300008],
  [14.314118579000024, 122.74034544400001],
  [14.314316611000038, 122.74051665700006],
  [14.314755459000025, 122.74046139600011],
  [14.314948129000072, 122.74051661800002],
  [14.31530671400003, 122.74070991200006],
  [14.315611786000034, 122.7410192000001],
  [14.31568672900005, 122.74131193200003],
  [14.315831230000072, 122.7413561090001],
  [14.315986463000058, 122.74188633900008],
  [14.31620590700004, 122.74225086500007],
  [14.316286197000068, 122.74247179300005],
  [14.316569861000062, 122.7427976560001],
  [14.31693279700005, 122.74305665100007],
  [14.317032156000039, 122.74295409600006],
  [14.316833418000044, 122.74279295800011],
  [14.316861802000062, 122.74269773100002],
  [14.317117309000025, 122.74264644200002],
  [14.317294752000066, 122.7427636320001],
  [14.317571555000029, 122.74280024200004],
  [14.31767803400004, 122.74312253700009],
  [14.317805794000037, 122.74324705300012],
  [14.31802581900007, 122.74331296600008],
  [14.318210362000059, 122.7434960810001],
  [14.31828134400007, 122.7436352520001],
  [14.318103911000037, 122.74370851100002],
  [14.317770325000026, 122.74359865400004],
  [14.317536118000078, 122.74378179100006],
  [14.31742966400003, 122.74394294500007],
  [14.317543228000034, 122.7440381670001],
  [14.317706470000076, 122.74404548400003],
  [14.318210411000052, 122.74450693200004],
  [14.318323964000058, 122.74437507700009],
  [14.318501404000074, 122.74443366800006],
  [14.318801989000065, 122.74430092900002],
  [14.318903957000032, 122.74435880900012],
  [14.318873370000063, 122.74462197300011],
  [14.319026363000035, 122.74463249100006],
  [14.319419030000063, 122.74435352000012],
  [14.319791315000032, 122.74446403000002],
  [14.320076892000031, 122.74430611800005],
  [14.320240085000023, 122.74434295300011],
  [14.320377774000065, 122.74426926100011],
  [14.320862397000042, 122.74430081700007],
  [14.321285672000045, 122.7442113190001],
  [14.32152026700004, 122.74437447000003],
  [14.321530476000078, 122.74459026400007],
  [14.32119390500003, 122.74484818200006],
  [14.321229607000078, 122.74494818300002],
  [14.321453991000055, 122.74483764400009],
  [14.321443798000075, 122.74500606900006],
  [14.32156619500006, 122.74506395900005],
  [14.32164268400004, 122.7449060570001],
  [14.321902774000023, 122.74499025800003],
  [14.321714072000077, 122.74472710200007],
  [14.321872146000032, 122.7443744520001],
  [14.322086324000054, 122.74413233000007],
  [14.322346406000065, 122.74402705000011],
  [14.322438206000072, 122.74413231200003],
  [14.322611591000054, 122.74401651000005],
  [14.322652395000034, 122.74413756400008],
  [14.322479013000077, 122.74434284200004],
  [14.322999202000062, 122.74465335500008],
  [14.323233795000021, 122.74475861000008],
  [14.323386792000065, 122.74488492400008],
  [14.323789677000036, 122.74503227800005],
  [14.324059973000033, 122.74529017000009],
  [14.324304773000051, 122.74557964400003],
  [14.324406775000057, 122.74576912000009],
  [14.324717875000033, 122.74612175400011],
  [14.324677117000022, 122.74578490200008],
  [14.324483315000066, 122.74547437300009],
  [14.324136518000046, 122.74512700500009],
  [14.324039613000025, 122.74491647500008],
  [14.323743816000047, 122.74467963500001],
  [14.323805007000033, 122.74455331000001],
  [14.324161995000054, 122.74466382600008],
  [14.324335392000023, 122.74479014000008],
  [14.324432298000033, 122.74502172400003],
  [14.324712786000077, 122.74509013500005],
  [14.324891283000056, 122.7452480280001],
  [14.324911697000061, 122.7456112000001],
  [14.325391079000042, 122.74580066100009],
  [14.325508382000066, 122.74602171800007],
  [14.325697074000061, 122.74605329100007],
  [14.325913208000031, 122.74618076800004],
  [14.325979510000025, 122.74632287800011],
  [14.326168206000034, 122.74645445600004],
  [14.326270209000029, 122.74668078000002],
  [14.326479304000031, 122.7468597300001],
  [14.326647603000026, 122.7470965760001],
  [14.327167786000075, 122.74733867400005],
  [14.327162689000033, 122.7474281530001],
  [14.326611925000066, 122.74770187100012],
  [14.326515036000046, 122.74790188400004],
  [14.326734328000043, 122.74800714500009],
  [14.326872026000046, 122.74815978100003],
  [14.326815933000034, 122.74827031400002],
  [14.32710662900007, 122.748586111],
  [14.326953641000046, 122.74874401900001],
  [14.326907752000068, 122.7490335110001],
  [14.327137246000063, 122.74914930200009],
  [14.326974058000076, 122.74931773700007],
  [14.32705080200003, 122.74959795100006],
  [14.32718131100006, 122.74965568000005],
  [14.327521588000025, 122.74993951200008],
  [14.32753557600006, 122.75012713700005],
  [14.327656772000068, 122.75025702700009],
  [14.327906497000072, 122.7506379990001],
  [14.327917512000056, 122.75074592900012],
  [14.328071634000025, 122.75095041700001],
  [14.32836885200004, 122.75106968600005],
  [14.328495450000048, 122.75120601000003],
  [14.328473445000043, 122.75137642900006],
  [14.32831935300004, 122.75160934300004],
  [14.328148737000049, 122.75167752100003],
  [14.32781946700004, 122.75200176600003],
  [14.32768313400004, 122.75233195500005],
  [14.327242659000035, 122.75321425900006],
  [14.32708535200004, 122.75363104700011],
  [14.326954258000058, 122.75388545300007],
  [14.326644884000075, 122.75470279400008],
  [14.326461370000061, 122.75554720000002],
  [14.326569790000065, 122.75580824100007],
  [14.32678721600007, 122.75596851400007],
  [14.326790672000072, 122.756082493],
  [14.326945985000066, 122.75637099800008],
  [14.327121996000074, 122.756470723],
  [14.326990866000074, 122.75670937600012],
  [14.326935659000071, 122.75694090400009],
  [14.326759653000067, 122.75697444700006],
  [14.32647322300005, 122.75720954300004],
  [14.326231653000036, 122.75734490500008],
  [14.326048306000075, 122.7575685170001],
  [14.32609663200003, 122.75782140500007],
  [14.325882682000042, 122.75826308300009],
  [14.325910298000053, 122.75844829800008],
  [14.325855086000047, 122.75860858300007],
  [14.325623866000058, 122.75869407700009],
  [14.325469688000055, 122.75864778000005],
  [14.325431731000037, 122.75880094100012],
  [14.32530058900005, 122.7587653270001],
  [14.325034858000038, 122.75886863000005],
  [14.32456551000007, 122.75889001900009],
  [14.324361897000074, 122.75893276900001],
  [14.324078904000032, 122.75882236300004],
  [14.323930507000057, 122.75883305400009],
  [14.323592296000072, 122.75875114700011],
  [14.323575308000045, 122.7589924460001],
  [14.323729638000032, 122.7590392730001],
  [14.323793204000026, 122.7592360210001],
  [14.32365704700004, 122.75936720400011],
  [14.32353905900004, 122.75962955500006],
  [14.323293971000055, 122.75979822800002],
  [14.323021637000068, 122.75984510400008],
  [14.322903644000064, 122.76005123700008],
  [14.322467892000077, 122.7599388540001],
  [14.32231357300003, 122.75999508500001],
  [14.322250018000034, 122.75989203200004],
  [14.322349853000048, 122.75967653100008],
  [14.322567715000048, 122.75959218500009],
  [14.322976218000065, 122.759554664],
  [14.322930805000055, 122.75932044000001],
  [14.322949315000074, 122.75909302800005],
  [14.322298618000048, 122.7595808430001],
  [14.32201518900007, 122.75974803800011],
  [14.32164675200005, 122.7601743460001],
  [14.321326894000038, 122.76048781200006],
  [14.321244600000057, 122.76076810500001],
  [14.321511859000054, 122.7608725560001],
  [14.32113938200007, 122.76141587600011],
  [14.321123199000056, 122.76156214500008],
  [14.320924793000074, 122.76162485100008],
  [14.320904562000067, 122.76179201700006],
  [14.320600873000046, 122.7618254790001],
  [14.320499664000067, 122.76206369800002],
  [14.320447061000039, 122.76248997300002],
  [14.320588788000066, 122.76253592900002],
  [14.320690038000066, 122.76274905500009],
  [14.32058478600004, 122.7630917570001],
  [14.320319940000047, 122.7630623770001],
  [14.320291607000058, 122.76320028900011],
  [14.320315015000062, 122.76391357800003],
  [14.320258731000024, 122.764164606],
  [14.320421286000055, 122.76442892700004],
  [14.320538752000061, 122.76490115700005],
  [14.320627890000026, 122.76561742100012],
  [14.320631975000026, 122.7660938360001],
  [14.320832598000038, 122.76665381900011],
  [14.321225394000066, 122.76695886200002],
  [14.321354985000028, 122.76717616600001],
  [14.321334757000045, 122.76744780800004],
  [14.321456286000057, 122.768183324],
  [14.321330411000076, 122.76840516900006],
  [14.321311611000056, 122.7685456800001],
  [14.321117006000065, 122.76894936700012],
  [14.321091958000068, 122.76916612500008],
  [14.321182518000057, 122.7693212370001],
  [14.321460439000077, 122.76953318000005],
  [14.321508609000034, 122.76974596100001],
  [14.321418049000044, 122.76991101600004],
  [14.321223481000061, 122.77042958000004],
  [14.321012824000036, 122.77080367600001],
  [14.32090392300006, 122.7713939360001],
  [14.320885793000059, 122.77188113300008],
  [14.320658870000045, 122.77238708200002],
  [14.320522718000063, 122.77276186000006],
  [14.320277632000057, 122.7731928610001],
  [14.32011863200006, 122.77368383600003],
  [14.320194668000056, 122.774042914],
  [14.320180844000049, 122.77434492100008],
  [14.320505725000032, 122.77474443000006],
  [14.320302962000028, 122.7747872330001],
  [14.320155498000076, 122.7746397950001],
  [14.319968864000032, 122.77462077100006],
  [14.319473207000044, 122.77486407000004],
  [14.319264416000067, 122.77493903200002],
  [14.318892227000049, 122.7751639060001],
  [14.318601866000051, 122.77525206000007],
  [14.318394548000072, 122.77539471500006],
  [14.317895443000054, 122.77561662900007],
  [14.31765740700007, 122.77568003500005],
  [14.31732723400006, 122.77597326500006],
  [14.316682237000066, 122.77635368000006],
  [14.31633669100006, 122.77622689600003],
  [14.31591385300004, 122.77635370400003],
  [14.31561438700004, 122.77640126200004],
  [14.315215103000071, 122.77662317000011],
  [14.314969394000059, 122.77693224400002],
  [14.314984755000069, 122.77710659000002],
  [14.315084586000069, 122.77740773000005],
  [14.315212685000063, 122.77745554500007],
  [14.315234888000077, 122.77760562100002],
  [14.314592967000067, 122.77866842000003],
  [14.314345557000024, 122.77889616300001],
  [14.314198443000066, 122.77876504500011],
  [14.313710292000053, 122.7781370560001],
  [14.313563179000028, 122.7781733590001],
  [14.312921251000034, 122.77886349000005],
  [14.312764311000024, 122.77907965200006],
  [14.312613665000072, 122.77940868300004],
  [14.312413065000044, 122.77974684000003],
  [14.312058666000041, 122.78004359200008],
  [14.31180457000005, 122.7803886480001],
  [14.311503662000064, 122.7805956850001],
  [14.311215483000069, 122.78087737900012],
  [14.310780837000038, 122.78100160300005],
  [14.310045285000058, 122.78130525600011],
  [14.30983799300003, 122.78143637800008],
  [14.309436776000041, 122.78148220600008],
  [14.309328704000052, 122.78180940700008],
  [14.309235035000029, 122.78192095500003],
  [14.308817109000074, 122.78188378900006],
  [14.308595187000037, 122.78173526700004],
  [14.308481935000032, 122.78133732800006],
  [14.30839295100003, 122.7813011510001],
  [14.308181013000024, 122.78149186300004],
  [14.307551571000033, 122.78193304000001],
  [14.30683758400005, 122.78265538800008],
  [14.306172464000042, 122.78349266400005],
  [14.305853052000032, 122.78398229800007],
  [14.305651073000035, 122.78439921200004],
  [14.305214227000022, 122.78518456400002],
  [14.305124979000027, 122.78543665200004],
  [14.304974667000067, 122.78602809300003],
  [14.30492769500006, 122.78633351200006],
  [14.305073333000053, 122.7867226520001],
  [14.305264634000025, 122.78713688300002],
  [14.30546182200004, 122.78740189700011],
  [14.305704868000078, 122.78759119200004],
  [14.305995237000047, 122.78772374100004],
  [14.306320825000057, 122.78781838800012],
  [14.30708205700006, 122.78814018900005],
  [14.307425986000055, 122.78834368300011],
  [14.30752687200004, 122.78845726100008],
  [14.307760744000063, 122.78858503700008],
  [14.308544907000055, 122.78907721300004],
  [14.30879711800003, 122.78903461800007],
  [14.30890258900007, 122.78910560500003],
  [14.309443703000056, 122.78918605500007],
  [14.309732603000043, 122.78927123900007],
  [14.310117304000073, 122.78959271400004],
  [14.310273316000064, 122.78967724200004],
  [14.310784256000034, 122.78987447500003],
  [14.311053377000064, 122.79016428900002],
  [14.311386998000046, 122.79044682900008],
  [14.31146500400007, 122.7903059460001],
  [14.311609315000055, 122.79048305600008],
  [14.311496206000072, 122.7909177790001],
  [14.311299596000026, 122.79102468500002],
  [14.31114758800004, 122.79118585900005],
  [14.31100182800003, 122.79125677500008],
  [14.310477090000063, 122.79162209900005],
  [14.309814854000024, 122.79223348300002],
  [14.309483769000053, 122.7924225910001],
  [14.30876496600007, 122.79293496800005],
  [14.307957896000062, 122.79336817800004],
  [14.307060167000031, 122.79390241800002],
  [14.306629414000042, 122.7941356660001],
  [14.306416997000042, 122.79429434600002],
  [14.306033042000024, 122.79443187200002],
  [14.305878951000068, 122.79453716700004],
  [14.305806947000065, 122.79469883400009],
  [14.305316998000023, 122.79493724800011],
  [14.304823070000054, 122.794924906],
  [14.304970448000063, 122.79515100200001],
  [14.304862898000067, 122.79521677200012],
  [14.304611955000041, 122.79495778700004],
  [14.304472539000074, 122.79501944600008],
  [14.304361008000058, 122.7949660050001],
  [14.304177776000074, 122.79500299800009],
  [14.304118028000062, 122.79490433900003],
  [14.303512910000052, 122.79471112100009],
  [14.303492997000035, 122.79454258100009],
  [14.302875592000078, 122.79433703300003],
  [14.302943310000046, 122.79420960300001],
  [14.302815846000044, 122.79412327600005],
  [14.30264057900007, 122.79433703000007],
  [14.302508525000064, 122.7943648050001],
  [14.302440807000039, 122.7945045670001],
  [14.302086297000074, 122.79449633900003],
  [14.301823399000057, 122.79460732300004],
  [14.301552538000067, 122.79452510400006],
  [14.301421092000055, 122.79436889700003],
  [14.301220374000025, 122.79439355700003],
  [14.300798151000038, 122.79417568500003],
  [14.300637746000064, 122.79417865500011],
  [14.300386308000043, 122.7938356090001],
  [14.300007016000052, 122.7937256560001],
  [14.299930304000043, 122.79387079000003],
  [14.299734267000076, 122.79365088800012],
  [14.299593629000071, 122.79380481500004],
  [14.299529707000033, 122.79347496600008],
  [14.299533970000027, 122.79329025100003],
  [14.299308100000076, 122.79309673800003],
  [14.299150416000032, 122.79321108500005],
  [14.299035348000075, 122.79343537900002],
  [14.298724242000048, 122.79347495800005],
  [14.29835378100006, 122.79318478100004],
  [14.298332475000052, 122.79284174100007],
  [14.298285596000028, 122.7927493840001],
  [14.29792761300007, 122.79261744300004],
  [14.297765668000068, 122.79235796300009],
  [14.297812548000024, 122.79221722900002],
  [14.29796170800006, 122.79208529100003],
  [14.29789352000006, 122.79189617700001],
  [14.297637162000058, 122.79186273300002],
  [14.297573236000062, 122.79192870300005],
  [14.297287702000062, 122.79180995700005],
  [14.297483741000065, 122.79173079500003],
  [14.29736867500003, 122.79153288900011],
  [14.297347366000054, 122.7913701650001],
  [14.297108710000032, 122.79125142100008],
  [14.29691267100003, 122.79096555700005],
  [14.296882839000034, 122.79083801700006],
  [14.29672359600005, 122.79063163900003],
  [14.296565913000052, 122.79053488500006],
  [14.296476416000075, 122.7902182360001],
  [14.296463627000037, 122.78971247700008],
  [14.296290942000041, 122.7895482780001],
  [14.296329296000067, 122.78930639300006],
  [14.296205703000055, 122.78896775600003],
  [14.296504020000043, 122.78876544800005],
  [14.296474187000058, 122.788607124],
  [14.296290933000023, 122.78859393200003],
  [14.29624442200003, 122.7883938110001],
  [14.29632281000005, 122.788164606],
  [14.296257480000065, 122.78785450400005],
  [14.296159488000058, 122.78763204200004],
  [14.295943909000073, 122.78741632300012],
  [14.295604213000047, 122.78726802100005],
  [14.295375573000058, 122.78728150800009],
  [14.295244925000077, 122.78747026700012],
  [14.294650455000067, 122.78707928300003],
  [14.294376086000057, 122.786978169],
  [14.29434995300005, 122.78688379000005],
  [14.294072898000024, 122.78653627300002],
  [14.293764972000076, 122.78619481200008],
  [14.293342150000058, 122.78577747300005],
  [14.293158315000028, 122.78562571400005],
  [14.292850394000027, 122.78550241500011],
  [14.292505704000064, 122.78531746300007],
  [14.292018546000065, 122.78524633500001],
  [14.291551961000039, 122.78524974700008],
  [14.290850895000062, 122.78542876600011],
  [14.290217283000061, 122.78554065700007],
  [14.28998600400007, 122.78561524600002],
  [14.289523443000064, 122.78562271600003],
  [14.289223503000073, 122.78568611900005],
  [14.288552811000045, 122.78590988300004],
  [14.288191437000023, 122.7859919330001],
  [14.287681814000052, 122.78615975500009],
  [14.287432468000077, 122.78631638400009],
  [14.286810906000028, 122.7866035400001],
  [14.286338665000073, 122.78678766300004],
  [14.28592154200004, 122.78692013400007],
  [14.285742782000057, 122.78709988800006],
  [14.285423003000062, 122.78720396900007],
  [14.285180060000073, 122.78724182400003],
  [14.284455846000071, 122.7878662390001],
  [14.284020407000071, 122.78834873100004],
  [14.283855405000054, 122.78867038500005],
  [14.283844900000076, 122.78898969900001],
  [14.283343029000036, 122.78934450600002],
  [14.283169158000021, 122.78956472200002],
  [14.283109894000063, 122.78987872300002],
  [14.283200805000035, 122.79028243200003],
  [14.283224527000073, 122.79052710600001],
  [14.283022993000031, 122.79078402200003],
  [14.282928327000036, 122.79122388600001],
  [14.282659609000063, 122.79145226000003],
  [14.282438306000074, 122.79150528200012],
  [14.282062880000069, 122.79147267400003],
  [14.281841568000061, 122.79124024500004],
  [14.28177832700004, 122.79096295000011],
  [14.281519266000032, 122.79042060400002],
  [14.28089484800006, 122.78985380900008],
  [14.280894843000056, 122.78974370700007],
  [14.280720957000028, 122.78963361300009],
  [14.280491753000035, 122.78970702600009],
  [14.28036925400005, 122.78989461300011],
  [14.280250696000053, 122.78984568400006],
  [14.280163765000054, 122.79007404800006],
  [14.28026651600004, 122.79012705500008],
  [14.280140069000026, 122.79044580200002],
  [14.280294210000022, 122.79085358200007],
  [14.280641970000033, 122.7907801660001],
  [14.280827716000033, 122.79101667400005],
  [14.281442595000044, 122.79134619600006],
  [14.282090720000042, 122.79185998500009],
  [14.282497765000073, 122.7920108510001],
  [14.282884060000072, 122.79200665700012],
  [14.283058422000067, 122.79206346800004],
  [14.283235837000063, 122.7919529830001],
  [14.283624331000055, 122.79219286400007],
  [14.28386295100006, 122.79278425200005],
  [14.283875193000029, 122.79299574000004],
  [14.283789545000047, 122.79307465700003],
  [14.283835439000029, 122.7934092480001],
  [14.283832389000054, 122.79372490100002],
  [14.283974783000076, 122.79402578600002],
  [14.284086114000047, 122.79407273100003],
  [14.28433456700003, 122.79440578100002],
  [14.284493352000027, 122.7945674660001],
  [14.284653569000056, 122.79487655600008],
  [14.285275334000062, 122.79531981500008],
  [14.285600411000075, 122.79563130400004],
  [14.285797779000063, 122.7959475880001],
  [14.285941300000047, 122.79640484000004],
  [14.286407432000033, 122.79711741100004],
  [14.28671008300006, 122.79718484300008],
  [14.286899238000046, 122.79736406800009],
  [14.28704751500004, 122.79758849400002],
  [14.28709566200007, 122.7978351490001],
  [14.28733984400003, 122.79807115900007],
  [14.287719878000075, 122.79801615300005],
  [14.28792340800004, 122.79809335900006],
  [14.28804023500004, 122.79822396100008],
  [14.288176534000058, 122.79851865000012],
  [14.288406942000051, 122.79869278500007],
  [14.288679538000054, 122.79939267600002],
  [14.288673049000067, 122.79975099500007],
  [14.288750933000074, 122.79984141300008],
  [14.288956291000034, 122.80034245400009],
  [14.289115540000068, 122.80080453300002],
  [14.289180440000052, 122.80129345600005],
  [14.289141493000045, 122.80187949100002],
  [14.289063605000024, 122.80226459800008],
  [14.28884292500004, 122.80293100400002],
  [14.288628580000022, 122.80342627200002],
  [14.288336510000022, 122.80373100400004],
  [14.287970199000029, 122.8040273680001],
  [14.287697601000048, 122.80417805600007],
  [14.287506135000058, 122.80420819200003],
  [14.287272484000027, 122.8041613050001],
  [14.286831147000044, 122.80384651400004],
  [14.286298621000071, 122.80397375500002],
  [14.286084440000025, 122.80397710000011],
  [14.285639854000067, 122.80386323500011],
  [14.285186779000071, 122.80416478000006],
  [14.284628596000061, 122.80482446300005],
  [14.284385930000042, 122.8055607550001],
  [14.28436070400005, 122.80602256700001],
  [14.284732593000058, 122.80626974400002],
  [14.284839745000056, 122.80643235700006],
  [14.28487125600003, 122.80664049900008],
  [14.284839734000059, 122.80683563100001],
  [14.284726270000021, 122.80697222000003],
  [14.284852329000046, 122.80727142800004],
  [14.284373268000024, 122.80750556800001],
  [14.284058103000064, 122.80749254700004],
  [14.283824886000048, 122.80733643300005],
  [14.283522331000029, 122.80722584800003],
  [14.283055897000054, 122.8069396410001],
  [14.282917225000062, 122.80695264500002],
  [14.28268399700005, 122.80715427300004],
  [14.282478197000046, 122.80703103300004],
  [14.281967052000027, 122.80690691000007],
  [14.281768610000029, 122.80679520900003],
  [14.281624286000067, 122.8067889990001],
  [14.281317601000069, 122.806646268],
  [14.281004908000057, 122.8063235840001],
  [14.280776408000065, 122.80580233400008],
  [14.280620062000025, 122.8055106810001],
  [14.280440697000074, 122.80527370700008],
  [14.27992105800007, 122.80510510300007],
  [14.279795327000045, 122.80489755400004],
  [14.279849787000046, 122.80467269500002],
  [14.280059288000075, 122.80433539600006],
  [14.280080227000042, 122.80415378100008],
  [14.279946109000036, 122.80388137300008],
  [14.279979619000073, 122.80369543200004],
  [14.27970722200007, 122.80352681300008],
  [14.279493480000042, 122.80320684500009],
  [14.279040649000024, 122.80280166900002],
  [14.278994526000076, 122.80246871700001],
  [14.278780786000027, 122.80218767000008],
  [14.278755870000055, 122.8014716890001],
  [14.278772623000066, 122.80136790900008],
  [14.278684600000076, 122.80111279400012],
  [14.278424757000039, 122.80076689100008],
  [14.278102076000039, 122.80065017200002],
  [14.27790090700006, 122.80038209800011],
  [14.277854770000033, 122.79994969200004],
  [14.27750274300007, 122.79972487200007],
  [14.277384812000037, 122.79974082500007],
  [14.277238176000026, 122.80010406400004],
  [14.277116664000062, 122.80024677100005],
  [14.276693455000043, 122.80061003300011],
  [14.276412697000069, 122.80068789300003],
  [14.275990445000048, 122.80068361000008],
  [14.275814436000076, 122.80060579400003],
  [14.275386966000042, 122.80019937600002],
  [14.275257053000075, 122.80013020400008],
  [14.274984673000063, 122.80016482300005],
  [14.274817046000067, 122.80009998000003],
  [14.274687149000044, 122.80019944500009],
  [14.27456101000007, 122.80046322200008],
  [14.274505161000036, 122.80225853700006],
  [14.274730073000057, 122.80221721100008],
  [14.274940340000057, 122.80226726100011],
  [14.275126354000065, 122.80240076100006],
  [14.275579226000048, 122.8023673450001],
  [14.27591889300004, 122.80246745500006],
  [14.276270129000068, 122.80260919700004],
  [14.276770007000039, 122.80285923100007],
  [14.27706792400005, 122.80311449500005],
  [14.277364986000066, 122.80316656800005],
  [14.27759220400003, 122.80331242800003],
  [14.277834580000047, 122.80357290600011],
  [14.278203204000022, 122.8041042970001],
  [14.278301003000024, 122.80440407900005],
  [14.278422229000057, 122.80505011000002],
  [14.278393434000066, 122.805250408],
  [14.278245189000074, 122.80553921],
  [14.278039664000062, 122.80609921200005],
  [14.277940999000066, 122.8061925500001],
  [14.277377775000048, 122.8065107650001],
  [14.277003655000044, 122.8066210930001],
  [14.27680631800007, 122.80671443900007],
  [14.276565376000065, 122.80707806500004],
  [14.276479062000021, 122.80743442500011],
  [14.27615841200003, 122.807939282],
  [14.275940538000043, 122.80837201800011],
  [14.27584599200003, 122.80863929300006],
  [14.275559792000024, 122.80920644500009],
  [14.275428239000064, 122.80936765900003],
  [14.27519392000005, 122.80984281600001],
  [14.27512219700003, 122.81005592300005],
  [14.274994782000022, 122.81077711700004],
  [14.274645363000047, 122.81174014300007],
  [14.274553964000063, 122.81228783400002],
  [14.274418315000048, 122.81286054400005],
  [14.274352540000052, 122.81298781500004],
  [14.274192226000025, 122.8137132490001],
  [14.274023683000053, 122.81437081000001],
  [14.273990931000071, 122.81503329600002],
  [14.274006549000035, 122.81533387700006],
  [14.273990964000063, 122.81600480200007],
  [14.274277090000055, 122.81746472700002],
  [14.274360321000074, 122.81772236200004],
  [14.27449557500006, 122.81829323500006],
  [14.274734864000038, 122.81893195900011],
  [14.274963745000036, 122.81943112600004],
  [14.275326762000077, 122.82010108600002],
  [14.275524431000065, 122.82035872300003],
  [14.275982185000032, 122.82079885100006],
  [14.276502354000058, 122.82099744300001],
  [14.277194178000059, 122.82120140200004],
  [14.277417851000052, 122.82123360500009],
  [14.277779168000052, 122.82145283500006],
  [14.27794562300005, 122.82165679900004],
  [14.278210909000052, 122.82168900300007],
  [14.278325346000031, 122.82184466000001],
  [14.278221313000074, 122.82197348000011],
  [14.277961229000027, 122.82211303500003],
  [14.277919616000077, 122.82227405900005],
  [14.278190102000053, 122.82232773400005],
  [14.278538615000059, 122.8222472220001],
  [14.278772691000029, 122.82208083000012],
  [14.278897531000041, 122.8220486240001],
  [14.279022372000043, 122.82274103100008],
  [14.278855917000044, 122.82297183300011],
  [14.278611438000041, 122.82309528600001],
  [14.278767488000028, 122.82326704600007],
  [14.278966696000055, 122.82321657000011],
  [14.279099081000027, 122.82311591400003],
  [14.27913740400004, 122.8229577400001],
  [14.279266306000068, 122.82276361600009],
  [14.27946140000006, 122.82260544000007],
  [14.279524109000022, 122.82246523900005],
  [14.27975380600003, 122.82255633900002],
  [14.280015068000068, 122.822408042],
  [14.280047747000026, 122.82273830100007],
  [14.28019144600006, 122.82274503100007],
  [14.280224093000072, 122.82254282800011],
  [14.280387387000076, 122.8225428180001],
  [14.280518030000053, 122.82267087000002],
  [14.280622520000065, 122.82236756100008],
  [14.280746621000048, 122.82232037200004],
  [14.280831544000023, 122.82248212900004],
  [14.281034033000026, 122.8225495150001],
  [14.281105879000052, 122.82248884900002],
  [14.281099331000064, 122.82223272700003],
  [14.281203824000045, 122.82197659600001],
  [14.281510820000051, 122.82199005400003],
  [14.28177861100005, 122.82185523700002],
  [14.281889641000078, 122.82167998600005],
  [14.282098649000034, 122.82153842800005],
  [14.282457898000075, 122.8215249210001],
  [14.282958259000054, 122.82235805800008],
  [14.283037362000073, 122.82259032900004],
  [14.283225969000057, 122.82278492600005],
  [14.28323206500005, 122.82298581500004],
  [14.283414606000065, 122.82348802100012],
  [14.283755300000053, 122.82357588900004],
  [14.283846560000029, 122.82364493900002],
  [14.28383440500005, 122.82385210500001],
  [14.283897921000062, 122.82401345800008],
  [14.284400033000054, 122.82424686600007],
  [14.284808356000042, 122.82464539300008],
  [14.284869061000052, 122.82486744000005],
  [14.285023570000021, 122.82518058000005],
  [14.285034619000044, 122.82543109800008],
  [14.285117401000036, 122.82576701800008],
  [14.285349157000041, 122.82608015700009],
  [14.286060861000067, 122.82638820500006],
  [14.286242951000077, 122.82659316700006],
  [14.286220897000021, 122.82692340000006],
  [14.28596157800007, 122.8270714470001],
  [14.286022284000069, 122.82736182100007],
  [14.285801592000041, 122.82760665800004],
  [14.28570228500007, 122.8278116350001],
  [14.28551469000007, 122.82786858100008],
  [14.28539885400005, 122.8287112590001],
  [14.285365785000067, 122.82968493800001],
  [14.285299588000044, 122.83012335300009],
  [14.285387882000066, 122.8305162150001],
  [14.285470650000036, 122.83066425100003],
  [14.285525835000044, 122.83095103800008],
  [14.28547067200003, 122.83141222200004],
  [14.285249985000064, 122.8320043650001],
  [14.284946540000021, 122.83306908600002],
  [14.284929890000058, 122.83323752700005],
  [14.284829200000047, 122.83344066500001],
  [14.284533812000063, 122.83430820600006],
  [14.28445731100004, 122.83464034500003],
  [14.284440217000054, 122.83491698700004],
  [14.284339786000032, 122.83521231800012],
  [14.284282305000033, 122.83595621500001],
  [14.284396664000042, 122.836379985],
  [14.284327044000065, 122.83704402300009],
  [14.284322651000025, 122.8376033830001],
  [14.284377237000058, 122.83795417800002],
  [14.284325962000025, 122.83879025200008],
  [14.284144087000072, 122.83978653000008],
  [14.284109545000035, 122.84021689500003],
  [14.284099673000071, 122.84095037300006],
  [14.28404719200006, 122.8413561960001],
  [14.283953220000058, 122.8418358990001],
  [14.283817297000041, 122.84222353100006],
  [14.283776877000037, 122.84268465600007],
  [14.283652676000031, 122.8430046420001],
  [14.283540899000059, 122.84350898200012],
  [14.283399169000063, 122.84395194500007],
  [14.283358086000021, 122.84417333800002],
  [14.283175206000067, 122.84481310800004],
  [14.28290301100003, 122.84546543600004],
  [14.282523930000025, 122.84612989900006],
  [14.282418475000043, 122.84642044200007],
  [14.282119832000035, 122.84691793800005],
  [14.282032969000056, 122.84715037900003],
  [14.28187728100005, 122.84735722200003],
  [14.281883834000041, 122.84746684600009],
  [14.282147533000057, 122.84789819900004],
  [14.28212379300004, 122.84835619400008],
  [14.281924654000022, 122.84867281100003],
  [14.281774613000039, 122.84866034900006],
  [14.281537972000024, 122.8489770760001],
  [14.281363018000036, 122.84899693700004],
  [14.281200972000022, 122.84916510300002],
  [14.281094735000067, 122.84917186400003],
  [14.280975609000052, 122.849036769],
  [14.280863086000068, 122.84903064700006],
  [14.280483544000049, 122.84927424400007],
  [14.280277889000047, 122.84949412900005],
  [14.280096898000068, 122.84959140100011],
  [14.279691607000075, 122.84994731200004],
  [14.279578996000055, 122.84990894500004],
  [14.279298654000058, 122.85024514200006],
  [14.27891784600007, 122.85041394500001],
  [14.278749406000031, 122.85053053100012],
  [14.278393279000056, 122.85058317000005],
  [14.278330582000024, 122.85051240900009],
  [14.277901535000069, 122.85062395200009],
  [14.276784529000054, 122.85127860500006],
  [14.276447738000059, 122.85154401700004],
  [14.276335586000073, 122.85167333400011],
  [14.27610749400003, 122.85177088900002],
  [14.27569666100004, 122.85203895000006],
  [14.275297914000078, 122.85212622200004],
  [14.274707182000043, 122.85215738300008],
  [14.274386976000073, 122.85225089000005],
  [14.27406073000003, 122.85220724600003],
  [14.273915731000045, 122.85224464900011],
  [14.273474690000057, 122.85246283300012],
  [14.273166561000039, 122.852787],
  [14.272781252000073, 122.85293660900004],
  [14.272654376000048, 122.85304258600002],
  [14.272160455000062, 122.85326839000004],
  [14.272069491000025, 122.85337901200012],
  [14.272007766000058, 122.85362036800007],
  [14.271877817000075, 122.85388518900004],
  [14.271695889000057, 122.85396564200005],
  [14.271582185000057, 122.85385166700007],
  [14.271634163000044, 122.85366729800012],
  [14.271523708000075, 122.85354327000005],
  [14.271322288000022, 122.85367400300004],
  [14.271332034000068, 122.85394552700006],
  [14.271185843000069, 122.85389859700001],
  [14.270986874000073, 122.85369089500011],
  [14.270611651000024, 122.85382489300002],
  [14.270430400000066, 122.85399320600004],
  [14.270031650000021, 122.85419268400005],
  [14.269783938000046, 122.85443580100002],
  [14.269644974000073, 122.85470385800011],
  [14.269342890000075, 122.85479112700011],
  [14.26899965900003, 122.85472072200002],
  [14.268860634000077, 122.85500040400007]

];

const polygonParacale = [
  [// paracale 
    polygonCoordsPCL
  ],
  [// paracale 2
    polygonCoordsPCL2
  ]
]

const polygonCoordsVIN2 = [
  [14.177125820000072, 122.9437849300001],
  [14.176934870000025, 122.9432452960001],
  [14.175388945000066, 122.93937771500009],
  [14.173968867000042, 122.93870334600001],
  [14.164125407000029, 122.92878370000005],
  [14.156977657000027, 122.92360169400001],
  [14.15643618300004, 122.92400518700003],
  [14.155863585000077, 122.92426462900005],
  [14.155390457000067, 122.92236586100012],
  [14.155193108000049, 122.92154156100003],
  [14.155115899000066, 122.92131349400006],
  [14.154933148000055, 122.9209896320001],
  [14.154757672000073, 122.92079774900003],
  [14.15332496600007, 122.91957077900008],
  [14.152505146000067, 122.91885390900006],
  [14.152088862000028, 122.91845792800007],
  [14.15194440700003, 122.9182628750001],
  [14.151724449000028, 122.9177575110001],
  [14.151214152000023, 122.91794108900001],
  [14.151199761000044, 122.91788534000011],
  [14.150790390000054, 122.90610226800004],
  [14.15048168100003, 122.9060407390001],
  [14.150298105000047, 122.90586951700004],
  [14.150157276000073, 122.90586380700006],
  [14.15004889100004, 122.905746356],
  [14.149788166000064, 122.9056550680001],
  [14.149716768000076, 122.90549405700006],
  [14.149539367000045, 122.90541871800008],
  [14.14930361100005, 122.90516880100006],
  [14.14918040300006, 122.9051684100001],
  [14.14892211800003, 122.90531639900007],
  [14.148598253000046, 122.90506549400004],
  [14.148431479000067, 122.90506622300006],
  [14.148373502000027, 122.90518444400004],
  [14.14562373700005, 122.90241031300002],
  [14.14591669300006, 122.90217505100009],
  [14.146046951000073, 122.90194385000007],
  [14.146160922000035, 122.90155367300008],
  [14.146143453000036, 122.90128611300008],
  [14.14595285300004, 122.90009864800004],
  [14.146025365000071, 122.89983668000002],
  [14.146262473000036, 122.89957087500011],
  [14.146115259000055, 122.8991315080001],
  [14.143742045000067, 122.89723780600002],
  [14.140678484000034, 122.8944057760001],
  [14.136625496000022, 122.89275695600008],
  [14.136722681000037, 122.89012864000006],
  [14.135600991000047, 122.88361144800001],
  [14.12851364200003, 122.8720410200001],
  [14.123901701000023, 122.86726613000008],
  [14.124314378000065, 122.86735606700006],
  [14.124640200000044, 122.86723230200005],
  [14.124770392000073, 122.86709707700004],
  [14.124879650000025, 122.86714213900007],
  [14.124816107000072, 122.86755846200003],
  [14.124675686000046, 122.86777159100006],
  [14.12469851800006, 122.86798440900009],
  [14.124861882000062, 122.86806288000002],
  [14.125155256000028, 122.86801841500005],
  [14.125361696000027, 122.867895384],
  [14.125459239000065, 122.86760359300001],
  [14.125413509000055, 122.86735599200006],
  [14.12558105100004, 122.86716326200008],
  [14.125790313000039, 122.86708741300004],
  [14.125920381000071, 122.86716906800007],
  [14.12622159800003, 122.86745505300007],
  [14.126374122000072, 122.86747835100005],
  [14.126509145000057, 122.86732676000008],
  [14.126684442000055, 122.86722775100009],
  [14.126877953000076, 122.86750640000002],
  [14.127070993000075, 122.86759237900003],
  [14.127400574000035, 122.86730821800006],
  [14.127676179000048, 122.86723510600007],
  [14.127762120000057, 122.86729114500008],
  [14.12791145400007, 122.86755969300009],
  [14.128051982000045, 122.86760119400003],
  [14.128303979000066, 122.86756286900004],
  [14.128430023000021, 122.86762414500004],
  [14.128656251000052, 122.86790801200004],
  [14.128704690000063, 122.8682357140001],
  [14.128686032000076, 122.86858137000002],
  [14.128630931000032, 122.86890742500009],
  [14.128627037000058, 122.86918955700003],
  [14.128705495000077, 122.8692933000001],
  [14.12904689000004, 122.8695652130001],
  [14.129149994000045, 122.869784019],
  [14.12939652800003, 122.8701546850001],
  [14.130019172000061, 122.87061677000008],
  [14.130158347000076, 122.87065625900004],
  [14.130451422000021, 122.87060143800011],
  [14.13052863400003, 122.87072278800008],
  [14.130483187000038, 122.87090906700007],
  [14.130556859000023, 122.87100420600007],
  [14.130901019000078, 122.8711208310001],
  [14.131168420000051, 122.87116286000003],
  [14.13147045200003, 122.87112661300011],
  [14.131913720000057, 122.87090213800002],
  [14.13202485200003, 122.87098266200007],
  [14.13208807500007, 122.8713445190001],
  [14.131977512000049, 122.87148264000007],
  [14.132036352000057, 122.8716687320001],
  [14.132248725000068, 122.87172720300009],
  [14.132399564000025, 122.87166124100008],
  [14.132389526000054, 122.8714783900001],
  [14.132309204000023, 122.87136522100002],
  [14.132463749000067, 122.87126575000002],
  [14.132624239000052, 122.871300141],
  [14.132930472000055, 122.87154956900008],
  [14.13281816600005, 122.87165024100011],
  [14.132580554000072, 122.87165292500003],
  [14.13258187300005, 122.87184122000008],
  [14.132412931000033, 122.8719211230001],
  [14.132469843000024, 122.87210295200009],
  [14.132750113000043, 122.87227278300008],
  [14.13288114200003, 122.87230304700006],
  [14.133115799000052, 122.87215717700008],
  [14.133219962000055, 122.87235851900004],
  [14.133447918000059, 122.87232964100008],
  [14.133469776000027, 122.87220719900006],
  [14.133365982000043, 122.87197982200007],
  [14.133423974000038, 122.87183564500003],
  [14.133623563000071, 122.87180841800011],
  [14.133697443000074, 122.87200886200003],
  [14.133919228000025, 122.87221134900005],
  [14.134100271000023, 122.87220284600005],
  [14.134252893000053, 122.8720538660001],
  [14.13413416000003, 122.87185437500011],
  [14.134175824000067, 122.8716491890001],
  [14.134352547000049, 122.8715848170001],
  [14.134620222000024, 122.8717411660001],
  [14.134750595000071, 122.87171567100006],
  [14.134785737000072, 122.87148621100005],
  [14.13471030200003, 122.8712822870001],
  [14.134386649000021, 122.87122854800009],
  [14.134383816000025, 122.87107662400001],
  [14.134505199000046, 122.87082577600006],
  [14.134760312000026, 122.87073517400006],
  [14.135051556000064, 122.87089844000002],
  [14.135399286000052, 122.87098005100006],
  [14.135650586000054, 122.87110330200005],
  [14.135836397000048, 122.87103074100003],
  [14.136232977000077, 122.87109426800009],
  [14.136399028000028, 122.87101655800006],
  [14.136472526000034, 122.87083270000005],
  [14.136750930000062, 122.8708371680001],
  [14.136906357000043, 122.87092344000007],
  [14.136804558000051, 122.8710997820001],
  [14.136824408000052, 122.87120216500011],
  [14.137079122000046, 122.87132729900009],
  [14.137255222000022, 122.871145124],
  [14.137242980000053, 122.87096437100001],
  [14.137154413000076, 122.87081680200004],
  [14.137367115000075, 122.87066108400006],
  [14.13750384900004, 122.8706555550001],
  [14.137700883000036, 122.87083609000001],
  [14.13790926300004, 122.87070156900006],
  [14.137893306000024, 122.87052720400004],
  [14.137774379000064, 122.87045539500002],
  [14.137435207000067, 122.87048590200004],
  [14.137175770000056, 122.87032421700007],
  [14.137133130000052, 122.87010620100011],
  [14.13686173900004, 122.87010070100007],
  [14.13675354000003, 122.86994956700005],
  [14.136771274000068, 122.86956310700009],
  [14.136613931000056, 122.869523436],
  [14.136484701000029, 122.86938270500002],
  [14.136453227000061, 122.86917555000002],
  [14.136497332000033, 122.86894791600002],
  [14.136649480000074, 122.8688533610001],
  [14.136937235000062, 122.86881956200011],
  [14.137013642000056, 122.86867408400008],
  [14.137241482000036, 122.86849090500004],
  [14.137215431000072, 122.86819514800004],
  [14.137145417000056, 122.86809445900008],
  [14.136799153000027, 122.86807645200008],
  [14.136480543000062, 122.86787999700005],
  [14.136475978000021, 122.86762886300005],
  [14.136638616000027, 122.86749541800009],
  [14.13682805700006, 122.86745686900008],
  [14.136931557000025, 122.86725044100001],
  [14.137153432000048, 122.86731270900009],
  [14.137381333000064, 122.86719616100004],
  [14.137753001000021, 122.86721161000003],
  [14.138378484000043, 122.86742711200009],
  [14.138553416000036, 122.86762480700008],
  [14.138705455000036, 122.86765349500001],
  [14.139501533000043, 122.867552036],
  [14.140168576000065, 122.86759440500009],
  [14.140270065000038, 122.86747217600009],
  [14.140141440000036, 122.86712033000003],
  [14.140156151000042, 122.86703406900006],
  [14.140407395000068, 122.86696956900005],
  [14.140554204000068, 122.86710120500004],
  [14.140784932000031, 122.86721835600008],
  [14.141047706000052, 122.86726099700002],
  [14.141213428000071, 122.86740871800009],
  [14.141270452000072, 122.867620224],
  [14.141602474000024, 122.86775297200006],
  [14.141871263000041, 122.86774423400004],
  [14.142214028000069, 122.86761084500006],
  [14.142515771000035, 122.8676355990001],
  [14.142485626000052, 122.86775815400006],
  [14.142611948000024, 122.86858842900006],
  [14.14268869700004, 122.86880240500011],
  [14.142778455000041, 122.86887237600001],
  [14.143021339000029, 122.86886878000007],
  [14.143231324000055, 122.86866194200002],
  [14.143135813000072, 122.86834990300008],
  [14.143157712000061, 122.86822176800001],
  [14.143387725000025, 122.86792734200003],
  [14.143708627000024, 122.86786640900004],
  [14.14374321400004, 122.86763790500004],
  [14.14384386200004, 122.86750060000008],
  [14.144123668000077, 122.86750728900006],
  [14.144338216000051, 122.86768416600012],
  [14.144467962000022, 122.86785379500009],
  [14.144848163000063, 122.86820654400003],
  [14.145319811000036, 122.86844529200005],
  [14.145492521000051, 122.86839183300003],
  [14.14561615200006, 122.86809965000009],
  [14.145936605000031, 122.86778008400006],
  [14.146167309000077, 122.86780605400008],
  [14.146213678000038, 122.86810254800002],
  [14.14661574300004, 122.86832490400002],
  [14.146699760000047, 122.8685380710001],
  [14.146611289000077, 122.8687749070001],
  [14.146455178000053, 122.86876304300006],
  [14.146191108000039, 122.86858678300007],
  [14.145891229000028, 122.86881940700005],
  [14.145636158000059, 122.8689477800001],
  [14.145514453000033, 122.86914442500006],
  [14.145628444000067, 122.86944755200011],
  [14.145964452000044, 122.86970964500006],
  [14.146124968000038, 122.86972433200003],
  [14.146240462000037, 122.86964259900003],
  [14.146311730000036, 122.8694528530001],
  [14.146454737000056, 122.86943938000002],
  [14.146517886000026, 122.8697416330001],
  [14.146376162000024, 122.86978091600008],
  [14.146312648000048, 122.87010077100001],
  [14.14670291300007, 122.869968416],
  [14.146933336000075, 122.86995344600007],
  [14.147001012000032, 122.87023955900008],
  [14.147159696000074, 122.87037829700012],
  [14.147114230000057, 122.8705098370001],
  [14.146783420000077, 122.87049450900008],
  [14.14677041300007, 122.87067457500007],
  [14.14691771300005, 122.87088884900004],
  [14.147329239000044, 122.8707747310001],
  [14.147408848000055, 122.87059146500008],
  [14.147591388000023, 122.87054849700007],
  [14.14779033700006, 122.87105991900012],
  [14.147356865000063, 122.87118809200001],
  [14.147377965000032, 122.87150344000008],
  [14.147636631000069, 122.87152706800009],
  [14.147803667000062, 122.87142636300007],
  [14.148018734000061, 122.87144132000003],
  [14.148071937000054, 122.871714988],
  [14.148287337000056, 122.87177053800008],
  [14.148404310000046, 122.8716651100001],
  [14.14876874500004, 122.87171582600001],
  [14.148831373000064, 122.87193337300005],
  [14.14854144700007, 122.872001339],
  [14.148528782000028, 122.87211932800005],
  [14.148741864000044, 122.87218051100001],
  [14.149055761000056, 122.87204642000006],
  [14.14899586200005, 122.87179171900004],
  [14.149026186000071, 122.87151864500004],
  [14.149167229000057, 122.8714803040001],
  [14.149399558000027, 122.87167411200005],
  [14.14958815700004, 122.87171035600011],
  [14.149836508000021, 122.87132136500009],
  [14.150172402000067, 122.8708647740001],
  [14.150387611000042, 122.87074126100003],
  [14.150472713000056, 122.87060091],
  [14.150465316000066, 122.87043752500006],
  [14.150373690000038, 122.8702254000001],
  [14.150436656000068, 122.87016270700008],
  [14.150760112000057, 122.87014763400009],
  [14.15077163500007, 122.86997754800007],
  [14.150528940000072, 122.86975917000007],
  [14.150453971000047, 122.86957158900009],
  [14.150463220000063, 122.86913733600011],
  [14.150516321000055, 122.86903234100009],
  [14.150772919000076, 122.86907901100005],
  [14.150952055000062, 122.86925873300004],
  [14.151070953000044, 122.86969458500005],
  [14.151179745000036, 122.86985745400011],
  [14.151391842000066, 122.86984501400002],
  [14.151516541000035, 122.86971462100007],
  [14.151783192000039, 122.8697101250001],
  [14.152160766000065, 122.86942980300012],
  [14.152326753000068, 122.86958443600008],
  [14.152487768000071, 122.86963542100011],
  [14.152982231000067, 122.86951968100004],
  [14.153248274000077, 122.86960957400004],
  [14.153666633000057, 122.86968382700002],
  [14.153824174000022, 122.86956372100008],
  [14.153828247000035, 122.86935236600004],
  [14.15373020800007, 122.86900942300008],
  [14.153590445000077, 122.86883655300005],
  [14.153295122000031, 122.86889248200009],
  [14.153194730000052, 122.8687878390001],
  [14.153264901000057, 122.86857525300002],
  [14.153550605000078, 122.86839100100008],
  [14.15402044900003, 122.86850628800005],
  [14.15438639100006, 122.86849621100009],
  [14.154552343000034, 122.86837635000006],
  [14.154910825000059, 122.86781073100008],
  [14.155151355000044, 122.86782938600004],
  [14.155188503000034, 122.86800607300006],
  [14.15500509900005, 122.86817747700002],
  [14.155134204000035, 122.86869452000008],
  [14.155398602000048, 122.86880623500008],
  [14.155931468000063, 122.868503615],
  [14.156149148000054, 122.86820997200005],
  [14.15624335800004, 122.86785401100008],
  [14.156763773000023, 122.86769297700005],
  [14.156922695000048, 122.86749593000002],
  [14.156890550000071, 122.86719123800003],
  [14.156718376000072, 122.86692974900006],
  [14.156714813000065, 122.86666301100001],
  [14.157024233000072, 122.86655056500001],
  [14.157094186000052, 122.86634453600004],
  [14.157060254000044, 122.86609514500003],
  [14.157296880000047, 122.86600781900006],
  [14.157480488000033, 122.86611894100008],
  [14.157673214000056, 122.86603177600011],
  [14.157487261000028, 122.865781534],
  [14.157193515000074, 122.86560979100011],
  [14.157028853000043, 122.86547001800011],
  [14.156999122000059, 122.86530803200003],
  [14.157518050000022, 122.86549389600009],
  [14.15769139300005, 122.86551111100005],
  [14.157892460000028, 122.86527860800004],
  [14.157898791000036, 122.86499817700007],
  [14.157732667000062, 122.86462404000008],
  [14.157629475000022, 122.86430952100011],
  [14.157707105000043, 122.86410570900011],
  [14.157779507000043, 122.86363045300004],
  [14.157726366000077, 122.8634893730001],
  [14.157579446000057, 122.86335654000004],
  [14.157413494000025, 122.86333696400004],
  [14.157318632000056, 122.86321336700007],
  [14.157239588000039, 122.86271441200006],
  [14.157302108000067, 122.86249584300003],
  [14.157461221000062, 122.86235933300009],
  [14.15780908000005, 122.86236406300009],
  [14.158155987000043, 122.86254698500011],
  [14.158390262000069, 122.86256043100002],
  [14.158757801000036, 122.86249878800004],
  [14.158912861000033, 122.86273397100001],
  [14.158926763000068, 122.86295220400007],
  [14.159065596000062, 122.86323259000005],
  [14.158971394000048, 122.86335688100007],
  [14.158657036000022, 122.8634593810001],
  [14.158367309000027, 122.8633218220001],
  [14.15835628700006, 122.86352056100009],
  [14.158533846000068, 122.86371137700007],
  [14.158929856000043, 122.86393763700005],
  [14.15912234700005, 122.8640973360001],
  [14.159196809000036, 122.86423062000006],
  [14.159242990000052, 122.86470879],
  [14.159296467000047, 122.86488055000007],
  [14.159588965000069, 122.86536253400004],
  [14.159717078000028, 122.86549338900011],
  [14.159976818000075, 122.86552584600008],
  [14.160113577000061, 122.86564621100001],
  [14.160115068000039, 122.86607806400002],
  [14.160330654000063, 122.86612154400007],
  [14.160499952000066, 122.86593705200005],
  [14.16068365600006, 122.86596970900007],
  [14.160814680000044, 122.866090127],
  [14.160962374000064, 122.86644209600001],
  [14.161325047000048, 122.86707304800007],
  [14.161580494000077, 122.86711823500002],
  [14.161906529000078, 122.86699847900002],
  [14.162048263000031, 122.86708959500004],
  [14.162197483000057, 122.86738094300006],
  [14.16229174600005, 122.867699291],
  [14.162479482000037, 122.86769133900009],
  [14.162499770000068, 122.86750236000012],
  [14.162585653000065, 122.86738217300001],
  [14.162722952000024, 122.86753350000004],
  [14.16268274500004, 122.86774409400005],
  [14.16275534700003, 122.86786256100004],
  [14.162901113000032, 122.86785874500004],
  [14.163145665000059, 122.86771965900004],
  [14.163336842000035, 122.86754179600007],
  [14.16347643000006, 122.8675360090001],
  [14.16382266900007, 122.86784199600004],
  [14.163755946000038, 122.86800727600007],
  [14.163784373000055, 122.8682013350001],
  [14.164059555000051, 122.86815740400004],
  [14.16420968500006, 122.86822909200009],
  [14.164145941000072, 122.86861498700011],
  [14.164235218000044, 122.86866679900004],
  [14.16443141800005, 122.868469935],
  [14.164604453000038, 122.86844672000007],
  [14.164902952000034, 122.86853686000006],
  [14.164964444000077, 122.86825456400004],
  [14.165131834000022, 122.86824554900011],
  [14.165200822000031, 122.86837009400006],
  [14.165151371000036, 122.86860600900002],
  [14.16525660800005, 122.86889738100001],
  [14.16548315600005, 122.86940510600004],
  [14.165783052000052, 122.86937549200002],
  [14.16594817500004, 122.86924658500004],
  [14.166088844000058, 122.86903723700004],
  [14.166354094000042, 122.869008172],
  [14.166643092000072, 122.86883430900002],
  [14.167011066000043, 122.86878105900007],
  [14.16710951500005, 122.86867190600003],
  [14.167548521000072, 122.8685768690001],
  [14.167704404000062, 122.86873016100003],
  [14.167879693000032, 122.86852877100011],
  [14.167966314000068, 122.86813630000006],
  [14.16814753500006, 122.868038866],
  [14.168561345000057, 122.86808242300003],
  [14.168718622000029, 122.86803853800006],
  [14.168930739000075, 122.8678424960001],
  [14.169250142000067, 122.86741510900004],
  [14.169817052000042, 122.8670906100001],
  [14.169976035000047, 122.86671381300005],
  [14.17010988100003, 122.86661286200001],
  [14.170389912000076, 122.86655557600011],
  [14.170573580000053, 122.86660589100006],
  [14.170675174000053, 122.86683064400006],
  [14.170651793000047, 122.86695775400005],
  [14.170475562000036, 122.86734054900012],
  [14.170570809000026, 122.86754741700008],
  [14.170826761000058, 122.86756124800002],
  [14.170933281000032, 122.86747767700001],
  [14.171118310000054, 122.867517098],
  [14.171154949000027, 122.86771294800008],
  [14.171360742000047, 122.86773302400002],
  [14.171500931000026, 122.86757442800001],
  [14.171696273000066, 122.86745780100011],
  [14.171846128000027, 122.86747984500005],
  [14.171920577000037, 122.86763269000005],
  [14.172047321000036, 122.86767608500008],
  [14.17233292700007, 122.86758476500006],
  [14.17255861700005, 122.8673319720001],
  [14.173022478000064, 122.86697445700008],
  [14.173197383000058, 122.86697656600006],
  [14.173637519000067, 122.86708850900004],
  [14.174532327000065, 122.86701757000003],
  [14.17593043100004, 122.86742178200006],
  [14.177895893000027, 122.86763479400008],
  [14.180187769000042, 122.86746432300004],
  [14.185944910000046, 122.86696853500007],
  [14.18902584600005, 122.86664950300008],
  [14.189725637000038, 122.86621535300003],
  [14.190165298000068, 122.86528818300008],
  [14.19045847800004, 122.86425845700012],
  [14.190501209000047, 122.86326920600004],
  [14.190318911000077, 122.86240314100007],
  [14.189973758000065, 122.86162163300003],
  [14.189544481000041, 122.86103343800005],
  [14.189133857000058, 122.8604042830001],
  [14.188900644000057, 122.85976125400009],
  [14.188717702000076, 122.85938574500005],
  [14.188383086000044, 122.859187259],
  [14.187971350000055, 122.85882986000001],
  [14.187385235000022, 122.85798981900007],
  [14.187001286000054, 122.85717889000011],
  [14.186838006000073, 122.85656529100004],
  [14.186903193000035, 122.85533059900001],
  [14.187278680000077, 122.85407394800006],
  [14.18792039400006, 122.85323484000003],
  [14.188473007000027, 122.85288316200001],
  [14.189029571000049, 122.85273641700007],
  [14.189633763000074, 122.85287558000005],
  [14.19023760600004, 122.85296559500011],
  [14.190832648000026, 122.85334005000004],
  [14.191321260000052, 122.85397383300005],
  [14.191503202000035, 122.8546169230001],
  [14.191696233000073, 122.85566611200011],
  [14.192051669000023, 122.85673715300004],
  [14.192568946000051, 122.85808980400009],
  [14.193460385000037, 122.8595431870001],
  [14.194292398000073, 122.86056145100008],
  [14.194562634000022, 122.86093826500007],
  [14.195014792000052, 122.86040840600003],
  [14.195172326000034, 122.8602905780001],
  [14.195665943000051, 122.86035929700006],
  [14.19583711100006, 122.86026827400008],
  [14.195982426000057, 122.86009298400006],
  [14.196217640000043, 122.86013837000007],
  [14.196396686000071, 122.86034718400003],
  [14.196555592000038, 122.86078723900005],
  [14.196902677000026, 122.86112651100007],
  [14.197308270000065, 122.86129701400012],
  [14.197745325000028, 122.86134243900005],
  [14.197977364000053, 122.86156960000005],
  [14.198182733000067, 122.86170426100011],
  [14.198519881000038, 122.86179327500008],
  [14.198839659000043, 122.86144630800004],
  [14.198983848000069, 122.86142990300004],
  [14.19921593500004, 122.86175522600001],
  [14.199392516000046, 122.86185376300011],
  [14.199726223000027, 122.86187515300003],
  [14.200186423000048, 122.86184295800001],
  [14.200930350000021, 122.86149736400012],
  [14.201205896000033, 122.86112120400003],
  [14.201554176000059, 122.86095986200007],
  [14.201778065000042, 122.86075011600008],
  [14.201831349000031, 122.86054315400008],
  [14.20180343100003, 122.86007017300005],
  [14.201712103000034, 122.85988396500011],
  [14.20205538600004, 122.85987203800005],
  [14.20238555900005, 122.85991283400006],
  [14.20270368100006, 122.860112275],
  [14.202961549000065, 122.86042620100011],
  [14.203069596000034, 122.86075836900011],
  [14.203091847000053, 122.86101817100007],
  [14.20300859200006, 122.86113094100006],
  [14.202811559000054, 122.86117451100006],
  [14.202451917000076, 122.86142949500004],
  [14.20233229300004, 122.86167843300007],
  [14.202378232000058, 122.86192764100008],
  [14.205262312000059, 122.86180372400008],
  [14.211034643000062, 122.86133445900009],
  [14.211142905000031, 122.8615221120001],
  [14.211154100000044, 122.86167992000003],
  [14.211057109000024, 122.861992471],
  [14.210912638000025, 122.86226526300004],
  [14.21069813500003, 122.86247233100005],
  [14.210681479000073, 122.8626448220001],
  [14.210742870000047, 122.8628632760001],
  [14.21101337600004, 122.86310778100005],
  [14.21116448400005, 122.86346639600004],
  [14.21106704500005, 122.86383471400006],
  [14.211176064000028, 122.86407966100012],
  [14.211531069000046, 122.86452352100002],
  [14.212008465000054, 122.86474921500007],
  [14.212083910000047, 122.86481258100002],
  [14.212170625000056, 122.86514104700007],
  [14.212162312000032, 122.86532259000012],
  [14.212531653000042, 122.86545072400008],
  [14.212718856000038, 122.86602650500004],
  [14.212844285000074, 122.86619298500011],
  [14.213159203000032, 122.86648476100004],
  [14.213599282000075, 122.86678344200004],
  [14.214147726000022, 122.86693862600009],
  [14.214334283000028, 122.86692710000011],
  [14.214386123000054, 122.86720055600006],
  [14.214485343000035, 122.86731645200007],
  [14.214687022000021, 122.86740974000008],
  [14.221664167000029, 122.86633306500005],
  [14.224441899000055, 122.86434295700008],
  [14.232762240000056, 122.86352062700007],
  [14.23451250100004, 122.86281425600009],
  [14.23869140000005, 122.86099050500002],
  [14.239038794000066, 122.86085889000003],
  [14.240727650000053, 122.86027003000004],
  [14.241531935000069, 122.85993350600006],
  [14.243297787000074, 122.85910801900002],
  [14.243870574000027, 122.85884980300011],
  [14.244270210000025, 122.85871653100003],
  [14.24576432200007, 122.85810820600011],
  [14.24672141800005, 122.8577277280001],
  [14.24931612000006, 122.85676369300006],
  [14.249759793000067, 122.85640411300005],
  [14.25001018000006, 122.85604797000008],
  [14.25020446600007, 122.85568639200005],
  [14.250281891000043, 122.8551287460001],
  [14.25079028600004, 122.85312951500009],
  [14.250987805000022, 122.85277577200009],
  [14.251286196000024, 122.85280390700007],
  [14.251455344000021, 122.85288519900007],
  [14.251611105000052, 122.85285922100002],
  [14.251838196000051, 122.85294864600007],
  [14.252022090000025, 122.85278977000007],
  [14.252082832000042, 122.85291375500003],
  [14.25200847700006, 122.8534126720001],
  [14.252179019000039, 122.85354034300008],
  [14.252324093000027, 122.85352323200004],
  [14.252447852000046, 122.85340979600005],
  [14.25267879200004, 122.85347511200007],
  [14.252989521000075, 122.8533475480001],
  [14.25333409600006, 122.85353488200008],
  [14.25352102000005, 122.85368812600007],
  [14.253658424000037, 122.85394064600007],
  [14.253801295000073, 122.85394074700002],
  [14.25403542500004, 122.85365107400003],
  [14.254153621000057, 122.8537702970001],
  [14.254139810000027, 122.85399471000005],
  [14.254280080000058, 122.85417938300009],
  [14.254585561000056, 122.85431021300008],
  [14.25476747600004, 122.85404014500011],
  [14.25493836000004, 122.85402313500003],
  [14.254974151000056, 122.85415964200001],
  [14.254825222000022, 122.85450652400004],
  [14.255180137000025, 122.85471634700002],
  [14.255365695000023, 122.85464095700002],
  [14.255332287000044, 122.85436519400002],
  [14.255505818000074, 122.85424321900007],
  [14.255625257000077, 122.85446560700007],
  [14.255581508000034, 122.85470476000012],
  [14.255830466000077, 122.854615953],
  [14.25595746600004, 122.8542735210001],
  [14.256165654000029, 122.85426050900003],
  [14.256424945000049, 122.8536615160001],
  [14.256571430000065, 122.85366291800005],
  [14.256760929000052, 122.85393503600005],
  [14.256922575000033, 122.85364790500012],
  [14.256981343000064, 122.85330328600003],
  [14.257148734000054, 122.85310045500012],
  [14.257159674000036, 122.85298665800008],
  [14.257720250000034, 122.85257135900008],
  [14.257999964000021, 122.85246631900009],
  [14.258466420000047, 122.85221913100008],
  [14.25858508500005, 122.8521219480001],
  [14.25868151800006, 122.85171521400002],
  [14.258800270000052, 122.85153501200011],
  [14.258971428000052, 122.85139253800003],
  [14.259229621000031, 122.85126971400007],
  [14.259455974000048, 122.8512248190001],
  [14.259543976000032, 122.85110171200006],
  [14.259905662000051, 122.85090258500009],
  [14.259955152000032, 122.85080056000004],
  [14.259951139000066, 122.85040097900003],
  [14.259679086000062, 122.85015422000004],
  [14.259757508000064, 122.84990342200001],
  [14.260112055000036, 122.84964879400002],
  [14.260248988000058, 122.84912367200002],
  [14.260514327000067, 122.84886179400007],
  [14.26092456300006, 122.8487542680001],
  [14.261319718000038, 122.84874026900002],
  [14.26147233200004, 122.8487013570001],
  [14.26222496500003, 122.84869204900008],
  [14.26228694100007, 122.84847451900009],
  [14.262489316000028, 122.84835379100002],
  [14.262907658000074, 122.84841562200006],
  [14.26311905600005, 122.84835003600006],
  [14.263663466000025, 122.84865646800006],
  [14.263829330000021, 122.84909598400009],
  [14.264074693000055, 122.8493480940001],
  [14.264248973000065, 122.84980305900001],
  [14.264418406000061, 122.85011208000003],
  [14.26459651500005, 122.85031427400008],
  [14.264836760000037, 122.85040590000006],
  [14.265063178000048, 122.85109906500008],
  [14.265017964000037, 122.85149466300004],
  [14.265034809000042, 122.85176818200011],
  [14.265198296000051, 122.85196021200011],
  [14.265745055000025, 122.85239077900007],
  [14.265842540000051, 122.85274541000001],
  [14.266074655000068, 122.85306262200004],
  [14.266371943000024, 122.8533867220001],
  [14.266770965000035, 122.85394564700005],
  [14.267226386000061, 122.85439665000001],
  [14.267656049000038, 122.85452528200005],
  [14.268009208000024, 122.8545981310001],
  [14.268346636000047, 122.85483733800004],
  [14.268860634000077, 122.85500040400007],
  [14.26892028800006, 122.85514244],
  [14.26932899600007, 122.85513016800007],
  [14.269666278000045, 122.85522434800009],
  [14.269928167000046, 122.85535128300012],
  [14.27011863000007, 122.85553553900002],
  [14.270186083000056, 122.85574026100005],
  [14.27017814100003, 122.85597364400007],
  [14.270105145000059, 122.85619746200007],
  [14.270021795000048, 122.85684437200007],
  [14.269994003000022, 122.85729065700002],
  [14.27000588900006, 122.85780655000008],
  [14.269946352000034, 122.85823646000006],
  [14.269974113000046, 122.85858529100005],
  [14.269978059000039, 122.85911756000007],
  [14.269934395000064, 122.85947786400004],
  [14.269954218000066, 122.85987092800008],
  [14.270057350000059, 122.86061292000011],
  [14.270164472000033, 122.86094047800009],
  [14.270371297000054, 122.86192676400003],
  [14.270584778000057, 122.86241787800009],
  [14.270669286000043, 122.86252803600007],
  [14.270776024000043, 122.86282178600004],
  [14.270945041000061, 122.86302374500008],
  [14.271056222000027, 122.86341388300002],
  [14.271038408000038, 122.86379024700011],
  [14.270976121000047, 122.86401514300007],
  [14.270669165000072, 122.864493003],
  [14.270242128000064, 122.86480048500005],
  [14.269712783000045, 122.86509878000004],
  [14.269570440000052, 122.8651492570001],
  [14.269050009000068, 122.86514922200001],
  [14.268801925000048, 122.86518071500006],
  [14.268677382000021, 122.86512563000008],
  [14.268463869000072, 122.86519446000011],
  [14.26813026900004, 122.86509346200012],
  [14.267827787000044, 122.86524949000011],
  [14.267652372000043, 122.86522126400007],
  [14.267465096000024, 122.86528676800003],
  [14.267287343000078, 122.86542760200007],
  [14.266455712000038, 122.86591233000001],
  [14.266232638000076, 122.86601367600008],
  [14.265858086000037, 122.86624293700004],
  [14.265347045000055, 122.86662940500003],
  [14.264668992000054, 122.86691761700001],
  [14.264568929000063, 122.86702715400008],
  [14.264238815000056, 122.86719418500002],
  [14.26388965600006, 122.86731208800006],
  [14.263495395000064, 122.86759908200008],
  [14.263289073000067, 122.86781196400011],
  [14.26290817100005, 122.86799864200009],
  [14.262721579000072, 122.86826995400008],
  [14.262423560000059, 122.8685675260001],
  [14.26226839800006, 122.86861746800002],
  [14.261650086000031, 122.86893409100003],
  [14.261405425000078, 122.86912224200012],
  [14.261271967000027, 122.86930122100011],
  [14.261036200000035, 122.8694985510001],
  [14.260902739000073, 122.86968212300008],
  [14.260524625000073, 122.86992074700004],
  [14.260290686000076, 122.86999855200008],
  [14.259946678000063, 122.87025575200005],
  [14.259368378000033, 122.8707605400001],
  [14.258563215000038, 122.87138922100007],
  [14.258185108000077, 122.87157276300002],
  [14.258047205000025, 122.87168748800002],
  [14.257809370000075, 122.87176787100009],
  [14.257551340000077, 122.872176308],
  [14.257377863000045, 122.8721900590001],
  [14.257191024000065, 122.8723782080001],
  [14.256941913000048, 122.87253881600009],
  [14.256675013000063, 122.87265811600003],
  [14.25598253100003, 122.87309430700009],
  [14.255563180000024, 122.87319512500005],
  [14.254598541000064, 122.8737178660001],
  [14.254491337000047, 122.87396312300007],
  [14.254202356000064, 122.8742853230001],
  [14.25395532400006, 122.87450172500007],
  [14.253437954000049, 122.87478064100003],
  [14.253115101000049, 122.87486571300008],
  [14.252872731000025, 122.87500517],
  [14.252579087000072, 122.87527446900003],
  [14.251907239000047, 122.87578490200008],
  [14.251375886000062, 122.87608785600003],
  [14.251058941000053, 122.87615036700004],
  [14.250816568000062, 122.87629944000003],
  [14.250173337000035, 122.8768380360001],
  [14.249905986000044, 122.87694183400004],
  [14.249383953000063, 122.87720150400003],
  [14.249015729000064, 122.87744675200008],
  [14.248829284000067, 122.87761025400005],
  [14.248674220000055, 122.877668319],
  [14.24799370900007, 122.87819246700008],
  [14.247602184000073, 122.87840404600001],
  [14.24667772500004, 122.87908746000005],
  [14.246527248000064, 122.87927634900007],
  [14.246100545000047, 122.87955771800011],
  [14.24575578100007, 122.87995844900001],
  [14.245466183000076, 122.88017185100011],
  [14.245266224000034, 122.88027617900002],
  [14.244313976000058, 122.8809041720001],
  [14.243886275000023, 122.88130183900012],
  [14.243484054000078, 122.88159822500006],
  [14.243288693000068, 122.88166935400011],
  [14.242920948000062, 122.8819017190001],
  [14.242783041000052, 122.88202976000002],
  [14.242484354000055, 122.8822028300001],
  [14.242155680000053, 122.882487362],
  [14.241913367000052, 122.88264838700002],
  [14.241859231000035, 122.88275247700005],
  [14.24154429600003, 122.88301611400004],
  [14.241332930000056, 122.8831515810001],
  [14.240988164000044, 122.88346456200009],
  [14.240818078000075, 122.88365899200005],
  [14.240259559000037, 122.88407392500005],
  [14.240078390000065, 122.88417365100008],
  [14.239862336000044, 122.88437756200005],
  [14.239381963000028, 122.88473321600009],
  [14.23900995300005, 122.88497832300004],
  [14.238846757000033, 122.88521306000007],
  [14.238499188000048, 122.88548594500003],
  [14.23825325200005, 122.8857254190001],
  [14.237834945000031, 122.88588663300004],
  [14.237724616000037, 122.88602178300005],
  [14.237667307000038, 122.88623590300006],
  [14.236992499000053, 122.88685701400004],
  [14.236454697000056, 122.88722470500011],
  [14.23622956500003, 122.8874601650001],
  [14.236041958000044, 122.88758918100007],
  [14.235785601000032, 122.88791395900012],
  [14.235280342000067, 122.88833536600009],
  [14.235111689000064, 122.88863670500007],
  [14.234370629000068, 122.88933016900012],
  [14.234123603000057, 122.88964949000001],
  [14.233501355000044, 122.89028167100003],
  [14.233216809000055, 122.89052357700007],
  [14.233049321000067, 122.89076680000005],
  [14.232744364000041, 122.89109461200007],
  [14.232373493000068, 122.89144336400011],
  [14.231900875000065, 122.8918496770001],
  [14.231554949000042, 122.89228711200008],
  [14.231443357000046, 122.89258168800006],
  [14.231302228000061, 122.892723896],
  [14.230872274000035, 122.89326564200007],
  [14.23059986000004, 122.89346541100008],
  [14.230517805000034, 122.89361100800011],
  [14.229978582000058, 122.89424503100008],
  [14.229492834000041, 122.8946614890001],
  [14.229404215000045, 122.89488157200003],
  [14.229184314000065, 122.89505086400004],
  [14.22899723100005, 122.89529464600002],
  [14.228895015000035, 122.89558403300009],
  [14.228553676000047, 122.89595308700007],
  [14.228232023000032, 122.89644741900008],
  [14.228074482000068, 122.89657607900006],
  [14.227910373000043, 122.89685033],
  [14.227765960000056, 122.89698576100011],
  [14.227480412000034, 122.89734465700008],
  [14.227303173000053, 122.897622296],
  [14.227073420000067, 122.89788639100004],
  [14.227119363000043, 122.89802183100005],
  [14.227002413000037, 122.8982675420001],
  [14.226733276000061, 122.89853163100008],
  [14.226089965000028, 122.89942886000006],
  [14.226021034000041, 122.8996184670001],
  [14.225785052000049, 122.89999881100005],
  [14.225443701000074, 122.90046265800004],
  [14.225479796000059, 122.90063195400012],
  [14.225066231000028, 122.90124139200009],
  [14.225055178000048, 122.90159729800007],
  [14.224766339000041, 122.90197988200009],
  [14.224664586000074, 122.90220334300011],
  [14.224461087000066, 122.90246404000004],
  [14.224149262000026, 122.90303961600011],
  [14.224101709000024, 122.9035344880001],
  [14.223763632000043, 122.90401864000012],
  [14.223665160000053, 122.90420824000012],
  [14.223579966000045, 122.90458024600002],
  [14.223589799000024, 122.90477324000005],
  [14.223343628000066, 122.90505424800006],
  [14.22320903800005, 122.90548423900009],
  [14.223238041000059, 122.9060204750001],
  [14.22320493500007, 122.90627174600002],
  [14.223060686000053, 122.90662303800002],
  [14.222836660000041, 122.90736538800002],
  [14.222687680000035, 122.90769228500005],
  [14.222549196000045, 122.90832023500002],
  [14.222527910000053, 122.90873983200004],
  [14.222564744000067, 122.90914168600011],
  [14.222538481000072, 122.90934813700005],
  [14.22234164200006, 122.9093041220001],
  [14.222303261000036, 122.90946688300005],
  [14.222122785000067, 122.90990684600001],
  [14.221994777000077, 122.91062095000007],
  [14.221733259000075, 122.91180752700006],
  [14.22172011400005, 122.91204782000011],
  [14.22178569600004, 122.91240319200006],
  [14.221775786000023, 122.91314438900008],
  [14.221738494000022, 122.91352148900012],
  [14.221646588000056, 122.91399868400003],
  [14.221620310000048, 122.9143235890001],
  [14.22150729200007, 122.91496378800002],
  [14.221517100000028, 122.91529884900001],
  [14.221458030000065, 122.91546806400004],
  [14.221198827000023, 122.91571509800008],
  [14.220637797000052, 122.9159756360001],
  [14.220273615000053, 122.91616850900004],
  [14.219718433000025, 122.91642628700004],
  [14.219468379000034, 122.916578909],
  [14.219379321000076, 122.91655955600004],
  [14.219266851000043, 122.91630809600008],
  [14.21927155800006, 122.91613401600011],
  [14.219374706000053, 122.91589708700008],
  [14.219370046000051, 122.91564080400008],
  [14.21951069000005, 122.91543289200001],
  [14.219632568000065, 122.91537004400004],
  [14.219665399000064, 122.9151862970001],
  [14.219543551000072, 122.91495901300004],
  [14.219482647000063, 122.91464953000002],
  [14.219111876000056, 122.91435308100006],
  [14.218761198000038, 122.91421619300002],
  [14.218685376000053, 122.91413797300004],
  [14.218239922000066, 122.91387398100005],
  [14.21797341000007, 122.91367861700007],
  [14.217675390000068, 122.91371704700009],
  [14.217597499000021, 122.91352664600004],
  [14.217557528000043, 122.91306246300007],
  [14.217623874000026, 122.91274959200007],
  [14.21792716300007, 122.91229983900007],
  [14.218154630000072, 122.91203585400001],
  [14.218173585000045, 122.91179142100009],
  [14.21809776300006, 122.9114589940001],
  [14.218183062000037, 122.91127322500006],
  [14.218459147000033, 122.91101056700006],
  [14.218740238000066, 122.91082514100003],
  [14.21882819800004, 122.91084881300003],
  [14.218984998000053, 122.91065549600012],
  [14.219389274000036, 122.91038776900007],
  [14.219661874000053, 122.91015612800004],
  [14.21987791500004, 122.90990681300002],
  [14.220218450000061, 122.90935529900003],
  [14.220416175000025, 122.9087584560001],
  [14.220496730000036, 122.90845247800007],
  [14.220493050000073, 122.90738865500009],
  [14.220386474000065, 122.9069668520001],
  [14.220368866000058, 122.90660978900007],
  [14.220233927000038, 122.90614984800004],
  [14.220304314000032, 122.90582909900002],
  [14.220122453000045, 122.90563544500003],
  [14.220016851000025, 122.90537522000011],
  [14.219834989000049, 122.90519972400011],
  [14.219852584000023, 122.9050544800001],
  [14.21970592200006, 122.90493345000004],
  [14.219688318000067, 122.90478820700002],
  [14.219518185000027, 122.90450983100004],
  [14.219494716000042, 122.90437064100001],
  [14.219612032000043, 122.90404989400008],
  [14.219958144000032, 122.90392884500011],
  [14.220251460000043, 122.90388042000006],
  [14.220456784000078, 122.90391672300007],
  [14.220474376000027, 122.9037109620001],
  [14.220873287000074, 122.90360806400008],
  [14.221125538000024, 122.90346886100008],
  [14.221329665000042, 122.90318631600007],
  [14.221570180000072, 122.90302895900004],
  [14.221757896000042, 122.90282319000005],
  [14.221928009000067, 122.90253874700011],
  [14.222180243000025, 122.90203643200005],
  [14.222227167000028, 122.90188513300006],
  [14.222385551000059, 122.90166725900008],
  [14.222520577000068, 122.90088031100004],
  [14.222584979000032, 122.90061450400003],
  [14.222459728000047, 122.90059605200008],
  [14.222377402000063, 122.90019365900002],
  [14.222380960000066, 122.89977649700006],
  [14.22262069900006, 122.899315021],
  [14.222753099000045, 122.89918580200003],
  [14.22272446200003, 122.89903444300012],
  [14.222607976000063, 122.89894586700007],
  [14.222672741000054, 122.89875374700011],
  [14.222607956000047, 122.89857416400002],
  [14.222247637000066, 122.89842383400003],
  [14.22205331400005, 122.89845308200006],
  [14.221945861000052, 122.89875789400003],
  [14.22191348900003, 122.89904607000005],
  [14.221694895000041, 122.89945537200003],
  [14.221415577000073, 122.89991479500009],
  [14.221504657000025, 122.90019878700002],
  [14.221472305000077, 122.90091714300002],
  [14.221500646000038, 122.90101370400009],
  [14.221371137000062, 122.90193670300005],
  [14.221172781000064, 122.90235853400009],
  [14.221039189000066, 122.90253395200011],
  [14.220745475000058, 122.90278331500008],
  [14.220660456000076, 122.9027624360001],
  [14.220411975000047, 122.90288613100006],
  [14.220148825000024, 122.902873612],
  [14.220148818000041, 122.90268567400005],
  [14.219857337000064, 122.90284856500011],
  [14.219501079000054, 122.90296551800009],
  [14.219181068000069, 122.90302282000005],
  [14.218975604000036, 122.9026620730001],
  [14.218818234000025, 122.90256287200009],
  [14.218678363000038, 122.90279736800005],
  [14.218464166000047, 122.90270718800002],
  [14.218337403000078, 122.90281541900004],
  [14.218647773000043, 122.90304989700007],
  [14.218764135000072, 122.9033485120001],
  [14.218723064000073, 122.90353493500004],
  [14.218573155000058, 122.90362814700006],
  [14.217994737000026, 122.90338234400008],
  [14.217758702000026, 122.903415257],
  [14.217656629000032, 122.90334945200004],
  [14.217465249000043, 122.90336262100004],
  [14.21723559000003, 122.90329024000005],
  [14.216993177000063, 122.90332973500006],
  [14.216489201000059, 122.9031323270001],
  [14.216182996000043, 122.90322447200003],
  [14.216004378000036, 122.903362678],
  [14.215506789000074, 122.90344824700003],
  [14.21534534400007, 122.9033501780001],
  [14.21491792200004, 122.90317909400005],
  [14.214809468000055, 122.90306064200001],
  [14.21475687900005, 122.9028018890001],
  [14.214756864000037, 122.9023675520001],
  [14.214846162000072, 122.90201876200001],
  [14.21535648300005, 122.90134749100002],
  [14.215611653000053, 122.90125534800006],
  [14.215873205000037, 122.90122901300003],
  [14.216185793000022, 122.90124874000003],
  [14.216453737000052, 122.90149222000002],
  [14.216479246000063, 122.90130137300002],
  [14.216740793000042, 122.9011631620001],
  [14.216970450000076, 122.90115657000001],
  [14.21739787100006, 122.90122235800004],
  [14.217633902000046, 122.90104466100001],
  [14.217742351000027, 122.90103149200002],
  [14.21829230700007, 122.90115650700011],
  [14.218860067000037, 122.90112357400005],
  [14.219532840000056, 122.90084955800012],
  [14.219978175000051, 122.90041493800004],
  [14.220327220000058, 122.90000515400004],
  [14.220483682000065, 122.89969471600011],
  [14.220796602000064, 122.89886273900004],
  [14.220868806000055, 122.89846537800008],
  [14.220856755000057, 122.898154943],
  [14.220916929000055, 122.89793142300005],
  [14.220909536000022, 122.89736403500001],
  [14.220777093000038, 122.89673077300006],
  [14.220668751000062, 122.8965196910001],
  [14.220572437000044, 122.8961968540001],
  [14.220295573000044, 122.89583678000008],
  [14.220006666000074, 122.8954022040001],
  [14.219814056000075, 122.89504212500003],
  [14.219464960000039, 122.89453305100005],
  [14.219163993000052, 122.893837716],
  [14.219055653000055, 122.893713552],
  [14.218899149000038, 122.893353466],
  [14.218802851000078, 122.89329138800008],
  [14.218526005000058, 122.89326657400011],
  [14.218405648000044, 122.89337833900004],
  [14.218658461000075, 122.89391225800011],
  [14.218670512000074, 122.894098514],
  [14.21876682800007, 122.89440893500011],
  [14.21885112900003, 122.8950049450001],
  [14.219128014000034, 122.89563819200009],
  [14.219224326000074, 122.89592377500003],
  [14.219525289000046, 122.8966811900001],
  [14.219645686000035, 122.89719027800004],
  [14.21969385500006, 122.8975752020001],
  [14.219705946000033, 122.89849406300004],
  [14.219657824000024, 122.89894108200008],
  [14.219344914000033, 122.89973580000003],
  [14.219068088000029, 122.90009591700004],
  [14.218887547000065, 122.90028218700002],
  [14.218502381000064, 122.90048088600008],
  [14.21830979400005, 122.90050573200006],
  [14.217936652000049, 122.90041883300012],
  [14.217671839000047, 122.90031951100002],
  [14.217394991000049, 122.9002698600001],
  [14.216997769000045, 122.90007120900009],
  [14.216880839000055, 122.90013328500004],
  [14.216628067000045, 122.90009604600004],
  [14.216543806000061, 122.90002154700005],
  [14.216291033000061, 122.89995947400007],
  [14.216242881000028, 122.89984772200012],
  [14.21643546100006, 122.89972354100007],
  [14.216531745000054, 122.8995621140001],
  [14.216483590000053, 122.89941311200005],
  [14.216339149000078, 122.8993758680001],
  [14.21542439500007, 122.89997193800002],
  [14.215207737000071, 122.90000920000011],
  [14.214954972000044, 122.9001458030001],
  [14.214617961000044, 122.9005307540001],
  [14.214482029000067, 122.90080656100008],
  [14.214289456000074, 122.90106732100003],
  [14.214012639000032, 122.90160125200009],
  [14.213964501000078, 122.90178750500002],
  [14.213928408000072, 122.9021972600001],
  [14.21397657700004, 122.90275601200005],
  [14.214193255000055, 122.90322784400007],
  [14.21427753000006, 122.90371210100011],
  [14.214409941000042, 122.90393560300004],
  [14.21460253600003, 122.9041342700001],
  [14.21479512600007, 122.90422118400011],
  [14.215324747000068, 122.90433292600005],
  [14.215637708000031, 122.90443225700005],
  [14.216083069000035, 122.90440741300006],
  [14.216383993000022, 122.90443224300009],
  [14.216636767000068, 122.90439498700005],
  [14.217126035000035, 122.90443409400007],
  [14.217355157000043, 122.90447841200012],
  [14.21783700900005, 122.90478019400007],
  [14.218123411000022, 122.90501444600011],
  [14.21846914200006, 122.90538798700004],
  [14.21866258700004, 122.905791442],
  [14.218742428000041, 122.9063960310001],
  [14.218754476000072, 122.90681821500004],
  [14.218706337000071, 122.90710381100007],
  [14.218525800000066, 122.90768742600005],
  [14.218365577000043, 122.90799875500011],
  [14.218112075000022, 122.90827321400002],
  [14.217866101000027, 122.9084881230001],
  [14.217652757000053, 122.90860981700007],
  [14.217160517000025, 122.90872374300011],
  [14.21692960300004, 122.90860981600008],
  [14.217050080000035, 122.90815411000005],
  [14.217160517000025, 122.90809714700003],
  [14.217153622000069, 122.90787370900011],
  [14.21763508600003, 122.90769985800011],
  [14.21734620500007, 122.9076377780001],
  [14.217286019000028, 122.90745152000011],
  [14.217322124000077, 122.90717834100008],
  [14.21714157100007, 122.90701692200003],
  [14.217045275000032, 122.90683066800011],
  [14.216936942000075, 122.90675616900012],
  [14.216696210000066, 122.90673134000008],
  [14.216480284000056, 122.90736657500008],
  [14.216450166000072, 122.90761255200005],
  [14.216339730000072, 122.9079957570001],
  [14.216106306000029, 122.90817182600006],
  [14.216000889000043, 122.90842816000008],
  [14.21626443200006, 122.90863271100011],
  [14.21628200200007, 122.90879065600006],
  [14.216181603000052, 122.90914647700004],
  [14.216111325000043, 122.90921897500004],
  [14.215953199000069, 122.90913870800011],
  [14.21592307800006, 122.90947013000005],
  [14.215855565000027, 122.9097227420001],
  [14.215730066000049, 122.9100075550001],
  [14.215359854000042, 122.91037113700008],
  [14.215211769000064, 122.91046693600003],
  [14.214892244000055, 122.91082563900011],
  [14.214492001000053, 122.91119341800004],
  [14.214056347000053, 122.91154860100005],
  [14.213665194000043, 122.9115848450001],
  [14.213683932000038, 122.91180471900009],
  [14.214083445000028, 122.91207243600002],
  [14.21413263200003, 122.91217875000007],
  [14.21404596900004, 122.9123937920001],
  [14.213592279000068, 122.91243983900006],
  [14.21341172800004, 122.91241500700005],
  [14.213038587000028, 122.91243984500011],
  [14.212737666000066, 122.91235293000011],
  [14.211606021000023, 122.91184539800008],
  [14.211425441000074, 122.91179302100011],
  [14.211109186000044, 122.91178084800003],
  [14.211010061000025, 122.91182223800001],
  [14.210661964000053, 122.91181006400006],
  [14.209882736000054, 122.91192461000003],
  [14.209517292000044, 122.91183558800003],
  [14.208953895000036, 122.91180415300005],
  [14.208537688000035, 122.91187744000001],
  [14.208329587000037, 122.91180936700005],
  [14.207959060000064, 122.91186171200002],
  [14.20765829100003, 122.91181981500006],
  [14.207292848000066, 122.91166272900011],
  [14.206978158000027, 122.91168889700009],
  [14.206729942000038, 122.91150071100003],
  [14.206335392000028, 122.91162424100003],
  [14.20634853100006, 122.9119506190001],
  [14.206282779000048, 122.9120710850001],
  [14.206094979000056, 122.9120344270001],
  [14.205678037000041, 122.91205012000012],
  [14.205190769000069, 122.91215481900008],
  [14.204919311000026, 122.91241781400004],
  [14.20469627400007, 122.91254785300009],
  [14.204489399000067, 122.91275458500002],
  [14.204146761000061, 122.91302466900004],
  [14.203910793000034, 122.91314137200004],
  [14.203280834000054, 122.91318471700004],
  [14.203028705000065, 122.91324473500003],
  [14.202792737000038, 122.91339478100008],
  [14.20261368100006, 122.91331295900011],
  [14.202239529000053, 122.91346946600004],
  [14.202100266000059, 122.91340727900001],
  [14.201906955000027, 122.9134737390001],
  [14.20169923800006, 122.91347587400003],
  [14.200982348000025, 122.91361849100008],
  [14.200737720000063, 122.91358636500001],
  [14.200266250000027, 122.91366434100007],
  [14.199914875000047, 122.91365056100005],
  [14.199701383000047, 122.91360008400011],
  [14.19945675300005, 122.91362760200002],
  [14.19910141300005, 122.91373875900001],
  [14.198701108000023, 122.9138029720001],
  [14.198238537000066, 122.91377083600003],
  [14.19814513700004, 122.91367907200004],
  [14.197873824000055, 122.91359189000002],
  [14.19766478300005, 122.91344506700011],
  [14.197433508000074, 122.91316060500003],
  [14.197135622000076, 122.9129324270001],
  [14.197077807000028, 122.91274890700004],
  [14.196806502000072, 122.91242774000011],
  [14.19646847200005, 122.91236808600001],
  [14.196464013000025, 122.91265712400002],
  [14.196281655000064, 122.91261123800007],
  [14.196014801000047, 122.91223960800005],
  [14.195792427000072, 122.91175328200006],
  [14.195716825000034, 122.91137248300004],
  [14.195832481000025, 122.91093204800006],
  [14.196108252000045, 122.91069348500002],
  [14.196708716000046, 122.91030352900009],
  [14.196844378000037, 122.9101526390001],
  [14.197387018000029, 122.90979020800012],
  [14.197782878000055, 122.90949658900001],
  [14.19794300500007, 122.9093084860001],
  [14.198267701000077, 122.909092858],
  [14.19867389500007, 122.90867848100004],
  [14.19883402000005, 122.90839861900008],
  [14.19915872200005, 122.90772419400002],
  [14.199416835000022, 122.906964635],
  [14.199585856000056, 122.90654712800006],
  [14.199701502000039, 122.90613879800003],
  [14.199861630000044, 122.90514779600005],
  [14.199910560000035, 122.90448712500006],
  [14.19991501000004, 122.90400997000006],
  [14.199843842000064, 122.90333959500003],
  [14.199745987000028, 122.902844092],
  [14.199648134000029, 122.90253669600008],
  [14.199581412000043, 122.90189896900006],
  [14.199519141000053, 122.90170168600002],
  [14.199442523000073, 122.90090265000003],
  [14.199335414000075, 122.90041827900006],
  [14.199063540000054, 122.89991691600005],
  [14.19901823500004, 122.90010811900004],
  [14.198894661000054, 122.90004439000006],
  [14.198399657000039, 122.89967103000004],
  [14.19832550700005, 122.89948832900006],
  [14.198082468000052, 122.89911018600003],
  [14.198020667000037, 122.8986895480001],
  [14.197942399000056, 122.89854509000008],
  [14.197934141000076, 122.89802248],
  [14.197897066000053, 122.8979332560001],
  [14.197864091000042, 122.89738940200004],
  [14.198016480000035, 122.89698150200002],
  [14.198053540000046, 122.89672181200001],
  [14.198152383000036, 122.89638189800007],
  [14.198251234000054, 122.89623318300005],
  [14.198350078000033, 122.89593575700007],
  [14.198564260000069, 122.8957360490001],
  [14.198737258000051, 122.89565531100004],
  [14.198782558000062, 122.89544286500006],
  [14.198914355000056, 122.89514118500006],
  [14.199146092000035, 122.89475776800009],
  [14.199545625000042, 122.89441783500001],
  [14.200025410000023, 122.8941373030001],
  [14.200293136000028, 122.89387385500004],
  [14.200478489000034, 122.893759122],
  [14.20088196200004, 122.89335398300011],
  [14.20112086100005, 122.89321800200003],
  [14.20154922200004, 122.89283132100002],
  [14.201969340000062, 122.89235966100011],
  [14.202286472000026, 122.89173504400003],
  [14.202344130000029, 122.89158207700007],
  [14.202420365000023, 122.89112352500001],
  [14.202552143000048, 122.89065187900007],
  [14.202568593000024, 122.89028646800011],
  [14.20253972200004, 122.88977234800006],
  [14.202403763000063, 122.88936021000006],
  [14.202045362000035, 122.88882487],
  [14.201847634000046, 122.88867617200003],
  [14.201217444000065, 122.88837255500005],
  [14.201097981000032, 122.88825784300002],
  [14.200771183000029, 122.888166554],
  [14.200585062000073, 122.88871168900005],
  [14.200603100000023, 122.88900902600005],
  [14.200861329000077, 122.88905236700009],
  [14.201287722000075, 122.88931869800001],
  [14.201612036000029, 122.88973990500006],
  [14.201702134000072, 122.89000007300001],
  [14.201756212000078, 122.890415114],
  [14.20171033400004, 122.89070003000006],
  [14.201560220000033, 122.89096021400007],
  [14.201476167000067, 122.891257558],
  [14.201151899000024, 122.89148677900005],
  [14.20101379600004, 122.89174076800009],
  [14.200767596000048, 122.89198237300002],
  [14.200605472000063, 122.89226114200005],
  [14.200335257000063, 122.89263283900004],
  [14.199944928000036, 122.89289304300007],
  [14.19948252100005, 122.89295502300001],
  [14.19923631000006, 122.89312229900008],
  [14.19896006700003, 122.89322763100006],
  [14.198522529000059, 122.89359755700002],
  [14.198480480000057, 122.89339933400004],
  [14.198648608000042, 122.89308959900006],
  [14.198630582000021, 122.89291615200011],
  [14.198522481000055, 122.89281704700011],
  [14.19884674900004, 122.89256305200001],
  [14.19887075500003, 122.89233385400007],
  [14.198618536000026, 122.89236484300011],
  [14.198528468000063, 122.89253210000004],
  [14.198324325000044, 122.89309581200007],
  [14.198198228000024, 122.89331882200008],
  [14.197825950000038, 122.89416130500001],
  [14.19775389800003, 122.89437812100005],
  [14.197597380000047, 122.89512778900007],
  [14.197501303000024, 122.89527646200008],
  [14.19733917900004, 122.8956667220001],
  [14.197141041000066, 122.8964286470001],
  [14.197026954000023, 122.89670740200006],
  [14.196906887000068, 122.89756224000007],
  [14.19699098500007, 122.89813832400012],
  [14.197021026000073, 122.89850380100006],
  [14.197003023000036, 122.89884450300008],
  [14.197216552000043, 122.89955662300008],
  [14.197366707000072, 122.9003247280001],
  [14.197360705000051, 122.90042383900004],
  [14.197516852000035, 122.90073355800007],
  [14.19761294600005, 122.90101850200006],
  [14.197811135000052, 122.90143353100007],
  [14.197841173000029, 122.90178662100004],
  [14.197949275000042, 122.90196626300008],
  [14.197931263000044, 122.90209015500011],
  [14.198027354000033, 122.90223882500004],
  [14.198021355000037, 122.90242466500001],
  [14.198120393000067, 122.90309446000003],
  [14.198264529000028, 122.90368293200004],
  [14.198264539000036, 122.9043333510001],
  [14.19818648200004, 122.90509527600011],
  [14.198066382000036, 122.90557225700002],
  [14.198060379000026, 122.90574570600006],
  [14.197880225000063, 122.90624127500007],
  [14.197808163000047, 122.90634039000008],
  [14.197762271000045, 122.90658225100003],
  [14.19778020900003, 122.90681662500003],
  [14.19761279000005, 122.90756291600007],
  [14.197547017000034, 122.90801316000011],
  [14.197547017000034, 122.908296876],
  [14.197487224000042, 122.90838939200012],
  [14.197080629000027, 122.90869160900002],
  [14.196901248000074, 122.90877178800008],
  [14.196518569000034, 122.90902466500006],
  [14.19608207400006, 122.90925287100004],
  [14.195860836000065, 122.90932688500004],
  [14.195328665000034, 122.90961060500001],
  [14.195461821000038, 122.90930618400012],
  [14.19533625500003, 122.90928151000003],
  [14.195138935000045, 122.90946653900005],
  [14.19467254500006, 122.90965773300002],
  [14.194164302000047, 122.90957754700003],
  [14.193793585000037, 122.90942335200009],
  [14.193141840000067, 122.90905329200007],
  [14.192956482000056, 122.90891760200009],
  [14.193046174000074, 122.90873874100009],
  [14.193219577000036, 122.9085845510001],
  [14.193458750000048, 122.90856604900011],
  [14.193315248000033, 122.90840569200009],
  [14.193345145000023, 122.90825150100011],
  [14.193488650000063, 122.90813431800007],
  [14.193763699000044, 122.90800479800009],
  [14.194128436000028, 122.90797396100004],
  [14.194439360000047, 122.90802947000009],
  [14.194511111000054, 122.90817132600012],
  [14.194696470000054, 122.90820216400004],
  [14.194373588000076, 122.90781360400001],
  [14.194373588000076, 122.90762240800007],
  [14.19421214700003, 122.90754839800002],
  [14.194014830000071, 122.90761624100003],
  [14.193823493000025, 122.90759157000002],
  [14.193793596000035, 122.9074743860001],
  [14.194056685000078, 122.90730786100005],
  [14.194283899000027, 122.90711049700008],
  [14.194236064000052, 122.90687612800002],
  [14.194259980000027, 122.90665409500002],
  [14.194182248000061, 122.90637038300008],
  [14.194176267000046, 122.9059879880001],
  [14.194207304000031, 122.90576543400005],
  [14.194603259000075, 122.90511840500005],
  [14.194846319000021, 122.90483128800008],
  [14.19482279500005, 122.90469784000004],
  [14.194944325000051, 122.904491602],
  [14.194928641000047, 122.90432580400011],
  [14.194771819000039, 122.90386885100008],
  [14.19483062300003, 122.9037717980001],
  [14.194630682000025, 122.90363835500011],
  [14.194466023000075, 122.903460429],
  [14.194340570000065, 122.90341594900008],
  [14.194250404000059, 122.90352109000003],
  [14.194164164000028, 122.90407509600004],
  [14.194081839000035, 122.90430964000006],
  [14.194038720000037, 122.90484342800005],
  [14.194172016000039, 122.9050739270001],
  [14.194160255000043, 122.90532060400005],
  [14.194077930000049, 122.9055672830001],
  [14.193819187000031, 122.90586478700004],
  [14.193779982000024, 122.90550488400004],
  [14.193799581000064, 122.9052501220001],
  [14.193650608000041, 122.90529460700009],
  [14.193603566000036, 122.90580817400007],
  [14.193393994000076, 122.90639005700007],
  [14.193229340000073, 122.90664077400004],
  [14.192982357000062, 122.90685509800005],
  [14.192994118000058, 122.90723117300001],
  [14.192753691000064, 122.90731386200002],
  [14.192655160000072, 122.90744321300008],
  [14.192225207000035, 122.90785436300007],
  [14.191883921000056, 122.90795979500001],
  [14.191839134000077, 122.908061427],
  [14.191525625000054, 122.90838018300008],
  [14.191427093000073, 122.90842638000004],
  [14.191279296000062, 122.90863426300007],
  [14.191091192000044, 122.90873127400005],
  [14.190925480000033, 122.9089068190001],
  [14.190365643000064, 122.9093456820001],
  [14.189928162000058, 122.90960637],
  [14.18971455600007, 122.90980076300002],
  [14.18940043300006, 122.91033301900006],
  [14.189092565000067, 122.91113653200011],
  [14.188910344000021, 122.91192061000004],
  [14.188828663000038, 122.91215389000001],
  [14.18880979200003, 122.91269822000004],
  [14.18890809900006, 122.91312770400009],
  [14.189102827000056, 122.91359427700002],
  [14.18939806800006, 122.91414510000004],
  [14.189655625000057, 122.91450800300004],
  [14.189932032000058, 122.91482554700008],
  [14.190403195000044, 122.91514958900007],
  [14.190736155000025, 122.91533754200009],
  [14.190956037000035, 122.91541532200006],
  [14.191584999000042, 122.9157647180001],
  [14.19267183200003, 122.91617305200009],
  [14.193112119000034, 122.91627862400003],
  [14.19359475400006, 122.91646703100002],
  [14.193820857000048, 122.91650292700001],
  [14.194073795000065, 122.91683916900001],
  [14.194205511000064, 122.91710785500004],
  [14.194232434000071, 122.91743829800009],
  [14.194106642000065, 122.91809299500005],
  [14.19362456400006, 122.91880325600005],
  [14.193394026000021, 122.91883412100003],
  [14.193432965000056, 122.91860559400004],
  [14.19351381100006, 122.91848515700008],
  [14.193412032000026, 122.91826588300012],
  [14.19343600700006, 122.91794779700001],
  [14.193603687000063, 122.917676045],
  [14.193732448000048, 122.91737649700008],
  [14.193361192000054, 122.91746602800004],
  [14.193199521000054, 122.91742278200002],
  [14.193001930000037, 122.91725600300003],
  [14.19289408000003, 122.91746290600008],
  [14.193031785000073, 122.9177099740001],
  [14.192983859000037, 122.91804041000012],
  [14.192834130000051, 122.91846657500002],
  [14.192732332000048, 122.91852215500012],
  [14.192723261000026, 122.91873013800011],
  [14.192618454000069, 122.91897409900002],
  [14.192378917000042, 122.91925510800002],
  [14.192345973000045, 122.91939098900002],
  [14.192071757000065, 122.91989823600011],
  [14.191979252000067, 122.92021738000005],
  [14.191985627000065, 122.92067800300003],
  [14.192062178000072, 122.92087541300009],
  [14.192237611000053, 122.9211156],
  [14.19279128900007, 122.9215045200001],
  [14.193415578000042, 122.92181203500002],
  [14.19365056600003, 122.92183012600003],
  [14.193818914000076, 122.92194227900006],
  [14.194098392000058, 122.92224948300009],
  [14.194182555000054, 122.92285364300005],
  [14.194105386000047, 122.92329138500008],
  [14.193976899000063, 122.92365538600006],
  [14.19367876800004, 122.92417994700008],
  [14.193608616000063, 122.92443318500011],
  [14.193422724000072, 122.92468280300011],
  [14.193321019000052, 122.92448744300009],
  [14.193229829000074, 122.92451638200009],
  [14.193233358000043, 122.92476916300006],
  [14.193135146000031, 122.92504772300003],
  [14.193100066000056, 122.92528287200003],
  [14.193008871000075, 122.9255071660001],
  [14.192998342000067, 122.92571699300004],
  [14.192898609000054, 122.92606744800003],
  [14.192670818000067, 122.92673256800003],
  [14.192527134000045, 122.92707235600005],
  [14.192653292000045, 122.92740130300001],
  [14.192558667000071, 122.92792545000009],
  [14.192579691000049, 122.92812426600005],
  [14.192509599000061, 122.92857000900005],
  [14.19225410200005, 122.92978362300005],
  [14.192266437000058, 122.92997029000003],
  [14.192130692000035, 122.93091635100006],
  [14.191842784000073, 122.93106058700005],
  [14.191764634000037, 122.93121331500004],
  [14.191789291000077, 122.93208483],
  [14.191678233000061, 122.932398766],
  [14.191575396000076, 122.9328527020001],
  [14.191530133000072, 122.93349755500003],
  [14.191464635000045, 122.93382956700009],
  [14.191168472000072, 122.93471621800006],
  [14.191008057000033, 122.93500469600008],
  [14.191002295000033, 122.93549460300005],
  [14.190936977000035, 122.93602100100009],
  [14.190769591000048, 122.93629472900011],
  [14.190773677000038, 122.93667794300006],
  [14.190606294000077, 122.93744859000003],
  [14.190430740000068, 122.9376001930001],
  [14.189757099000076, 122.93800026200006],
  [14.189441395000074, 122.9382821700001],
  [14.189086204000034, 122.93882961700001],
  [14.188898401000074, 122.9391833520001],
  [14.188628945000062, 122.93942338600004],
  [14.188253337000049, 122.93962552100004],
  [14.188105380000025, 122.93998551900006],
  [14.188015561000043, 122.9400697420001],
  [14.187766519000036, 122.94002762800005],
  [14.187537890000044, 122.94010342700005],
  [14.187207194000052, 122.94028450200005],
  [14.186747248000074, 122.94041940700004],
  [14.186277892000021, 122.94047830000011],
  [14.185885971000062, 122.94064382300007],
  [14.185482802000024, 122.94072755000002],
  [14.185251122000068, 122.9408361290001],
  [14.18490418600004, 122.94088335700008],
  [14.184344550000048, 122.94108808700003],
  [14.183862064000039, 122.94115154500003],
  [14.183043144000067, 122.94145856800003],
  [14.182736256000055, 122.94151127700002],
  [14.182387234000032, 122.94164466100005],
  [14.181929309000054, 122.94186301600007],
  [14.181063872000038, 122.94212565100008],
  [14.18043528800007, 122.9423676020001],
  [14.180233703000056, 122.94240791100003],
  [14.180071223000027, 122.94250408500011],
  [14.179241172000047, 122.94276294200006],
  [14.178561528000046, 122.94310762600003],
  [14.178140279000047, 122.9433682230001],
  [14.177911607000055, 122.94347059100005],
  [14.177538952000077, 122.94356024400008],
  [14.177125820000072, 122.9437849300001],


]

const polygonCoordsVIN = [
  [14.222311371000046, 122.924726286],
  [14.222302414000069, 122.92510496300008],
  [14.222235264000062, 122.9253035370001],
  [14.221984578000047, 122.92552057900002],
  [14.221823424000036, 122.92559908400005],
  [14.221630935000064, 122.92560369700004],
  [14.221474262000072, 122.92546977400002],
  [14.221232536000059, 122.92513265600007],
  [14.22113405500005, 122.92504953100001],
  [14.220681938000041, 122.92433373800009],
  [14.220319344000075, 122.9238950240001],
  [14.220077612000068, 122.92367335600011],
  [14.219696173000045, 122.9233941980001],
  [14.219290786000045, 122.92297492500006],
  [14.218738234000057, 122.9226899030001],
  [14.218321113000059, 122.92255577200001],
  [14.217909410000061, 122.92230428700009],
  [14.21754646200003, 122.92204721600001],
  [14.216939738000065, 122.92178455100009],
  [14.21675555400003, 122.92166160300008],
  [14.215961364000066, 122.92130393700006],
  [14.215300468000066, 122.92110833600009],
  [14.214715410000053, 122.92104685600009],
  [14.214444549000063, 122.92108597000004],
  [14.214173687000027, 122.92106361200001],
  [14.213347193000061, 122.92110272100001],
  [14.21293006600007, 122.92118654],
  [14.21276213300007, 122.92118094900002],
  [14.212372093000056, 122.92124800200008],
  [14.212204159000066, 122.9212424110001],
  [14.211013237000031, 122.92140445300004],
  [14.210709874000031, 122.92140444900008],
  [14.21030795300004, 122.92155115500009],
  [14.209915508000051, 122.92151434800007],
  [14.209502676000056, 122.92165103700006],
  [14.209166295000045, 122.92163000200003],
  [14.208942042000047, 122.92170360300008],
  [14.207779994000077, 122.92190336700003],
  [14.206930603000046, 122.92222931600008],
  [14.206558542000039, 122.92241857700003],
  [14.206359772000042, 122.92246063300001],
  [14.205799133000028, 122.9226709190001],
  [14.205371005000075, 122.92292852600008],
  [14.204698422000035, 122.92339079400006],
  [14.204229523000038, 122.923622109],
  [14.203959395000027, 122.92384291300004],
  [14.203633202000049, 122.92402691300003],
  [14.203495588000067, 122.92415308700004],
  [14.203047068000046, 122.92446852300009],
  [14.202992790000053, 122.92454153800008],
  [14.202620726000077, 122.92480439600001],
  [14.202258853000046, 122.92514085500011],
  [14.201820526000063, 122.9255982300001],
  [14.201231676000077, 122.92611176000003],
  [14.201099943000031, 122.92627833000006],
  [14.200887469000065, 122.92640544800008],
  [14.200420025000028, 122.92687885700002],
  [14.200275541000053, 122.92698844200004],
  [14.199999322000053, 122.92729966600007],
  [14.199876163000056, 122.92749706400002],
  [14.19962969200003, 122.92771184900005],
  [14.199251484000058, 122.92814580200002],
  [14.199017758000025, 122.92850085400005],
  [14.198813777000055, 122.92872879100003],
  [14.198313819000077, 122.92947200800006],
  [14.197846368000057, 122.92997170300009],
  [14.197565892000057, 122.930580985],
  [14.197242919000075, 122.93107630300005],
  [14.197043180000037, 122.9315234070001],
  [14.196775449000029, 122.93183901200007],
  [14.196715063000056, 122.93212034600003],
  [14.196608820000051, 122.93232636000005],
  [14.196370841000032, 122.93264633700005],
  [14.19609885500006, 122.93329506400005],
  [14.195958614000062, 122.9335405270001],
  [14.195754628000032, 122.93379475800009],
  [14.195605883000042, 122.93410597400009],
  [14.19542314000006, 122.93440404400008],
  [14.195430901000066, 122.934467175],
  [14.195141917000058, 122.93496247900009],
  [14.194987457000025, 122.93537787200012],
  [14.194740185000057, 122.93569830400008],
  [14.194562649000034, 122.93608413400011],
  [14.194467548000034, 122.93614952500002],
  [14.194378777000054, 122.93641110600004],
  [14.19420124900006, 122.93662690400004],
  [14.193953969000063, 122.9370977430001],
  [14.193890561000046, 122.93727430900003],
  [14.193541838000044, 122.937764764],
  [14.193300911000051, 122.9379086240001],
  [14.19305264600007, 122.93788942000003],
  [14.192906820000076, 122.93802674400001],
  [14.192906807000043, 122.93830140600005],
  [14.192799021000042, 122.9384060320001],
  [14.192234264000035, 122.93869192200009],
  [14.191914372000042, 122.93887714000005],
  [14.191257759000052, 122.93918968500009],
  [14.190876140000057, 122.9393517420001],
  [14.190410350000036, 122.93938065700002],
  [14.19028127300004, 122.93943853500002],
  [14.190096055000026, 122.93982635600003],
  [14.189759337000055, 122.93987264400005],
  [14.189613421000047, 122.93996525200009],
  [14.189759322000043, 122.94013312800007],
  [14.189720032000025, 122.94022574300004],
  [14.189534839000032, 122.94019678900008],
  [14.189405769000075, 122.9401041640001],
  [14.189338441000075, 122.93986104100009],
  [14.189422640000032, 122.93953110000007],
  [14.18952366700006, 122.93931114100008],
  [14.189574194000045, 122.93896383200001],
  [14.189781846000074, 122.93878440000003],
  [14.190096123000046, 122.93862233900006],
  [14.190213983000035, 122.93844290000004],
  [14.190561925000054, 122.93837924600007],
  [14.191117519000045, 122.93813036800009],
  [14.191583329000025, 122.93776570800003],
  [14.191807812000036, 122.9376615220001],
  [14.191952651000065, 122.93735869400007],
  [14.192284873000062, 122.93697519400007],
  [14.192812807000053, 122.93644373100005],
  [14.19309811900007, 122.93611676500007],
  [14.193548283000041, 122.93550860100004],
  [14.193770013000062, 122.93516491200012],
  [14.194111817000078, 122.93438417800007],
  [14.194239163000077, 122.93385191900006],
  [14.19424923500003, 122.9332781820001],
  [14.194152080000038, 122.93271135200007],
  [14.19400130300005, 122.93251537300011],
  [14.193810321000058, 122.93215591800003],
  [14.193645795000066, 122.93102516500005],
  [14.193652507000024, 122.9305620240001],
  [14.193766444000062, 122.92991916300002],
  [14.193711592000056, 122.92955076900012],
  [14.19379871800004, 122.92907035200005],
  [14.193993072000069, 122.92818555400004],
  [14.19414720800006, 122.92790214200011],
  [14.194426194000073, 122.92777572700004],
  [14.194516664000048, 122.92779300900008],
  [14.194801470000073, 122.92844624500003],
  [14.19481151900004, 122.92870892100007],
  [14.194932143000074, 122.92888864800011],
  [14.194928790000063, 122.92906837300006],
  [14.195005855000034, 122.92920317000005],
  [14.195056110000053, 122.92970087700007],
  [14.195055307000075, 122.93000382800005],
  [14.195196037000073, 122.93008332600004],
  [14.195175930000062, 122.93021466400012],
  [14.195417166000027, 122.93105108800012],
  [14.195400410000047, 122.93116860100008],
  [14.195447307000052, 122.9317354310001],
  [14.195507620000058, 122.9317354320001],
  [14.195792439000058, 122.93149004400004],
  [14.195953276000068, 122.93142783500002],
  [14.196114120000061, 122.93104073500001],
  [14.196171088000028, 122.93082496900001],
  [14.195926488000055, 122.93059684900004],
  [14.195919797000045, 122.93023384800006],
  [14.195755612000028, 122.93006794000007],
  [14.195802526000023, 122.9298640180001],
  [14.195675202000075, 122.92952875300011],
  [14.195551226000077, 122.92944234400011],
  [14.195551229000046, 122.92920731600009],
  [14.19536024100006, 122.92890661800004],
  [14.195015040000044, 122.92811752900002],
  [14.194941325000059, 122.92764401400007],
  [14.19481399700004, 122.92738824700007],
  [14.194740283000044, 122.92711865800004],
  [14.19468332200006, 122.92662786800008],
  [14.19473023300003, 122.92644814300002],
  [14.19452583900005, 122.92632717400011],
  [14.194458824000037, 122.92622348600003],
  [14.194367348000071, 122.92580738700008],
  [14.194371626000077, 122.92556602800005],
  [14.194320615000038, 122.92515352100008],
  [14.194444058000045, 122.92443824400004],
  [14.194873825000059, 122.9235474730001],
  [14.194933405000029, 122.92334123000012],
  [14.194946194000067, 122.92306915800009],
  [14.194869677000042, 122.9224284600001],
  [14.194597448000025, 122.92188030800003],
  [14.194452820000038, 122.92166965700005],
  [14.194121005000056, 122.92143266000005],
  [14.193789185000071, 122.92130098300004],
  [14.193938092000053, 122.92121323200001],
  [14.194155049000074, 122.92133612200007],
  [14.194384763000073, 122.92153361400005],
  [14.19442732300007, 122.92133175800006],
  [14.194286936000026, 122.92127469700006],
  [14.194034055000031, 122.92087488800007],
  [14.193634159000055, 122.92076514300004],
  [14.193655418000048, 122.92091434800011],
  [14.193566062000059, 122.92110742400007],
  [14.193042784000056, 122.92111615400006],
  [14.192932219000056, 122.9205544460001],
  [14.192979037000043, 122.92029993200003],
  [14.193268383000031, 122.91961100500009],
  [14.193489620000037, 122.919396],
  [14.19361299700006, 122.91936529200007],
  [14.193877133000058, 122.91919485100004],
  [14.194528064000053, 122.91886578200001],
  [14.194753559000048, 122.91861567],
  [14.19497904800005, 122.91844454500006],
  [14.195247098000038, 122.91800135100004],
  [14.195340710000039, 122.91774683900007],
  [14.19554918700004, 122.91750988500007],
  [14.195668306000073, 122.91753183600008],
  [14.195822852000049, 122.91771202900009],
  [14.195954720000032, 122.917975339],
  [14.196014250000076, 122.91839223200009],
  [14.196009963000051, 122.9188530020001],
  [14.196060996000028, 122.91910313800008],
  [14.196150322000051, 122.91928306300008],
  [14.196401306000041, 122.91952005200005],
  [14.196745904000068, 122.91950252700008],
  [14.197158584000022, 122.91928753400009],
  [14.197354283000038, 122.91924805500003],
  [14.197728676000054, 122.91904183500003],
  [14.197918007000055, 122.91890115300009],
  [14.19804564900005, 122.91873001700003],
  [14.198211596000021, 122.91835263200005],
  [14.198271177000038, 122.91806300700011],
  [14.198441373000037, 122.91771195400008],
  [14.198671124000043, 122.91740040000002],
  [14.198960433000025, 122.9171415080001],
  [14.19912830000004, 122.91704582700004],
  [14.19931893000006, 122.917095001],
  [14.199567815000023, 122.91698577500006],
  [14.200039097000058, 122.91698581000003],
  [14.200203245000068, 122.91706775400007],
  [14.200452125000027, 122.91705138600003],
  [14.200801609000052, 122.91713334300005],
  [14.201241122000056, 122.91704597900002],
  [14.201470055000073, 122.91705550100005],
  [14.201891984000042, 122.91696845400008],
  [14.202099704000034, 122.91697514900011],
  [14.202690403000076, 122.9168546200001],
  [14.20344338700005, 122.91664034400003],
  [14.203863234000039, 122.91641951500003],
  [14.204664727000022, 122.91613421500006],
  [14.205138848000047, 122.9159129620001],
  [14.205449403000046, 122.91587220100007],
  [14.205556646000048, 122.91590713500011],
  [14.205923529000074, 122.91583144100002],
  [14.206538763000026, 122.91588966000006],
  [14.206922578000047, 122.9160410390001],
  [14.207278170000052, 122.91612837200012],
  [14.207729717000063, 122.91618659100004],
  [14.208423975000073, 122.91646606400002],
  [14.208615885000029, 122.916570868],
  [14.208987505000039, 122.91666769500011],
  [14.20943253200005, 122.9167300360001],
  [14.209624828000074, 122.91679237800008],
  [14.209734711000067, 122.91691706400002],
  [14.210613582000065, 122.91716419000011],
  [14.21081263800005, 122.917318195],
  [14.211335158000054, 122.91743113200005],
  [14.21186762800005, 122.91757486900008],
  [14.212136352000073, 122.91754406700011],
  [14.212561526000059, 122.91766726700007],
  [14.212969590000057, 122.91767240200011],
  [14.213168646000042, 122.91774427100006],
  [14.213526942000044, 122.91770320400008],
  [14.213979790000053, 122.91773400500006],
  [14.214168891000043, 122.91778534000002],
  [14.214417709000031, 122.91776480500005],
  [14.21499245900003, 122.9179547440001],
  [14.21540052100005, 122.9180214810001],
  [14.215798629000062, 122.91797528000006],
  [14.21609223300004, 122.91800608100004],
  [14.216520199000058, 122.91766213200003],
  [14.216903602000059, 122.91753783700005],
  [14.217416169000046, 122.91750190300002],
  [14.217804325000031, 122.91750703700006],
  [14.21846120300006, 122.91768157900003],
  [14.218824477000055, 122.91787152200004],
  [14.219467188000067, 122.9181552120001],
  [14.219811439000068, 122.91843199900006],
  [14.220178511000029, 122.91855206000002],
  [14.220868556000028, 122.91863429800003],
  [14.221132668000052, 122.91871741800003],
  [14.221423642000047, 122.91892522300009],
  [14.221754910000072, 122.91948861500009],
  [14.221951878000027, 122.91968257000008],
  [14.221701196000026, 122.91994786200007],
  [14.221705674000077, 122.92017876200009],
  [14.221778679000067, 122.92045262500005],
  [14.221993553000061, 122.92087748000006],
  [14.221921931000054, 122.92120997800009],
  [14.221935363000057, 122.92182417800007],
  [14.22204279600004, 122.92246111800011],
  [14.222033843000077, 122.92271972700007],
  [14.222132324000029, 122.923019898],
  [14.222248711000077, 122.92360638800005],
  [14.222288998000067, 122.92392965200008],
  [14.222253185000056, 122.92428986100003],
  [14.222311371000046, 122.924726286]

];

const polygonVinzons = [
  [// Vinzons 
    polygonCoordsVIN
  ],
  [// Vinzons 2
    polygonCoordsVIN2
  ]
]

const polygonCoordsTAL2 = [
  [14.149930749000077, 122.97844479100002],
  [14.149478170000066, 122.97853878900003],
  [14.148808338000038, 122.97820461000003],
  [14.148730808000039, 122.97814463600002],
  [14.14815905100005, 122.9772850380001],
  [14.148013681000066, 122.97716509000009],
  [14.147839248000025, 122.97685523700011],
  [14.147655133000058, 122.97630550600002],
  [14.147606693000057, 122.97594568600005],
  [14.147432275000028, 122.97517605900009],
  [14.147441984000068, 122.97473627700003],
  [14.147490454000035, 122.97445641600007],
  [14.147442021000074, 122.97422598500009],
  [14.14744203600003, 122.97395612300011],
  [14.147529277000046, 122.97366627300005],
  [14.147829753000053, 122.97304660400005],
  [14.147984832000077, 122.97278674400002],
  [14.148285303000023, 122.97213709100004],
  [14.148364171000026, 122.97178984600009],
  [14.148449455000048, 122.9716334950001],
  [14.148496841000053, 122.97139896700003],
  [14.148468425000033, 122.97110580600008],
  [14.148326307000048, 122.97082241400005],
  [14.148204780000071, 122.9701466780001],
  [14.148211927000034, 122.96983750700008],
  [14.148154838000039, 122.9693884720001],
  [14.14797640100005, 122.969086661],
  [14.147912169000051, 122.9688142980001],
  [14.147897904000047, 122.96837999400009],
  [14.14771946600007, 122.96810763100007],
  [14.147705199000029, 122.96779110600005],
  [14.147762310000076, 122.96734208600003],
  [14.147969316000058, 122.9667458450001],
  [14.148240560000033, 122.96622321600012],
  [14.148640283000077, 122.96566377700003],
  [14.148775902000068, 122.96559752900009],
  [14.14943259100005, 122.96499391500004],
  [14.149850229000037, 122.96457456900009],
  [14.15036415000003, 122.96415499000011],
  [14.150763869000059, 122.96358818500005],
  [14.15098514300007, 122.96310234700002],
  [14.150956593000046, 122.96280789800005],
  [14.151035111000056, 122.9625134470001],
  [14.151020833000075, 122.9622414590001],
  [14.151135036000028, 122.96187339900007],
  [14.151284928000052, 122.96163784100008],
  [14.151306340000076, 122.9614611720001],
  [14.151213548000044, 122.96132867200004],
  [14.151142167000046, 122.9610783920001],
  [14.151063648000047, 122.96059991900006],
  [14.151127884000061, 122.96006991700006],
  [14.151006533000043, 122.95921602300007],
  [14.150913738000042, 122.95887741000001],
  [14.15092087100004, 122.95854615700011],
  [14.150806664000072, 122.958369489],
  [14.150706737000064, 122.95850199400002],
  [14.150706742000068, 122.95889949800005],
  [14.15082809200004, 122.95959144500011],
  [14.150692477000064, 122.95979756000008],
  [14.150728168000057, 122.95998158700002],
  [14.15071389600007, 122.96043061500006],
  [14.150642523000045, 122.960945895],
  [14.150721040000064, 122.96112992200005],
  [14.150642525000023, 122.96130659100004],
  [14.150571151000065, 122.96188812100002],
  [14.150571153000044, 122.96265368900004],
  [14.150478362000058, 122.96295549900003],
  [14.15029011100006, 122.96325262000005],
  [14.149290816000075, 122.96444511200002],
  [14.148905375000027, 122.9646880250001],
  [14.148534209000047, 122.96496038200007],
  [14.148113075000026, 122.96532107200005],
  [14.147720485000036, 122.96587315500005],
  [14.14765624000006, 122.9660645460001],
  [14.147463509000033, 122.96641788300008],
  [14.147285246000024, 122.96709879100001],
  [14.147196439000027, 122.96727540900008],
  [14.147120315000052, 122.9676155630001],
  [14.147126640000067, 122.96837436800001],
  [14.14708222400003, 122.9689506630001],
  [14.146520379000037, 122.96909768400008],
  [14.14620704400005, 122.969249955],
  [14.145985047000067, 122.96926835400006],
  [14.145802046000028, 122.96916331400007],
  [14.145553342000028, 122.96877201100006],
  [14.142731896000043, 122.95620810800006],
  [14.13697092600006, 122.9553016430001],
  [14.136578875000055, 122.95473007400005],
  [14.135161019000066, 122.95262104500011],
  [14.134816107000063, 122.95110804400008],
  [14.12653371700003, 122.94236211500004],
  [14.125259535000055, 122.9405215160001],
  [14.125085805000026, 122.94014816900005],
  [14.124058138000066, 122.93844688400009],
  [14.122662051000077, 122.93569179800011],
  [14.122618268000053, 122.93553658700012],
  [14.122499108000056, 122.9354165960001],
  [14.122076905000029, 122.93550843100002],
  [14.121673231000045, 122.93518712700006],
  [14.121475797000073, 122.93484755100008],
  [14.121537484000044, 122.93440970400002],
  [14.121263653000028, 122.93414171800009],
  [14.121358866000037, 122.9338717820001],
  [14.121491603000038, 122.93362609400003],
  [14.121282758000063, 122.93338585300012],
  [14.121225612000046, 122.9331079320001],
  [14.120958225000038, 122.93296413300004],
  [14.118762357000037, 122.92599601900008],
  [14.115693642000053, 122.921097357],
  [14.115728239000077, 122.9206796200001],
  [14.117542765000053, 122.91842554900006],
  [14.11790320700004, 122.91790337100008],
  [14.118111816000066, 122.9172540620001],
  [14.114780179000036, 122.91572298100004],
  [14.10948224200007, 122.91329414000006],
  [14.114117286000067, 122.90079259200002],
  [14.117550270000038, 122.89011168200011],
  [14.119467149000059, 122.88993019500003],
  [14.12893209200007, 122.88933210400012],
  [14.13252316200004, 122.88919040500002],
  [14.135137524000072, 122.8886460760001],
  [14.136722681000037, 122.89012864000006],
  [14.136625496000022, 122.89275695600008],
  [14.140678484000034, 122.8944057760001],
  [14.143742045000067, 122.89723780600002],
  [14.146115259000055, 122.8991315080001],
  [14.146262473000036, 122.89957087500011],
  [14.146025365000071, 122.89983668000002],
  [14.14595285300004, 122.90009864800004],
  [14.146143453000036, 122.90128611300008],
  [14.146160922000035, 122.90155367300008],
  [14.146046951000073, 122.90194385000007],
  [14.14591669300006, 122.90217505100009],
  [14.14562373700005, 122.90241031300002],
  [14.148373502000027, 122.90518444400004],
  [14.148431479000067, 122.90506622300006],
  [14.148598253000046, 122.90506549400004],
  [14.14892211800003, 122.90531639900007],
  [14.14918040300006, 122.9051684100001],
  [14.14930361100005, 122.90516880100006],
  [14.149539367000045, 122.90541871800008],
  [14.149716768000076, 122.90549405700006],
  [14.149788166000064, 122.9056550680001],
  [14.15004889100004, 122.905746356],
  [14.150157276000073, 122.90586380700006],
  [14.150298105000047, 122.90586951700004],
  [14.15048168100003, 122.9060407390001],
  [14.150790390000054, 122.90610226800004],
  [14.151199761000044, 122.91788534000011],
  [14.151214152000023, 122.91794108900001],
  [14.151724449000028, 122.9177575110001],
  [14.15194440700003, 122.9182628750001],
  [14.152088862000028, 122.91845792800007],
  [14.152505146000067, 122.91885390900006],
  [14.15332496600007, 122.91957077900008],
  [14.154757672000073, 122.92079774900003],
  [14.154933148000055, 122.9209896320001],
  [14.155115899000066, 122.92131349400006],
  [14.155193108000049, 122.92154156100003],
  [14.155390457000067, 122.92236586100012],
  [14.155863585000077, 122.92426462900005],
  [14.15643618300004, 122.92400518700003],
  [14.156977657000027, 122.92360169400001],
  [14.164125407000029, 122.92878370000005],
  [14.173968867000042, 122.93870334600001],
  [14.175388945000066, 122.93937771500009],
  [14.176934870000025, 122.9432452960001],
  [14.177125820000072, 122.9437849300001],
  [14.176554622000026, 122.94409175100009],
  [14.176356045000034, 122.94413205900003],
  [14.175938104000068, 122.94430210000007],
  [14.175525882000045, 122.94455338500006],
  [14.175039937000065, 122.94479394100006],
  [14.174736035000024, 122.94498318],
  [14.174119211000061, 122.94531510800005],
  [14.173761672000069, 122.94556216600006],
  [14.173262192000038, 122.94584135900004],
  [14.17302148500005, 122.94594061600003],
  [14.172446255000068, 122.94632644100011],
  [14.172313846000065, 122.94650329300009],
  [14.17216340300007, 122.94657153500009],
  [14.171758235000027, 122.94687613500002],
  [14.171553994000021, 122.94693244900009],
  [14.171096632000058, 122.94722095200007],
  [14.170937146000028, 122.94739159200003],
  [14.170677197000032, 122.94754127700003],
  [14.170421427000065, 122.94775223800002],
  [14.17003993700007, 122.94796037600008],
  [14.169468221000045, 122.94839159500009],
  [14.169332771000029, 122.9485312590001],
  [14.168724960000077, 122.94891281900004],
  [14.16834949500003, 122.94922412100004],
  [14.168093743000043, 122.9493295740001],
  [14.167774766000036, 122.94967395000003],
  [14.167191081000055, 122.95002249700008],
  [14.166631895000023, 122.95051214900002],
  [14.166249754000034, 122.95075411000005],
  [14.166030603000024, 122.95093547300007],
  [14.165796138000076, 122.95106562300009],
  [14.165327185000024, 122.95146541600002],
  [14.164849246000074, 122.951707112],
  [14.164515575000053, 122.95195813500004],
  [14.164317188000041, 122.95204179200005],
  [14.163996418000067, 122.95225988900006],
  [14.163873521000028, 122.95246021900005],
  [14.163596017000032, 122.95272596000007],
  [14.16345257100005, 122.95299988500005],
  [14.163349499000049, 122.95309800200005],
  [14.162961007000035, 122.95315931100004],
  [14.162814327000035, 122.95327787000008],
  [14.162719172000038, 122.95361312200009],
  [14.16249448900004, 122.95393818500008],
  [14.162316662000023, 122.95437126600007],
  [14.162286384000026, 122.95464438400006],
  [14.162331764000044, 122.95499554100002],
  [14.162252307000074, 122.95519452500002],
  [14.161909347000062, 122.95540221400006],
  [14.161693697000032, 122.95563240400008],
  [14.161625589000039, 122.95586650100006],
  [14.161296420000042, 122.95653757600007],
  [14.160854766000057, 122.95723916400004],
  [14.16065044100003, 122.95784001000004],
  [14.160446120000074, 122.95836672300004],
  [14.160366644000021, 122.95882321600004],
  [14.160139622000031, 122.95931481700006],
  [14.159882347000064, 122.95959962800009],
  [14.159509977000027, 122.95989460300007],
  [14.159158124000044, 122.96022231500001],
  [14.158768446000067, 122.96044467900003],
  [14.158386332000077, 122.9607021590001],
  [14.157883155000036, 122.960947927],
  [14.157521074000044, 122.96122443700006],
  [14.157014110000034, 122.96155993500008],
  [14.156673606000027, 122.96184862700011],
  [14.155861820000041, 122.9622464790001],
  [14.15544546600006, 122.96250056200006],
  [14.155177803000072, 122.96272836900005],
  [14.15495506700006, 122.96299501700003],
  [14.154534772000034, 122.96376930100007],
  [14.153887335000036, 122.96436162300006],
  [14.153463449000071, 122.96463576700012],
  [14.152802475000044, 122.96495436400005],
  [14.15256538400007, 122.9651321880001],
  [14.152154890000077, 122.96551145600006],
  [14.151871097000026, 122.96588192100012],
  [14.151655558000073, 122.96621163300006],
  [14.15122026000006, 122.96714675900012],
  [14.151166371000045, 122.96742090300006],
  [14.151169958000025, 122.96763577400009],
  [14.150972375000038, 122.96807291800008],
  [14.150864598000055, 122.96851376900008],
  [14.150674200000026, 122.96892127600006],
  [14.150429915000075, 122.96932878400003],
  [14.15036165600003, 122.96953624500009],
  [14.150261270000044, 122.97002564200011],
  [14.150275628000031, 122.97038128800011],
  [14.150232515000027, 122.97055910900008],
  [14.150175018000027, 122.97118148800007],
  [14.149919950000026, 122.97167049600012],
  [14.149743159000025, 122.97205967000002],
  [14.149685649000048, 122.97237259500002],
  [14.149409537000054, 122.97314390500003],
  [14.149228779000055, 122.97429310500002],
  [14.14916351100004, 122.97457263900003],
  [14.149108266000042, 122.97515759700002],
  [14.149123286000076, 122.97590821900008],
  [14.149158410000041, 122.97616705900009],
  [14.14931156800003, 122.97674250600005],
  [14.149532373000056, 122.97766913600003],
  [14.149647799000036, 122.97804186000008],
  [14.149930749000077, 122.97844479100002],


]

const polygonCoordsTAL = [
  [14.147385370000052, 122.97707111500006],
  [14.147545796000031, 122.977316674],
  [14.147691525000027, 122.97744819200011],
  [14.147989037000059, 122.97797424400005],
  [14.148110461000044, 122.97833120400003],
  [14.148255368000036, 122.97858628900008],
  [14.148143596000068, 122.9785437380001],
  [14.147887085000036, 122.97821377800005],
  [14.147481815000049, 122.97752848200003],
  [14.147385370000052, 122.97707111500006]

];

const polygonTalisay = [
  [// tal
    polygonCoordsTAL
  ],
  [// tal 2
    polygonCoordsTAL2
  ]
]

const polygonCoordsDaet = [
  [14.127008266000075, 122.99112768600003],
  [14.12673522700004, 122.99071804300002],
  [14.12642285000004, 122.99044744600008],
  [14.126046914000028, 122.99022065000008],
  [14.125682727000026, 122.99014319000003],
  [14.125287294000032, 122.99015697800007],
  [14.124786043000029, 122.99023702800002],
  [14.124459491000039, 122.99033623300011],
  [14.124169786000039, 122.99037384500002],
  [14.123877883000034, 122.99005586100009],
  [14.123809268000059, 122.98971442000004],
  [14.12385542800007, 122.98953904600012],
  [14.124039627000059, 122.98926425800005],
  [14.124389173000054, 122.98916033400008],
  [14.125043529000038, 122.98939424600007],
  [14.125296451000054, 122.98935659400001],
  [14.12539326600006, 122.98910541400005],
  [14.125384285000052, 122.98889679000001],
  [14.12549475700007, 122.9887783800001],
  [14.125784501000055, 122.98870283700012],
  [14.12608352500007, 122.98854670300011],
  [14.126235510000072, 122.98830032400008],
  [14.126267905000077, 122.98809648300005],
  [14.126106937000031, 122.98761802100012],
  [14.125909061000073, 122.9874005150001],
  [14.125449614000047, 122.98706502300001],
  [14.124264181000058, 122.9863468640001],
  [14.12394570400005, 122.98631857700002],
  [14.123977745000047, 122.98586184300007],
  [14.123903851000023, 122.98577852400001],
  [14.123677502000078, 122.98587347000012],
  [14.123585458000036, 122.9856749700001],
  [14.123451455000065, 122.98561457400001],
  [14.123242073000029, 122.98564051000005],
  [14.123032817000023, 122.98558878800009],
  [14.122932405000029, 122.98548527800006],
  [14.122731611000063, 122.9854766840001],
  [14.122581037000032, 122.98553710800002],
  [14.122380243000066, 122.98551125800009],
  [14.122087427000054, 122.98538191300008],
  [14.122003717000041, 122.98526113800006],
  [14.122045250000042, 122.984562062],
  [14.121543921000068, 122.98429557300005],
  [14.121351665000077, 122.98423534200003],
  [14.121234852000043, 122.98407183000006],
  [14.121477668000068, 122.9837965050001],
  [14.12140838700003, 122.983600593],
  [14.121938586000056, 122.98331545200006],
  [14.122109494000028, 122.98296798400008],
  [14.122055727000031, 122.98278181],
  [14.121629849000044, 122.98254936100011],
  [14.121062054000049, 122.98250179500008],
  [14.120979509000051, 122.98266628500005],
  [14.120788237000056, 122.98256569800003],
  [14.12029274200006, 122.98274914600006],
  [14.120075198000052, 122.98275729600005],
  [14.119787417000055, 122.98256550400004],
  [14.119492179000076, 122.9826625500001],
  [14.11929348800004, 122.98261856400006],
  [14.119106306000049, 122.98246992800011],
  [14.119102457000054, 122.98195135800006],
  [14.119058119000044, 122.98176423100006],
  [14.118762006000054, 122.98150814500002],
  [14.118581692000078, 122.98155667800006],
  [14.118562772000075, 122.9817373200001],
  [14.118467234000036, 122.98182266200001],
  [14.11824727100003, 122.98184528600007],
  [14.117954318000045, 122.98173969700008],
  [14.117908317000058, 122.98143097800005],
  [14.11781488400004, 122.98136275200011],
  [14.117538572000058, 122.98101762200008],
  [14.11743348400006, 122.98098955800003],
  [14.117445413000041, 122.98054653500003],
  [14.117334477000043, 122.98035837600003],
  [14.11712602700004, 122.98031892200004],
  [14.116873129000055, 122.98046470500003],
  [14.116632043000038, 122.98065601200005],
  [14.116441292000047, 122.98070473100006],
  [14.116093701000068, 122.98087103900002],
  [14.115914944000053, 122.9810312400001],
  [14.115249609000045, 122.98115269200002],
  [14.11486588200006, 122.98138946000006],
  [14.114596145000064, 122.98134828400009],
  [14.114835554000024, 122.98094917600008],
  [14.114752616000033, 122.98062834500001],
  [14.11410415100005, 122.98041380300003],
  [14.114034220000065, 122.9800212350001],
  [14.11396028200005, 122.9799790730001],
  [14.113990141000045, 122.97959331200002],
  [14.10761879000006, 122.97885861200007],
  [14.107595642000035, 122.97933203000002],
  [14.107414593000044, 122.97995448200004],
  [14.107187125000053, 122.98058548000006],
  [14.106965456000069, 122.98112990300001],
  [14.106695971000022, 122.98220018100005],
  [14.106661417000055, 122.98278402800008],
  [14.106812891000061, 122.9838381300001],
  [14.106813643000066, 122.98431102500001],
  [14.106749825000065, 122.98508577600012],
  [14.106604391000076, 122.98548723600004],
  [14.106465763000074, 122.98598927100011],
  [14.106273061000024, 122.98701661200005],
  [14.106132091000063, 122.98810688800006],
  [14.105945205000069, 122.98931916800007],
  [14.105763546000048, 122.99037352700009],
  [14.10553184500003, 122.9916032430001],
  [14.105448526000032, 122.99237450800001],
  [14.105408251000028, 122.9933099210001],
  [14.105075665000072, 122.99491105400011],
  [14.104992088000074, 122.99541604300009],
  [14.104452704000039, 122.9952803540001],
  [14.10419098400007, 122.99482179600011],
  [14.10403993500006, 122.99469067900009],
  [14.10376136900004, 122.99462497600007],
  [14.103610504000073, 122.9944202160001],
  [14.103419755000061, 122.99426455800005],
  [14.103268932000049, 122.99390410100011],
  [14.102966662000028, 122.99328172600008],
  [14.102783834000036, 122.99317546300006],
  [14.102463054000054, 122.99311867100005],
  [14.102389165000034, 122.992994242],
  [14.102208165000036, 122.99290410800006],
  [14.102228401000048, 122.99263436800004],
  [14.10202097000007, 122.99250889200005],
  [14.101907537000045, 122.99222388400005],
  [14.101659329000029, 122.9921882650001],
  [14.101572143000055, 122.99204241200005],
  [14.101464415000066, 122.9921538850001],
  [14.101457517000028, 122.9922997760001],
  [14.101195494000024, 122.99230732400008],
  [14.101074508000067, 122.99249495900006],
  [14.100913456000058, 122.99251609300006],
  [14.100618415000042, 122.99219765300006],
  [14.100417204000053, 122.99222550500008],
  [14.100329927000075, 122.99235687400005],
  [14.100423723000063, 122.99258517900012],
  [14.100436706000039, 122.99281951300009],
  [14.100361948000057, 122.9930878560001],
  [14.100213308000036, 122.99326701100006],
  [14.099817537000035, 122.99353115100007],
  [14.09946816400003, 122.99359334300004],
  [14.099300773000039, 122.99355885800003],
  [14.099114297000028, 122.99337250400004],
  [14.098891394000077, 122.99338647400009],
  [14.098750141000039, 122.99348992500006],
  [14.098528491000025, 122.99353123000003],
  [14.098441496000078, 122.99346904000004],
  [14.098408685000038, 122.99316505200011],
  [14.09824781900005, 122.99290904400004],
  [14.098045414000069, 122.99287336900011],
  [14.09768712500005, 122.99305860400011],
  [14.09751234600003, 122.99308079900004],
  [14.097237409000059, 122.99328877500011],
  [14.094596318000072, 122.99342949900006],
  [14.094455245000063, 122.9938537380001],
  [14.094347890000051, 122.99389597600009],
  [14.093762714000036, 122.9938022770001],
  [14.093614423000076, 122.99380228300004],
  [14.09351549300004, 122.99398034400008],
  [14.093392073000075, 122.99443139300001],
  [14.093367417000024, 122.99466140600009],
  [14.093235535000076, 122.99471254200012],
  [14.09297176800004, 122.99457621700003],
  [14.092765733000022, 122.99453363100008],
  [14.092575966000027, 122.99462787000004],
  [14.092468455000073, 122.99498612200011],
  [14.092476592000025, 122.99528428700012],
  [14.091679501000044, 122.99589311900002],
  [14.090169417000027, 122.99634017200003],
  [14.088577757000053, 122.99685319000002],
  [14.08747386300007, 122.99062327100012],
  [14.086301219000063, 122.9801619640001],
  [14.087218404000055, 122.97424538100006],
  [14.085735727000042, 122.96491713400007],
  [14.088153660000046, 122.95745552500011],
  [14.088275070000066, 122.95709736800006],
  [14.07970658000005, 122.94749001500008],
  [14.076478050000048, 122.94441605800012],
  [14.076483296000049, 122.94415116400012],
  [14.076389491000043, 122.94354084100007],
  [14.076147872000035, 122.94302386400011],
  [14.075791180000067, 122.94247306600005],
  [14.075292022000042, 122.94198270100003],
  [14.074977133000061, 122.9416344120001],
  [14.074757285000032, 122.94123320400001],
  [14.07439719100006, 122.94024064400003],
  [14.074289484000076, 122.94011770400004],
  [14.073459501000059, 122.938929991],
  [14.073000707000062, 122.9386313010001],
  [14.072799109000073, 122.9383692240001],
  [14.064588093000054, 122.93129622200001],
  [14.067914114000075, 122.93024694400003],
  [14.078704884000047, 122.92002638200006],
  [14.087420261000034, 122.91670233600007],
  [14.089837694000039, 122.912562352],
  [14.089784290000068, 122.90705135000007],
  [14.089844844000027, 122.90464820800003],
  [14.089820269000029, 122.90414078600008],
  [14.089970497000024, 122.9026838960001],
  [14.089980685000057, 122.90186401300002],
  [14.090060030000075, 122.89907869900003],
  [14.085902951000037, 122.89635831700002],
  [14.08869478500003, 122.89384133700003],
  [14.091251627000076, 122.89233435500012],
  [14.09892553700007, 122.89501826900005],
  [14.103500133000068, 122.89660706400002],
  [14.105946471000038, 122.89749161600002],
  [14.114117286000067, 122.90079259200002],
  [14.10948224200007, 122.91329414000006],
  [14.114780179000036, 122.91572298100004],
  [14.118111816000066, 122.9172540620001],
  [14.11790320700004, 122.91790337100008],
  [14.117542765000053, 122.91842554900006],
  [14.115728239000077, 122.9206796200001],
  [14.115693642000053, 122.921097357],
  [14.118762357000037, 122.92599601900008],
  [14.120958225000038, 122.93296413300004],
  [14.121225612000046, 122.9331079320001],
  [14.121282758000063, 122.93338585300012],
  [14.121491603000038, 122.93362609400003],
  [14.121358866000037, 122.9338717820001],
  [14.121263653000028, 122.93414171800009],
  [14.121537484000044, 122.93440970400002],
  [14.121475797000073, 122.93484755100008],
  [14.121673231000045, 122.93518712700006],
  [14.122076905000029, 122.93550843100002],
  [14.122499108000056, 122.9354165960001],
  [14.122618268000053, 122.93553658700012],
  [14.122662051000077, 122.93569179800011],
  [14.124058138000066, 122.93844688400009],
  [14.125085805000026, 122.94014816900005],
  [14.125259535000055, 122.9405215160001],
  [14.12653371700003, 122.94236211500004],
  [14.134816107000063, 122.95110804400008],
  [14.135161019000066, 122.95262104500011],
  [14.136578875000055, 122.95473007400005],
  [14.13697092600006, 122.9553016430001],
  [14.142731896000043, 122.95620810800006],
  [14.145553342000028, 122.96877201100006],
  [14.145802046000028, 122.96916331400007],
  [14.145985047000067, 122.96926835400006],
  [14.14620704400005, 122.969249955],
  [14.146520379000037, 122.96909768400008],
  [14.14708222400003, 122.9689506630001],
  [14.147101244000055, 122.96929016500007],
  [14.147171006000065, 122.96972190100007],
  [14.14711390900004, 122.97006859500004],
  [14.14697435200003, 122.97040874600009],
  [14.146898221000072, 122.97086664800008],
  [14.146346343000062, 122.97178033500006],
  [14.146244840000065, 122.97215973100003],
  [14.146200423000039, 122.97254567100003],
  [14.146270152000056, 122.9735465120001],
  [14.146206704000065, 122.9739455350001],
  [14.146466726000028, 122.97481555200011],
  [14.146587232000059, 122.9750445090001],
  [14.146606250000048, 122.97527345900005],
  [14.146720404000064, 122.97570520200009],
  [14.146834569000077, 122.97590799500006],
  [14.14701850800003, 122.97611079500007],
  [14.147107299000027, 122.97637900400002],
  [14.147265864000076, 122.97664067900007],
  [14.147321137000063, 122.97702859500009],
  [14.147385370000052, 122.97707111500006],
  [14.147481815000049, 122.97752848200003],
  [14.147887085000036, 122.97821377800005],
  [14.148143596000068, 122.9785437380001],
  [14.148255368000036, 122.97858628900008],
  [14.14851827800004, 122.9788433120001],
  [14.148657938000042, 122.97890594500007],
  [14.148925103000067, 122.97916898300002],
  [14.149204417000021, 122.97938818500006],
  [14.149416943000062, 122.97948839900005],
  [14.149495873000035, 122.97965122700009],
  [14.149750905000076, 122.97977023200008],
  [14.150030223000044, 122.97996439100007],
  [14.149926985000036, 122.98007084200003],
  [14.149647659000038, 122.97999567000011],
  [14.149465496000062, 122.97987667000007],
  [14.148971610000046, 122.9798168420001],
  [14.14824360800003, 122.97995756000012],
  [14.147679108000034, 122.97982868100007],
  [14.14744438300005, 122.9798132840001],
  [14.147087959000032, 122.97984381100002],
  [14.146615504000067, 122.97992639200004],
  [14.146059912000055, 122.98006716400005],
  [14.145497804000058, 122.98023244800004],
  [14.145111487000065, 122.98040391600011],
  [14.144427452000059, 122.98077764600009],
  [14.14379094700007, 122.98099978700009],
  [14.143562293000059, 122.98117919900005],
  [14.14355265000006, 122.98135229800005],
  [14.143144714000073, 122.98166559600008],
  [14.142745671000057, 122.98182084000007],
  [14.142135169000028, 122.98179508700002],
  [14.141607771000054, 122.98199929300006],
  [14.14130493700003, 122.98204547800003],
  [14.140579388000049, 122.98237301800009],
  [14.140216629000065, 122.98248087200011],
  [14.140108161000057, 122.98256181800002],
  [14.139622015000043, 122.98268564000011],
  [14.139149360000033, 122.98290603500004],
  [14.138873472000057, 122.98289543700002],
  [14.138454248000073, 122.9830628520001],
  [14.138002205000078, 122.98317568900006],
  [14.137673083000038, 122.98334139500002],
  [14.137459915000022, 122.98340687900009],
  [14.137048521000054, 122.98359183800005],
  [14.136779211000032, 122.98381540600008],
  [14.136292265000066, 122.98409288700009],
  [14.13599307000004, 122.98422004000008],
  [14.135368441000026, 122.98473056000012],
  [14.13499181900005, 122.9849643340001],
  [14.134550115000025, 122.98528483100006],
  [14.134173493000048, 122.98551860500004],
  [14.133661423000035, 122.9857828270001],
  [14.133169235000025, 122.98585359200001],
  [14.13283264200004, 122.98599229500007],
  [14.13243245600006, 122.98620038600006],
  [14.132326947000024, 122.98637600900008],
  [14.131899883000074, 122.9866567360001],
  [14.13140659000004, 122.98686911700008],
  [14.13088379800007, 122.98724849200005],
  [14.130383130000041, 122.98750641400011],
  [14.130066469000042, 122.98785549600007],
  [14.129897105000055, 122.98799966900003],
  [14.129403809000053, 122.98821963600005],
  [14.129168167000046, 122.98843210600012],
  [14.129135101000031, 122.98862187400005],
  [14.128965703000063, 122.98886473000005],
  [14.12822202700005, 122.98934270500001],
  [14.127875930000073, 122.98964622900007],
  [14.127721267000027, 122.98985113700007],
  [14.127618084000062, 122.99020030100007],
  [14.127488370000037, 122.99049542000012],
  [14.127208517000042, 122.99082932400006],
  [14.127008266000075, 122.99112768600003],
  [14.149478170000066, 122.97853878900003],
  [14.149930749000077, 122.97844479100002],
  [14.150084460000073, 122.97867861700001],
  [14.150019764000035, 122.97872443100005],
  [14.149564264000048, 122.97859446400003],
  [14.149478170000066, 122.97853878900003]

];

const polygonCoordsSLR = [
  [14.064588093000054, 122.93129622200001],
  [14.059313495000026, 122.928378113],
  [14.057448630000067, 122.92610010600004],
  [14.056150334000051, 122.92426860100011],
  [14.05608485700003, 122.92389031000005],
  [14.056010107000077, 122.92306504100009],
  [14.056142668000064, 122.92273513700002],
  [14.056117869000047, 122.92238956800009],
  [14.056016601000067, 122.92226392600003],
  [14.056011787000045, 122.9221070110001],
  [14.056184683000026, 122.921851091],
  [14.055946257000073, 122.92113073300004],
  [14.05594366300005, 122.92046379600004],
  [14.05585506500006, 122.919889],
  [14.05519389400007, 122.91877043800002],
  [14.05507297500003, 122.91897755700006],
  [14.054833880000047, 122.91899499600004],
  [14.054573016000063, 122.91888082700007],
  [14.054428818000076, 122.91869253000004],
  [14.053957861000072, 122.91864712300003],
  [14.052411994000067, 122.91993108100007],
  [14.049133836000067, 122.91715093100004],
  [14.048619678000023, 122.91670569100006],
  [14.045144723000021, 122.9137678840001],
  [14.040877824000063, 122.90683841500004],
  [14.038002038000059, 122.90212601400003],
  [14.03517424200004, 122.89864500100009],
  [14.031185531000062, 122.8938099830001],
  [14.028320739000037, 122.890256969],
  [14.025761928000065, 122.88646295800004],
  [14.02225018200005, 122.88207594200003],
  [14.017333534000045, 122.87677291800003],
  [14.013678797000068, 122.87251090100006],
  [14.012356904000058, 122.86911989700002],
  [14.002261568000051, 122.86809883600006],
  [13.995916661000024, 122.86789136700008],
  [13.995803258000024, 122.86814471800005],
  [13.995803324000065, 122.86828071500008],
  [13.995917399000064, 122.86845355700007],
  [13.996301174000052, 122.868615266],
  [13.996934836000037, 122.86903233500004],
  [13.997299741000063, 122.86947878100011],
  [13.997149103000027, 122.86990862500011],
  [13.996843721000062, 122.86998783600006],
  [13.996687741000073, 122.87071761700008],
  [13.996764169000073, 122.87085448800008],
  [13.99751629900004, 122.87074927600008],
  [13.998105911000039, 122.87082906800003],
  [13.998477566000076, 122.87083406600004],
  [13.99902938300005, 122.87070584600008],
  [13.99908554800004, 122.87076474700007],
  [13.999256795000065, 122.87127217800003],
  [13.999144932000036, 122.871388765],
  [13.99817880200004, 122.87141559600002],
  [13.997626068000045, 122.87146183100003],
  [13.996946309000066, 122.8716871370001],
  [13.99663590800003, 122.87186816000008],
  [13.996733824000046, 122.87221889200009],
  [13.997033853000062, 122.87233917300011],
  [13.998256152000067, 122.87257154400004],
  [13.998285964000047, 122.873116535],
  [13.99815620000004, 122.87331167600007],
  [13.996803635000049, 122.87346835700009],
  [13.996637833000023, 122.87387916500006],
  [13.99681191600007, 122.87401627800011],
  [13.99702287200006, 122.87405543900002],
  [13.99720492800003, 122.874664402],
  [13.997348831000068, 122.87537456900009],
  [13.997165893000044, 122.87590904800004],
  [13.996439365000072, 122.87610755000003],
  [13.99692475300003, 122.87650488600002],
  [13.997177102000023, 122.87686250600007],
  [13.997139136000044, 122.87716033800007],
  [13.996513372000038, 122.87729537500002],
  [13.99647275700005, 122.87751222600002],
  [13.996243418000063, 122.8779681640001],
  [13.995797401000061, 122.87797803400008],
  [13.995466057000044, 122.87761809100004],
  [13.995093500000053, 122.8770785690001],
  [13.994742753000025, 122.87668018000011],
  [13.994330526000056, 122.87665977400002],
  [13.994095498000036, 122.87686732800012],
  [13.994052329000056, 122.87720992900006],
  [13.994068653000056, 122.87764666300006],
  [13.993974078000065, 122.87823197600005],
  [13.994110472000045, 122.87843128600002],
  [13.994271640000022, 122.87888418900002],
  [13.994143645000065, 122.87931738600003],
  [13.993889169000056, 122.87960269300004],
  [13.993523543000038, 122.8800985040001],
  [13.993899948000035, 122.88034933300003],
  [13.994038432000025, 122.88079232100006],
  [13.994056236000063, 122.88133014900006],
  [13.993768571000032, 122.8817444450001],
  [13.993325838000032, 122.8817275340001],
  [13.99279387300004, 122.88202757200008],
  [13.99258446600004, 122.88255280600004],
  [13.99285295900006, 122.88288604900004],
  [13.993165551000061, 122.88312228900008],
  [13.993482854000035, 122.88350332400012],
  [13.993330307000065, 122.88406235700006],
  [13.992990829000064, 122.88423836100003],
  [13.992670048000036, 122.88452639900004],
  [13.992239808000022, 122.88460631400005],
  [13.992564816000026, 122.88504898000008],
  [13.992323230000068, 122.88548704800007],
  [13.992161754000051, 122.88559549000001],
  [13.992081437000024, 122.88578722900002],
  [13.992055621000077, 122.88619969600006],
  [13.99173214700005, 122.88605841600008],
  [13.991147307000062, 122.88586703400006],
  [13.990584910000052, 122.88575259800007],
  [13.990184605000024, 122.88591443900009],
  [13.990104841000061, 122.88605123500008],
  [13.990212684000028, 122.8864647480001],
  [13.988787090000073, 122.886444],
  [13.985431296000058, 122.89761658600003],
  [13.979349146000061, 122.91782974400007],
  [13.979374266000036, 122.9184158700001],
  [13.97960871600003, 122.91868381300003],
  [13.979709195000055, 122.91894338300006],
  [13.979650582000033, 122.91922807300011],
  [13.97928631800005, 122.91946377500005],
  [13.97923118600005, 122.91981735700006],
  [13.979359093000028, 122.92000554200001],
  [13.979470828000046, 122.92035397900008],
  [13.97929881500005, 122.92061787900002],
  [13.979165027000022, 122.92068844900007],
  [13.979204723000066, 122.92094793800004],
  [13.979173848000073, 122.92116552700008],
  [13.97908637200004, 122.92129710900008],
  [13.979157676000057, 122.92143824800007],
  [13.97908420300007, 122.92164222500003],
  [13.979095200000074, 122.9219664630001],
  [13.978883919000054, 122.92240206500003],
  [13.97869888100007, 122.92256537800006],
  [13.978381480000053, 122.92255156700003],
  [13.978130289000035, 122.92275572400001],
  [13.97806421100006, 122.92287828000008],
  [13.978170069000043, 122.9229736960001],
  [13.978275053000061, 122.92320960800009],
  [13.978276081000047, 122.92338246400004],
  [13.978038016000028, 122.9234777150001],
  [13.977945544000022, 122.9237910820001],
  [13.978276319000031, 122.92387301200006],
  [13.978421857000058, 122.92400940900006],
  [13.97839543400005, 122.92418661400006],
  [13.978170653000063, 122.92424094400008],
  [13.97799873200006, 122.92443169900002],
  [13.978276489000052, 122.9245546190001],
  [13.978448350000065, 122.92485477000002],
  [13.978355837000038, 122.92511377000005],
  [13.977906315000041, 122.925304331],
  [13.977853422000067, 122.9254816030001],
  [13.974037053000075, 122.92867251700011],
  [13.97379203500003, 122.92895224200004],
  [13.973465611000051, 122.92906401300002],
  [13.973368565000044, 122.92914756400012],
  [13.973348197000064, 122.92973462000009],
  [13.97326694800006, 122.93017477500007],
  [13.973022694000065, 122.93065684500004],
  [13.972575566000046, 122.9309499100001],
  [13.972250554000027, 122.93109627700005],
  [13.971478920000038, 122.93164013300009],
  [13.97111381700006, 122.93168148500001],
  [13.970748981000042, 122.93161809300011],
  [13.97036410800007, 122.93151282600002],
  [13.97001980500005, 122.93151233700007],
  [13.969938586000069, 122.93170062700005],
  [13.970019304000061, 122.93193105700004],
  [13.970667424000055, 122.93207877300006],
  [13.970950982000033, 122.93230974900007],
  [13.971153333000075, 122.93275024900004],
  [13.971321347000071, 122.9336396870001],
  [13.971485375000043, 122.93413868800008],
  [13.971438455000055, 122.93432387200005],
  [13.971305426000072, 122.93442073800009],
  [13.971031673000027, 122.93436506300009],
  [13.970841688000064, 122.93443606300002],
  [13.970701203000033, 122.93459678400006],
  [13.970685500000059, 122.93475776200012],
  [13.969156156000054, 122.93712164700003],
  [13.965688259000046, 122.939351009],
  [13.960665473000063, 122.93092731900003],
  [13.960480234000045, 122.93087282700003],
  [13.960241952000047, 122.930586779],
  [13.959180681000078, 122.93038213900002],
  [13.958505786000046, 122.93021836800006],
  [13.957961623000074, 122.92991883900004],
  [13.957511194000062, 122.92945520700005],
  [13.95662363100007, 122.92948228500006],
  [13.955807527000047, 122.92940016200009],
  [13.955451842000059, 122.92929107200007],
  [13.955211236000025, 122.92890935600008],
  [13.955353827000067, 122.92874548800012],
  [13.955722044000026, 122.92875891800009],
  [13.955986438000025, 122.92869069100004],
  [13.955933229000038, 122.92841768500011],
  [13.956185163000043, 122.92824039800007],
  [13.956543018000048, 122.927749274],
  [13.95694071400004, 122.92751758000009],
  [13.956913990000032, 122.92697152400001],
  [13.957046453000032, 122.92661671000008],
  [13.957351310000035, 122.926385],
  [13.957576580000023, 122.92609859600009],
  [13.957696307000049, 122.9257581490001],
  [13.957710199000076, 122.92537703500011],
  [13.957570318000023, 122.92512487500005],
  [13.957278975000065, 122.92508399300004],
  [13.956749056000035, 122.92505680300007],
  [13.956470898000077, 122.92497494800011],
  [13.956219824000073, 122.92459280300011],
  [13.95609337600007, 122.92446998800006],
  [13.955656572000066, 122.9244426890001],
  [13.955034204000071, 122.92448362700009],
  [13.954689804000054, 122.9244699730001],
  [13.954265754000062, 122.92452458600008],
  [13.954027021000059, 122.92468847900011],
  [13.953429506000077, 122.925317188],
  [13.952855871000054, 122.92624308400002],
  [13.952521789000059, 122.9266120960001],
  [13.952122903000031, 122.92693936300009],
  [13.951632808000056, 122.92702094700007],
  [13.950356260000035, 122.92698354700008],
  [13.949932250000074, 122.92703768900003],
  [13.949615398000049, 122.9269151530001],
  [13.949255685000026, 122.92666543900009],
  [13.949075204000053, 122.92642482200006],
  [13.948586567000063, 122.92604276800012],
  [13.948046155000043, 122.92567303500005],
  [13.947501393000039, 122.92563184800008],
  [13.947063018000051, 122.9254266480001],
  [13.946598430000051, 122.9251668280001],
  [13.946306712000023, 122.92479783300007],
  [13.94604184900004, 122.92451131700011],
  [13.945618730000035, 122.92447163300005],
  [13.94523543400004, 122.92429495200008],
  [13.944952476000026, 122.92395996100004],
  [13.944859919000066, 122.92363251200004],
  [13.944555507000075, 122.92334588900007],
  [13.944198038000025, 122.92320930300002],
  [13.943800767000027, 122.9228672270001],
  [13.943389423000042, 122.9224418440001],
  [13.94320360200004, 122.92193543100007],
  [13.943136553000045, 122.92090809400008],
  [13.94297664100003, 122.92039033100002],
  [13.94276459300005, 122.91983099600009],
  [13.942420208000044, 122.91962621700009],
  [13.94211548800007, 122.91972185200007],
  [13.941897941000036, 122.91685378000011],
  [13.941751433000036, 122.91671363900002],
  [13.939665857000023, 122.91333867600008],
  [13.939948492000042, 122.91205145300012],
  [13.939978901000075, 122.91127159400003],
  [13.939978901000075, 122.91064641500009],
  [13.939750744000037, 122.90843043300004],
  [13.939815976000034, 122.90533795700003],
  [13.939800737000041, 122.90378068100006],
  [13.940015133000031, 122.90170771100009],
  [13.940135232000046, 122.90082775600001],
  [13.940508263000027, 122.90051745200003],
  [13.94091693100006, 122.90037135300008],
  [13.94120237900006, 122.90048164200005],
  [13.941418420000048, 122.90094108000005],
  [13.941701911000052, 122.90114210000002],
  [13.941985801000044, 122.90119673000004],
  [13.942252298000028, 122.90101341400009],
  [13.942483622000054, 122.90068368900006],
  [13.942644173000076, 122.90029899800004],
  [13.942970759000048, 122.90018722500008],
  [13.943085593000035, 122.90018664900003],
  [13.94346850200003, 122.90046218400005],
  [13.943744730000049, 122.90050809800005],
  [13.944252844000061, 122.90072018800004],
  [13.944422687000042, 122.90083098900004],
  [13.944778517000032, 122.90125274900004],
  [13.944956651000041, 122.901939577],
  [13.945127236000076, 122.90216336300011],
  [13.945366335000074, 122.90256086600004],
  [13.94555166400005, 122.9027441720001],
  [13.945813089000069, 122.90284230100008],
  [13.946355610000069, 122.90268404500011],
  [13.946660404000056, 122.90229381400002],
  [13.946939666000048, 122.9021472390001],
  [13.947167255000068, 122.90213850100008],
  [13.94727886100003, 122.90219619800007],
  [13.947376556000052, 122.90268586500008],
  [13.947577426000066, 122.90313358800006],
  [13.947716798000044, 122.90322329800006],
  [13.947805508000044, 122.90298493400007],
  [13.948007331000042, 122.90301547900003],
  [13.948087433000069, 122.90351448600006],
  [13.948030844000073, 122.9038967570001],
  [13.948048835000066, 122.90404127400006],
  [13.948189600000035, 122.90413488800004],
  [13.948424829000032, 122.90408282400006],
  [13.948604555000031, 122.90397522100011],
  [13.948699912000052, 122.90382023100005],
  [13.964380530000028, 122.89186546700012],
  [13.970773529000041, 122.87509830500005],
  [13.975632146000066, 122.85880389600004],
  [13.975920576000021, 122.85851826500004],
  [13.975915021000048, 122.85793279100005],
  [13.975767501000064, 122.85740538800007],
  [13.97602771000004, 122.85676225300006],
  [13.97638309100006, 122.85628550800004],
  [13.97715370900005, 122.85551460200008],
  [13.977365439000039, 122.8550517750001],
  [13.977364802000068, 122.8545751900001],
  [13.977188444000035, 122.85396032200003],
  [13.976848998000037, 122.8535124120001],
  [13.976679415000035, 122.85295762900012],
  [13.976814888000035, 122.8524973420001],
  [13.977272086000028, 122.85200137900006],
  [13.97775957500005, 122.85167454600003],
  [13.978069567000034, 122.85158983400004],
  [13.978459942000029, 122.85114465800007],
  [13.979021703000058, 122.85070898400011],
  [13.979566459000068, 122.85048385400012],
  [13.980005541000025, 122.85007000300004],
  [13.980353361000027, 122.84951980400001],
  [13.980425990000072, 122.84899606300007],
  [13.980400598000074, 122.84860932200002],
  [13.980435575000058, 122.84822522000002],
  [13.980841209000062, 122.84732982900005],
  [13.981249744000024, 122.84692929000005],
  [13.981690064000077, 122.84680721000007],
  [13.982076732000053, 122.84690364800008],
  [13.982337608000023, 122.84717494000006],
  [13.982719627000051, 122.84715983000001],
  [13.983000723000032, 122.84694209600002],
  [13.983399837000036, 122.84675085000003],
  [13.983912495000027, 122.84658548900006],
  [13.984432620000064, 122.84629696500008],
  [13.984732321000024, 122.84601731600003],
  [13.984949151000023, 122.8455589450001],
  [13.985161830000038, 122.845167263],
  [13.985469984000076, 122.84502192800005],
  [13.98558823600007, 122.84465426400004],
  [13.989171990000045, 122.83802157500008],
  [14.013752262000025, 122.79251799700012],
  [14.034623674000045, 122.83133907900003],
  [14.040017887000033, 122.83884199300007],
  [14.042796074000023, 122.84287211700007],
  [14.046572785000023, 122.8500581300001],
  [14.048510646000068, 122.85229814000002],
  [14.051420734000033, 122.86704851400009],
  [14.052182886000026, 122.86833421600011],
  [14.052356744000065, 122.8690184720001],
  [14.053035253000075, 122.86920418500006],
  [14.053464421000058, 122.86924445900002],
  [14.053619648000051, 122.86958708700001],
  [14.053833323000049, 122.87019342000008],
  [14.054048113000022, 122.87055828500002],
  [14.05432233700003, 122.87072090200002],
  [14.054714340000032, 122.870820105],
  [14.05506659100007, 122.87116202700008],
  [14.055048175000024, 122.87169281800004],
  [14.054812454000057, 122.872094317],
  [14.054538045000072, 122.87221046800005],
  [14.05399052000007, 122.87178471400011],
  [14.053621473000021, 122.87207895000006],
  [14.053661455000054, 122.87283870600004],
  [14.053914439000039, 122.87336257900006],
  [14.054265039000029, 122.87376751300008],
  [14.054830931000026, 122.87389414400002],
  [14.054967678000025, 122.87431775800007],
  [14.055496118000065, 122.87426368600006],
  [14.055849309000052, 122.87380457900008],
  [14.056338280000034, 122.87403584000003],
  [14.057038584000054, 122.87470913200002],
  [14.057595348000063, 122.87488606600004],
  [14.058944502000031, 122.8751601890001],
  [14.05964509100005, 122.87513550000006],
  [14.06046617000004, 122.87540853300004],
  [14.06087670200003, 122.875805119],
  [14.06145727300003, 122.87702227600005],
  [14.061602548000053, 122.87699742300003],
  [14.06186903500003, 122.87714677600002],
  [14.061772460000043, 122.87752030800004],
  [14.061700092000024, 122.87799404500004],
  [14.061894589000076, 122.87851843700003],
  [14.06230699100007, 122.87844351900003],
  [14.062647569000035, 122.87901868000006],
  [14.062889473000041, 122.87918914600004],
  [14.062623638000048, 122.879508944],
  [14.062137827000072, 122.87980995300006],
  [14.062016253000024, 122.88023657400004],
  [14.062040550000063, 122.8804616430001],
  [14.062259384000072, 122.88066014600008],
  [14.062259363000067, 122.88108579700008],
  [14.061943301000042, 122.88106309600005],
  [14.061603181000066, 122.88121630600006],
  [14.061651871000038, 122.8815395040001],
  [14.061943302000032, 122.8816385240001],
  [14.06194376600007, 122.8822113870001],
  [14.061603619000039, 122.88226176300009],
  [14.06167692400004, 122.88321013300003],
  [14.061239201000035, 122.88411076500006],
  [14.060995693000052, 122.88473698700011],
  [14.061117336000052, 122.88538888200003],
  [14.061312310000062, 122.88606684600006],
  [14.061873586000047, 122.88649429600002],
  [14.062608191000038, 122.88672130000009],
  [14.063145674000054, 122.88679192500001],
  [14.063496625000028, 122.88666933900004],
  [14.063881007000077, 122.88690193600007],
  [14.06388013700007, 122.88742113800004],
  [14.063459346000059, 122.88801509900009],
  [14.063426099000026, 122.88874170400004],
  [14.06368802000003, 122.8890110100001],
  [14.06408927800004, 122.88884358400003],
  [14.064422204000039, 122.88877099700005],
  [14.064579859000048, 122.889005343],
  [14.064632390000043, 122.889185655],
  [14.064877805000037, 122.88943853700005],
  [14.065105678000066, 122.8895298010001],
  [14.065368455000055, 122.8896932560001],
  [14.065281023000068, 122.8899806500001],
  [14.065000472000065, 122.89019718400004],
  [14.06457985700007, 122.89092147500003],
  [14.064772486000038, 122.89113763000012],
  [14.065088124000056, 122.89099215700003],
  [14.065456631000075, 122.89077481000004],
  [14.06600099800005, 122.890737951],
  [14.06689782500007, 122.89135236200002],
  [14.067549663000023, 122.89182288600011],
  [14.068349148000038, 122.89217156000007],
  [14.068717973000048, 122.89206186500007],
  [14.068822965000038, 122.89153361400008],
  [14.068946684000025, 122.89126414400005],
  [14.069316024000045, 122.89128260400003],
  [14.069527007000033, 122.89112155900011],
  [14.069702900000038, 122.89133696700003],
  [14.06986196500003, 122.89187617400012],
  [14.070161516000042, 122.89211234900006],
  [14.070567180000069, 122.89231158300004],
  [14.07076070100004, 122.89256646700005],
  [14.071025076000069, 122.89276629300002],
  [14.071377779000045, 122.89283883500002],
  [14.071696623000037, 122.89274700800001],
  [14.072033513000065, 122.8925277190001],
  [14.072475638000071, 122.89230888100008],
  [14.072881702000075, 122.89218152],
  [14.073322765000057, 122.89218172100004],
  [14.073586381000041, 122.8923460640001],
  [14.073858305000044, 122.89238724800009],
  [14.07439075600007, 122.89210887100012],
  [14.074745130000053, 122.891560776],
  [14.075116295000043, 122.89143321900008],
  [14.075916109000048, 122.89135466300002],
  [14.07620128800005, 122.89158946000009],
  [14.076043673000072, 122.89251853600001],
  [14.076001919000078, 122.89292937900007],
  [14.076110223000057, 122.89378565600009],
  [14.076246555000068, 122.894136204],
  [14.076390098000047, 122.89410261000012],
  [14.076577575000044, 122.89388659500003],
  [14.076866998000071, 122.89375287100006],
  [14.077451016000055, 122.89361613500012],
  [14.077773673000024, 122.8935896590001],
  [14.078019823000034, 122.89364259900003],
  [14.078179157000022, 122.89378081400002],
  [14.078405198000041, 122.89434106500005],
  [14.078471420000028, 122.89459952100003],
  [14.078473039000073, 122.89494528000012],
  [14.078240867000034, 122.89588657600007],
  [14.078263595000067, 122.89653515500004],
  [14.078358398000034, 122.89669709200007],
  [14.078646395000021, 122.896640466],
  [14.078887791000056, 122.896292205],
  [14.078988331000062, 122.89602416200012],
  [14.079042534000052, 122.89563239200004],
  [14.079142773000058, 122.89552409800001],
  [14.079329002000065, 122.89551623000011],
  [14.079463555000075, 122.89558309600011],
  [14.079733221000026, 122.89584535600011],
  [14.079876650000074, 122.89607281000008],
  [14.07996853700007, 122.89643693300002],
  [14.080069270000024, 122.89661535800008],
  [14.080386873000066, 122.89654019800003],
  [14.080676741000048, 122.89670623200004],
  [14.081204435000075, 122.8968080200001],
  [14.081404822000025, 122.89679665000006],
  [14.081553274000044, 122.89671256600002],
  [14.082024454000077, 122.8965341500001],
  [14.082154439000021, 122.89640017300007],
  [14.082417798000051, 122.89584620000005],
  [14.082640524000055, 122.89573830000006],
  [14.082927600000062, 122.89576354500002],
  [14.083116382000071, 122.89586172200006],
  [14.083505934000073, 122.89579575000005],
  [14.083724550000056, 122.89583847500012],
  [14.085902951000037, 122.89635831700002],
  [14.090060030000075, 122.89907869900003],
  [14.089980685000057, 122.90186401300002],
  [14.089970497000024, 122.9026838960001],
  [14.089820269000029, 122.90414078600008],
  [14.089844844000027, 122.90464820800003],
  [14.089784290000068, 122.90705135000007],
  [14.089837694000039, 122.912562352],
  [14.087420261000034, 122.91670233600007],
  [14.078704884000047, 122.92002638200006],
  [14.067914114000075, 122.93024694400003],
  [14.064588093000054, 122.93129622200001]

];

const polygonCoordsBAS = [
  [14.067508729000053, 123.03237773700005],
  [14.067596802000025, 123.03212170900008],
  [14.06747394100006, 123.03194108800005],
  [14.067175507000059, 123.03216231200008],
  [14.067019045000052, 123.0324537140001],
  [14.066554541000073, 123.03270468300002],
  [14.066324733000044, 123.03272109400007],
  [14.065832367000041, 123.03261083000007],
  [14.065201470000034, 123.03254512900003],
  [14.065076333000036, 123.03238327500003],
  [14.064475758000071, 123.03211266200003],
  [14.064220993000049, 123.03186313900005],
  [14.064118517000054, 123.03169485500007],
  [14.063560671000062, 123.03146502900006],
  [14.063016283000024, 123.03118244000007],
  [14.062561834000064, 123.03090281200002],
  [14.062240674000066, 123.03085028300006],
  [14.061743561000071, 123.03061490000005],
  [14.061627118000047, 123.03043232800007],
  [14.06140150400006, 123.03032978400006],
  [14.061113764000027, 123.030351461],
  [14.060618868000063, 123.030076344],
  [14.06041751500004, 123.02988376700011],
  [14.06022058800005, 123.02983874600011],
  [14.059939171000053, 123.0299237700001],
  [14.059708698000065, 123.0300938260001],
  [14.059555900000078, 123.03029480100008],
  [14.059490392000043, 123.03055989600011],
  [14.059344030000034, 123.03080682100006],
  [14.059232432000044, 123.03088184700005],
  [14.05886692200005, 123.03091118200007],
  [14.05862675000003, 123.03084114900003],
  [14.05853699100004, 123.03074861200002],
  [14.058241017000057, 123.03081362800003],
  [14.057962791000023, 123.03064295100012],
  [14.057839064000063, 123.03070547100003],
  [14.057843923000064, 123.03045287900011],
  [14.057322656000053, 123.03020275000006],
  [14.056897210000045, 123.0300526850001],
  [14.05637891300006, 123.02990867800008],
  [14.055903772000022, 123.02980863000005],
  [14.055182458000047, 123.02970857800005],
  [14.054757669000026, 123.02968606000002],
  [14.054289272000062, 123.02969605300007],
  [14.053325972000039, 123.0295769810001],
  [14.052593220000063, 123.02951317700001],
  [14.051806154000076, 123.02946410900006],
  [14.051173962000064, 123.02927334800006],
  [14.050980997000067, 123.02905807400009],
  [14.05076159500004, 123.02897632000008],
  [14.050604755000052, 123.02865087100008],
  [14.050477637000029, 123.0288407600001],
  [14.050205360000064, 123.0291050730001],
  [14.049645794000071, 123.02942193000001],
  [14.048982441000021, 123.02951183800008],
  [14.048497331000021, 123.02967259700006],
  [14.048285857000053, 123.02971148600011],
  [14.04686977700004, 123.02952615900006],
  [14.046594092000078, 123.03001671000004],
  [14.046263662000058, 123.03015294500005],
  [14.045957018000024, 123.0304063530001],
  [14.045824846000073, 123.0304363240001],
  [14.045170082000027, 123.03014474200006],
  [14.044622253000057, 123.02995943500002],
  [14.043833649000021, 123.02973052000004],
  [14.043124469000077, 123.02957006000008],
  [14.042147790000058, 123.02941472000009],
  [14.041565570000046, 123.0293520350001],
  [14.040984282000068, 123.02931115000001],
  [14.04024914300004, 123.02930840800002],
  [14.039350245000037, 123.02935198600005],
  [14.038472285000068, 123.02946260400006],
  [14.036900138000021, 123.02971052500004],
  [14.035893950000059, 123.02995300500004],
  [14.035104660000059, 123.03015996300007],
  [14.034019464000039, 123.03049327600002],
  [14.033395401000064, 123.03070241900002],
  [14.032568899000069, 123.03102936500011],
  [14.031593196000074, 123.03144198000007],
  [14.031097186000068, 123.03169030900006],
  [14.030360047000045, 123.03216265900005],
  [14.029824751000035, 123.03253156900007],
  [14.029387128000053, 123.03298981800003],
  [14.028156581000076, 123.0339810160001],
  [14.02770711200003, 123.03436557100008],
  [14.027218230000074, 123.03469237800005],
  [14.02695956100007, 123.03480317800006],
  [14.026708562000067, 123.03482454100003],
  [14.025977262000026, 123.03466552500004],
  [14.025927438000053, 123.03473281800007],
  [14.02563069100006, 123.0346221210001],
  [14.025385086000028, 123.03447615800007],
  [14.025299119000067, 123.03429340000002],
  [14.024903170000073, 123.0251987800001],
  [14.024915157000066, 123.02071728400006],
  [14.024764724000022, 123.02069852400007],
  [14.024575604000063, 123.0205096630001],
  [14.024608645000058, 123.02013791000002],
  [14.024551604000067, 123.02007305000006],
  [14.02414815700007, 123.0202044780001],
  [14.023491732000025, 123.02038641900003],
  [14.022803968000062, 123.02066427600005],
  [14.022467947000052, 123.02061914900003],
  [14.022166076000076, 123.02068250200011],
  [14.02191928700006, 123.02052939300006],
  [14.021812211000054, 123.02029572800006],
  [14.021603704000029, 123.02006329000005],
  [14.02132259700005, 123.01990629800002],
  [14.020907744000056, 123.01983874600012],
  [14.020363279000037, 123.02002366300007],
  [14.020074627000042, 123.02003815000012],
  [14.01986538400007, 123.01996605800002],
  [14.019751495000037, 123.019842777],
  [14.01992628100004, 123.01938728400012],
  [14.02010639200006, 123.01903194800002],
  [14.02024306800007, 123.01883248200011],
  [14.020162612000036, 123.018583239],
  [14.019814793000023, 123.01821592300007],
  [14.019540327000072, 123.0180060990001],
  [14.019340061000037, 123.01793351900005],
  [14.019050225000058, 123.01793734900002],
  [14.018366817000071, 123.01823156900002],
  [14.018359659000055, 123.0183428150001],
  [14.018598449000024, 123.01875002400004],
  [14.018569427000045, 123.01903696800002],
  [14.01826559500006, 123.01932016],
  [14.017987186000028, 123.01942793300009],
  [14.017732852000051, 123.01961072000006],
  [14.01768273700003, 123.01974370800008],
  [14.01776897600007, 123.01996606600005],
  [14.017880414000047, 123.02003615800004],
  [14.018101170000023, 123.02029551700002],
  [14.018131248000032, 123.0204329820001],
  [14.018283555000039, 123.02060426600008],
  [14.01820000600003, 123.0208109990001],
  [14.018075846000045, 123.02090333400008],
  [14.017815528000028, 123.02095728800009],
  [14.01766709800006, 123.02087970000002],
  [14.017567378000024, 123.0206773110001],
  [14.017440702000044, 123.02029224000012],
  [14.017307252000023, 123.01970123000001],
  [14.017068228000028, 123.01957939300007],
  [14.016706580000061, 123.01972650700009],
  [14.016367302000049, 123.0198051320001],
  [14.016086775000076, 123.01970668800004],
  [14.015946561000021, 123.01908506600012],
  [14.015856137000071, 123.01895032300001],
  [14.015584421000028, 123.01874277800005],
  [14.015312434000066, 123.01827554300007],
  [14.015075240000044, 123.01801072100011],
  [14.014710856000022, 123.01788759500005],
  [14.014481032000049, 123.0180330180001],
  [14.014345535000075, 123.01828722300002],
  [14.014301362000026, 123.01847940100004],
  [14.01379027300004, 123.01863841700003],
  [14.013603363000072, 123.0186645010001],
  [14.013159519000055, 123.01889955200011],
  [14.012860641000032, 123.0192313020001],
  [14.012490799000034, 123.01948588400012],
  [14.012249126000029, 123.0195205330001],
  [14.012198080000076, 123.01930400100002],
  [14.012077605000059, 123.01913897200006],
  [14.011878572000057, 123.01907619100007],
  [14.011278429000072, 123.01928774900011],
  [14.011152800000048, 123.01928255300004],
  [14.010749984000029, 123.01937874100008],
  [14.01037560900005, 123.019917544],
  [14.010262201000046, 123.02021031400011],
  [14.010020261000022, 123.02027032400008],
  [14.009709817000044, 123.0204090630001],
  [14.009465221000028, 123.02039701900003],
  [14.009345139000061, 123.02025864600012],
  [14.009216741000046, 123.01998720100005],
  [14.008961888000044, 123.01918099900001],
  [14.009030920000043, 123.0188607980001],
  [14.009022246000029, 123.01852452500009],
  [14.00881709500004, 123.01841367100008],
  [14.008692464000035, 123.01864221400001],
  [14.00859704800007, 123.01892518800003],
  [14.008367994000025, 123.01935732600009],
  [14.008226663000073, 123.01938696900004],
  [14.008027610000056, 123.01928927800009],
  [14.007497731000058, 123.01877465200005],
  [14.00720543600005, 123.01883102800002],
  [14.007114387000058, 123.0190034090001],
  [14.007117151000045, 123.01935339600004],
  [14.007056994000038, 123.01946319300009],
  [14.007178199000066, 123.01979848700012],
  [14.007243297000059, 123.02024991000008],
  [14.007128417000047, 123.02029775100004],
  [14.006467894000025, 123.01993798800004],
  [14.00651149600003, 123.01973654800008],
  [14.006303183000057, 123.01928208000004],
  [14.00611912100004, 123.0192543280001],
  [14.005987117000075, 123.01904612700002],
  [14.006026299000041, 123.01885646100004],
  [14.00635251400007, 123.01870783800007],
  [14.006624156000044, 123.01840812900002],
  [14.006770025000037, 123.01843087800012],
  [14.007065211000054, 123.01825159100008],
  [14.00709037300004, 123.01814644900003],
  [14.00676204900003, 123.01788171100009],
  [14.006694014000061, 123.0177722520001],
  [14.006276489000072, 123.01765538500001],
  [14.006057987000077, 123.01769711300005],
  [14.00583356900006, 123.0178132740001],
  [14.005486622000035, 123.01784288800002],
  [14.005238072000054, 123.01775412500001],
  [14.00511565100004, 123.01744643800009],
  [14.005001207000078, 123.01733279700011],
  [14.004935222000029, 123.01710773600007],
  [14.004837014000032, 123.01711547800005],
  [14.004634993000025, 123.01735290300007],
  [14.004527408000058, 123.01755083900002],
  [14.004296561000046, 123.01765615500005],
  [14.004139126000041, 123.01759443300011],
  [14.004109803000063, 123.01745802200003],
  [14.004104178000034, 123.01688117800006],
  [14.003938928000025, 123.01678517100004],
  [14.003631381000048, 123.01687523100009],
  [14.003529162000063, 123.01685736000002],
  [14.003270318000034, 123.01663448800002],
  [14.003169310000033, 123.0164804960001],
  [14.002839154000071, 123.01619154100001],
  [14.002750057000071, 123.01615649400003],
  [14.002511041000048, 123.01646896700004],
  [14.00234869600007, 123.01654689000009],
  [14.002102656000034, 123.01650208100011],
  [14.001925479000022, 123.01639235000005],
  [14.001845640000056, 123.01624802800006],
  [14.001493682000046, 123.01596669300011],
  [14.001131715000042, 123.01612782400002],
  [14.000679938000076, 123.01611797300006],
  [14.000524301000041, 123.01595163000002],
  [14.000423788000035, 123.01574670800005],
  [14.000407774000053, 123.01544564900007],
  [14.000615125000024, 123.01527915300005],
  [14.000780147000057, 123.01528008100001],
  [14.000982833000023, 123.01537255800008],
  [14.001071592000073, 123.0152661080001],
  [14.001138369000046, 123.01490660500008],
  [14.001125502000036, 123.01475779500004],
  [14.000966743000049, 123.01444301700008],
  [14.000833845000045, 123.0143912630001],
  [14.00025697500007, 123.01462891400001],
  [14.000100923000048, 123.01497082500009],
  [13.999995598000055, 123.0158088170001],
  [14.000009250000062, 123.01629437600002],
  [13.999799695000036, 123.01631695200001],
  [13.99958267900007, 123.01610353400008],
  [13.999503235000077, 123.0159499670001],
  [13.999222119000024, 123.01568092900004],
  [13.999138233000053, 123.0155204880001],
  [13.998929029000067, 123.01532960300005],
  [13.998745180000071, 123.01533719400004],
  [13.998494404000041, 123.01552485200011],
  [13.99842290500004, 123.0156965330001],
  [13.998171755000044, 123.01599595700009],
  [13.997939402000043, 123.0160827410001],
  [13.997831263000023, 123.01598869400004],
  [13.997743022000066, 123.01578596800005],
  [13.997536680000053, 123.01509596500011],
  [13.997298087000047, 123.01452895400007],
  [13.996969891000049, 123.01395835000005],
  [13.996736354000063, 123.01378379500011],
  [13.996967253000037, 123.01357728100004],
  [13.997060852000061, 123.01343612800008],
  [13.997030577000032, 123.01310102100001],
  [13.996505437000053, 123.01298724700007],
  [13.996232426000063, 123.0129967040001],
  [13.99606504600007, 123.01305454400006],
  [13.995960360000026, 123.01327308900011],
  [13.995979958000078, 123.01368291100005],
  [13.995948095000074, 123.01387761500007],
  [13.995726927000021, 123.01398301200004],
  [13.995493673000055, 123.01387821700007],
  [13.995497155000066, 123.01358398100001],
  [13.995643812000026, 123.01334229800011],
  [13.995412456000054, 123.01327203400001],
  [13.99497976200007, 123.01345817300012],
  [13.99450231000003, 123.01326800800007],
  [13.994038652000029, 123.01280454800008],
  [13.99391788400004, 123.01286948300003],
  [13.993551746000037, 123.01378205500009],
  [13.993434227000023, 123.01413054500006],
  [13.993310331000032, 123.01429918000008],
  [13.99312741500006, 123.01426558300011],
  [13.993031536000046, 123.01407584900005],
  [13.992915511000035, 123.01367440900003],
  [13.992820172000052, 123.01355878700008],
  [13.992448867000064, 123.01356473100009],
  [13.99201057700003, 123.01373695200004],
  [13.991923370000052, 123.01383005100001],
  [13.991919188000054, 123.01400909600011],
  [13.992002299000035, 123.01413560300011],
  [13.992069609000055, 123.01458545700007],
  [13.991834528000027, 123.01485214500008],
  [13.991630040000075, 123.01487730200006],
  [13.991536411000027, 123.0148160330001],
  [13.991361664000067, 123.01447677100009],
  [13.991033209000022, 123.014077186],
  [13.990779762000045, 123.01365458200007],
  [13.990721533000055, 123.01349297400009],
  [13.990589914000054, 123.01349805200005],
  [13.99043479900007, 123.01374714000008],
  [13.99038383800007, 123.01394120400005],
  [13.990202258000068, 123.01416374700011],
  [13.990094290000059, 123.01437335100002],
  [13.989820080000072, 123.01454614400006],
  [13.989447184000028, 123.01464664700006],
  [13.989053468000066, 123.01485612200008],
  [13.988897941000062, 123.01513148400011],
  [13.988784491000047, 123.0149663520001],
  [13.98856760800004, 123.01492443100005],
  [13.988607341000034, 123.01461324000002],
  [13.988724064000053, 123.01427665500012],
  [13.988705323000033, 123.0140996990001],
  [13.988610593000033, 123.01389526900005],
  [13.988464056000055, 123.01336264000008],
  [13.988267295000071, 123.0131468290001],
  [13.988111970000034, 123.01324073900003],
  [13.988039232000062, 123.01343569100004],
  [13.988071002000027, 123.01383094700009],
  [13.988021587000048, 123.01399435100006],
  [13.987878884000054, 123.01398083800007],
  [13.987479048000068, 123.01373548000004],
  [13.987254875000076, 123.01355623200004],
  [13.987198241000044, 123.01373661600007],
  [13.986845318000064, 123.01413447000004],
  [13.986804241000073, 123.01428658900011],
  [13.986828735000074, 123.01469827800008],
  [13.98658647600007, 123.01485003700009],
  [13.986289015000068, 123.01466274500001],
  [13.98608944800003, 123.01440225700003],
  [13.985817174000033, 123.01438391300007],
  [13.985482902000058, 123.01466572100003],
  [13.985297794000076, 123.01846254500003],
  [13.983439930000031, 123.01607253600002],
  [13.982231971000033, 123.02252152000005],
  [13.979622125000049, 123.02526050100005],
  [13.977302257000076, 123.02868148200002],
  [13.986863609000068, 123.03259253600004],
  [13.986566649000054, 123.03462102900005],
  [13.986077714000032, 123.03471711300006],
  [13.985673466000037, 123.03487159300005],
  [13.985233494000056, 123.03488156500009],
  [13.984816033000072, 123.03479450100008],
  [13.984189560000061, 123.03457976600009],
  [13.983951227000034, 123.03480036100007],
  [13.983909423000057, 123.03508922500009],
  [13.983914040000059, 123.03537692600003],
  [13.98378393300004, 123.03583532100004],
  [13.983610526000064, 123.0358380130001],
  [13.983480278000059, 123.03563743500001],
  [13.98328125100005, 123.03543842600004],
  [13.983113638000077, 123.03504799300003],
  [13.982730885000024, 123.03474747300004],
  [13.981967616000077, 123.03426357800004],
  [13.981517709000059, 123.03427389900003],
  [13.981306428000039, 123.03432153100005],
  [13.980956455000069, 123.03446221400009],
  [13.980446123000036, 123.03431248400011],
  [13.980283715000041, 123.03430089400001],
  [13.980390401000022, 123.03499117900003],
  [13.980283539000027, 123.03556464300004],
  [13.98015085000003, 123.03577699400012],
  [13.979867003000038, 123.03597693000006],
  [13.979509091000068, 123.03599458400004],
  [13.97920238000006, 123.03588847800006],
  [13.978826039000069, 123.03595763600003],
  [13.978589334000048, 123.0364188420001],
  [13.978585235000025, 123.03673652200007],
  [13.978469032000078, 123.03727070900004],
  [13.978292900000042, 123.037476136],
  [13.97773763500004, 123.03770197600011],
  [13.977520137000056, 123.03772037500005],
  [13.977321048000022, 123.03767845200002],
  [13.977154319000022, 123.03775754500009],
  [13.97701019300007, 123.0379268370001],
  [13.976557488000026, 123.03822943000011],
  [13.976286066000057, 123.03895213100009],
  [13.976086678000058, 123.0391675520001],
  [13.975477307000062, 123.04048345700005],
  [13.974221378000038, 123.04233544700003],
  [13.972808463000035, 123.04363043500007],
  [13.971238560000074, 123.04455542500011],
  [13.969040705000054, 123.04437041100005],
  [13.970923560000074, 123.04808041800004],
  [13.969824619000065, 123.0503004090001],
  [13.969352620000052, 123.05530939900007],
  [13.96715475600007, 123.05660438400002],
  [13.966212807000034, 123.0584543760001],
  [13.96511386700007, 123.06048936600007],
  [13.963072994000072, 123.06159935300002],
  [13.961660076000044, 123.06344934100002],
  [13.960247164000066, 123.06418933200007],
  [13.957892313000059, 123.06492931600008],
  [13.954124558000046, 123.06511129300009],
  [13.949131925000074, 123.05813527200007],
  [13.947150054000076, 123.05825925900001],
  [13.944958193000048, 123.0589992460001],
  [13.94391525900005, 123.05936823800005],
  [13.943289297000035, 123.0598612340001],
  [13.942247365000071, 123.05998522700008],
  [13.94224736800004, 123.05936922900003],
  [13.942038388000071, 123.05838322800003],
  [13.941204446000029, 123.05776722400003],
  [13.940474493000067, 123.05789021900011],
  [13.940057526000032, 123.05690421800011],
  [13.940057534000061, 123.0555492200001],
  [13.93850864600006, 123.05367721200003],
  [13.93694176200006, 123.0513332060001],
  [13.935585869000022, 123.04825020200008],
  [13.934866933000023, 123.0451812010001],
  [13.933616007000069, 123.0466631920001],
  [13.932051095000077, 123.04912917900003],
  [13.930800170000055, 123.05011617100001],
  [13.930071207000026, 123.0520911640001],
  [13.929446238000025, 123.05369315700011],
  [13.928299312000036, 123.05394015000002],
  [13.92663142200007, 123.05369314000006],
  [13.926318451000043, 123.05233714000008],
  [13.926110469000037, 123.05159813900002],
  [13.925588503000029, 123.05159813600005],
  [13.92475455400006, 123.05221513100003],
  [13.924545562000048, 123.05320112800007],
  [13.923607618000062, 123.05381712100007],
  [13.922393706000037, 123.05254811500004],
  [13.918109996000055, 123.0509100910001],
  [13.91684807100006, 123.05218208200006],
  [13.915419171000053, 123.05113207500006],
  [13.914126245000034, 123.05280106500004],
  [13.91321630400006, 123.05303105900009],
  [13.911953392000044, 123.05195205200005],
  [13.910873451000043, 123.05407804300012],
  [13.909793510000043, 123.05598903400005],
  [13.907481651000069, 123.05771401800007],
  [13.906702719000066, 123.05482801700009],
  [13.906853727000055, 123.05175802200006],
  [13.908558621000054, 123.05069403400012],
  [13.909472575000052, 123.04853304200003],
  [13.909354595000025, 123.04638804400008],
  [13.908097683000051, 123.04528703800008],
  [13.907944706000023, 123.04294704000006],
  [13.90809771000005, 123.04078704300002],
  [13.90839670400004, 123.03844104900008],
  [13.907335785000043, 123.03645804400003],
  [13.906109873000048, 123.03501903900008],
  [13.904743964000033, 123.03484303100004],
  [13.904276982000056, 123.03699902500011],
  [13.901993136000044, 123.03609901200002],
  [13.89975927300003, 123.037747996],
  [13.89854334000006, 123.03990798600012],
  [13.897405397000057, 123.04293997500008],
  [13.896639470000025, 123.03898097600006],
  [13.895878537000044, 123.03592097500007],
  [13.894330656000022, 123.03286097000012],
  [13.893139752000025, 123.02979896700003],
  [13.89283179000006, 123.02673896800002],
  [13.892837805000056, 123.02403997300007],
  [13.892809828000054, 123.02042397600007],
  [13.892494868000028, 123.01711398000009],
  [13.89353281600006, 123.0144619890001],
  [13.89268188400007, 123.01221398700011],
  [13.890612029000067, 123.01057497500005],
  [13.887325256000054, 123.00853495800004],
  [13.884730430000047, 123.00792294300004],
  [13.881269652000071, 123.00853492100009],
  [13.879259786000034, 123.00822191000009],
  [13.887083331000042, 122.9983289700001],
  [13.891340081000067, 122.9935150020001],
  [13.896254594000027, 122.98670266900001],
  [13.897980517000065, 122.98449074100006],
  [13.918252184000039, 122.95698706100006],
  [13.917876234000062, 122.95538050300001],
  [13.917822288000025, 122.954332883],
  [13.918461408000042, 122.95306168700006],
  [13.919741462000047, 122.95086083500007],
  [13.919315148000067, 122.948912231],
  [13.919956576000061, 122.9476964160001],
  [13.919475087000023, 122.94693252800005],
  [13.919446552000068, 122.946346962],
  [13.919786592000037, 122.94621312200002],
  [13.920274506000055, 122.9461252970001],
  [13.920881429000076, 122.94607179700006],
  [13.921250770000029, 122.94574729200008],
  [13.921653622000065, 122.94525985100006],
  [13.921699297000032, 122.94489340000007],
  [13.921571284000038, 122.94439012900011],
  [13.921667761000037, 122.94368265800006],
  [13.92181170300006, 122.9429983980001],
  [13.921925459000022, 122.94265867500008],
  [13.922166867000044, 122.94229343500001],
  [13.922559113000034, 122.94149387800007],
  [13.922882583000046, 122.94104603700009],
  [13.922917850000033, 122.94065676800005],
  [13.922652502000062, 122.94045602800009],
  [13.922190035000028, 122.94016024000007],
  [13.921823827000026, 122.93973122200009],
  [13.921315249000031, 122.93901622500005],
  [13.921453850000034, 122.93831526400004],
  [13.921778550000056, 122.93800681400012],
  [13.92186022900006, 122.93769959600002],
  [13.921931340000071, 122.93712592600002],
  [13.922062736000044, 122.9364983910001],
  [13.922040757000048, 122.93592525100007],
  [13.921855101000062, 122.93557880300011],
  [13.921655659000066, 122.93531151500008],
  [13.921271404000038, 122.93509736100009],
  [13.920838865000064, 122.93509871900005],
  [13.920559690000061, 122.93501293800011],
  [13.92049015300006, 122.93471289000001],
  [13.920722890000036, 122.93457062100003],
  [13.92105067700004, 122.93451030200004],
  [13.921144785000024, 122.93424974800007],
  [13.921133957000052, 122.93385481400003],
  [13.920923905000052, 122.93353323200006],
  [13.920760618000031, 122.9330906450001],
  [13.920761308000067, 122.9327183470001],
  [13.920844275000036, 122.93228527700012],
  [13.921360451000055, 122.93201857400004],
  [13.921874957000057, 122.93186069300009],
  [13.922132619000024, 122.93163834600011],
  [13.922216245000072, 122.93122072200003],
  [13.92221913800006, 122.93013329700011],
  [13.922280360000059, 122.92942160200005],
  [13.922242766000068, 122.92897968800003],
  [13.922099048000064, 122.92858871300007],
  [13.921913442000061, 122.92846333500006],
  [13.921589894000022, 122.9283874140001],
  [13.921038649000025, 122.92853637000007],
  [13.920689289000052, 122.92850561400007],
  [13.920438687000058, 122.92836363100002],
  [13.920268638000039, 122.92809870200006],
  [13.92008217800003, 122.92768320200003],
  [13.919911339000066, 122.9274085400001],
  [13.91962069400006, 122.9271722310001],
  [13.919327576000057, 122.92687137400003],
  [13.919010267000033, 122.92667827000002],
  [13.918920770000057, 122.9264771930001],
  [13.91897724100005, 122.92622626700006],
  [13.919146055000056, 122.92574046200002],
  [13.919486692000078, 122.92493757800003],
  [13.919666286000052, 122.92438440400008],
  [13.91974896000005, 122.9239657380001],
  [13.920034225000052, 122.92371483200009],
  [13.920286151000028, 122.92381620100002],
  [13.920649015000038, 122.92424495000012],
  [13.92093503700005, 122.92424593700002],
  [13.921101760000056, 122.92414485100005],
  [13.921227914000042, 122.92371209300006],
  [13.92163473100004, 122.92317923900009],
  [13.921879109000031, 122.92305734800004],
  [13.922154160000048, 122.9230904420001],
  [13.92236697100003, 122.92323022900007],
  [13.922391007000044, 122.92367344100012],
  [13.922559905000071, 122.92408140500004],
  [13.922796357000038, 122.92437587400002],
  [13.923102261000054, 122.92465281400007],
  [13.923468497000044, 122.92490985600011],
  [13.923843327000043, 122.92501281500006],
  [13.924317105000057, 122.92524140100011],
  [13.92459318400006, 122.9254096740001],
  [13.924937554000053, 122.92554095300011],
  [13.925287842000046, 122.9257253720001],
  [13.925616926000032, 122.9257336930001],
  [13.926059534000046, 122.92565058000002],
  [13.926565492000066, 122.92509062500005],
  [13.92673523600007, 122.92459973200005],
  [13.92701973800007, 122.92438361400002],
  [13.927349166000056, 122.92428415400002],
  [13.927750349000064, 122.92424381500007],
  [13.928509020000035, 122.92446326700008],
  [13.92880137000003, 122.92472880300011],
  [13.929111205000027, 122.92493393600012],
  [13.929393145000063, 122.92506970000011],
  [13.929670817000044, 122.92499613900009],
  [13.929950189000067, 122.92484133300002],
  [13.930516348000026, 122.92431477000002],
  [13.931043802000033, 122.92405648300007],
  [13.931359694000037, 122.92394904200012],
  [13.931669793000026, 122.92399562800006],
  [13.932024171000023, 122.92409945400004],
  [13.932267925000076, 122.92442017400003],
  [13.932491941000023, 122.92517350500009],
  [13.932756845000029, 122.92541940800004],
  [13.933176457000059, 122.9256313200001],
  [13.933504740000046, 122.9255885120001],
  [13.933792653000069, 122.92549251300011],
  [13.93399826600006, 122.92524811200008],
  [13.93432000100006, 122.92462242900001],
  [13.934418014000073, 122.92415061300005],
  [13.934627222000074, 122.92400237900006],
  [13.934987057000058, 122.92383142500012],
  [13.935386694000044, 122.92380483600004],
  [13.93600007200007, 122.92385595500002],
  [13.93648588800005, 122.9238411340001],
  [13.936843948000046, 122.92387406000012],
  [13.937194400000067, 122.92404471800012],
  [13.937348782000072, 122.92432674200006],
  [13.937720640000066, 122.92448373800005],
  [13.937945274000072, 122.92434125600005],
  [13.937919541000042, 122.9240351740001],
  [13.937649903000022, 122.92368392500009],
  [13.937025749000043, 122.92329597600008],
  [13.936728779000077, 122.92304225900011],
  [13.936583829000028, 122.92284513100003],
  [13.936572030000036, 122.92248369100002],
  [13.936788370000045, 122.92203945000006],
  [13.936944936000032, 122.92187690700007],
  [13.93731060400006, 122.92166983600009],
  [13.937657563000073, 122.92153024600009],
  [13.937997515000063, 122.92129362300011],
  [13.938259487000039, 122.92116434800005],
  [13.938515966000068, 122.92109169100002],
  [13.938803076000056, 122.9206084220001],
  [13.93905779000005, 122.91975988600007],
  [13.93907510200006, 122.9187455870001],
  [13.938807030000078, 122.91742029300008],
  [13.938465289000021, 122.91643492600008],
  [13.939071395000042, 122.91523747400004],
  [13.939410483000074, 122.9138506910001],
  [13.939665857000023, 122.91333867600008],
  [13.941751433000036, 122.91671363900002],
  [13.941897941000036, 122.91685378000011],
  [13.94211548800007, 122.91972185200007],
  [13.942420208000044, 122.91962621700009],
  [13.94276459300005, 122.91983099600009],
  [13.94297664100003, 122.92039033100002],
  [13.943136553000045, 122.92090809400008],
  [13.94320360200004, 122.92193543100007],
  [13.943389423000042, 122.9224418440001],
  [13.943800767000027, 122.9228672270001],
  [13.944198038000025, 122.92320930300002],
  [13.944555507000075, 122.92334588900007],
  [13.944859919000066, 122.92363251200004],
  [13.944952476000026, 122.92395996100004],
  [13.94523543400004, 122.92429495200008],
  [13.945618730000035, 122.92447163300005],
  [13.94604184900004, 122.92451131700011],
  [13.946306712000023, 122.92479783300007],
  [13.946598430000051, 122.9251668280001],
  [13.947063018000051, 122.9254266480001],
  [13.947501393000039, 122.92563184800008],
  [13.948046155000043, 122.92567303500005],
  [13.948586567000063, 122.92604276800012],
  [13.949075204000053, 122.92642482200006],
  [13.949255685000026, 122.92666543900009],
  [13.949615398000049, 122.9269151530001],
  [13.949932250000074, 122.92703768900003],
  [13.950356260000035, 122.92698354700008],
  [13.951632808000056, 122.92702094700007],
  [13.952122903000031, 122.92693936300009],
  [13.952521789000059, 122.9266120960001],
  [13.952855871000054, 122.92624308400002],
  [13.953429506000077, 122.925317188],
  [13.954027021000059, 122.92468847900011],
  [13.954265754000062, 122.92452458600008],
  [13.954689804000054, 122.9244699730001],
  [13.955034204000071, 122.92448362700009],
  [13.955656572000066, 122.9244426890001],
  [13.95609337600007, 122.92446998800006],
  [13.956219824000073, 122.92459280300011],
  [13.956470898000077, 122.92497494800011],
  [13.956749056000035, 122.92505680300007],
  [13.957278975000065, 122.92508399300004],
  [13.957570318000023, 122.92512487500005],
  [13.957710199000076, 122.92537703500011],
  [13.957696307000049, 122.9257581490001],
  [13.957576580000023, 122.92609859600009],
  [13.957351310000035, 122.926385],
  [13.957046453000032, 122.92661671000008],
  [13.956913990000032, 122.92697152400001],
  [13.95694071400004, 122.92751758000009],
  [13.956543018000048, 122.927749274],
  [13.956185163000043, 122.92824039800007],
  [13.955933229000038, 122.92841768500011],
  [13.955986438000025, 122.92869069100004],
  [13.955722044000026, 122.92875891800009],
  [13.955353827000067, 122.92874548800012],
  [13.955211236000025, 122.92890935600008],
  [13.955451842000059, 122.92929107200007],
  [13.955807527000047, 122.92940016200009],
  [13.95662363100007, 122.92948228500006],
  [13.957511194000062, 122.92945520700005],
  [13.957961623000074, 122.92991883900004],
  [13.958505786000046, 122.93021836800006],
  [13.959180681000078, 122.93038213900002],
  [13.960241952000047, 122.930586779],
  [13.960480234000045, 122.93087282700003],
  [13.960665473000063, 122.93092731900003],
  [13.965688259000046, 122.939351009],
  [13.969156156000054, 122.93712164700003],
  [13.970685500000059, 122.93475776200012],
  [13.970701203000033, 122.93459678400006],
  [13.970841688000064, 122.93443606300002],
  [13.971031673000027, 122.93436506300009],
  [13.971305426000072, 122.93442073800009],
  [13.971438455000055, 122.93432387200005],
  [13.971485375000043, 122.93413868800008],
  [13.971321347000071, 122.9336396870001],
  [13.971153333000075, 122.93275024900004],
  [13.970950982000033, 122.93230974900007],
  [13.970667424000055, 122.93207877300006],
  [13.970019304000061, 122.93193105700004],
  [13.969938586000069, 122.93170062700005],
  [13.97001980500005, 122.93151233700007],
  [13.97036410800007, 122.93151282600002],
  [13.970748981000042, 122.93161809300011],
  [13.97111381700006, 122.93168148500001],
  [13.971478920000038, 122.93164013300009],
  [13.972250554000027, 122.93109627700005],
  [13.972575566000046, 122.9309499100001],
  [13.973022694000065, 122.93065684500004],
  [13.97326694800006, 122.93017477500007],
  [13.973348197000064, 122.92973462000009],
  [13.973368565000044, 122.92914756400012],
  [13.973465611000051, 122.92906401300002],
  [13.97379203500003, 122.92895224200004],
  [13.974037053000075, 122.92867251700011],
  [13.977853422000067, 122.9254816030001],
  [13.977906315000041, 122.925304331],
  [13.978355837000038, 122.92511377000005],
  [13.978448350000065, 122.92485477000002],
  [13.978276489000052, 122.9245546190001],
  [13.97799873200006, 122.92443169900002],
  [13.978170653000063, 122.92424094400008],
  [13.97839543400005, 122.92418661400006],
  [13.978421857000058, 122.92400940900006],
  [13.978276319000031, 122.92387301200006],
  [13.977945544000022, 122.9237910820001],
  [13.978038016000028, 122.9234777150001],
  [13.978276081000047, 122.92338246400004],
  [13.978275053000061, 122.92320960800009],
  [13.978170069000043, 122.9229736960001],
  [13.97806421100006, 122.92287828000008],
  [13.978130289000035, 122.92275572400001],
  [13.978381480000053, 122.92255156700003],
  [13.97869888100007, 122.92256537800006],
  [13.978883919000054, 122.92240206500003],
  [13.979095200000074, 122.9219664630001],
  [13.97908420300007, 122.92164222500003],
  [13.979157676000057, 122.92143824800007],
  [13.97908637200004, 122.92129710900008],
  [13.979173848000073, 122.92116552700008],
  [13.979204723000066, 122.92094793800004],
  [13.979165027000022, 122.92068844900007],
  [13.97929881500005, 122.92061787900002],
  [13.979470828000046, 122.92035397900008],
  [13.979359093000028, 122.92000554200001],
  [13.97923118600005, 122.91981735700006],
  [13.97928631800005, 122.91946377500005],
  [13.979650582000033, 122.91922807300011],
  [13.979709195000055, 122.91894338300006],
  [13.97960871600003, 122.91868381300003],
  [13.979374266000036, 122.9184158700001],
  [13.979349146000061, 122.91782974400007],
  [13.985431296000058, 122.89761658600003],
  [13.988787090000073, 122.886444],
  [13.990212684000028, 122.8864647480001],
  [13.990104841000061, 122.88605123500008],
  [13.990184605000024, 122.88591443900009],
  [13.990584910000052, 122.88575259800007],
  [13.991147307000062, 122.88586703400006],
  [13.99173214700005, 122.88605841600008],
  [13.992055621000077, 122.88619969600006],
  [13.992081437000024, 122.88578722900002],
  [13.992161754000051, 122.88559549000001],
  [13.992323230000068, 122.88548704800007],
  [13.992564816000026, 122.88504898000008],
  [13.992239808000022, 122.88460631400005],
  [13.992670048000036, 122.88452639900004],
  [13.992990829000064, 122.88423836100003],
  [13.993330307000065, 122.88406235700006],
  [13.993482854000035, 122.88350332400012],
  [13.993165551000061, 122.88312228900008],
  [13.99285295900006, 122.88288604900004],
  [13.99258446600004, 122.88255280600004],
  [13.99279387300004, 122.88202757200008],
  [13.993325838000032, 122.8817275340001],
  [13.993768571000032, 122.8817444450001],
  [13.994056236000063, 122.88133014900006],
  [13.994038432000025, 122.88079232100006],
  [13.993899948000035, 122.88034933300003],
  [13.993523543000038, 122.8800985040001],
  [13.993889169000056, 122.87960269300004],
  [13.994143645000065, 122.87931738600003],
  [13.994271640000022, 122.87888418900002],
  [13.994110472000045, 122.87843128600002],
  [13.993974078000065, 122.87823197600005],
  [13.994068653000056, 122.87764666300006],
  [13.994052329000056, 122.87720992900006],
  [13.994095498000036, 122.87686732800012],
  [13.994330526000056, 122.87665977400002],
  [13.994742753000025, 122.87668018000011],
  [13.995093500000053, 122.8770785690001],
  [13.995466057000044, 122.87761809100004],
  [13.995797401000061, 122.87797803400008],
  [13.996243418000063, 122.8779681640001],
  [13.99647275700005, 122.87751222600002],
  [13.996513372000038, 122.87729537500002],
  [13.997139136000044, 122.87716033800007],
  [13.997177102000023, 122.87686250600007],
  [13.99692475300003, 122.87650488600002],
  [13.996439365000072, 122.87610755000003],
  [13.997165893000044, 122.87590904800004],
  [13.997348831000068, 122.87537456900009],
  [13.99720492800003, 122.874664402],
  [13.99702287200006, 122.87405543900002],
  [13.99681191600007, 122.87401627800011],
  [13.996637833000023, 122.87387916500006],
  [13.996803635000049, 122.87346835700009],
  [13.99815620000004, 122.87331167600007],
  [13.998285964000047, 122.873116535],
  [13.998256152000067, 122.87257154400004],
  [13.997033853000062, 122.87233917300011],
  [13.996733824000046, 122.87221889200009],
  [13.99663590800003, 122.87186816000008],
  [13.996946309000066, 122.8716871370001],
  [13.997626068000045, 122.87146183100003],
  [13.99817880200004, 122.87141559600002],
  [13.999144932000036, 122.871388765],
  [13.999256795000065, 122.87127217800003],
  [13.99908554800004, 122.87076474700007],
  [13.99902938300005, 122.87070584600008],
  [13.998477566000076, 122.87083406600004],
  [13.998105911000039, 122.87082906800003],
  [13.99751629900004, 122.87074927600008],
  [13.996764169000073, 122.87085448800008],
  [13.996687741000073, 122.87071761700008],
  [13.996843721000062, 122.86998783600006],
  [13.997149103000027, 122.86990862500011],
  [13.997299741000063, 122.86947878100011],
  [13.996934836000037, 122.86903233500004],
  [13.996301174000052, 122.868615266],
  [13.995917399000064, 122.86845355700007],
  [13.995803324000065, 122.86828071500008],
  [13.995803258000024, 122.86814471800005],
  [13.995916661000024, 122.86789136700008],
  [14.002261568000051, 122.86809883600006],
  [14.012356904000058, 122.86911989700002],
  [14.013678797000068, 122.87251090100006],
  [14.017333534000045, 122.87677291800003],
  [14.02225018200005, 122.88207594200003],
  [14.025761928000065, 122.88646295800004],
  [14.028320739000037, 122.890256969],
  [14.031185531000062, 122.8938099830001],
  [14.03517424200004, 122.89864500100009],
  [14.038002038000059, 122.90212601400003],
  [14.040877824000063, 122.90683841500004],
  [14.045144723000021, 122.9137678840001],
  [14.048619678000023, 122.91670569100006],
  [14.049133836000067, 122.91715093100004],
  [14.052411994000067, 122.91993108100007],
  [14.053957861000072, 122.91864712300003],
  [14.054428818000076, 122.91869253000004],
  [14.054573016000063, 122.91888082700007],
  [14.054833880000047, 122.91899499600004],
  [14.05507297500003, 122.91897755700006],
  [14.05519389400007, 122.91877043800002],
  [14.05585506500006, 122.919889],
  [14.05594366300005, 122.92046379600004],
  [14.055946257000073, 122.92113073300004],
  [14.056184683000026, 122.921851091],
  [14.056011787000045, 122.9221070110001],
  [14.056016601000067, 122.92226392600003],
  [14.056117869000047, 122.92238956800009],
  [14.056142668000064, 122.92273513700002],
  [14.056010107000077, 122.92306504100009],
  [14.05608485700003, 122.92389031000005],
  [14.056150334000051, 122.92426860100011],
  [14.057448630000067, 122.92610010600004],
  [14.059313495000026, 122.928378113],
  [14.064588093000054, 122.93129622200001],
  [14.072799109000073, 122.9383692240001],
  [14.073000707000062, 122.9386313010001],
  [14.073459501000059, 122.938929991],
  [14.074289484000076, 122.94011770400004],
  [14.07439719100006, 122.94024064400003],
  [14.074757285000032, 122.94123320400001],
  [14.074977133000061, 122.9416344120001],
  [14.075292022000042, 122.94198270100003],
  [14.075791180000067, 122.94247306600005],
  [14.076147872000035, 122.94302386400011],
  [14.076389491000043, 122.94354084100007],
  [14.076483296000049, 122.94415116400012],
  [14.076478050000048, 122.94441605800012],
  [14.07970658000005, 122.94749001500008],
  [14.088275070000066, 122.95709736800006],
  [14.088153660000046, 122.95745552500011],
  [14.085735727000042, 122.96491713400007],
  [14.087218404000055, 122.97424538100006],
  [14.086301219000063, 122.9801619640001],
  [14.08747386300007, 122.99062327100012],
  [14.088577757000053, 122.99685319000002],
  [14.08646264600003, 122.99699962200009],
  [14.084704397000053, 122.99695530800011],
  [14.083544158000052, 122.99649992700006],
  [14.082366187000048, 122.99641474900011],
  [14.081663390000074, 122.99624416300003],
  [14.081143366000049, 122.99680610300004],
  [14.080896064000058, 122.99733296200009],
  [14.080756285000064, 122.99770929100009],
  [14.080573496000056, 122.99805336300005],
  [14.080369204000021, 122.99820389500007],
  [14.08003588400004, 122.99823615100001],
  [14.07993911400007, 122.99868774600009],
  [14.079820839000035, 122.99891354300007],
  [14.079863848000059, 122.99942965200012],
  [14.080218672000058, 122.99952642100004],
  [14.080734780000057, 123.00025757500009],
  [14.080724028000077, 123.0012360290001],
  [14.08062725800005, 123.00211771400006],
  [14.080476726000029, 123.00265532600008],
  [14.080433717000062, 123.00331121400006],
  [14.080519735000053, 123.00363378200007],
  [14.08054124000006, 123.00425741100003],
  [14.080455222000069, 123.00512834300002],
  [14.080326195000055, 123.00594551400002],
  [14.080272433000061, 123.00684870300006],
  [14.080089645000044, 123.00761211300005],
  [14.079908543000045, 123.00804517400002],
  [14.079919496000059, 123.00871037500008],
  [14.079770755000027, 123.0085989700001],
  [14.079535581000073, 123.00861800500002],
  [14.079411705000041, 123.00872933900007],
  [14.079451610000035, 123.00931080200007],
  [14.079439187000048, 123.00957646100005],
  [14.079205109000043, 123.00993109900003],
  [14.078745831000049, 123.01021940200008],
  [14.078297687000031, 123.010617797],
  [14.078030160000026, 123.01088813900003],
  [14.077814892000049, 123.01105861100007],
  [14.077586391000068, 123.01109217100009],
  [14.07699745900004, 123.0111042420001],
  [14.076729346000036, 123.01103049700009],
  [14.076569033000055, 123.01091136000002],
  [14.076389043000063, 123.01055632700002],
  [14.076150407000057, 123.00993727500008],
  [14.076038437000022, 123.00976420000006],
  [14.075764739000022, 123.00962036200008],
  [14.075468034000039, 123.00960747200008],
  [14.075119931000074, 123.00976083300009],
  [14.074873835000062, 123.00992660000009],
  [14.074043048000021, 123.01115210000012],
  [14.073831640000037, 123.01219174500011],
  [14.071266268000045, 123.0266313940001],
  [14.071061753000038, 123.02825571100004],
  [14.070014988000025, 123.02940671300007],
  [14.069770798000036, 123.02949031000003],
  [14.069491664000054, 123.02969367300011],
  [14.069189427000026, 123.02978941200001],
  [14.068887664000044, 123.0299334770001],
  [14.068921878000026, 123.03036428900009],
  [14.068875379000076, 123.03089146100001],
  [14.068890084000031, 123.0315691620001],
  [14.06814987100006, 123.03190200200004],
  [14.067709593000075, 123.03235682700006],
  [14.067463048000036, 123.03270534300009],
  [14.067508729000053, 123.03237773700005]
];

export default App;
